import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MatToggle, MatToggleExp, SidenavContent } from './sidenav.types';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
}) export class SidenavService {

  // Events
  private sidenavContentSource = new Subject<SidenavContent[]>();
  private sidenavToggleSource = new Subject<MatToggle>();
  private expansionToggleSource = new Subject<MatToggleExp>();
  private showMenuIconSource = new Subject<boolean>();

  public sidenavContent$ = this.sidenavContentSource.asObservable();
  public sidenavToggle$ = this.sidenavToggleSource.asObservable();
  public expansionToggle$ = this.expansionToggleSource.asObservable();
  public showMenuIcon$ = this.showMenuIconSource.asObservable();

  public passSidenavContent(sidenavContent: SidenavContent[]) {
    this.sidenavContentSource.next(sidenavContent);
  }
  public passSidenavToggle(sidenavToggle: MatToggle) {
    this.sidenavToggleSource.next(sidenavToggle);
  }
  public passExpansionToggle(expansionToggle: MatToggleExp) {
    this.expansionToggleSource.next(expansionToggle);
  }
  public passMenuIconToggle(showMenuIcon: boolean) {
    this.showMenuIconSource.next(showMenuIcon);
  }

  // Methods
  public scrollIntoView(element: string) {
    const scrollToElement = document.getElementById(element);
    if (!scrollToElement) { return; }
    scrollToElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  }
  public checkForScrollHash() {
    const hashSplit = this.router.url.split('#');

    if (hashSplit.length > 1) {
      const hash = decodeURI(hashSplit[1]);
      this.scrollIntoView(hash);
    }
  }

  constructor(
    private router: Router,
  ) { }


}
