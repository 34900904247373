import { ProductPlantDbServer } from '../../../server/src/routes/product/types';
import { ApiService } from '../services/api.service';
import { Product } from './product.class';

export class SevesoDb {

  static api: ApiService;

  dbRef = '';
  reference = '';
  gevaarlijkeStoffen = '';
  gevarencategorieën = '';
  lagedrempelinrichtingen = 0;
  hogedrempelinrichtingen = 0;
  indeling = '';

  public saveSeveso() {
    if (!this.dbRef) { return SevesoDb.api.addSeveso(this); }
    return SevesoDb.api.updateSeveso(this);
  }

  public deleteSeveso() {
    if (!this.dbRef) { throw new Error('No dbRef present'); }
    return SevesoDb.api.deleteSeveso(this.dbRef);
  }

  constructor(api: ApiService, input?: SevesoDb) {
    if (input) {
      Object.keys(input).forEach(key => {
        if (this[key] !== undefined) { this[key] = input[key]; }
      });
    }

    SevesoDb.api = api;
  }

}

export class Seveso {

  sevesoIndeling: string[] = [];
  gezondheidsgevarenLaag = 0;
  gezondheidsgevarenHoog = 0;
  fysischeGevarenLaag = 0;
  fysischeGevarenHoog = 0;
  milieugevarenLaag = 0;
  milieugevarenHoog = 0;
  coefficientLaagGezondheid = 0;
  coefficientHoogGezondheid = 0;
  coefficientLaagFysisch = 0;
  coefficientHoogFysisch = 0;
  coefficientLaagMilieu = 0;
  coefficientHoogMilieu = 0;

  private buildSevesoData(sevesoList: SevesoDb[], product: Product, plant: ProductPlantDbServer) {

    const casSeveso = sevesoList.find(y => product.casNummer === y.reference);
    const dangerousSeveso = sevesoList.find(y => product.opmerkingenSeveso === y.reference);

    this.sevesoIndeling = [...new Set(sevesoList
      .filter(y =>
        product.hNr.some(z => z.split('+').map(a => a.trim()).some(b => b === y.reference)) ||
        product.pNr.some(z => z.split('+').map(a => a.trim()).some(b => b === y.reference)) ||
        product.casNummer === y.reference)
      .map(a => a.indeling))];

    if (dangerousSeveso) { this.sevesoIndeling.push(dangerousSeveso.indeling); }

    this.sevesoIndeling.forEach(y => {
      const findSeveso = sevesoList.find(z => z.indeling === y);

      if (findSeveso && y.startsWith('H')) {
        if (casSeveso) {
          this.gezondheidsgevarenLaag = (dangerousSeveso || casSeveso).lagedrempelinrichtingen;
          this.gezondheidsgevarenHoog = (dangerousSeveso || casSeveso).hogedrempelinrichtingen;
        } else {
          this.gezondheidsgevarenLaag = (dangerousSeveso || findSeveso).lagedrempelinrichtingen;
          this.gezondheidsgevarenHoog = (dangerousSeveso || findSeveso).hogedrempelinrichtingen;
        }
      }
      if (findSeveso && y.startsWith('P')) {
        if (casSeveso) {
          this.fysischeGevarenLaag = (dangerousSeveso || casSeveso).lagedrempelinrichtingen;
          this.fysischeGevarenHoog = (dangerousSeveso || casSeveso).hogedrempelinrichtingen;
        } else {
          this.fysischeGevarenLaag = (dangerousSeveso || findSeveso).lagedrempelinrichtingen;
          this.fysischeGevarenHoog = (dangerousSeveso || findSeveso).hogedrempelinrichtingen;
        }
      }
      if (findSeveso && y.startsWith('M')) {
        if (casSeveso) {
          this.milieugevarenLaag = (dangerousSeveso || casSeveso).lagedrempelinrichtingen;
          this.milieugevarenHoog = (dangerousSeveso || casSeveso).hogedrempelinrichtingen;
        } else {
          this.milieugevarenLaag = (dangerousSeveso || findSeveso).lagedrempelinrichtingen;
          this.milieugevarenHoog = (dangerousSeveso || findSeveso).hogedrempelinrichtingen;
        }
      }

      const coefficientBuildKG = (inputRisk: number) => {
        if (!plant.maximumStockKG || !inputRisk) { return 0; }
        return Number((plant.maximumStockKG / inputRisk).toFixed(3));
      };

      this.coefficientLaagGezondheid = coefficientBuildKG(this.gezondheidsgevarenLaag);
      this.coefficientHoogGezondheid = coefficientBuildKG(this.gezondheidsgevarenHoog);
      this.coefficientLaagFysisch = coefficientBuildKG(this.fysischeGevarenLaag);
      this.coefficientHoogFysisch = coefficientBuildKG(this.fysischeGevarenHoog);
      this.coefficientLaagMilieu = coefficientBuildKG(this.milieugevarenLaag);
      this.coefficientHoogMilieu = coefficientBuildKG(this.milieugevarenHoog);

    });
  }

  constructor(
    plant?: ProductPlantDbServer,
    product?: Product,
    sevesoList?: SevesoDb[],
  ) {
    if (sevesoList && plant && product) {
      this.buildSevesoData(sevesoList, product, plant);
    }
  }

}
