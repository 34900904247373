import { Pipe, PipeTransform } from '@angular/core';
import { Zzs } from '../../../classes/zzs.class';

@Pipe({
  name: 'zzsTooltip'
})
export class ZzsTooltipPipe implements PipeTransform {

  transform(zzs: Zzs): any {

    return 'CAS-number: ' + zzs.casNummer + '\n' + 'EG-number: ' + zzs.egNummer;

  }

}
