import { Component, OnInit } from '@angular/core';
import { ApiService } from './services/api.service';
import { FireAuthService } from './services/fireAuth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {

  // Variables
  public loggingIn = true;

  // Life cycle
  constructor(
    private fireAuth: FireAuthService,
    private api: ApiService,
  ) { }

  ngOnInit() {

    // Login is set to redirect so it will destroy the app on failures
    this.fireAuth.user$.subscribe(async user => {
      if (!user) {
        this.fireAuth.signInWithGoogle();
        return;
      }

      await this.api.setCustomClaims()
        .then(async () => {
          // this.database.initializeFirestore();
          // await this.database.products$.pipe(first(), auditTime(1000)).toPromise();
          this.loggingIn = false;
        })
        .catch(async () => {
          this.fireAuth.signInWithGoogle();
        });
    });
  }

}
