import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { UnSubstanceCategory, UnSubstanceCategoryDb } from '../../../classes/un.class';
import { DialogComponent, DialogContent } from '../../../_shared/_components/dialog/dialog.component';
import { InfoTableService } from '../../../_shared/_components/info-table/info-table.service';
import { SnackbarComponent } from '../../../_shared/_components/snackbar/snackbar.component';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-un-edit',
  templateUrl: './un-edit.component.html',
  styleUrls: ['./un-edit.component.css']
})
export class UnEditComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription;

  // Variables
  public unCategory: UnSubstanceCategory;
  public editable: boolean;
  public validated = false;
  public unCategoryForm: UnSubstanceCategory;

  // Methods
  public getUnCategory(unCategory: UnSubstanceCategoryDb) {
    this.unCategoryForm = new UnSubstanceCategory(this.api, unCategory);
  }

  public unCategoryValidate(validated: boolean) {
    this.validated = validated;
  }

  public closeDialog() {
    this.matDialog.closeAll();
  }

  public async saveData() {
    await this.unCategoryForm.saveUn();
    this.snackbar.snackbarSuccess('Saved UN Substance entry');
  }

  public deleteEntry() {

    const data: DialogContent = {
      dialogData: {
        title: 'Confirm',
        body: `Are you sure you want to delete this entry?`,
        button: 'Delete',
        button2: 'Cancel'
      }
    };
    const dialog = this.matDialog.open(DialogComponent, { data, disableClose: true });

    dialog.afterClosed().subscribe(async response => {
      if (!response) { return; }
      await this.unCategoryForm.deleteUn();
      this.snackbar.snackbarSuccess('Deleted Un Substance entry');
      this.matDialog.closeAll();
    });
  }

  // Life cycle methods
  private subscribeToObservables() {
    const rowDataSub = this.infoTableService.rowData$.subscribe(unCategory => {
      this.unCategory = unCategory;
    });

    this.subscriptions.add(rowDataSub);
  }

  // Life cycle
  constructor(
    private infoTableService: InfoTableService,
    private matDialog: MatDialog,
    private api: ApiService,
    private snackbar: SnackbarComponent
  ) { }

  ngOnInit() {
    this.subscribeToObservables();
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
