import { xor } from 'lodash';
import { take } from 'rxjs/operators';
import { LanguageClassPipe } from '../_shared/_pipes/language-class.pipe';
import { LanguageClass } from './language.class';
import { ProductDb, Product, physicalState } from './product.class';
import { ProductComponent } from './product-component.class';

/**
 * Loads the 'controlled Date' from a ProductDb class.
 */
function loadControlledDate(input: ProductDb): Date {
  if (input.controlledDate && !isNaN(new Date(input.controlledDate).getTime())) {
    return new Date(input.controlledDate);
  } else if (input.datum && !isNaN(new Date(input.datum).getTime())) {
    return new Date(input.datum);
  } else {
    return new Date();
  }
}

class Section1 {
  name = new LanguageClass;
  artikelnummerLev = '';
  beschrijving = new LanguageClass;
  supplier = '';
  vibNr = '';
  versie = '';
  datum = new Date('');
  vorige = new Date('');
  casNummer = '';
  egNummer = '';
  eNummer = new LanguageClass;
  indexNummer = '';
  registrationNr = '';
  ufiCode = '';
  formule = '';
  noodnummer: string[] = [];

  constructor(input?: Section1) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          case 'name': case 'beschrijving': this[key] = new LanguageClass(input[key]); break;
          case 'datum': case 'dateArchvied': this[key] = new Date(input[key]); break;
          case 'vorige': this[key] = new Date(input[key]); break;
          case 'eNummer': this[key] = new LanguageClass(input[key]); break;
          case 'indexNummer': this[key] = input[key].toString(); break;
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }

}
class Section2 {
  signalword: 'warning' | 'danger' | '' = '';
  ghs: string[] = [];
  hNr: string[] = [];
  pNr: string[] = [];
  hazard: string[] = [];
  classification: string[] = [];

  constructor(input?: Section2) {
    if (input) {
      Object.keys(input).forEach(key => {
        if (this[key] !== undefined) { this[key] = input[key]; }
      });
    }
  }
}
class Section3 {
  productComponents: ProductComponent[] = [];
  molecularWeight = '';

  constructor(input?: Section3) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          case 'productComponents': {
            if (input[key].length === 0) {
              this[key] = [new ProductComponent];
            } else {
              this[key] = input[key].map(c => new ProductComponent(c));
            }
            break;
          }
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    } else {
      this['productComponents'] = [new ProductComponent];
    }
  }

}

class Section4 {

  inhalation = new LanguageClass;
  skinContact = new LanguageClass;
  eyeContact = new LanguageClass;
  ingestion = new LanguageClass;

  constructor(input?: Section4) {
    if (input) {
      Object.keys(input).forEach(key => {
        if (this[key] !== undefined) { this[key] = input[key]; }
      });
    }
  }

}
class Section5 {
  suiteableExtinguishingMedia = new LanguageClass;
  unsuiteableExtinguishingMedia = new LanguageClass;

  constructor(input?: Section5) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          case 'suiteableExtinguishingMedia': this[key] = new LanguageClass(input[key]); break;
          case 'unsuiteableExtinguishingMedia': this[key] = new LanguageClass(input[key]); break;
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }
}

class Section6 {
  emergencyNumberStdText = '';

  constructor(input?: Section6) {
    if (input) {
      Object.keys(input).forEach(key => {
        if (this[key] !== undefined) { this[key] = input[key]; }
      });
    }
  }
}
class Section8 {
  grenswaarde8uur = 0;
  grenswaarde8uurPpm = 0;

  grenswaarde15minuten = 0;
  grenswaarde15minutenPpm = 0;

  controlledDate = new Date;

  constructor(input?: Product) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          case 'controlledDate': this[key] = loadControlledDate(input); break;
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }

}
// Note: the commented properties are not implemented yet,
// but were not in the backlog.
// when implementing, uncomment them, and add them in all other necessary places.
class Section9 {
  physicalState: physicalState = '';
  colourProduct = new LanguageClass;
  odourProduct = new LanguageClass;
  dichtheidGas = '';
  dichtheidWater = 0;
  vapourDensity = 0;
  liquid_temperatureBoilingPoint = 0;
  flashpoint = 0;
  explosiveLimits = '';
  pH = 0;
  meltingPoint = 0;
  vosRegistration = '';
  vosPercentage = 0;

  constructor(input?: Section9) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          case 'colourProduct': this[key] = new LanguageClass(input[key]); break;
          case 'odourProduct': this[key] = new LanguageClass(input[key]); break;
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }

}
class Section11 {
  lc50ppm1h = '';
  lc50 = ''; // this is LC50 - inademing PPM /4h
  lc50mgl1h = '';
  lc50mgl4h = '';

  constructor(input?: Section11) {
    if (input) {
      Object.keys(input).forEach(key => {
        if (this[key] !== undefined) { this[key] = input[key]; }
      });
    }
  }
}

class Section12 {
  wgkClass = 0;
  waterSolubility = 0;
  otherDamagingEffects = new LanguageClass;
  ozonLayerEffects = new LanguageClass;
  globalWarmingEffects = new LanguageClass;

  constructor(input?: Section12) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          case 'otherDamagingEffects': this[key] = new LanguageClass(input[key]); break;
          case 'ozonLayerEffects': this[key] = new LanguageClass(input[key]); break;
          case 'globalWarmingEffects': this[key] = new LanguageClass(input[key]); break;
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }

}

class Section14 {
  un = '';
  properShippingName = new LanguageClass;
  classificationCode = '';
  adr: string[] = [];
  packingGroup = '';

  constructor(input?: Section14) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          case 'properShippingName': this[key] = new LanguageClass(input[key]); break;
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }

}

class Section15 {

  opmerkingenSeveso = '';
  foodCompliant = false;

  constructor(input?: Section14) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }

}

/**
 * Product form divided into sections following the SDS structure and as communicated with Air-Liquide.
 */
export class ProductForm {

  // dateArchived = '';

  static languageClassPipe?: LanguageClassPipe;
  section1 = new Section1;
  section2 = new Section2;
  section3 = new Section3;
  section4 = new Section4;
  section5 = new Section5;
  section6 = new Section6;
  section8 = new Section8;
  section9 = new Section9;

  section11 = new Section11;
  section12 = new Section12;

  section14 = new Section14;
  section15 = new Section15;
  /**
   * Get the view with translated properties.
   * @param languageClassPipe Insert this class so we reuse the same translation logic.
   */
  async getAsView(languageClassPipe: LanguageClassPipe) {

    const viewObject = {};
    await Promise.all(Object.entries(this).map(async ([key, value]) => {
      if (!viewObject[key]) { viewObject[key] = value; }

      return Promise.all(Object.entries(value).map(async ([key2, value2]) => {

        if (value2 instanceof LanguageClass) {
          const translatedValue = await languageClassPipe.transform(value2, key2).pipe(take(1)).toPromise();
          viewObject[key][key2] = translatedValue;
        } else {
          viewObject[key][key2] = value2;
        }

      }));
    }));
    return viewObject;
  }

  constructor(product?: Product, productForm?: ProductForm) {
    if (product) {
      this.section1 = new Section1(product);
      this.section2 = new Section2(product);
      this.section3 = new Section3(product);
      this.section4 = new Section4(product);
      this.section5 = new Section5(product);
      this.section6 = new Section6(product);
      this.section8 = new Section8(product);
      this.section9 = new Section9(product);
      this.section11 = new Section11(product);
      this.section12 = new Section12(product);
      this.section14 = new Section14(product);
      this.section15 = new Section15(product);
    }

    if (productForm) {
      Object.entries(productForm).forEach(([key, value]) => {
        switch (key) {
          case 'section1': this.section1 = new Section1(value); break;
          case 'section2': this.section2 = new Section2(value); break;
          case 'section3': this.section3 = new Section3(value); break;
          case 'section4': this.section4 = new Section4(value); break;
          case 'section5': this.section5 = new Section5(value); break;
          case 'section6': this.section6 = new Section6(value); break;
          case 'section8': this.section8 = new Section8(value); break;
          case 'section9': this.section9 = new Section9(value); break;
          case 'section11': this.section11 = new Section11(value); break;
          case 'section14': this.section14 = new Section14(value); break;
          case 'section15': this.section15 = new Section15(value); break;
        }
      });
    }

    // Test to check if nothing is missing when creating instance
    let sectionsObject = {} as ProductDb;
    Object.keys(this).forEach((x: keyof ProductForm) => {
      sectionsObject = { ...sectionsObject, ...this[x] };
    });


    const xorProduct = { ...new ProductDb, emergencyNumberStdText: '' };
    const productKeys = Object.keys(xorProduct).filter(x => x !== 'pbm' && x !== 'metaData' && x !== 'vlarem' && x !== 'dateArchived');
    const sectionsArray = Object.keys(sectionsObject);

    const difference = xor(productKeys, sectionsArray);

    if (difference.length !== 0) {
      throw new Error('ProductDb is not equal to ProductForm. Difference: ' + difference);
    }

  }

}
