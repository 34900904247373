import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule, MatDividerModule, MatExpansionModule, MatIconModule, MatListModule, MatSidenavModule, MatTabsModule, MatToolbarModule, MatMenuModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { OverflowHideDirective } from './overflow-hide.directive';
import { PagePathHighlightDirective } from './page-path-highlight.directive';
import { SidenavComponent } from './sidenav.component';
import { ToolbarUserComponent } from './toolbar-user/toolbar-user.component';
import { SharedModule } from '../_shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatExpansionModule,
    MatDividerModule,
    MatListModule,
    MatIconModule,
    MatTabsModule,
    MatButtonModule,
    MatMenuModule,
    SharedModule
  ],
  exports: [
    SidenavComponent,
    OverflowHideDirective
  ],
  declarations: [
    SidenavComponent,
    OverflowHideDirective,
    PagePathHighlightDirective,
    ToolbarUserComponent
  ]
})
export class SidenavModule { }
