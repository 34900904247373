import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductPlant } from '../../classes/product-plant.class';
import { Product } from '../../classes/product.class';

@Component({
  selector: 'app-risk-analysis',
  templateUrl: './risk-analysis.component.html',
  styleUrls: ['./risk-analysis.component.css']
})
export class RiskAnalysisComponent implements OnInit, OnChanges {

  public plant: ProductPlant;

  @Input() public product: Product;

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['product'] && changes['product'].currentValue) {

      // Get plant data
      const plantView = this.activatedRoute.snapshot.queryParamMap.get('plant');

      const findPlant = this.product.metaData.plants.find(plant => plant.metaDataPlant.plant === plantView);
      if (!findPlant) { throw new Error('Plant not found'); }

      this.plant = findPlant;
    }
  }

}
