import { MatPaginatorIntl } from '@angular/material';
import { UiService } from '../services/ui.service';
import { UsersService } from '../services/users.service';
import { combineLatest } from 'rxjs';
import { Key } from './language.class';

export class SDSPaginatorIntl extends MatPaginatorIntl {

    private rangeLabelIntl = '{{startIndex}} - {{endIndex}} of {{length}}';

    constructor(private uiService: UiService, private userService: UsersService) {
        super();
        this.getTranslations();
    }

    findTranslation = (keys: Key[], lang: string, value: string): string => {
        const _map = keys.find(key => key.key === value);
        return (_map && _map[lang]) ? _map[lang] : value;
    }

    getTranslations() {
        combineLatest(
            this.userService.currentUser$,
            this.uiService.uiKeys$
        ).subscribe(([user, keys]) => {
            const language = user.language || 'english';

            this.itemsPerPageLabel = this.findTranslation(keys, language, 'items_per_page');
            this.nextPageLabel = this.findTranslation(keys, language, 'next_page_label');
            this.previousPageLabel = this.findTranslation(keys, language, 'prev_page_label');
            this.rangeLabelIntl = this.findTranslation(keys, language, 'range_label_intl');

            this.changes.next();

        });
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return this.rangeLabelIntl
            .replace('{{startIndex}}', startIndex.toString())
            .replace('{{endIndex}}', endIndex.toString())
            .replace('{{length}}', length.toString());
    }

}
