import { Pipe, PipeTransform } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { flatMap, map, take } from 'rxjs/operators';
import { Hnumber, Pnumber } from '../../classes/h-and-p.class';
import { Key, LanguageClass, Translations } from '../../classes/language.class';
import { ProductService } from '../../services/product.service';
import { UsersService } from '../../services/users.service';
import { TranslationService } from '../../services/translation.service';
import { ProductForm } from '../../classes/product-sections.class';

type types = LanguageClass | Hnumber | Pnumber | Key | string;

enum translationMap {
  'dutch' = 'nl',
  'english' = 'en',
  'german' = 'de',
  'french' = 'fr'
}

@Pipe({
  name: 'languageClass'
})
export class LanguageClassPipe implements PipeTransform {

  constructor(
    private userService: UsersService,
    private productService: ProductService,
    private translateService: TranslationService
  ) { }

  transform(value: types, key?: string): Observable<string> {

    return combineLatest(
      this.userService.currentUser$,
      this.productService.translations$
    ).pipe(
      flatMap(([user, translations]) => {
        const language = user.language || 'english';
        return this.setLanguage(value, language, translations, key);
      })
    );
  }

  /**
   * @param key - Set the product key so we can exclude section 1 from auto google translating.
   */
  async setLanguage(value: types, language: keyof LanguageClass, translations?: Translations, key?: string) {
    if (
      value instanceof Key ||
      value instanceof LanguageClass ||
      value instanceof Hnumber ||
      value instanceof Pnumber
    ) {

      if (value[language]) {
        return value[language];
        // Exclude section 1 values of the product
      } else if (key && !Object.keys(new ProductForm().section1).some(x => x === key)) {

        const findOther = Object.entries(value).find(([_language, _translation]) => _translation && typeof _translation === 'string');
        if (!findOther) { return ''; }

        // Find google translate
        return await this.translateService
          .translationResult$(findOther[1], translationMap[findOther[0]], translationMap[language])
          .pipe(
            take(1),
            map(string => string === 'translation not found' ? `${findOther[1]} (translation not found)` : `${string} (google)`)
          ).toPromise();

      } else {
        const findOther = Object.entries(value).find(([_language, _translation]) => _translation && typeof _translation === 'string');
        if (!findOther) { return ''; }
        return findOther[1] + ' (' + findOther[0] + ')';
      }

    } else if (typeof value === 'string' && translations) {

    


      const findTranslation = Object.values(translations)
        .reduce((string, value2) => {
          const find = value2.find(x => x.english === value);
          if (find) { string = find[language]; }
          return string;
        }, '');

      if (findTranslation) {
        return findTranslation;
      } else { 

        return value; 
      }

    } else {
      //dit is zeker niet altijd een string:
      if (!value) {
        return ""
      } else {
        return value[language] || value;
      }
    }
  }

}
