import { FirebaseAppConfig } from '@angular/fire';
import { env } from '../../environment';

export class ConfigService {
  static version = "2.6.2 (beta)";

  static cloudfunctionsUrl = "";
  static firebaseDev: FirebaseAppConfig = {
    apiKey: "AIzaSyDf0dG_6lNHBg8XhFBTpnBTCYBYQoSa-bs",
    authDomain: "sds-legacy.zzapps.dev",
    databaseURL: "https://sds-air-liquide-dev.firebaseio.com",
    projectId: "sds-air-liquide-dev",
    storageBucket: "",
    messagingSenderId: "824307566829",
    appId: "1:824307566829:web:3b7cee4ca9d6268f",
  };
  static googleMapsApi = "AIzaSyCtvUUzLCRVKPsEgLMzG4VsmSbDb8eQ_Q4";
  static firebaseProd: FirebaseAppConfig = {
    apiKey: "AIzaSyCtvUUzLCRVKPsEgLMzG4VsmSbDb8eQ_Q4",
    authDomain: "sds-legacy.zzapps.app",
    databaseURL: "https://sds-air-liquide.firebaseio.com",
    projectId: "sds-air-liquide",
    storageBucket: "sds-air-liquide.appspot.com",
    messagingSenderId: "618169823596",
  };

  static firebase =
    env === "dev" ? ConfigService.firebaseDev : ConfigService.firebaseProd;
  static spreadsheetUrl =
    env === "dev"
      ? "https://script.google.com/macros/s/AKfycbxVNV9Owg4HxIinFRxt-GyESkUv-YyiHobcOWU7pWYUVb36gPU/exec" // DEV --> owner: roel@zzapps.nl
      : "https://script.google.com/macros/s/AKfycbxgyEJnqQKfud6d-2Xj6k4kGPP_iQXVJtkjixoierD9vlqBwv0/exec"; // PROD --> owner: riel@zzapps.nl

  static useAdminComponent = false;

  constructor() {}
}
