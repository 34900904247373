import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, ReplaySubject, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { PbmOptionsDb } from '../../classes/pbm.class';
import { Product } from '../../classes/product.class';
import { ProductService } from '../../services/product.service';
import { UsersService } from '../../services/users.service';
import { LanguageKeys } from '../../_shared/_components/recursive-form/language-key.pipe';
import { SnackbarComponent } from '../../_shared/_components/snackbar/snackbar.component';
import { LanguageClassPipe } from '../../_shared/_pipes/language-class.pipe';

@Component({
  selector: 'app-product-pbm-edit',
  templateUrl: './product-pbm-edit.component.html',
  styleUrls: ['./product-pbm-edit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductPbmEditComponent implements OnInit, OnChanges, OnDestroy {

  private _subs = new Subscription();

  public pbmForm: PbmOptionsDb;

  public newProductPbm: PbmOptionsDb;

  @Input() product: Product;
  @Input() view: 'plant' | 'all' = 'all';

  /**
   * Pbm option selector for dynamic form
   */
  public pbmOptionsSelection$ = this.productService.pbmOptionSelections(this.languageClassPipe);
  public formOutput$ = new ReplaySubject<PbmOptionsDb>(1);
  public formOutputPlants: PbmOptionsDb[];
  public pbmFormValidated = false;
  public pbmFormPlantValidated: boolean[];

  public activeTranslatedDescriptions$ = combineLatest(
    this.userService.currentUser$,
    this.productService.pbmKeys$,
  ).pipe(filter(([user]) => user !== null),
    map(([user, keys]) => {

      // Set translations for form keys
      const translatedDescriptions: LanguageKeys = {};
      keys.forEach(x => {
        if (x.description && x.description.visible && x.description[user.language]) {
          translatedDescriptions[x.key] = x.description[user.language];
        }
      });
      return translatedDescriptions;
    }));

  // We need to translate the keys here because the dynamic form stands on its own
  public translatedKeys$ = combineLatest(
    this.userService.currentUser$,
    this.productService.pbmKeys$
  ).pipe(filter(([user]) => user !== null),
    map(([user, keys]) => {
      // Set translations for form keys
      const translatedKeys: LanguageKeys = {};
      keys.forEach(x => {
        if (x[user.language]) {
          translatedKeys[x.key] = x[user.language];
        } else {
          translatedKeys[x.key] = x.key;
        }
      });

      return translatedKeys;
    })
  );

  public async saveProduct() {

    const newPbm = await this.formOutput$.pipe(take(1)).toPromise();
    this.newProductPbm = newPbm;
    const plantView = this.activatedRoute.snapshot.queryParamMap.get('plant');

    if (plantView) {
      this.product.setPbmPlant(newPbm, plantView);
    } else {
      this.product.setPbm(newPbm);

      for (const [i, plant] of this.product.metaData.plants.entries()) {
        const plantId = plant.metaDataPlant.plant;
        const newPlantPbm = this.formOutputPlants[i];
        this.product.setPbmPlant(newPlantPbm, plantId);
      }
    }

    await this.product.saveProduct();
    this.changeDetector.detectChanges();
    this.snackbar.snackbarSuccess('Pbm saved');
  }

  updateFormOutput(pbm: PbmOptionsDb, index: number) {
    this.formOutputPlants[index] = pbm;
  }

  pbmsMatch(productPbm: PbmOptionsDb, plantPbm: PbmOptionsDb): boolean {
    const newProductPbm = this.newProductPbm || productPbm;
    const equal = Object.keys(newProductPbm).every(key => newProductPbm[key] === plantPbm[key]);
    return equal;
  }

  constructor(
    private productService: ProductService,
    private userService: UsersService,
    private changeDetector: ChangeDetectorRef,
    private snackbar: SnackbarComponent,
    private languageClassPipe: LanguageClassPipe,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.formOutput$.pipe(take(1)).toPromise()
      .then(x => this.newProductPbm = x);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['product'] && changes['product'].currentValue) {

      const plantView = this.activatedRoute.snapshot.queryParamMap.get('plant');

      if (plantView) {
        const findPlant = this.product.metaData.plants.find(plant => plant.metaDataPlant.plant === plantView);
        if (!findPlant) { throw new Error('Plant not found'); }

        this.pbmForm = findPlant.pbm;
      } else {

        this.pbmForm = this.product.pbm;
        this.formOutputPlants = this.product.metaData.plants.map(plant => new PbmOptionsDb(plant.pbm));
        this.pbmFormPlantValidated = Array(this.product.metaData.plants.length).fill(false);
      }

    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

}
