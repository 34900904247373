import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valueColor'
})
export class ValueColorPipe implements PipeTransform {

  transform(value: any): string {

    let transformValue: string;

    switch (value) {
      case 'High': transformValue = 'value-high'; break;
      case 'Medium': transformValue = 'value-medium'; break;
      case 'Low': transformValue = 'value-low'; break;

      default: transformValue = value;
    }

    return transformValue;
  }

}
