import { Pipe, PipeTransform } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { UsersService } from '../../../services/users.service';

@Pipe({
  name: 'productComponentName'
})
export class ProductComponentNamePipe implements PipeTransform {

  transform(group: FormGroup) {
    const nameGroup = group.controls['name'] as FormGroup;

    return this.userService.currentUser$
      .pipe(
        switchMap(user => {
          const language = user.language || 'english';
          const langControl = nameGroup.controls[language] as FormControl;
          return langControl.valueChanges;
        })
      );
  }

  constructor(
    private userService: UsersService,
  ) { }

}
