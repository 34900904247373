import { Component, Input, OnInit } from '@angular/core';
import { Key } from '../../classes/language.class';
import { ApiService } from '../../services/api.service';
import { KeyPath } from '../../services/product.service';
import { SnackbarComponent } from '../../_shared/_components/snackbar/snackbar.component';

@Component({
  selector: 'app-edit-key-list',
  templateUrl: './edit-key-list.component.html',
  styleUrls: ['./edit-key-list.component.css']
})
export class EditKeyListComponent implements OnInit {

  @Input() keys: Key[];
  @Input() keyPath: KeyPath;

  public inputKeys: Key[];

  public async updateKeys(keys: Key[], path: KeyPath) {
    await this.api.keys(keys, path);
    this.snackbarComponent.snackbarSuccess('Document updated');
  }

  public orderChanged(keys: Key[]) {
    this.inputKeys = keys;
  }

  constructor(
    private api: ApiService,
    private snackbarComponent: SnackbarComponent
  ) { }

  ngOnInit() {
    this.inputKeys = this.keys;
  }

}
