import { ApiService } from '../services/api.service';
import { ZzsDbServer } from '../../../server/src/routes/product/types';

export class ZzsDb implements ZzsDbServer {

  casNummer = '';
  datumToevoeging: string | Date = '';
  egNummer = '';
  emissiegrenswaarde = '';
  engelseStofnaam = '';
  grensmassastroom = '';
  nederlandsStofnaam = '';
  stofklasseVoorLuchtemissies = '';
  voetnoot1 = '';
  voetnoot2 = '';
  voetnoot3 = '';
  voetnoot4 = '';
  zzsVolgensEuGevaarsindeling: '';
  zzsVolgensEuPopVerordening: '';
  zzsVolgensKrw: '';
  zzsVolgensOspar: '';
  zzsVolgensReach: '';
  dbRef = '';

  constructor(input: ZzsDb | undefined) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          case 'datumToevoeging': this[key] = new Date(input[key]); break;
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }

}
export class Zzs extends ZzsDb {

  static api: ApiService;

  datumToevoeging = new Date('');

  public updateFromForm(zzsForm: Zzs) {
    Object.keys(zzsForm).forEach(key => {
      if (this[key] !== undefined) { this[key] = zzsForm[key]; }
    });
  }

  public saveZzs() {
    if (!this.dbRef) { return Zzs.api.addZzs(this); }
    return Zzs.api.updateZzs(this);
  }

  public deleteZzs() {
    if (!this.dbRef) { throw new Error('No dbRef present'); }
    return Zzs.api.deleteZzs(this.dbRef);
  }

  constructor(api: ApiService, input?: ZzsDb) {
    super(input);

    if (input && input.datumToevoeging) { this.datumToevoeging = new Date(input.datumToevoeging); }

    Zzs.api = api;
  }

}
