import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'storageImg'
})
export class StorageImgPipe implements PipeTransform {

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  transform(storageId: string): Observable<SafeUrl> {
    if (!storageId) { return of(''); }
    const path = "/storage/image/";
    const id = encodeURIComponent(storageId);
    const url = path + id;
    return this.http
      .get(url, { responseType: 'blob' })
      .pipe(
        map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val)))
      );
  }

}
