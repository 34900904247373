import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({
  name: 'sortBy'
})
export class SortByPipe implements PipeTransform {

  transform(array: any[], key: string, isObj: boolean = false): any {
    if (isObj) {
      array = Object.keys(array).map((k) => ({ ...array[k], key: k })).filter(a => a != null);
    }

    return orderBy(array, key);
  }

}
