import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class RecursiveFormService {

  public removeMatDateError(control: AbstractControl) {
    const errors = control.errors;
    if (errors) {
      delete errors['matDatepickerParse'];
      if (!Object.keys(errors).length) {
        control.setErrors(null);
      } else {
        control.setErrors(errors);
      }
    }
  }

  // Remove standard matDate validations because it sucks
  public recursiveRemoveMatDateError(input: AbstractControl) {
    const form = input;
    if (form instanceof FormGroup) {
      Object.keys(form.controls).forEach(x => {

        if (form.controls[x] instanceof FormArray) {
          (form.controls[x] as FormArray).controls.forEach(y => {
            this.recursiveRemoveMatDateError(y);
          });
        }

        if (form.controls[x] instanceof FormGroup) { this.recursiveRemoveMatDateError(form.controls[x]); }

        if (
          form.controls[x] instanceof FormControl &&
          form.controls[x].value instanceof Date) {
          this.removeMatDateError(form.controls[x]);
        }
      });
    }

    if (form instanceof FormArray) { form.controls.forEach(x => this.recursiveRemoveMatDateError(x)); }
  }


  constructor() { }
}
