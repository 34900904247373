import { Injectable } from '@angular/core';
import { ProductPlant } from '../classes/product-plant.class';
import { Product } from '../classes/product.class';
import { RiskAnalysis, RiskDb, Ths } from '../classes/risk.class';

interface RiskTotalPlantsObject {
  [prop: string]: {
    ammountPerPostPerYearKg: number[],
    ammountPerPostPerYearL: number[],

    cmrToxicRisk_potential_Score_HRP: number[],
    cmrToxicRisk_inhalation_Score_HRP: number[],
    cmrToxicRisk_contact_Score_HRP: number[],

    fireExplosion_potential_Score_HRP: number[],

    environment_potential_water_Score_HRP: number[],
    environment_potential_air_Score_HRP: number[],
    environment_potential_soil_Score_HRP: number[]
  };
}
interface RiskMaxTotalPlantsPerPlant {
  maxAmmountPerPostPerYearKg: number;
  maxAmmountPerPostPerYearL: number;

  totalCmrToxicRisk_potential_Score_HRP: number;
  totalCmrToxicRisk_inhalation_Score_HRP: number;
  totalCmrToxicRisk_contact_Score_HRP: number;

  totalFireExplosion_potential_Score_HRP: number;

  totalEnvironment_potential_water_Score_HRP: number;
  totalEnvironment_potential_air_Score_HRP: number;
  totalEnvironment_potential_soil_Score_HRP: number;
}
interface RiskMaxTotalPlants {
  [prop: string]: RiskMaxTotalPlantsPerPlant;
}

enum PhysicalState {
  'solid' = 'solid',
  'liquid' = 'liquid',
  'gas' = 'gas',
  'aerosol' = 'gas',
  'gas liquified under pressure' = 'liquid',
  'pasta' = 'liquid',
  'fine powder: solid fine powder' = 'solid',
  'granulate: solid granulate' = 'solid',
  'powder: solid powder' = 'solid',
}

@Injectable({
  providedIn: 'root'
})
export class RiskService {

  public calculateRiskAnalysisTotals(products: Product[], ths: Ths) {

    // Filter out archived products
    const filteredProducts = products.reduce((acc, product) => {
      if (product.metaData.archived) { return acc; }

      // Transform physical state to only solid, liquid or gas. This what the risk analysis only accepts.
      product.metaData.plants = product.metaData.plants.reduce((accPlant, plant) => {
        if (plant.metaDataPlant.archived) { return [...accPlant, plant]; }

        if (product.physicalState === 'gas liquified under pressure') {
          plant.risk = plant.risk.map(risk => {
            if (!risk.physicalStateRisk) {
              risk.physicalStateRisk = 'liquid';
            } else {
              risk.physicalStateRisk = PhysicalState[product.physicalState] || '';
            }
            return risk;
          });
        }

        return [...accPlant, plant];
      }, [] as ProductPlant[]);

      return [...acc, product];
    }, []);

    // Create objects that contains total and max values per plant
    const totalPlantsObject: RiskTotalPlantsObject = {};
    const riskTotalsMaxPerPlant: RiskMaxTotalPlants = {};

    // First, get totals ammounts for each plant
    filteredProducts.forEach(x => {
      x.metaData.plants.forEach(a => {

        a.risk.forEach(y => {

          if (!totalPlantsObject[a.metaDataPlant.plant]) {
            totalPlantsObject[a.metaDataPlant.plant] = {
              ammountPerPostPerYearKg: [],
              ammountPerPostPerYearL: [],

              cmrToxicRisk_potential_Score_HRP: [],
              cmrToxicRisk_inhalation_Score_HRP: [],
              cmrToxicRisk_contact_Score_HRP: [],

              fireExplosion_potential_Score_HRP: [],

              environment_potential_water_Score_HRP: [],
              environment_potential_air_Score_HRP: [],
              environment_potential_soil_Score_HRP: []
            };
          }

          totalPlantsObject[a.metaDataPlant.plant].ammountPerPostPerYearKg.push(y.ammountPerPostPerYearKg);
          totalPlantsObject[a.metaDataPlant.plant].ammountPerPostPerYearL.push(y.ammountPerPostPerYearL);
        });
      });
    });

    // Second, set the max values on max/total object;
    Object.keys(totalPlantsObject).forEach(x => {

      // Set plant object
      riskTotalsMaxPerPlant[x] = {
        maxAmmountPerPostPerYearKg: 0,
        maxAmmountPerPostPerYearL: 0,

        totalCmrToxicRisk_potential_Score_HRP: 0,
        totalCmrToxicRisk_inhalation_Score_HRP: 0,
        totalCmrToxicRisk_contact_Score_HRP: 0,

        totalFireExplosion_potential_Score_HRP: 0,

        totalEnvironment_potential_water_Score_HRP: 0,
        totalEnvironment_potential_air_Score_HRP: 0,
        totalEnvironment_potential_soil_Score_HRP: 0
      };

      // Set the max of the total ammounts
      riskTotalsMaxPerPlant[x].maxAmmountPerPostPerYearKg =
        Math.max(...totalPlantsObject[x].ammountPerPostPerYearKg);
      riskTotalsMaxPerPlant[x].maxAmmountPerPostPerYearL =
        Math.max(...totalPlantsObject[x].ammountPerPostPerYearL);
    });

    // Third, calculations with the max of the total ammounts
    filteredProducts.forEach(x => {
      x.metaData.plants.forEach(a => {
        if (a.metaDataPlant.archived) { return; }
        const riskTotals = riskTotalsMaxPerPlant[a.metaDataPlant.plant];

        a.riskAnalysis.forEach(y => {
          this.calculateRisk(y, x, ths);
          this.calculateRiskFromTotalAmounts(y, x, ths, riskTotals);

          // Push calculated values to total array
          totalPlantsObject[a.metaDataPlant.plant].cmrToxicRisk_potential_Score_HRP
            .push(y.cmrToxicRisk_potential_Score_HRP);
          totalPlantsObject[a.metaDataPlant.plant].cmrToxicRisk_inhalation_Score_HRP
            .push(y.cmrToxicRisk_inhalation_Score_HRP);
          totalPlantsObject[a.metaDataPlant.plant].cmrToxicRisk_contact_Score_HRP
            .push(y.cmrToxicRisk_contact_Score_HRP);

          totalPlantsObject[a.metaDataPlant.plant].fireExplosion_potential_Score_HRP
            .push(y.fireExplosion_potential_Score_HRP);

          totalPlantsObject[a.metaDataPlant.plant].environment_potential_water_Score_HRP
            .push(y.environment_potential_water_Score_HRP);
          totalPlantsObject[a.metaDataPlant.plant].environment_potential_air_Score_HRP
            .push(y.environment_potential_air_Score_HRP);
          totalPlantsObject[a.metaDataPlant.plant].environment_potential_soil_Score_HRP
            .push(y.environment_potential_soil_Score_HRP);
        });
      });
    });

    // Fourth, set the total values from total array on max / total object
    Object.keys(totalPlantsObject).forEach(x => {

      riskTotalsMaxPerPlant[x].totalCmrToxicRisk_potential_Score_HRP =
        Math.round(totalPlantsObject[x].cmrToxicRisk_potential_Score_HRP
          .reduce((a, b) => a + b, 0) * 10000) / 10000;
      riskTotalsMaxPerPlant[x].totalCmrToxicRisk_inhalation_Score_HRP =
        Math.round(totalPlantsObject[x].cmrToxicRisk_inhalation_Score_HRP
          .reduce((a, b) => a + b, 0) * 10000) / 10000;
      riskTotalsMaxPerPlant[x].totalCmrToxicRisk_contact_Score_HRP =
        Math.round(totalPlantsObject[x].cmrToxicRisk_contact_Score_HRP
          .reduce((a, b) => a + b, 0) * 10000) / 10000;

      riskTotalsMaxPerPlant[x].totalFireExplosion_potential_Score_HRP =
        Math.round(totalPlantsObject[x].fireExplosion_potential_Score_HRP
          .reduce((a, b) => a + b, 0) * 10000) / 10000;

      riskTotalsMaxPerPlant[x].totalEnvironment_potential_water_Score_HRP =
        Math.round(totalPlantsObject[x].environment_potential_water_Score_HRP
          .reduce((a, b) => a + b, 0) * 10000) / 10000;
      riskTotalsMaxPerPlant[x].totalEnvironment_potential_air_Score_HRP =
        Math.round(totalPlantsObject[x].environment_potential_air_Score_HRP
          .reduce((a, b) => a + b, 0) * 10000) / 10000;
      riskTotalsMaxPerPlant[x].totalEnvironment_potential_soil_Score_HRP =
        Math.round(totalPlantsObject[x].environment_potential_soil_Score_HRP
          .reduce((a, b) => a + b, 0) * 10000) / 10000;
    });

    // Fifth, calculations with Score HRP
    filteredProducts.forEach(x => {
      x.metaData.plants.forEach(y => {
        if (y.metaDataPlant.archived) { return; }

        const riskTotals = riskTotalsMaxPerPlant[y.metaDataPlant.plant];
        y.riskAnalysis.forEach(a => this.calculateRiskFromTotalCalculations(a, ths, riskTotals));
      });
    });

    // Last, set risk totals on products
    products.forEach(x => {
      x.metaData.plants = x.metaData.plants.map(y => {

        if (y.riskAnalysis) {
          y.riskAnalysis.map(a => {
            a.riskAnalysisTotals = riskTotalsMaxPerPlant[y.metaDataPlant.plant];
            return new RiskAnalysis(a);
          });
        }
        return y;
      });
    });

    return products;
  }

  // Risk helper functions
  private calculateRisk(
    riskAnalysis: RiskAnalysis,
    product: Product,
    ths: Ths
  ) {

    riskAnalysis.cmrToxic_Class_SDS = product.cmr.toxic.max;
    riskAnalysis.cmrToxic_TWA_8_hmgM3 = product.grenswaarde8uur;
    riskAnalysis.cmrToxic_Class_TWA = riskAnalysis.cmrToxic_TWA_8_hmgM3 === 0 ? 0 :
      riskAnalysis.cmrToxic_TWA_8_hmgM3 > 100 ? 1 :
        riskAnalysis.cmrToxic_TWA_8_hmgM3 > 10 ? 2 :
          riskAnalysis.cmrToxic_TWA_8_hmgM3 > 1 ? 3 :
            riskAnalysis.cmrToxic_TWA_8_hmgM3 > 0.1 ? 4 : 5;
    riskAnalysis.cmrToxic_Class_max = Math.max(riskAnalysis.cmrToxic_Class_SDS, riskAnalysis.cmrToxic_Class_TWA);

    riskAnalysis.fire_Class_SDS = product.cmr.fire.max;
    riskAnalysis.fire_Class_Danger = riskAnalysis.fire_Class_SDS === -1 ?
      ths.waterExposition[riskAnalysis.fire_contactWithWater.toLowerCase() as keyof Ths['waterExposition']].fire :
      riskAnalysis.fire_Class_SDS === 0 ? riskAnalysis.physicalStateRisk === 'solid' ? 1 :
        riskAnalysis.physicalStateRisk === 'liquid' ? 2 : riskAnalysis.fire_Class_SDS : riskAnalysis.fire_Class_SDS;

    riskAnalysis.environment_Class_SDS = product.cmr.environment.max;
    riskAnalysis.environment_Class_Danger = riskAnalysis.environment_Class_SDS === 0 ? 1 :
      riskAnalysis.environment_Class_SDS === -1 ?
        ths.waterExposition[riskAnalysis.environment_contactWithWaterAcid.toLowerCase() as keyof Ths['waterExposition']].env1 :
        riskAnalysis.environment_Class_SDS === -2 ?
          ths.waterExposition[riskAnalysis.environment_contactWithWaterAcid.toLowerCase() as keyof Ths['waterExposition']].env2 :
          riskAnalysis.environment_Class_SDS === 0 ?
            riskAnalysis.environment_productOrWaste.toLowerCase() === 'waste' ?
              2 : riskAnalysis.environment_Class_SDS : riskAnalysis.environment_Class_SDS;
  }

  private calculateRiskFromTotalAmounts(
    riskInstance: RiskAnalysis, product: Product, ths: Ths, riskTotals: RiskMaxTotalPlantsPerPlant
  ) {

    // Toxic risk potential
    riskInstance.cmrToxicRisk_potential_Score_HRP = Math.round(this.Risk_Tox(
      riskTotals.maxAmmountPerPostPerYearL,
      riskInstance.ammountPerPostPerYearL,
      riskInstance.frequencyClass04CfrTable1,
      riskInstance.cmrToxic_Class_max
    ) * 1000) / 1000;

    // Toxic risk inhalation
    riskInstance.cmrToxicRisk_inhalation_Score_HRP = Math.round(this.Risk_Inh(
      riskInstance.cmrToxic_Class_max,
      riskInstance.physicalStateRisk,
      product.liquid_temperatureBoilingPoint,
      riskInstance.liquid_temperatureUsage,
      riskInstance.proces,
      riskInstance.workplaceVentilation,
      riskInstance.solid_descriptionSolid,
      ths
    ) * 1000) / 1000;

    // Toxic risk contact
    riskInstance.cmrToxicRisk_contact_Score_HRP = Math.round(this.Risk_Cont(
      riskInstance.cmrToxic_Class_max,
      riskInstance.cmrToxicRisk_contactWithSkin,
      riskInstance.frequencyClass04CfrTable1,
      ths
    ) * 1000) / 1000;

    // Fire risk potential
    riskInstance.fireExplosion_potential_Score_HRP = Math.round(this.Risk_Ex(
      riskTotals.maxAmmountPerPostPerYearKg,
      riskInstance.ammountPerPostPerYearKg,
      riskInstance.fire_Class_Danger,
      riskInstance.ignitionSource,
      ths
    ) * 1000) / 1000;

    // Environment risk potential
    // --------------- Water
    riskInstance.environment_potential_water_Score_HRP = Math.round(this.Risk_Env(
      riskTotals.maxAmmountPerPostPerYearKg,
      riskInstance.ammountPerPostPerYearKg,
      riskInstance.environment_Class_Danger,
      riskInstance.environment_productOrWaste,
      riskInstance.physicalStateRisk,
      'water',
      ths,
    ) * 1000) / 1000;

    // ------------- Air
    riskInstance.environment_potential_air_Score_HRP = Math.round(this.Risk_Env(
      riskTotals.maxAmmountPerPostPerYearKg,
      riskInstance.ammountPerPostPerYearKg,
      riskInstance.environment_Class_Danger,
      riskInstance.environment_productOrWaste,
      riskInstance.physicalStateRisk,
      'air',
      ths,
    ) * 1000) / 1000;

    // -------------- Soil
    riskInstance.environment_potential_soil_Score_HRP = Math.round(this.Risk_Env(
      riskTotals.maxAmmountPerPostPerYearKg,
      riskInstance.ammountPerPostPerYearKg,
      riskInstance.environment_Class_Danger,
      riskInstance.environment_productOrWaste,
      riskInstance.physicalStateRisk,
      'soil',
      ths,
    ) * 1000) / 1000;
  }

  private calculateRiskFromTotalCalculations(riskInstance: RiskAnalysis, ths: Ths, riskTotals: RiskAnalysis['riskAnalysisTotals']) {

    function divideHandler(a: number, b: number) {
      if (a === 0 || b === 0) { return 0; }
      const divide = a / b;
      if (!divide) { return 0; } else if (isNaN(divide)) {
        return 0;
      } else { return divide * 100; }
    }

    // Toxic risk potential
    riskInstance.cmrToxicRisk_potential_perc =
      divideHandler(riskInstance.cmrToxicRisk_potential_Score_HRP, riskTotals.totalCmrToxicRisk_potential_Score_HRP);
    riskInstance.cmrToxicRisk_potential_Priority =
      riskInstance.cmrToxicRisk_potential_Score_HRP > ths.toxPrio.high.value ?
        ths.toxPrio.high.name :
        riskInstance.cmrToxicRisk_potential_Score_HRP > ths.toxPrio.medium.value ?
          ths.toxPrio.medium.name :
          riskInstance.cmrToxicRisk_potential_Score_HRP > ths.toxPrio.low.value ?
            ths.toxPrio.low.name : ths.toxPrio.low.name;

    // Toxic risk inhalation
    riskInstance.cmrToxicRisk_inhalation_perc =
      divideHandler(riskInstance.cmrToxicRisk_inhalation_Score_HRP, riskTotals.totalCmrToxicRisk_inhalation_Score_HRP);
    riskInstance.cmrToxicRisk_inhalation_Priority =
      riskInstance.cmrToxicRisk_inhalation_Score_HRP > ths.toxInhalation.high.value ?
        ths.toxInhalation.high.name :
        riskInstance.cmrToxicRisk_inhalation_Score_HRP > ths.toxInhalation.medium.value ?
          ths.toxInhalation.medium.name :
          riskInstance.cmrToxicRisk_inhalation_Score_HRP > ths.toxInhalation.low.value ?
            ths.toxInhalation.low.name : ths.toxInhalation.low.name;

    // Toxic risk contact
    riskInstance.cmrToxicRisk_contact_perc =
      divideHandler(riskInstance.cmrToxicRisk_contact_Score_HRP, riskTotals.totalCmrToxicRisk_contact_Score_HRP);
    riskInstance.cmrToxicRisk_contact_Priority =
      riskInstance.cmrToxicRisk_contact_Score_HRP > ths.toxContact.high.value ?
        ths.toxContact.high.name :
        riskInstance.cmrToxicRisk_contact_Score_HRP > ths.toxContact.medium.value ?
          ths.toxContact.medium.name :
          riskInstance.cmrToxicRisk_contact_Score_HRP > ths.toxContact.low.value ?
            ths.toxContact.low.name : ths.toxContact.low.name;

    // Fire risk potential
    riskInstance.fireExplosion_potential_perc =
      divideHandler(riskInstance.fireExplosion_potential_Score_HRP, riskTotals.totalFireExplosion_potential_Score_HRP);
    riskInstance.fireExplosion_potential_Priority =
      riskInstance.fireExplosion_potential_Score_HRP > ths.explScore.extremelyHigh.value ?
        ths.explScore.extremelyHigh.name :
        riskInstance.fireExplosion_potential_Score_HRP > ths.explScore.high.value ?
          ths.explScore.high.name :
          riskInstance.fireExplosion_potential_Score_HRP > ths.explScore.medium.value ?
            ths.explScore.medium.name :
            riskInstance.fireExplosion_potential_Score_HRP > ths.explScore.low.value ?
              ths.explScore.low.name : ths.explScore.low.name;

    // Environment risk potential
    // --------------- Water
    riskInstance.environment_potential_water_perc = divideHandler(
      riskInstance.environment_potential_water_Score_HRP, riskTotals.totalEnvironment_potential_water_Score_HRP
    );
    riskInstance.environment_potential_water_Priority =
      riskInstance.environment_potential_water_Score_HRP > ths.envScore.extremelyHigh.value ?
        ths.envScore.extremelyHigh.name :
        riskInstance.environment_potential_water_Score_HRP > ths.envScore.high.value ?
          ths.envScore.high.name :
          riskInstance.environment_potential_water_Score_HRP > ths.envScore.medium.value ?
            ths.envScore.medium.name :
            riskInstance.environment_potential_water_Score_HRP > ths.envScore.low.value ?
              ths.envScore.low.name : ths.envScore.low.name;

    // ------------- Air
    riskInstance.environment_potential_air_perc = divideHandler(
      riskInstance.environment_potential_air_Score_HRP, riskTotals.totalEnvironment_potential_air_Score_HRP
    );
    riskInstance.environment_potential_air_Priority =
      riskInstance.environment_potential_air_Score_HRP > ths.envScore.extremelyHigh.value ?
        ths.envScore.extremelyHigh.name :
        riskInstance.environment_potential_air_Score_HRP > ths.envScore.high.value ?
          ths.envScore.high.name :
          riskInstance.environment_potential_air_Score_HRP > ths.envScore.medium.value ?
            ths.envScore.medium.name :
            riskInstance.environment_potential_air_Score_HRP > ths.envScore.low.value ?
              ths.envScore.low.name : ths.envScore.low.name;

    // -------------- Soil
    riskInstance.environment_potential_soil_perc = divideHandler(
      riskInstance.environment_potential_soil_Score_HRP, riskTotals.totalEnvironment_potential_soil_Score_HRP
    );
    riskInstance.environment_potential_soil_Priority =
      riskInstance.environment_potential_soil_Score_HRP > ths.envScore.extremelyHigh.value ?
        ths.envScore.extremelyHigh.name :
        riskInstance.environment_potential_soil_Score_HRP > ths.envScore.high.value ?
          ths.envScore.high.name :
          riskInstance.environment_potential_soil_Score_HRP > ths.envScore.medium.value ?
            ths.envScore.medium.name :
            riskInstance.environment_potential_soil_Score_HRP > ths.envScore.low.value ?
              ths.envScore.low.name : ths.envScore.low.name;

    return riskInstance;
  }

  // Apps Script functions from risk sheet
  private Risk_Tox(
    Q_max: RiskAnalysis['riskAnalysisTotals']['maxAmmountPerPostPerYearL'],
    Q: RiskDb['ammountPerPostPerYearL'],
    Fi: RiskDb['frequencyClass04CfrTable1'],
    S: RiskAnalysis['cmrToxic_Class_max']) {

    // __Quantity__
    let Q_Risk = 0;
    const Q_Rel = Q / Q_max;

    if (Q_Rel > 1) { Q_Risk = 0; }
    else if (Q_Rel > .33) { Q_Risk = 5; }
    else if (Q_Rel > .12) { Q_Risk = 4; }
    else if (Q_Rel > .05) { Q_Risk = 3; }
    else if (Q_Rel > .01) { Q_Risk = 2; }
    else if (Q_Rel > 0) { Q_Risk = 1; }
    else { Q_Risk = 0; }

    // __Exposure__
    let F_Risk = 0;

    if (Fi === 0) { F_Risk = 0; }
    else if (Fi === 1) {
      if (Q_Risk === 5) { F_Risk = 4; }
      else if (Q_Risk === 4) { F_Risk = 3; }
      else if (Q_Risk === 3) { F_Risk = 3; }
      else { F_Risk = Q_Risk; }
    }
    else if (Fi === 4) {
      if (Q_Risk === 3) { F_Risk = 4; }
      else if (Q_Risk > 3) { F_Risk = 5; }
      else { F_Risk = Q_Risk; }
    }
    else { F_Risk = Q_Risk; }

    // __Potential Risk__
    let P_Risk = 0;

    if (F_Risk === 1) { P_Risk = Math.pow(10, S - 1); }
    else if (F_Risk === 2) { P_Risk = 3 * Math.pow(10, S - 1); }
    else if (F_Risk === 3) { P_Risk = Math.pow(10, S); }
    else if (F_Risk === 4) { P_Risk = 3 * Math.pow(10, S); }
    else if (F_Risk === 5) { P_Risk = Math.pow(10, S + 1); }
    else { P_Risk = 0; }

    const Risk = P_Risk;
    return Risk;
  }

  private Risk_Inh(
    S: RiskAnalysis['cmrToxic_Class_max'],
    GL: RiskDb['physicalStateRisk'],
    Tb: Product['liquid_temperatureBoilingPoint'],
    Tu: RiskDb['liquid_temperatureUsage'],
    Pr: RiskDb['proces'],
    Sp: RiskDb['workplaceVentilation'],
    Solid: RiskDb['solid_descriptionSolid'],
    ths: Ths
  ) {

    Pr = Pr.toLowerCase();
    Sp = Sp.toLowerCase();
    Solid = Solid.toLowerCase();

    // __Score danger__
    const S_Risk = Math.pow(10, S - 1);

    // __Score Vaporization__
    // Low level Tb = 1.22 * Tu + 57.78
    // High Level Tb = 1.44 * Tu + 115.56

    const LowLimit = 1.22 * Tu + 57.78;
    const HighLimit = 1.44 * Tu + 115.56;

    let V_Risk = 0;

    // let ShTHS = SpreadsheetApp.getActive().getSheetByName('THS');
    // let Range = ShTHS.getRange(54, 1);
    const Values1 = ths.toxPoudre.finePowderDust.name.toLowerCase(); // --Dust
    // let Range = ShTHS.getRange(55, 1);
    const Values2 = ths.toxPoudre.powder.name.toLowerCase(); // --Powder
    // const Range = ShTHS.getRange(56, 1);
    const Values3 = ths.toxPoudre.granulate.name.toLowerCase();

    if (GL === 'gas') { V_Risk = 100; }

    else if (GL === 'solid' && Solid === Values1) { V_Risk = 100; }
    else if (GL === 'solid' && Solid === Values2) { V_Risk = 10; }
    else if (GL === 'solid' && Solid === Values3) { V_Risk = 1; }

    else if (GL === 'liquid') {
      if (Tb > HighLimit) { V_Risk = 1; }
      else if (Tb > LowLimit) { V_Risk = 10; }
      else { V_Risk = 100; }
    }

    // __Score Process__

    // let ShTHS = SpreadsheetApp.getActive().getSheetByName('THS');
    let Pr_Risk = 0;
    const AvaluesKeys = Object.keys(ths.toxProcess) as (keyof Ths['toxProcess'])[];
    for (let i = 0; i < AvaluesKeys.length; i++) {
      // const Arange = ShTHS.getRange(7 + j, 1);
      const Avalues = AvaluesKeys[i];
      const AvaluesName = ths.toxProcess[Avalues].name.toLowerCase();

      // const Brange = ShTHS.getRange(7 + j, 3);
      const Bvalues = ths.toxProcess[Avalues].value;

      if (Pr === AvaluesName) { Pr_Risk = Bvalues; }
    }

    // __Score Space__

    // const ShTHS = SpreadsheetApp.getActive().getSheetByName('THS');
    let Sp_Risk = 0;
    const AvaluesKeys2 = Object.keys(ths.toxEspace) as (keyof Ths['toxEspace'])[];
    for (let i = 0; i < AvaluesKeys2.length; i++) {
      // const Arange = ShTHS.getRange(1 + k, 1);
      const Avalues = AvaluesKeys2[i];
      const AvaluesName = ths.toxEspace[Avalues].name.toLowerCase();

      // const Brange = ShTHS.getRange(1 + k, 3);
      const Bvalues = ths.toxEspace[Avalues].value;

      if (Sp === AvaluesName) { Sp_Risk = Bvalues; }
    }

    const Risk_Inh = S_Risk * V_Risk * Pr_Risk * Sp_Risk;
    return Risk_Inh;
  }

  private Risk_Cont(
    S: RiskAnalysis['cmrToxic_Class_max'],
    Ct: RiskDb['cmrToxicRisk_contactWithSkin'],
    Fi: RiskDb['frequencyClass04CfrTable1'],
    ths: Ths
  ) {

    Ct = Ct.toLowerCase();

    // var randnumber = Math.random()*5000;
    //  Utilities.sleep(randnumber);

    // __Score danger__
    const S_Risk = Math.pow(10, S - 1);

    // __Score Contact__

    // const ShTHS = SpreadsheetApp.getActive().getSheetByName('THS');
    let Ct_Risk = 0;
    const Arange = Object.keys(ths.toxContactWithSkin) as (keyof Ths['toxContactWithSkin'])[];
    for (let i = 0; i < Arange.length; i++) {
      // const Arange = ShTHS.getRange(23 + j, 1);
      const Avalues = Arange[i];
      const AvaluesName = ths.toxContactWithSkin[Avalues].name.toLowerCase();

      // const Brange = ShTHS.getRange(23 + j, 3);
      const Bvalues = ths.toxContactWithSkin[Avalues].value;

      if (Ct === AvaluesName) { Ct_Risk = Bvalues; }
    }

    // __Score Frequency__

    let F_Risk = 0;
    if (Fi === 1) { F_Risk = 1; }
    else if (Fi === 2) { F_Risk = 2; }
    else if (Fi === 3) { F_Risk = 5; }
    else if (Fi === 4) { F_Risk = 10; }
    else { F_Risk = 0; }

    const Risk_Cont = S_Risk * Ct_Risk * F_Risk;
    return Risk_Cont;
  }

  private Risk_Ex(
    Q_max: RiskAnalysis['riskAnalysisTotals']['maxAmmountPerPostPerYearKg'],
    Q: RiskDb['ammountPerPostPerYearKg'],
    S: RiskAnalysis['fire_Class_Danger'],
    All: RiskDb['ignitionSource'],
    ths: Ths
  ) {

    All = All.toLowerCase();

    //  var randnumber = Math.random()*5000;
    //  Utilities.sleep(randnumber);

    // __Quantity__
    let Q_Risk = 0;
    const Q_Rel = Q / Q_max;

    if (Q_Rel > 1) { Q_Risk = 0; }
    else if (Q_Rel > .33) { Q_Risk = 5; }
    else if (Q_Rel > .12) { Q_Risk = 4; }
    else if (Q_Rel > .05) { Q_Risk = 3; }
    else if (Q_Rel > .01) { Q_Risk = 2; }
    else if (Q_Rel > 0) { Q_Risk = 1; }
    else { Q_Risk = 0; }

    if (S === 1 && Q < 100) { Q_Risk = 0; }
    else if (S === 2 && Q < 10) { Q_Risk = 0; }
    else if (S === 3 && Q < 1) { Q_Risk = 0; }
    else if (S === 4 && Q < .1) { Q_Risk = 0; }
    else if (S === 5 && Q < .01) { Q_Risk = 0; }

    // __Potential Explosion Risk__Quantity & Severity__
    let F_Risk = 0;

    if (S === 0) { F_Risk = 0; }
    if (Q_Risk === 0) { F_Risk = 0; }

    else if (S === 1) { F_Risk = 1; }

    else if (S === 2) {
      if (Q_Risk < 3) { F_Risk = 1; }
      else { F_Risk = 2; }
    }

    else if (S === 3) {
      if (Q_Risk < 5) { F_Risk = 3; }
      else if (Q_Risk < 3) { F_Risk = 2; }
      else { F_Risk = 4; }
    }

    else if (S === 4) {
      if (Q_Risk < 5) { F_Risk = 4; }
      else if (Q_Risk < 3) { F_Risk = 3; }
      else { F_Risk = 5; }
    }

    else if (S === 5) {
      if (Q_Risk < 3) { F_Risk = 4; }
      else if (Q_Risk < 2) { F_Risk = 3; }
      else { F_Risk = 5; }
    }

    // --Explosion Risk-- Frequency & Ignition

    // const ShTHS = SpreadsheetApp.getActive().getSheetByName('THS');
    let Is = 0;
    const Arange = Object.keys(ths.explIgnitionSource) as (keyof Ths['explIgnitionSource'])[];
    for (let i = 0; i < Arange.length; i++) {
      // const Arange = ShTHS.getRange(42 + j, 1);
      const Avalues = Arange[i];
      const AvaluesName = ths.explIgnitionSource[Avalues].name.toLowerCase();

      // const Brange = ShTHS.getRange(42 + j, 3);
      const Bvalues = ths.explIgnitionSource[Avalues].value;

      if (All === AvaluesName) { Is = Bvalues; }
    }

    let P_Risk = 0;
    if (F_Risk === 0) { P_Risk = 0; }
    else if (Is === 1 && F_Risk === 1) { P_Risk = 1; }
    else if (Is === 1 && F_Risk === 2) { P_Risk = 3; }
    else if (Is === 1 && F_Risk === 3) { P_Risk = 30; }
    else if (Is === 1 && F_Risk === 4) { P_Risk = 300; }
    else if (Is === 1 && F_Risk === 5) { P_Risk = 2000; }
    else if (Is === 2 && F_Risk === 1) { P_Risk = 1; }
    else if (Is === 2 && F_Risk === 2) { P_Risk = 10; }
    else if (Is === 2 && F_Risk === 3) { P_Risk = 100; }
    else if (Is === 2 && F_Risk === 4) { P_Risk = 1000; }
    else if (Is === 2 && F_Risk === 5) { P_Risk = 5000; }
    else if (Is === 3 && F_Risk === 1) { P_Risk = 3; }
    else if (Is === 3 && F_Risk === 2) { P_Risk = 30; }
    else if (Is === 3 && F_Risk === 3) { P_Risk = 300; }
    else if (Is === 3 && F_Risk === 4) { P_Risk = 2000; }
    else if (Is === 3 && F_Risk === 5) { P_Risk = 10000; }
    else if (Is === 4 && F_Risk === 1) { P_Risk = 10; }
    else if (Is === 4 && F_Risk === 2) { P_Risk = 100; }
    else if (Is === 4 && F_Risk === 3) { P_Risk = 1000; }
    else if (Is === 4 && F_Risk === 4) { P_Risk = 5000; }
    else if (Is === 4 && F_Risk === 5) { P_Risk = 30000; }
    else if (Is === 5 && F_Risk === 1) { P_Risk = 30; }
    else if (Is === 5 && F_Risk === 2) { P_Risk = 300; }
    else if (Is === 5 && F_Risk === 3) { P_Risk = 2000; }
    else if (Is === 5 && F_Risk === 4) { P_Risk = 10000; }
    else if (Is === 5 && F_Risk === 5) { P_Risk = 100000; }

    const Risk = P_Risk;
    return Risk;
  }

  private Risk_Env(
    Q_max: RiskAnalysis['riskAnalysisTotals']['maxAmmountPerPostPerYearKg'],
    Q: RiskDb['ammountPerPostPerYearKg'],
    S: RiskAnalysis['environment_Class_Danger'],
    PD: RiskDb['environment_productOrWaste'],
    State: RiskDb['physicalStateRisk'],
    Env: 'water' | 'air' | 'soil', // Dynamic property
    ths: Ths,
  ) {

    PD = PD.toLowerCase();

    //  var randnumber = Math.random()*5000;
    //  Utilities.sleep(randnumber);

    // __Quantity__
    let Q_Risk = 0;
    const Q_Rel = Q / Q_max;

    if (Q_Rel > 1) { Q_Risk = 0; }
    else if (Q_Rel > .33) { Q_Risk = 5; }
    else if (Q_Rel > .12) { Q_Risk = 4; }
    else if (Q_Rel > .05) { Q_Risk = 3; }
    else if (Q_Rel > .01) { Q_Risk = 2; }
    else if (Q_Rel > 0) { Q_Risk = 1; }

    // const ShTHS = SpreadsheetApp.getActive().getSheetByName('THS');
    // let Range = ShTHS.getRange(50, 1);
    const Values1 = ths.envProduitDechet.product.name.toLowerCase(); // Product

    // let Range = ShTHS.getRange(51, 1);
    let Values2 = ths.envProduitDechet.toxicWaste.name.toLowerCase(); // Waste Tox

    // const Range = ShTHS.getRange(52, 1);
    const Values3 = ths.envProduitDechet.waste.name.toLowerCase(); // Waste

    if (PD === Values2) { Values2 = Values3; }
    if (PD === Values1 && S === 1 && Q < 100) { Q_Risk = 0; }
    else if (PD === Values1 && S === 2 && Q < 100) { Q_Risk = 0; }
    else if (PD === Values1 && S === 3 && Q < 5) { Q_Risk = 0; }
    else if (PD === Values1 && S === 4 && Q < 5) { Q_Risk = 0; }
    else if (PD === Values1 && S === 5 && Q < 5) { Q_Risk = 0; }
    else if (PD === Values2 && S === 1 && Q < 500) { Q_Risk = 0; }
    else if (PD === Values2 && S === 2 && Q < 500) { Q_Risk = 0; }
    else if (PD === Values2 && S === 3 && Q < 100) { Q_Risk = 0; }
    else if (PD === Values2 && S === 4 && Q < 100) { Q_Risk = 0; }
    else if (PD === Values2 && S === 5 && Q < 100) { Q_Risk = 0; }

    // --Environment Risk--

    let P_Risk = 0;
    if (Q_Risk === 0) { P_Risk = 0; }
    else if (S === 1 && Q_Risk === 1) { P_Risk = 1; }
    else if (S === 1 && Q_Risk === 2) { P_Risk = 1; }
    else if (S === 1 && Q_Risk === 3) { P_Risk = 2; }
    else if (S === 1 && Q_Risk === 4) { P_Risk = 5; }
    else if (S === 1 && Q_Risk === 5) { P_Risk = 10; }
    else if (S === 2 && Q_Risk === 1) { P_Risk = 2; }
    else if (S === 2 && Q_Risk === 2) { P_Risk = 5; }
    else if (S === 2 && Q_Risk === 3) { P_Risk = 10; }
    else if (S === 2 && Q_Risk === 4) { P_Risk = 30; }
    else if (S === 2 && Q_Risk === 5) { P_Risk = 100; }
    else if (S === 3 && Q_Risk === 1) { P_Risk = 10; }
    else if (S === 3 && Q_Risk === 2) { P_Risk = 30; }
    else if (S === 3 && Q_Risk === 3) { P_Risk = 100; }
    else if (S === 3 && Q_Risk === 4) { P_Risk = 1000; }
    else if (S === 3 && Q_Risk === 5) { P_Risk = 2000; }
    else if (S === 4 && Q_Risk === 1) { P_Risk = 100; }
    else if (S === 4 && Q_Risk === 2) { P_Risk = 1000; }
    else if (S === 4 && Q_Risk === 3) { P_Risk = 2000; }
    else if (S === 4 && Q_Risk === 4) { P_Risk = 5000; }
    else if (S === 4 && Q_Risk === 5) { P_Risk = 10000; }
    else if (S === 5 && Q_Risk === 1) { P_Risk = 2000; }
    else if (S === 5 && Q_Risk === 2) { P_Risk = 5000; }
    else if (S === 5 && Q_Risk === 3) { P_Risk = 10000; }
    else if (S === 5 && Q_Risk === 4) { P_Risk = 30000; }
    else if (S === 5 && Q_Risk === 5) { P_Risk = 100000; }

    let Risk = 0;
    if (State === 'gas' && Env === 'water') { Risk = 0.05 * P_Risk; }
    if (State === 'liquid' && Env === 'water') { Risk = 0.35 * P_Risk; }
    if (State === 'solid' && Env === 'water') { Risk = 0.005 * P_Risk; }

    if (State === 'gas' && Env === 'air') { Risk = 0.95 * P_Risk; }
    if (State === 'liquid' && Env === 'air') { Risk = 0.5 * P_Risk; }
    if (State === 'solid' && Env === 'air') { Risk = 0.001 * P_Risk; }

    if (State === 'gas' && Env === 'soil') { Risk = 0.001 * P_Risk; }
    if (State === 'liquid' && Env === 'soil') { Risk = 0.002 * P_Risk; }
    if (State === 'solid' && Env === 'soil') { Risk = 0.005 * P_Risk; }

    return Risk;
  }

  constructor() { }
}
