import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { cloneDeep } from 'lodash';
import { combineLatest, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LanguageClass } from '../../../../classes/language.class';
import { ProductRequest } from '../../../../classes/product-request.class';
import { ProductForm } from '../../../../classes/product-sections.class';
import { Product } from '../../../../classes/product.class';
import { CurrentUser } from '../../../../classes/user.class';
import { CustomValidators } from '../../../../product-request/validators.service';
import { ApiService } from '../../../../services/api.service';
import { ProductService } from '../../../../services/product.service';
import { UsersService } from '../../../../services/users.service';
import { LanguageKeys } from '../../../../_shared/_components/recursive-form/language-key.pipe';
import { RecursiveFormComponent, Validator } from '../../../../_shared/_components/recursive-form/recursive-form.component';
import { SnackbarComponent } from '../../../../_shared/_components/snackbar/snackbar.component';
import { LanguageClassPipe } from '../../../../_shared/_pipes/language-class.pipe';
import { AppService } from '../../../../_shared/_services/app.service';

@Component({
  selector: 'app-request-handler-product',
  templateUrl: './request-handler-product.component.html',
  styleUrls: ['./request-handler-product.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestHandlerProductComponent implements OnInit, OnChanges {

  @Input() productInput: Product;
  @Input() requestIndex: number;

  @Output() private validated = new EventEmitter<boolean>();
  @Output() private product = new EventEmitter<Product>();
  @Output() private productSignedOff = new EventEmitter<boolean>();

  @ViewChild('productFormFromElement') private productFormFromElement: RecursiveFormComponent;

  public _product: Product;
  public request: ProductRequest;
  private plant: string;
  public _productSignedOff = false;
  public sdsFiles: { [prop: string]: File | null } = {};
  // public tdsFiles: { [prop: string]: File | null } = {};
  // public foodcrtfFiles: { [prop: string]: File | null } = {};
  public newProductImage: File | undefined;
  public languages = this.appService.languages;
  public productFormValidated = false;
  private user: CurrentUser;

  public progressSpinner = false;
  public uploadStatus: string;

  // Observables
  private formOutput$ = new Subject<ProductForm>();
  public productForm: ProductForm;
  public productAutoCompleteList$ = this.productService.productAutoCompleteList$;
  public productOptionSelection$ = this.productService.productOptionSelections(this.languageClassPipe);
  public productFormValidators: Validator[];
  public translatedKeys$ = combineLatest(
    this.productService.keys$,
    this.userService.currentUser$
  ).pipe(
    filter(([_, user]) => user !== null),
    map(([keys, user]) => {
      this.user = user;

      // Set translations for form keys
      const translatedKeys: LanguageKeys = {};
      [...keys['plant'], ...keys['requestBasicInfo']].forEach(x => {
        if (x[user.language]) {
          translatedKeys[x.key] = x[user.language];
        } else {
          translatedKeys[x.key] = x.key;
        }
      });
      translatedKeys['email'] = 'E-mail';
      translatedKeys['username'] = 'Username';
      this.appService.languages.forEach(x => translatedKeys[x.value] = x.label);
      return translatedKeys;
    }));


  // SDS
  public getProductImage(image: File | undefined) { this.newProductImage = image; }

  public addSDSToFileList(file: File, language: string) {
    this.sdsFiles[language] = file;
  }
  public removeSDSToFileList(language: string) {
    this.sdsFiles[language] = null;
  }

  // public addTDSToFileList(file: File, language: string) {
  //   this.tdsFiles[language] = file;
  // }
  // public removeTDSToFileList(language: string) {
  //   this.tdsFiles[language] = null;
  // }

  // public addFoodCrtfToFileList(file: File, language: string) {
  //    this.sdsFiles[language] = file;
  // }
  // public removeFoodCrtfToFileList(language: string) {
  //   this.sdsFiles[language] = null;
  // }

  // Form methods
  public validateProductForm(status: boolean) {
    this.productFormValidated = status;
    this.validateAll();
  }

  private validateAll() {
    const isValid = this.productFormValidated;
    this.validated.emit(isValid);
  }

  public validateForm() {
    this.productFormFromElement.validateForm();
  }

  public setProductForm(form: ProductForm) {
    this._product.updateProductWithProductForm(form);
    this.formOutput$.next(form);
    this.product.emit(this._product);
  }

  public async signoffProduct() {
    this.progressSpinner = true;
    this.uploadStatus = 'Uploading files';

    // Update product
    const sdsFiles = Object.entries(this.sdsFiles).reduce((acc, [language, sds]: [keyof LanguageClass, File]) => {
      if (sds) { return [...acc, { file: sds, language }]; }
      return acc;
    }, []);
    const product = await this._product.approveProduct(
      sdsFiles,
      this.newProductImage,
      this.requestIndex,
      this.plant
    ).catch(() => void 0);

    if (!product) { this.matDialog.closeAll(); this.snackbar.snackbarError('Something went wrong'); }

    this.request.approval.product.signed = true;
    this.request.approval.product.date = new Date();
    this.request.approval.product.user = this.user;
    this._productSignedOff = true;
    this.product.emit(this._product);
    this.productSignedOff.emit(true);

    this.progressSpinner = false;
    this.uploadStatus = '';
    this.changeDetector.detectChanges();
  }

  public async denyRequest() {
    this.progressSpinner = true;
    this.uploadStatus = 'Deleting files';

    await this._product.removeRequest(this.requestIndex, this.plant);

    this.progressSpinner = false;
    this.uploadStatus = 'Done';
    this.matDialog.closeAll();
  }

  constructor(
    private validators: CustomValidators,
    private productService: ProductService,
    private languageClassPipe: LanguageClassPipe,
    private userService: UsersService,
    private appService: AppService,
    private matDialog: MatDialog,
    private snackbar: SnackbarComponent,
    private changeDetector: ChangeDetectorRef,
    private api: ApiService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['productInput'] && changes['requestIndex']) {
      const clonedProduct = cloneDeep(this.productInput);
      this._product = new Product(this.api, clonedProduct);
      this.request = clonedProduct.metaData.requests[this.requestIndex];
      this.plant = this.request.requestForm.plantInfo.metaDataPlant.plant;
      this._productSignedOff = this.request.approval.product.signed;
      this.productSignedOff.emit(this.request.approval.product.signed);

      this.productFormValidators = this.validators.productFormValidators(
        this.formOutput$,
        this.productService.products$,
        [...Object.values(this._product.name), this._product.supplier].filter(x => x)
      );
      this.productForm = new ProductForm(this._product);
      setTimeout(() => this.validateForm());
    }
  }

}
