import { Injectable } from '@angular/core';
import { combineLatest, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Classification } from '../../classes/classifications.class';
import { Key, LanguageClass } from '../../classes/language.class';
import { Location } from '../../classes/location.class';
import { ProductPlant } from '../../classes/product-plant.class';
import { Product } from '../../classes/product.class';
import { RiskAnalysis } from '../../classes/risk.class';
import { ProductService } from '../../services/product.service';
import { ProductTableComponent } from '../_components/product-table/product-table.component';
import { AppService } from './app.service';

interface ColumnDescriptor {
  src: string;
  key: string;
  customFunc: ((data: any) => any) | (false);
}

@Injectable({
  providedIn: 'root'
})
export class SubstanceRegisterExportService {

  constructor(
    private productService: ProductService
  ) {
  }

  private productKeys$ = this.productService.productKeys$;
  private riskKeys$ = this.productService.riskKeys$;
  private plantKeys$ = this.productService.plantKeys$;

  registerColumns: ColumnDescriptor[] = [
    { src: 'pi', key: 'name', customFunc: false },
    { src: 'pi', key: 'artikelnummerLev', customFunc: false },
    { src: 'pi', key: 'supplier', customFunc: false },
    { src: 'pi', key: 'casNummer', customFunc: this.parseComponents },
    { src: 'pi', key: 'hNr', customFunc: false },
    { src: 'pi', key: 'classificatiesHSentence', customFunc: this.parseHSentenceClassifications },
    { src: 'pi', key: 'ghs', customFunc: false },
    { src: 'pi', key: 'classificaties', customFunc: this.parseClassifications },
    { src: 'pi', key: 'cmr', customFunc: this.isProductCMR },
    { src: 'ra', key: 'workplaceIdentification', customFunc: false },
    { src: 'ra', key: 'contactWithProduct', customFunc: false },
    // { src: 'ra', key: 'contactWithProductValue', customFunc: this.parseContactWithProduct },
    { src: 'ra', key: 'peopleInContactWithSubstance', customFunc: false },
    // { src: 'ra', key: 'peopleInContactWithSubstanceValue', customFunc: false },
    { src: 'pi', key: 'h_and_p_value', customFunc: this.parseHSentenceValue },
    { src: 'ra', key: 'priority', customFunc: this.parsePriority },
    { src: 'pi', key: 'adr', customFunc: false },
    { src: 'pi', key: 'packingGroup', customFunc: false },
    { src: 'pi', key: 'un', customFunc: false },
    { src: 'pi', key: 'vapourDensity', customFunc: false },
    { src: 'pi', key: 'flashpoint', customFunc: false },
    { src: 'pi', key: 'liquid_temperatureBoilingPoint', customFunc: false },
    { src: 'pi', key: 'physicalState', customFunc: false },
    { src: 'pi', key: 'grenswaarde8uur', customFunc: false },
    { src: 'pi', key: 'grenswaarde8uurPpm', customFunc: false },
    { src: 'pi', key: 'grenswaarde15minuten', customFunc: false },
    { src: 'pi', key: 'grenswaarde15minutenPpm', customFunc: false },
    { src: 'pi', key: 'controlledDate', customFunc: this.formatControlledDate },
    { src: 'pi', key: 'vosRegistration', customFunc: false },
    { src: 'pi', key: 'vosPercentage', customFunc: false },
    { src: 'ra', key: 'ammountPerPostPerYearL', customFunc: false },
    { src: 'plant', key: 'maximumStockL', customFunc: false }
  ];
  registerKeys = combineLatest(
    of(this.registerColumns),
    this.productKeys$,
    this.riskKeys$,
    this.plantKeys$
  ).pipe(
    map(([columns, productKeys, riskKeys, plantKeys]) => {
      return columns
        .map(col => {
          switch (col.src) {
            case 'ra': return riskKeys.find(k => k.key === col.key) || new Key(col.key);
            case 'plant': return plantKeys.find(k => k.key === col.key) || new Key(col.key);
            case 'pi': default: return productKeys.find(k => k.key === col.key) || new Key(col.key);
          }
        })
        .filter(AppService.notNullOrUndefined);
    }),
    take(1)
  );

  private async parseComponents(data: { product: Product, table: ProductTableComponent }) {
    const { product, table } = data;
    let string = '';
    if (
      product.productComponents &&
      product.productComponents.length > 0 &&
      product.productComponents[0].casNummer
    ) {

      for (const comp of product.productComponents) {
        const name = await table.languageClassPipe.setLanguage(comp.name, table.language);
        string += `${comp.casNummer}:${name}\r\n`;
      }
    } else {
      const name = await table.languageClassPipe.setLanguage(product.name, table.language);
      if (product.casNummer && name) {
        string += `${product.casNummer}:${name}`;
      }
    }
    return string;
  }

  private isProductCMR(data: { product: Product }) {
    const { product } = data;
    const cmrCodes = ['H340', 'H341', 'H350', 'H351', 'H360F', 'H360D', 'H361f', 'H361d', 'H362'];
    if (product.hNr.length === 0) { return 'N/A'; }
    if (product.hNr.some(hNr => cmrCodes.includes(hNr))) { return 'Ja'; }
    return 'Nee';
  }

  private formatControlledDate(data: { product: Product, table: ProductTableComponent }) {
    const { product } = data;
    const controlledDate = product.controlledDate;
    const out = controlledDate.toUTCString().slice(0, -13);
    return out;
  }

  private parseHSentenceClassifications(data: { product: Product, table: ProductTableComponent }) {
    const { product, table } = data;
    const classifications = product.hAndPSentences.hSentences
      .map(sentence => sentence[table.language]).join('\r\n');
    return classifications;
  }

  private async parseClassifications(data: { product: Product, table: ProductTableComponent }) {
    const { product, table } = data;
    const currentClassificationCodes = product.classification;
    const classificationData = await this.productService.classification$.pipe(
      map(classifications => currentClassificationCodes.
        map(currentCode => classifications.find(classification => classification.Code === currentCode))),
      take(1),
      map((res: Classification[]) => {
        if (res.length && res.every(s => !!s)) {
          return res.map(c => c.hazard_statement ? `${c.hazard_statement} - ${c[table.language]}` : c[table.language]).join('\r\n');
        } else {
          return '';
        }
      })
    ).toPromise();

    return classificationData;
  }

  private parseHSentenceValue(data: { product: Product }) {
    const { product } = data;
    const values = product.hAndPSentences.hSentences
      .map(sentence => sentence.value);
    return Math.max(...values);
  }

  private parseContactWithProduct(data: { product: Product, lookup: RiskAnalysis[] }) {
    const lookupArray = ['', 'none', 'irregular', 'low', 'mediocre', 'high'];

    if (data.lookup.length === 0) { return 1; }

    const contactValue = data.lookup[0].contactWithProduct;

    return lookupArray.indexOf(contactValue);
  }

  private parsePriority(data: { product: Product, lookup: RiskAnalysis[] }) {
    if (data.lookup.length === 0) { return 0; }

    return this.parseContactWithProduct(data) *
      data.lookup[0].peopleInContactWithSubstance *
      Math.max(...data.product.hAndPSentences.hSentences.map(s => s.value), 0);
  }

  private getPlantForColumn = (plants: ProductPlant[], match: Location) => {
    return plants.find(plant => plant.metaDataPlant.plant === match.dbRef);
  }

  private parseRegisterColumn = async (table: ProductTableComponent, product: Product,
    column: ColumnDescriptor, plant: Location, raIndex: number) => {
    let lookup;
    const plantData = this.getPlantForColumn(product.metaData.plants, plant);
    if (!plantData) {
      console.error('No plant data found while parsing column', column);
      return;
    } else {
      switch (column.src) {
        case 'ra': lookup = plantData.riskAnalysis; break;
        case 'plant': lookup = plantData; break;
        case 'pi': default: lookup = product;
      }
    }

    const genericData = column.src === 'ra' ? lookup[raIndex] && lookup[raIndex][column.key] : lookup[column.key];

    if (column.customFunc) {
      const customFunction = column.customFunc.bind(this);
      return await customFunction({ product, lookup, table });
    }

    return await this.parseGeneric(table, genericData);
  }

  private async parseGeneric(table: ProductTableComponent, property: any) {

    if (property instanceof LanguageClass) {
      return await table.languageClassPipe.setLanguage(property, table.language);
    }
    if (property instanceof Date) {
      const date = property as Date;
      if (isNaN(date.getTime())) { return ''; }
      return (property as Date).toUTCString();
    }
    if (Array.isArray(property)) { return property.join('\r\n'); }
    if (property instanceof Date) { return property; }
    if (!property) { return null; }
    return property;
  }

  async formatRegisterSheetData(table: ProductTableComponent, plant: Location) {

    const totalTableRows: { product: Product, raIndex: number }[] = [];
    table.dataSource.data.forEach(p => {
      let riskAnalysesCounter = 0;
      const plantData = this.getPlantForColumn(p.metaData.plants, plant);
      if (plantData && plantData.riskAnalysis) {

        for (let i = 0; i < plantData.riskAnalysis.length; i++) {
          totalTableRows.push({ product: p, raIndex: i });
          riskAnalysesCounter++;
        }

        if (!plantData.riskAnalysis.length) {
          totalTableRows.push({ product: p, raIndex: 0 });
        }

      } else {
        totalTableRows.push({ product: p, raIndex: riskAnalysesCounter });
      }
    });

    return await totalTableRows.reduce(async (acc, rowData) => {
      const product = rowData.product;
      const accumulator = await acc;
      const newData = await Promise.all(this.registerColumns.map(async column => {
        return await this.parseRegisterColumn(table, product, column, plant, rowData.raIndex);
      }));
      return Promise.resolve([...accumulator, newData]);

    }, Promise.resolve([]));
  }
}
