import { Component, Input, OnInit } from '@angular/core';
import { UnSubstanceCategoryDb } from '../../classes/un.class';
import { UnEditComponent } from './un-edit/un-edit.component';
import { NewEntry } from '../../_shared/_components/info-table/info-table.component';

@Component({
  selector: 'app-un-list',
  templateUrl: './un-list.component.html',
  styleUrls: ['./un-list.component.css']
})
export class UnListComponent implements OnInit {

  public newEntry: NewEntry = { class: UnSubstanceCategoryDb, dependency: undefined };

  // Variables
  @Input() public unList: UnSubstanceCategoryDb[];

  public editComponent = UnEditComponent;

  constructor() { }

  ngOnInit() {
  }

}
