// tslint:disable: max-line-length
import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[appSvgFlag]'
})
export class SvgFlagDirective implements OnChanges, AfterViewInit {

  @Input() appSvgFlag: string;

  private svgStyle: string;

  dutch: string;
  english: string;
  french: string;
  german: string;

  public loadSVG() {

    const element = this.elementRef.nativeElement;
    const styles = getComputedStyle(element);
    const color = styles.color || '';

    this.svgStyle = `
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    width: inherit;
    height: inherit;
    padding-left: 7px;
    padding-right: 7px;
    fill: ${color};
  `;

    this.dutch = `<svg style="${this.svgStyle}" xmlns="http://www.w3.org/2000/svg" id="dutch" viewBox="0 0 640 480">
      <g fill-rule="evenodd" stroke-width="1pt" transform="scale(1.25 .9375)">
          <rect width="512" height="509.8" fill="#fff" rx="0" ry="0"/>
          <rect width="512" height="169.9" y="342.1" fill="#21468b" rx="0" ry="0"/>
          <path fill="#ae1c28" d="M0 0h512v170H0z"/>
      </g>
    </svg>`;

    this.english = `<svg style="${this.svgStyle}" xmlns="http://www.w3.org/2000/svg" id="english" viewBox="0 0 640 480">
      <defs>
          <clipPath id="a">
          <path fill-opacity=".7" d="M-85.3 0h682.6v512H-85.3z"/>
          </clipPath>
      </defs>
      <g clip-path="url(#a)" transform="translate(80) scale(.94)">
          <g stroke-width="1pt">
          <path fill="#006" d="M-256 0H768v512H-256z"/>
          <path fill="#fff" d="M-256 0v57.2L653.5 512H768v-57.2L-141.5 0H-256zM768 0v57.2L-141.5 512H-256v-57.2L653.5 0H768z"/>
          <path fill="#fff" d="M170.7 0v512h170.6V0H170.7zM-256 170.7v170.6H768V170.7H-256z"/>
          <path fill="#c00" d="M-256 204.8v102.4H768V204.8H-256zM204.8 0v512h102.4V0H204.8zM-256 512L85.3 341.3h76.4L-179.7 512H-256zm0-512L85.3 170.7H9L-256 38.2V0zm606.4 170.7L691.7 0H768L426.7 170.7h-76.3zM768 512L426.7 341.3H503l265 132.5V512z"/>
          </g>
      </g>
    </svg>`;

    this.french = `<svg style="${this.svgStyle}" xmlns="http://www.w3.org/2000/svg" id="french" viewBox="0 0 640 480">
      <g fill-rule="evenodd" stroke-width="1pt">
          <path fill="#fff" d="M0 0h640v480H0z"/>
          <path fill="#00267f" d="M0 0h213.3v480H0z"/>
          <path fill="#f31830" d="M426.7 0H640v480H426.7z"/>
      </g>
    </svg>`;

    this.german = `<svg style="${this.svgStyle}" xmlns="http://www.w3.org/2000/svg" id="german" viewBox="0 0 640 480">
      <path fill="#ffce00" d="M0 320h640v160H0z"/>
      <path fill="#000000" d="M0 0h640v160H0z"/>
      <path fill="#d00" d="M0 160h640v160H0z"/>
    </svg>`;


    if (this[this.appSvgFlag]) {
      element.innerHTML = this[this.appSvgFlag];
    } else { }
  }

  // Life cycle
  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnChanges() {
    this.loadSVG();
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

}
