import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { Key, LanguageClass } from '../../../classes/language.class';
import { PbmOption } from '../../../classes/pbm.class';
import { DialogComponent, DialogContent } from '../../../_shared/_components/dialog/dialog.component';
import { SnackbarComponent } from '../../../_shared/_components/snackbar/snackbar.component';

@Component({
  selector: 'app-pbm-options-list',
  templateUrl: './pbm-options-list.component.html',
  styleUrls: ['./pbm-options-list.component.css']
})
export class PbmOptionsListComponent implements OnInit, OnChanges {

  icons = [
    { value: '', viewValue: '' },
    { value: 'xxxx', viewValue: 'pbm_xxxx' },
    { value: 'M003', viewValue: 'pbm_M003' },
    { value: 'M004', viewValue: 'pbm_M004' },
    { value: 'M008', viewValue: 'pbm_M008' },
    { value: 'M009', viewValue: 'pbm_M009' },
    { value: 'M010', viewValue: 'pbm_M010' },
    { value: 'M013', viewValue: 'pbm_M013' },
    { value: 'M014', viewValue: 'pbm_M014' },
    { value: 'M015', viewValue: 'pbm_M015' },
    { value: 'M016', viewValue: 'pbm_M016' },
    { value: 'M017', viewValue: 'pbm_M017' },
    { value: 'M018', viewValue: 'pbm_M018' },
    { value: 'M020', viewValue: 'pbm_M020' },
    { value: 'M046', viewValue: 'pbm_M046' },
    { value: 'M047', viewValue: 'pbm_M047' },
    { value: 'M048', viewValue: 'pbm_M048' },
    { value: 'M053', viewValue: 'pbm_M053' },
    { value: 'E011', viewValue: 'pbm_E011' },
    { value: 'E012', viewValue: 'pbm_E012' }
  ];
  // Variables
  @Input() public pbmOptionsList: PbmOption[];
  @Input() public category: string;

  public displayedColumns: string[] = ['icon', 'image', 'english', 'dutch', 'french', 'german', 'remove'];
  public headers = Object.keys(new LanguageClass);

  public dataSource: MatTableDataSource<PbmOption>;
  public editable = false;

  @Output() public updatedTranslations = new EventEmitter<Key[]>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  // Table Methods
  public async saveData() {
    await PbmOption.savePbmOptions(this.dataSource.data);
    this.snackbar.snackbarSuccess('Updated pbm options');
  }

  public addEntry() {
    const data = this.dataSource.data;
    data.unshift(new PbmOption(undefined, this.category));
    this.dataSource.data = data;
  }

  public removeEntry(row: PbmOption, index: number) {
    const data: DialogContent = {
      dialogData: {
        title: 'Confirm',
        body: `Are you sure you want to delete this entry?`,
        button: 'Delete',
        button2: 'Cancel'
      }
    };
    const dialog = this.matDialog.open(DialogComponent, { data, disableClose: true });

    dialog.afterClosed().subscribe(async response => {
      if (!response) { return; }
      if (index === -1) { this.matDialog.closeAll(); return; }
      const dataTable = this.dataSource.data;
      dataTable.splice(index, 1);
      await row.deletePbmOption();
      this.dataSource.data = dataTable;
      this.snackbar.snackbarSuccess('Deleted Pbm entry');
      this.matDialog.closeAll();
    });
  }

  // Life cycle
  constructor(
    private snackbar: SnackbarComponent,
    private matDialog: MatDialog,
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.pageSize = 50;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['pbmOptionsList'] && changes['pbmOptionsList'].currentValue && changes['pbmOptionsList'].firstChange) {
      this.dataSource.data = this.pbmOptionsList;
    }
  }



}
