import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ProductTableService } from '../_shared/_components/product-table/product-table.service';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductEditComponent implements OnInit {

  public routerState: string;

  public product$ = this.productTableService.activeProduct$;

  // Variables
  public progressSpinner = false;

  // Listeners
  @HostListener('window:beforeunload', ['$event'])
  preventTabClose(event: BeforeUnloadEvent) {
    event.preventDefault();
    return false;
  }

  // Helper methods
  public closeDialog() {
    this.matDialog.closeAll();
  }

  // Life cycle
  constructor(
    private productTableService: ProductTableService,
    private matDialog: MatDialog,
    private router: Router,
  ) {
    let url = this.router.url;
    if (url.includes('?')) { url = url.split('?')[0]; }
    if (url.startsWith('/')) { url = url.substring(1); }
    this.routerState = url;
  }

  ngOnInit() { }

}
