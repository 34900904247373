import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { UsersService } from './services/users.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.checkLogin();
  }

  async checkLogin() {
    const user = await this.usersService.currentUser$.pipe(take(1)).toPromise();

    if (user && user.isAdmin) { return true; }

    // Navigate back
    this.router.navigate(['/']);
    return false;
  }

  constructor(private usersService: UsersService, private router: Router) { }

}
