import { AbstractControl, ValidatorFn } from '@angular/forms';

export function arrayLengthValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const test = control.value.length > 0 && control.value.some((x: any) => !x);
    return test ? { 'arrayLength': { value: control.value.length } } : null;
  };
}

export function zeroNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const test = control.value === 0;
    return test ? { 'zeroNumber': { value: control.value } } : null;
  };
}

export function optionsValidator(options: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === '') { return null; }
    const test = !options.some(x => x === control.value);
    return test ? { 'options': { value: control.value } } : null;
  };
}
