import { NgModule } from '@angular/core';
import { MatAutocompleteModule, MatBadgeModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatDialogModule, MatDividerModule, MatExpansionModule, MatFormFieldModule, MatIconModule, MatInputModule, MatMenuModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatSelectModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MAT_DATE_LOCALE, MatPaginatorIntl } from '@angular/material';
import { UiService } from '../../services/ui.service';
import { UsersService } from '../../services/users.service';
import { SDSPaginatorIntl } from '../../classes/uitranslations.class';
import { DropdownService } from '../../services/dropdown.service';

const matModules = [
  MatButtonModule,
  MatToolbarModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatSnackBarModule,
  MatDialogModule,
  MatProgressBarModule,
  MatTabsModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatSelectModule,
  MatIconModule,
  MatExpansionModule,
  MatSlideToggleModule,
  MatCheckboxModule,
  MatMenuModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatProgressSpinnerModule,
  MatButtonToggleModule,
  MatStepperModule,
  MatBadgeModule,
  MatDividerModule,
  MatAutocompleteModule,
  MatChipsModule,
];

@NgModule({
  imports: matModules,
  exports: matModules,
  declarations: [],
  providers: [
    UiService,
    DropdownService,
    UsersService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: MatPaginatorIntl, deps: [UiService, UsersService],
      useFactory: (uiService: UiService, userService: UsersService) => new SDSPaginatorIntl(uiService, userService)
    }
  ]
})
export class MaterialModule { }

