import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Hnumber, Pnumber } from '../../classes/h-and-p.class';
import { LanguageClass } from '../../classes/language.class';
import { ProductPlant } from '../../classes/product-plant.class';
import { Product } from '../../classes/product.class';
import { CurrentUser } from '../../classes/user.class';
import { ProductService } from '../../services/product.service';
import { UsersService } from '../../services/users.service';
import { LanguageKeys } from '../../_shared/_components/recursive-form/language-key.pipe';
import { LazyScriptsService } from '../../_shared/_services/lazy-scripts.service';

declare function html2pdf(): any;

/**
 * Enum to categorise hazard statements in the VIK module.
 * The category is based on the UI translation key.
 */
enum HazardsToUiKey {
  'H2' = 'hazard_statements.hazard_statement_for_physical_hazards',
  'H3' = 'hazard_statements.hazard_statements_for_health_hazards',
  'H4' = 'hazard_statements.hazard_statements_for_environment_hazards',
  'EUH001' = 'additional_hazard_info.physical_properties',
  'EUH014' = 'additional_hazard_info.physical_properties',
  'EUH018' = 'additional_hazard_info.physical_properties',
  'EUH019' = 'additional_hazard_info.physical_properties',
  'EUH044' = 'additional_hazard_info.physical_properties',
  'EUH029' = 'additional_hazard_info.health_properties',
  'EUH031' = 'additional_hazard_info.health_properties',
  'EUH032' = 'additional_hazard_info.health_properties',
  'EUH066' = 'additional_hazard_info.health_properties',
  'EUH070' = 'additional_hazard_info.health_properties',
  'EUH071' = 'additional_hazard_info.health_properties',
  'EUH059' = 'additional_hazard_info.environmental_properties',
  'P1' = 'precautionary_measures.general',
  'P2' = 'precautionary_measures.precautionary_statement_prevention',
  'P3' = 'precautionary_measures.precautionary_statement_response'
}

@Component({
  selector: 'app-vik-info',
  templateUrl: './vik-info.component.html',
  styleUrls: ['./vik-info.component.css']
})
export class VikInfoComponent implements OnInit, OnChanges, OnDestroy {

  private _subs = new Subscription;

  @Input() public product: Product;

  public user: CurrentUser;
  public plant: ProductPlant;
  public asyncOperation = false;

  public firstAid?: { [prop: string]: LanguageClass };
  public fire?: { [prop: string]: LanguageClass };
  public hSentences?: { [prop: string]: Hnumber };
  public pSentences?: { [prop: string]: Pnumber };

  // We need to translate the keys here because the dynamic form stands on its own.
  private productKeys$ = combineLatest(
    this.productService.productKeys$,
    this.userService.currentUser$
  ).pipe(
    map(([keys, user]) => {
      const language = user.language;
      return keys.reduce((obj, x) => {
        obj[x.key] = x[language] || x.key;
        return obj;
      }, {} as LanguageKeys);
    })
  );

  public languageKeys: LanguageKeys;

  public checkForGhs(img: string) {
    return img.startsWith('GHS') && img.split('S')[1].length === 2;
  }

  public async pdfdownload() {
    this.asyncOperation = true;

    const vikElement = document.getElementById('vik');
    if (!vikElement) { return; }

    const opt = {
      margin: 10,
      filename: this.product.name[this.user.language] + '-' + this.product.supplier + '-VIK-' + new Date().toLocaleDateString() + '.pdf',
      image: { type: 'png', quality: 2 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      pagebreak: { mode: 'avoid-all' }
    };

    await html2pdf().set(opt).from(vikElement).save();
    this.asyncOperation = false;
  }

  private _convertSentenceToObjectForTemplate<T>(sentences: (Hnumber | Pnumber)[]): { [prop: string]: T; } | undefined {
    return sentences.reduce((acc, x) => {

      const uiKeys = HazardsToUiKey[x.code] || HazardsToUiKey[x.code.slice(0, 2)];
      if (!uiKeys) { return acc; }

      const splitUiKeys = uiKeys.split('.');
      if (!acc[splitUiKeys[0]]) { acc[splitUiKeys[0]] = {}; }

      acc[splitUiKeys[0]][splitUiKeys[1]] = [...acc[splitUiKeys[0]][splitUiKeys[1]] || [], x];
      return acc;
    }, {});
  }

  constructor(
    private productService: ProductService,
    private lazyScriptsService: LazyScriptsService,
    private userService: UsersService
  ) { }

  ngOnInit() {
    this.lazyScriptsService.loadScript('html2pdf');

    const userSub = this.userService.currentUser$.subscribe(user => {
      this.user = user;
      if (!this.product) { return; }
      const plant = this.product.metaData.plants.find(x => x.metaDataPlant.plant === this.user.plant);
      if (plant) { this.plant = plant; }
    });

    const keySub = this.productKeys$.subscribe(keys => this.languageKeys = keys);

    this._subs.add(userSub);
    this._subs.add(keySub);

    // ----- Group product items -----
    this.hSentences = this._convertSentenceToObjectForTemplate(this.product.hAndPSentences.hSentences);
    this.pSentences = this._convertSentenceToObjectForTemplate(this.product.hAndPSentences.pSentences);

    this.firstAid = {
      inhalation: this.product.inhalation,
      skinContact: this.product.skinContact,
      eyeContact: this.product.eyeContact,
      ingestion: this.product.ingestion
    };
    if (!Object.values(this.firstAid).some(x => Object.values(x).some(y => y))) {
      this.firstAid = undefined;
    }

    this.fire = {
      suiteableExtinguishingMedia: this.product.suiteableExtinguishingMedia,
      unsuiteableExtinguishingMedia: this.product.unsuiteableExtinguishingMedia,
    };
    if (!Object.values(this.fire).some(x => Object.values(x).some(y => y))) {
      this.fire = undefined;
    }
    // --------------------------
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['product'] && changes['product'].currentValue && this.user) {
      const plant = this.product.metaData.plants.find(x => x.metaDataPlant.plant === this.user.plant);
      if (plant) { this.plant = plant; }
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
