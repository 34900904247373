import { Injectable } from '@angular/core';
import { shareReplay, map } from 'rxjs/operators';
import { ProductService } from './product.service';
import { Key } from '../classes/language.class';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DropdownService {

    constructor(private productService: ProductService) { }

    /**
    * All dropdown keys
    */
    public dropdownKeys$: Observable<Key[]> = this.productService.keys$.pipe(
        map(keys => keys['dropdown']),
        shareReplay(1)
    );

    /**
     * Active dropdown keys
     */
    public activeDropdownKeys$ = this.dropdownKeys$.pipe(
        map(keys => keys.filter(key => key.visible)),
        shareReplay(1)
    );
}
