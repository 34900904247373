import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invalidDateCheck'
})
export class InvalidDateCheckPipe implements PipeTransform {

  transform(value: any): any {
    if (!(value instanceof Date)) { return value; }
    if (isNaN(value.getTime())) { return ''; }
    return value;
  }

}
