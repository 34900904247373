import { ApiService } from '../services/api.service';

export class ClassificationDb {

    Code = '';
    classification = '';
    hazard_type = '';
    hazard_statement = '';
    dutch = '';
    english = '';
    french = '';
    german = '';

    constructor(input: ClassificationDb | undefined) {
        if (input) {

            Object.keys(input).forEach(key => {
                switch (key) {
                    default: if (this[key] !== undefined) { this[key] = input[key]; }
                }
            });
        }
    }
}

export class Classification extends ClassificationDb {

    static api: ApiService;


    constructor(api: ApiService, input?: ClassificationDb) {
        super(input);

        Classification.api = api;
    }
}
