import { Pipe, PipeTransform } from '@angular/core';
import { Location } from '../../classes/location.class';

@Pipe({
  name: 'locationToString'
})
export class LocationToStringPipe implements PipeTransform {

  transform(value: Location, ): any {
    if (!(value instanceof Location)) { return value; }

    return value.lokatie + ' - ' + value.description;
  }

}
