import { MetaDataProductPlantServer, NoteDbServer, ProductPlantDbServer, ProductPlantVlaremStockDbServer } from '../../../server/src/routes/product/types';
import { PbmOptions, PbmOptionsDb } from './pbm.class';
import { RiskAnalysis, RiskDb } from './risk.class';
import { Seveso } from './seveso.class';
import { UserDb } from './user.class';

export class Note implements NoteDbServer {
  note = '';
  submittedBy = new UserDb;
  submittedOn = new Date();

  constructor(input?: Note, user?: UserDb) {
    if (user) { this.submittedBy = user; }

    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          case 'submittedBy': this[key] = new UserDb(input[key]); break;
          case 'submittedOn': this[key] = new Date(input[key]); break;
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }
}

export class MetaDataProductPlant implements MetaDataProductPlantServer {
  plant = '';
  archived = false;
  dateArchived = '';
  notes: Note[] = [];

  constructor(input?: MetaDataProductPlant) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          case 'notes': this[key] = input[key].map(x => new Note(x)); break;
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }

}

export class ProductPlantVlaremStockDb implements ProductPlantVlaremStockDbServer {
  solidTanksMaxStockKG = 0;
  solidTanksMaxStockL = 0;
  movableRecipientsMaxStockKG = 0;
  movableRecipientsMaxStockL = 0;

  constructor(input?: ProductPlantVlaremStockDb) {
    if (input) {
      Object.keys(input).forEach(key => {
        if (this[key] !== undefined) { this[key] = input[key]; }
      });
    }
  }
}

export class ProductPlantDb implements ProductPlantDbServer {

  metaDataPlant = new MetaDataProductPlant();
  risk: RiskDb[] = [];
  pbm = new PbmOptionsDb();

  vlaremStock = new ProductPlantVlaremStockDb();

  maximumStockKG = 0;
  maximumStockL = 0;
  vlarem_17_4 = false;



  constructor(input: ProductPlantDb | undefined) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          case 'metaDataPlant': this[key] = new MetaDataProductPlant(input[key]); break;
          case 'risk': this[key] = input[key].map(x => new RiskDb(x)); break;
          case 'pbm': this[key] = new PbmOptionsDb(input[key]); break;
          case 'vlaremStock': this[key] = new ProductPlantVlaremStockDb(input[key]); break;
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }
}
export class ProductPlant extends ProductPlantDb {

  // Calculated properties
  riskAnalysis: RiskAnalysis[] = [];
  seveso?: Seveso;

  pbmOptions: PbmOptions;

  vlarem_17_4 = false;

  constructor(input?: ProductPlant) {
    super(input);

    if (input) {

      const keys = Object.keys(input).filter(x => x !== 'vlaremStock');

      keys.forEach(key => {
        switch (key) {
          case 'risk': this.riskAnalysis = input[key].map(risk => new RiskAnalysis(risk)); break;
          case 'seveso': this.seveso = input[key]; break;
          case 'pbmOptions': this.pbmOptions = input[key]; break;
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }

}
