import { Pipe, PipeTransform } from '@angular/core';
import { Key } from '../../../classes/language.class';

@Pipe({
  name: 'checkHideUi'
})
export class CheckHideUiPipe implements PipeTransform {

  transform(keys: Key[]): Key[] {
    return keys.filter((key: Key) => !key.hideInUi);
  }
}
