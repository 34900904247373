import { ApiService } from '../services/api.service';

export class UserDb {
  userId = '';
  username = '';
  language: 'dutch' | 'english' | 'french' | 'german' = 'english';
  email = '';
  plant = '';
  dbRef = '';

  constructor(input?: UserDb | null) {
    if (input) {
      Object.keys(input).forEach(key => {
        if (this[key] !== undefined) { this[key] = input[key]; }
      });
    }
  }

}
export class User extends UserDb {

  static api: ApiService;

  async updatePlant(plant: string) {
    return User.api.updateUserPlant(plant);
  }

  constructor(
    input: UserDb | undefined,
    api: ApiService,
  ) {
    super(input);

    User.api = api;
  }

}

export class CurrentUser extends User {

  isAdmin = false;
  isDeveloper = false; // Is not set anywhere atm

  async updateLanguage(language: string) {
    return User.api.updateUserLanguage(language);
  }

  constructor(
    input: UserDb | undefined,
    api: ApiService,
    isAdmin: boolean
  ) {
    super(input, api);
    this.isAdmin = isAdmin;
  }

}
