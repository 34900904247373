// import { join, resolve as resolvePath, sep } from 'path';
import * as serviceAccountProd from "./google/service-account-prod.json";
import * as serviceAccountDev from "./google/service-account-dev.json";

// export const appRoot: Readonly<string> = resolvePath(join(__dirname, '../../../../')) + sep;
type environments = "dev" | "prod";
export const env = "dev" as environments;

export const googleAuthProd = {
  credentials: serviceAccountProd,
  projectId: serviceAccountProd.project_id,
};
export const googleAuthDev = {
  credentials: serviceAccountDev,
  projectId: serviceAccountDev.project_id,
};
export const googleAuth = env === "prod" ? googleAuthProd : googleAuthDev;

export const appName = "Zzapps app";
export const cloudfunctionUrl = `us-central1-${googleAuth.projectId}.cloudfunctions.net`;
export const acceptedDomains = ["zzapps.nl", "demo.zzapps.nl"];
export const developerDomains = ["zzapps.nl", "demo.zzapps.nl"];
export const errorMailTo = "";
export const errorMailFrom = "";
export const gmailCredentials = { gmail: "", password: "" };

export const googleAPIKey = "AIzaSyCpwVjtRjOBA0cy2FhM_s-4r6YbZP5u0A4";
export const appRoot = "/usr/src/";

export const newAppUrl =
  env === "prod" ? "https://sds.zzapps.app" : "https://sds.zzapps.dev";
