import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { combineLatest, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProductPlant } from '../../classes/product-plant.class';
import { Product } from '../../classes/product.class';
import { CurrentUser } from '../../classes/user.class';
import { CustomValidators } from '../../product-request/validators.service';
import { ProductService } from '../../services/product.service';
import { UsersService } from '../../services/users.service';
import { VlaremService } from '../../services/vlarem.service';
import { LanguageKeys } from '../../_shared/_components/recursive-form/language-key.pipe';
import { RecursiveFormComponent } from '../../_shared/_components/recursive-form/recursive-form.component';
import { SnackbarComponent } from '../../_shared/_components/snackbar/snackbar.component';
// import { ExplanationsService } from '../../services/explanations.service';

@Component({
  selector: 'app-product-plant-edit',
  templateUrl: './product-plant-edit.component.html',
  styleUrls: ['./product-plant-edit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductPlantEditComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  private _subs = new Subscription();

  @Input() product: Product;

  @ViewChild(RecursiveFormComponent) plantFormComponent: RecursiveFormComponent;

  private user: CurrentUser;
  public plant: ProductPlant;
  private plantIndex: number;
  private plantForm: ProductPlant;
  public plantFormValidators = this.validators.productPlantFormValidators();
  public isValid: boolean;

  // UI
  public isSaving = false;

  // Field control
  public readOnlyFields = ['maximumStockKG', 'maximumStockL'];

  public activeTranslatedDescriptions$ = combineLatest(
    this.userService.currentUser$,
    this.productService.plantKeys$,
  ).pipe(filter(([user]) => user !== null),
    map(([user, keys]) => {

      // Set translations for form keys
      const translatedDescriptions: LanguageKeys = {};
      keys.forEach(x => {
        if (x.description && x.description.visible && x.description[user.language]) {
          translatedDescriptions[x.key] = x.description[user.language];
        }
      });
      return translatedDescriptions;
    }));

  // We need to translate the keys here because the dynamic form stands on its own
  public translatedKeys$ = combineLatest(
    this.userService.currentUser$,
    this.productService.plantKeys$,
  ).pipe(filter(([user]) => user !== null),
    map(([user, keys]) => {

      // Set translations for form keys
      const translatedKeys: LanguageKeys = {};
      keys.forEach(x => {
        if (x[user.language]) {
          translatedKeys[x.key] = x[user.language];
        } else {
          translatedKeys[x.key] = x.key;
        }
      });
      return translatedKeys;
    }));


  public setProductPlantForm(form: ProductPlant) {
    this.plantForm = new ProductPlant(form);
  }

  public async saveProduct() {
    this.isSaving = true;
    this.product.metaData.plants[this.plantIndex] = new ProductPlant(this.plantForm);
    // this.product.pbmOptions = new PbmOptions(undefined, undefined, this.pbmOptions);
    await this.product.saveProduct();
    this.snackbar.snackbarSuccess('Product saved');
    this.isSaving = false;
  }

  constructor(
    private changeDetector: ChangeDetectorRef,
    private userService: UsersService,
    private validators: CustomValidators,
    private productService: ProductService,
    private snackbar: SnackbarComponent,
    private vlaremService: VlaremService
    // private explanationService: ExplanationsService
  ) { }


  ngOnInit() {
    this._subs.add(
      this.userService.currentUser$.subscribe(user => {
        this.user = user;
        if (this.product && !this.plant) {
          const clonedProduct = cloneDeep(this.product);
          const indexPlant = clonedProduct.metaData.plants.findIndex(x => x.metaDataPlant.plant === this.user.plant);
          if (indexPlant === -1) { throw new Error('Plant not found'); }
          this.plantIndex = indexPlant;
          this.plant = clonedProduct.metaData.plants[indexPlant];
          this.changeDetector.detectChanges();
          this.plantFormComponent.validateForm();
        }
      }));

  }

  ngAfterViewInit() {
    // a function to auto count the stock values
    this._subs.add(
      this.vlaremService.setVlaremListener(this.plantFormComponent).subscribe()
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['product'] && this.product && this.user) {
      const clonedProduct = cloneDeep(this.product);
      const indexPlant = clonedProduct.metaData.plants.findIndex(x => x.metaDataPlant.plant === this.user.plant);
      if (indexPlant === -1) { throw new Error('Plant not found'); }
      this.plantIndex = indexPlant;
      this.plant = clonedProduct.metaData.plants[indexPlant];
      this.changeDetector.detectChanges();
      this.plantFormComponent.validateForm();
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

}
