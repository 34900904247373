
export class LanguageClass {
  dutch = '';
  english = '';
  french = '';
  german = '';

  constructor(input?: LanguageClass) {
    if (input) {
      Object.keys(input).forEach(key => {
        if (this[key] !== undefined) { this[key] = input[key]; }
      });
    }
  }

}

export class KeyDbCategory extends LanguageClass {
  visible = true;

  constructor(input?: LanguageClass) {
    super(input);
    if (input) {
      Object.keys(input).forEach(key => {
        if (this[key] !== undefined) { this[key] = input[key]; }
      });
    }
  }
}


export class KeyCategory extends KeyDbCategory {
  key = '';

  constructor(parent?: Key, input?: LanguageClass) {
    super(input);
    if (parent) {
      this.key = parent.key;
    }
  }
}

export class KeyDb extends LanguageClass {
  key = '';
  visible = true;
  tooltip = new KeyDbCategory();
  description = new KeyDbCategory();

  constructor(input?: KeyDb) {
    super(input);
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          case 'tooltip': this.tooltip = new KeyDbCategory(input[key]); break;
          case 'description': this.description = new KeyDbCategory(input[key]); break;
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }

  }
}

export class Key extends KeyDb {

  hideInUi = false;

  constructor(keyValue?: string, input?: KeyDb, setToAll?: boolean) {
    super(input);

    if (keyValue) { this.key = keyValue; }
    if (keyValue && setToAll) {
      const keys = Object.keys(new LanguageClass);
      keys.forEach(x => this[x] = keyValue[x]);
    }

  }

}

export class TranslationDb {
  [prop: string]: LanguageClass[];

  constructor(input: TranslationDb) {
    Object.keys(input).forEach(x => {
      this[x] = input[x].map(y => new LanguageClass(y));
    });
  }

}

export class Translations {

  [prop: string]: LanguageClass[];

  constructor(input: TranslationDb[]) {
    input.forEach(translation => {
      Object.entries(translation).forEach(([key, value]) => {
        this[key] = value;
      });
    });
  }

}
