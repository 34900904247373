import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorReturn, ValidationError } from 'server/src/types';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackbarComponent } from '../_components/snackbar/snackbar.component';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private snackbar: SnackbarComponent) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Check for error status on incomming responses
    return next.handle(request).pipe(catchError((responseError: ErrorReturn) => {

      // Always throw catched responses
      console.error(responseError.error);

      if (responseError.error && responseError.error.validationError) {
        const error = responseError.error.validationError as ValidationError[];

        // const errorMessage = error.reduce((string, x, i) => {
        //   string = `${string}${i === 0 ? '' : ', '}${x.property}`;
        //   return string;
        // }, 'Could not validate: ');
        let errorMessage = 'Could not validate: ';
        error.forEach((err: ValidationError) => {
          errorMessage += extractField(err.toString()) + ', ';
        });

        this.snackbar.snackbarError(errorMessage.substr(0, errorMessage.length - 2));
      }

      return throwError(responseError);
    }));
  }

}
export const ErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true,
};

function extractField(errorString: string) {
  try {
    return errorString.split('property ')[1].split(' has')[0];
  } catch (err) {
    return errorString;
  }
}

