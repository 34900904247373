import { UsefulLinksLinkServer, UsefulLinksSectionServer } from '../../../server/src/routes/useful-links/types';
import { LanguageClass } from './language.class';
import { Languages } from '../_types/app';

export class UsefulLinksLinkDb extends LanguageClass implements UsefulLinksLinkServer {

    section = '';

    constructor(input?: UsefulLinksLinkDb) {
        super(input);
        if (input) {
            if (input['section'] !== undefined) { this.section = input['section']; }
        }
    }
}

export class UsefulLinksSectionDb extends LanguageClass implements UsefulLinksSectionServer {

    id = '';
    orderId = 0;

    constructor(input?: UsefulLinksSectionDb) {
        super(input);
        if (input) {
            if (input['id'] !== undefined) { this.id = input['id']; }
            if (input['orderId'] !== undefined) { this.orderId = input['orderId']; }
        }
    }
}

interface UsefulLinkParsed {
    title: string;
    url: string;
}

// R: TODO: Remove dependency on 'getTitle(), getURL()'.
// R: These can be observables that internally resolve their value.
// R: Making it so that the template doesn't depend on checking their value on every change-detection.
class UsefulLinkParsedMarkDown extends UsefulLinksLinkDb {

    public parsedLink = {} as { [L in Languages]: UsefulLinkParsed };
    public activeLanguage: UsefulLinkParsed;

    constructor(input?: UsefulLinksLinkDb) {
        super(input);

        if (input) {
            Object.keys(new LanguageClass()).forEach(key => {
                if (input[key] !== undefined) {
                    this.parsedLink[key] = this.parse(input[key]);
                }
            });
        }
    }

    /**
     * Get the parsed link title
     *
     * First try to get the current language title
     * else grab the first language from the LanguageClass languages
     *
     * @returns {string}
     */
    public getTitle(): string {
        if (this.activeLanguage.title) {
            return this.activeLanguage.title;
        } else {
            return this.getParsedPropery('title');
        }
    }

    /**
     * Get the parsed link url
     *
     * First try to get the current language title
     * else grab the first language from the LanguageClass languages
     *
     * @returns {string}
     */
    public getUrl(): string {
        if (this.activeLanguage.url) {
            return this.activeLanguage.url;
        } else {
            return this.getParsedPropery('url');
        }
    }

    private getParsedPropery(prop: keyof UsefulLinkParsed): string {
        for (const lang of Object.keys(new LanguageClass())) {
            if (this.parsedLink[lang][prop] !== '') { return this.parsedLink[lang][prop]; }
        }
        return '';
    }

    private parse(markDownStr: string): UsefulLinkParsed {
        const parsedLink: UsefulLinkParsed = {
            title: '',
            url: ''
        };

        // parse the title and url from format [title](https://www.domain.com)
        const data = markDownStr.trim().match(/\[([^\]]+)\]\(([^)]+)\)/);

        if (data && data.length === 3) {
            parsedLink.title = data[1];
            parsedLink.url = data[2];
        }

        return parsedLink;
    }
}

export class UsefulLinksPerSection extends UsefulLinksSectionDb {

    links: UsefulLinkParsedMarkDown[] = [];
    hasLinks: Boolean = false;
    activeLanguage = '';

    constructor(input?: UsefulLinksSectionDb, links?: UsefulLinksLinkDb[]) {
        super(input);

        if (links && links.length > 0) {
            this.links = links.map(link => new UsefulLinkParsedMarkDown(link));
            this.hasLinks = true;
        }
    }

    /**
     * Get the sections title
     *
     * First try to get the current language title
     * else grab the first language from the LanguageClass languages
     *
     * @returns {string}
     */
    public getTitle(): string {
        if (this.activeLanguage) {
            return this.activeLanguage;
        } else {
            for (const lang of Object.keys(new LanguageClass())) {
                if (this[lang] !== '') { return this[lang]; }
            }
            return '';
        }
    }
}
