import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { cloneDeep } from 'lodash';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProductPlant } from '../../../../classes/product-plant.class';
import { ProductRequest } from '../../../../classes/product-request.class';
import { Product } from '../../../../classes/product.class';
import { CurrentUser } from '../../../../classes/user.class';
import { CustomValidators } from '../../../../product-request/validators.service';
import { ProductService } from '../../../../services/product.service';
import { UsersService } from '../../../../services/users.service';
import { LanguageKeys } from '../../../../_shared/_components/recursive-form/language-key.pipe';
import { RecursiveFormComponent } from '../../../../_shared/_components/recursive-form/recursive-form.component';
import { SnackbarComponent } from '../../../../_shared/_components/snackbar/snackbar.component';
import { AppService } from '../../../../_shared/_services/app.service';

@Component({
  selector: 'app-request-handler-request',
  templateUrl: './request-handler-request.component.html',
  styleUrls: ['./request-handler-request.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestHandlerRequestComponent implements OnInit, OnChanges {

  @Input() productInput: Product;
  @Input() requestIndex: number;

  @Output() private validated = new EventEmitter<boolean>();
  @Output() private product = new EventEmitter<Product>();
  @Output() private plantSignedOff = new EventEmitter<boolean>();

  @ViewChild('plantInfoFromElement') private plantFormFromElement: RecursiveFormComponent;

  private user: CurrentUser;
  public userFormFilter: (keyof CurrentUser)[] = ['dbRef', 'language', 'userId', 'isAdmin', 'isDeveloper', 'plant'];

  private _product: Product;
  public plant: ProductPlant;
  public request: ProductRequest;
  public _plantSignedOff = false;
  public languages = this.appService.languages;
  public plantFormValidators = this.validators.productPlantFormValidators();
  public plantFormValidated = false;
  public productPlantFormFilter = ['metaDataPlant', 'risk', 'vlarem', 'pbm', 'seveso', 'riskAnalysis'];

  public progressSpinner = false;
  public uploadStatus: string;

  // Observables
  public translatedKeys$ = combineLatest(
    this.productService.keys$,
    this.userService.currentUser$
  ).pipe(
    filter(([_, user]) => user !== null),
    map(([keys, user]) => {
      this.user = user;

      // Set translations for form keys
      const translatedKeys: LanguageKeys = {};
      [...keys['plant'], ...keys['requestBasicInfo']].forEach(x => {
        if (x[user.language]) {
          translatedKeys[x.key] = x[user.language];
        } else {
          translatedKeys[x.key] = x.key;
        }
      });
      translatedKeys['email'] = 'E-mail';
      translatedKeys['username'] = 'Username';
      this.appService.languages.forEach(x => translatedKeys[x.value] = x.label);
      return translatedKeys;
    }));


  // Form methods
  public validateProductPlantForm(status: boolean) {
    this.plantFormValidated = status;
    this.validateAll();
    this.changeDetector.detectChanges();
  }

  private validateAll() {
    const isValid = this.plantFormValidated;
    this.validated.emit(isValid);
  }

  public validateForm() {
    this.plantFormFromElement.validateForm();
  }

  public setProductPlantForm(form: ProductPlant) {
    this._product.metaData.requests[this.requestIndex].requestForm.plantInfo = new ProductPlant(form);
    this.product.emit(this._product);
  }

  public async signoffPlant() {
    this.progressSpinner = true;
    this.uploadStatus = 'Uploading files';

    const product = await this._product.approvePlant(
      this.requestIndex,
      this.plant.metaDataPlant.plant,
      this._product.metaData.requests[this.requestIndex]
    ).catch(() => void 0);

    if (!product) { this.matDialog.closeAll(); this.snackbar.snackbarError('Something went wrong'); }

    this._product.metaData.requests[this.requestIndex].approval.plant.signed = true;
    this._product.metaData.requests[this.requestIndex].approval.plant.date = new Date();
    this._product.metaData.requests[this.requestIndex].approval.plant.user = this.user;

    this._plantSignedOff = this._product.metaData.requests[this.requestIndex].approval.plant.signed;
    this.product.emit(this._product);
    this.plantSignedOff.emit(true);

    this.progressSpinner = false;
    this.uploadStatus = '';
    this.changeDetector.detectChanges();
  }

  public async denyRequest() {
    this.progressSpinner = true;
    this.uploadStatus = 'Deleting files';

    await this._product.removeRequest(this.requestIndex, this.plant.metaDataPlant.plant);

    this.progressSpinner = false;
    this.uploadStatus = 'Done';
    this.matDialog.closeAll();
  }

  constructor(
    private validators: CustomValidators,
    private productService: ProductService,
    private userService: UsersService,
    private appService: AppService,
    private matDialog: MatDialog,
    private snackbar: SnackbarComponent,
    private changeDetector: ChangeDetectorRef,
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['productInput'] && changes['requestIndex']) {
      const clonedProduct = cloneDeep(this.productInput);
      this._product = clonedProduct;
      this.request = clonedProduct.metaData.requests[this.requestIndex];
      this.plant = this.request.requestForm.plantInfo;
      this._plantSignedOff = this.request.approval.plant.signed;
      this.plantSignedOff.emit(this.request.approval.plant.signed);
      setTimeout(() => this.validateForm());
    }
  }

}
