import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Product } from '../../classes/product.class';
import { RecursiveFormComponent } from '../../_shared/_components/recursive-form/recursive-form.component';
import { Vlarem } from '../../classes/vlarem.class';
import { cloneDeep } from 'lodash';
import { SnackbarComponent } from '../../_shared/_components/snackbar/snackbar.component';
import { ProductService } from '../../services/product.service';
import { UsersService } from '../../services/users.service';
import { map, filter } from 'rxjs/operators';
import { LanguageKeys } from '../../_shared/_components/recursive-form/language-key.pipe';
import { combineLatest, of } from 'rxjs';
import { VlaremService } from '../../services/vlarem.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-vlarem',
  templateUrl: './product-vlarem.component.html',
  styleUrls: ['./product-vlarem.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductVlaremComponent implements OnInit, OnChanges {
  //  private _subs = new Subscription();

  @Input() product: Product;

  @ViewChild(RecursiveFormComponent)
  vlaremFormComponent: RecursiveFormComponent;

  // UI
  public isSaving = false;

  public vlarem: Vlarem;
  public vlaremAuto: Vlarem;

  public productIsInUseOnPlants: boolean;

  public routerState: string;

  public vlarem_17_4: {};
  public translatedKeys_17_4: {};
  public activeTranslatedDescriptions_17_4: {};

  private vlaremForm: Vlarem;
  private vlarem_17_4_Form: {}; /// Vlarem_17_4

  // We need to translate the keys here because the dynamic form stands on its own

  public translatedKeys$ = combineLatest(
    this.userService.currentUser$,
    this.productService.vlaremKeys$,
  ).pipe(
    filter(([user]) => user !== null),
    map(([user, keys]) => {
      // Set translations for form keys
      const translatedKeys: LanguageKeys = {};
      keys.forEach((x) => {
        if (x[user.language]) {
          translatedKeys[x.key] = x[user.language];
        } else {
          translatedKeys[x.key] = x.key;
        }
      });
      return translatedKeys;
    })
  );

  // We just map the tooltip here to the descriptionfield to have this functionality on the description field.
  public activeTranslatedDescriptions$ = combineLatest(
    this.userService.currentUser$,
    this.productService.vlaremKeys$
  ).pipe(
    filter(([user]) => user !== null),
    map(([user, keys]) => {
      // Set translations for form keys
      const translatedDescriptions: LanguageKeys = {};

      keys.forEach((x) => {
        if (x.tooltip && x.tooltip[user.language]) {
          translatedDescriptions[x.key] = x.tooltip[user.language];
        }
      });
      return translatedDescriptions;
    })
  );

  public activeTranslatedDescriptions_17_4$ = combineLatest(
    this.userService.currentUser$,
    this.productService.vlaremKeys$
  ).pipe(
    filter(([user]) => user !== null),
    map(([user, keys]) => {
      // Set translations for form keys
      const translatedDescriptions: LanguageKeys = {};

      keys.forEach((x) => {
        if (x.tooltip && x.tooltip[user.language]) {
          translatedDescriptions[x.key] = x.tooltip[user.language];
        }
      });

      const vlarem_17_4 = this.getVlarem_17_4();
      // tslint:disable-next-line: forin
      for (const key in this.getVlarem_17_4()) {
        vlarem_17_4[key] = translatedDescriptions['_17_4'];
      }
      return vlarem_17_4;
    })
  );

  public setVlarem_17_4_Form(form: any) {
    /// create class like Vlarem
    this.vlarem_17_4_Form = form; /// new Vlarem_17_4_Form(form);
  }

  public setVlaremForm(form: Vlarem) {
    this.vlaremForm = new Vlarem(form);
  }

  public async saveProduct() {
    this.isSaving = true;
    this.product.vlarem = new Vlarem(this.vlaremForm);

    // tslint:disable-next-line: forin
    for (const plantDbRef in this.vlarem_17_4_Form) {
      const findPlant = this.product.metaData.plants.find(
        (plant) => plant.metaDataPlant.plant === plantDbRef
      );
      if (!findPlant) { continue; }
      findPlant['vlarem_17_4'] = this.vlarem_17_4_Form[plantDbRef];
    }

    await this.product.saveProduct();
    this.snackbar.snackbarSuccess('Vlarem saved');
    this.isSaving = false;
  }

  constructor(
    private snackbar: SnackbarComponent,
    private userService: UsersService,
    private vlaremService: VlaremService,
    private productService: ProductService,
    private router: Router
  ) {
    let url = this.router.url;
    if (url.includes('?')) {
      url = url.split('?')[0];
    }
    if (url.startsWith('/')) {
      url = url.substring(1);
    }
    this.routerState = url;
  }

  ngOnInit() {
    this.calcAutoVlarem(this.product);
    this.productIsInUseOnPlants = this.product.metaData.plants.some(
      (x) => !x.metaDataPlant.archived
    );
    // this.vlarem_17_4 = {
    //   _17_4: false,
    // };
  }



  getVlarem_17_4() {
    const result = {};
    this.product.metaData.plants.forEach((x) => {
      const dbRef = x.metaDataPlant.plant;
      result[dbRef] = x.vlarem_17_4;
    });
    return result;
  }

  getTranslatedKeysPerPlantLocation() {
    const plants$ = of(
      this.product.metaData.plants.map((x) => x.metaDataPlant.plant)
    );
    const result$ = combineLatest(
      plants$,
      this.productService.locations$,
      this.userService.currentUser$,
      this.productService.vlaremKeys$).pipe(
        map(([plants, locations, user, vlarem]) => {
          const result = {};
          plants.forEach((dbRef) => {
            const findPlant = locations.find(
              (location) => location.dbRef === dbRef
            );
            if (findPlant) {
              let vlarem_17_4 = '';
              const vlarem_17_4Text = vlarem.find(v => v.key === '_17_4');
              if (vlarem_17_4Text) {
                vlarem_17_4 = vlarem_17_4Text[user.language];
              }
              result[dbRef] = vlarem_17_4 + ' ' + findPlant.lokatie + ' - ' + findPlant.description;
            }
          });
          return result;
        })
      );
    return result$;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['product'] && this.product) {
      const cloneProduct = cloneDeep(this.product);
      this.vlarem = cloneProduct.vlarem;
    }
  }

  private calcAutoVlarem(product: Product) {
    this.vlaremAuto = this.vlaremService.autoSetVlarem(product);
  }
}
