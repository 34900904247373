import { Component, OnInit, Input } from '@angular/core';
import { KeyPath } from '../../services/product.service';
import { Key, KeyCategory, KeyDbCategory } from '../../classes/language.class';
import { ApiService } from '../../services/api.service';
import { SnackbarComponent } from '../../_shared/_components/snackbar/snackbar.component';

@Component({
  selector: 'app-vlarem-options',
  templateUrl: './vlarem-options.component.html',
  styleUrls: ['./vlarem-options.component.css']
})
export class VlaremOptionsComponent implements OnInit {

  @Input() keys: Key[];

  categories: { keys: Key[], tooltips: Key[], descriptions: Key[] };

  public async updateKeys(_keys: Key[], path: KeyPath) {
    // we filteren welke data naar de tabel gaat
    // bij het updaten mengen we deze data terug met de source
    // dit kan omdat de keys overeen komen.
    const recollectedKeys = this.categories.keys.map((key, index) => {
      const entry = {
        ...key,
        description: new KeyDbCategory(this.categories.descriptions[index]),
        tooltip: new KeyDbCategory(this.categories.tooltips[index])
      };
      return new Key(entry.key, entry);
    });

    await this.api.keys(recollectedKeys, path);
    this.snackbarComponent.snackbarSuccess('Document updated');
  }

  constructor(private api: ApiService, private snackbarComponent: SnackbarComponent) { }

  ngOnInit() {
    this.categories = this.keys.reduce((acc, key) => {
      acc.keys.push(key);
      acc.descriptions.push(new KeyCategory(key, key.description));
      acc.tooltips.push(new KeyCategory(key, key.tooltip));
      return acc;
    }
      , { keys: [] as any[], tooltips: [] as any[], descriptions: [] as any[] });
  }

}
