import { Pipe, PipeTransform } from "@angular/core";
import { Pzzs } from "../../../classes/pzzs.class";

@Pipe({
  name: "pzzsTooltip",
})
export class PzzsTooltipPipe implements PipeTransform {
  transform(pzzs: Pzzs): any {
    return (
      "CAS-number: " + pzzs.casNummer + "\n" + "EG-number: " + pzzs.egNummer
    );
  }
}
