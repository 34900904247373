import { Component, Input, OnInit } from '@angular/core';
import { Pnumber } from '../../../classes/h-and-p.class';
import { ApiService } from '../../../services/api.service';
import { NewEntry } from '../../../_shared/_components/info-table/info-table.component';
import { PnumberEditComponent } from './p-number-edit/p-number-edit.component';

@Component({
  selector: 'app-p-list',
  templateUrl: './p-list.component.html',
  styleUrls: ['./p-list.component.css']
})
export class PListComponent implements OnInit {

  // Variables
  public newEntry: NewEntry = { class: Pnumber, dependency: this.api };
  @Input() public pNumberList: Pnumber[];

  public editComponent = PnumberEditComponent;
  public infoRow: string[] = [];

  constructor(
    private api: ApiService
  ) { }

  ngOnInit() {
  }

}
