import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkGhs'
})
export class CheckGhsPipe implements PipeTransform {

  transform(value: string): boolean {
    return value.startsWith('GHS') && value.split('S')[1].length === 2;  }

}
