import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from '../../classes/product.class';
import { UsersService } from '../../services/users.service';
import { AppService } from '../../_shared/_services/app.service';

@Component({
  selector: 'app-request-sds',
  templateUrl: './request-sds.component.html',
  styleUrls: ['./request-sds.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestSDSComponent implements OnInit {

  @Input() product: Product;

  @Output() sdsFiles = new EventEmitter<{ [prop: string]: File | null }>();
  @Output() productImage = new EventEmitter<File>();

  public languages = this.appService.languages;
  private _sdsFiles: { [prop: string]: File | null } = {};

  public user$ = this.userService.currentUser$;

  public addSDSToFileList(file: File, language: string) {
    this._sdsFiles[language] = file;
    this.sdsFiles.emit(this._sdsFiles);
  }
  public removeSDSToFileList(language: string) {
    this._sdsFiles[language] = null;
    this.sdsFiles.emit(this._sdsFiles);
  }

  public getProductImage(image: File) { this.productImage.emit(image); }

  constructor(
    private appService: AppService,
    private userService: UsersService
  ) { }

  ngOnInit() {
  }

}
