import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isCoefficientKey'
})
export class IsCoefficientKeyPipe implements PipeTransform {

  transform(key: string): boolean {
    switch (key) {
      case 'coefficientHoogFysisch':
      case 'coefficientHoogGezondheid':
      case 'coefficientHoogMilieu':
      case 'coefficientLaagFysisch':
      case 'coefficientLaagGezondheid':
      case 'coefficientLaagMilieu':
        return true;
      default:
        return false;
    }

  }
}
