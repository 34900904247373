import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Location } from '../../classes/location.class';
import { ProductService } from '../../services/product.service';
import { UsersService } from '../../services/users.service';
import { CurrentUser } from '../../classes/user.class';

export interface Marker {
  latitude: number;
  longitude: number;
  address: string;
}

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleMapComponent implements OnInit, OnDestroy {

  private _subs = new Subscription;

  // Variables
  public latitude: number;
  public longitude: number;
  public zoom: number;
  public isLoggedIn = false;
  public locations$: Observable<Location[]>;
  private currentUser: CurrentUser;

  public styles = [
    {
      'featureType': 'administrative',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#444444'
        }
      ]
    },
    {
      'featureType': 'landscape',
      'elementType': 'all',
      'stylers': [
        {
          'color': '#f2f2f2'
        }
      ]
    },
    {
      'featureType': 'poi',
      'elementType': 'all',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'road',
      'elementType': 'all',
      'stylers': [
        {
          'saturation': -100
        },
        {
          'lightness': 45
        }
      ]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'all',
      'stylers': [
        {
          'visibility': 'simplified'
        }
      ]
    },
    {
      'featureType': 'road.arterial',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'transit',
      'elementType': 'all',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'water',
      'elementType': 'all',
      'stylers': [
        {
          'color': '#46bcec'
        },
        {
          'visibility': 'on'
        }
      ]
    }
  ];

  // Methods
  public async userClickedProductsInUse(marker: Location) {
    const location = marker.dbRef;
    await this.currentUser.updatePlant(location);
    this.router.navigate([`/plant-products`]);
  }

  public selectedLocation(location: Location) {
    this.latitude = location.latitude;
    this.longitude = location.longitude;
    this.zoom = 15;
  }

  // Life cycle
  constructor(
    private productService: ProductService,
    private userService: UsersService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.locations$ = this.productService.locationsActive$;

    this._subs.add(
      this.userService.currentUser$.subscribe(user => this.currentUser = user)
    );

  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

}
