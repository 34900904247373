import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material';
import { cloneDeep } from 'lodash';
import { combineLatest, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProductPlant } from '../../classes/product-plant.class';
import { Product } from '../../classes/product.class';
import { RiskDb } from '../../classes/risk.class';
import { CurrentUser } from '../../classes/user.class';
import { CustomValidators } from '../../product-request/validators.service';
import { ProductService } from '../../services/product.service';
import { UsersService } from '../../services/users.service';
import { DialogComponent, DialogContent } from '../../_shared/_components/dialog/dialog.component';
import { LanguageKeys } from '../../_shared/_components/recursive-form/language-key.pipe';
import { RecursiveFormComponent } from '../../_shared/_components/recursive-form/recursive-form.component';
import { SnackbarComponent } from '../../_shared/_components/snackbar/snackbar.component';
import { LanguageClassPipe } from '../../_shared/_pipes/language-class.pipe';

@Component({
  selector: 'app-product-risk-edit',
  templateUrl: './product-risk-edit.component.html',
  styleUrls: ['./product-risk-edit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductRiskEditComponent implements OnInit, OnChanges, OnDestroy {

  private _subs = new Subscription();

  @Input() product: Product;

  @ViewChildren(RecursiveFormComponent) riskFormComponents: QueryList<RecursiveFormComponent>;

  private user: CurrentUser;
  public plant: ProductPlant;
  private plantIndex: number;
  public riskValidators = this.validators.riskFormValidators();
  public riskOptionSelection$ = this.productService.riskOptionSelections(this.languageClassPipe);
  private riskFormValidatedArray: boolean[] = [];
  public riskFormValidated = false;
  private risks: RiskDb[];
  public riskForms: RiskDb[] = [];

  public activeTranslatedDescriptions$ = combineLatest(
    this.userService.currentUser$,
    this.productService.riskKeys$,
  ).pipe(filter(([user]) => user !== null),
    map(([user, keys]) => {
      // Set translations for form keys
      const translatedDescriptions: LanguageKeys = {};
      keys.forEach(x => {
        if (x.description && x.description.visible && x.description[user.language]) {
          translatedDescriptions[x.key] = x.description[user.language];
        }
      });
      return translatedDescriptions;
    }));

  // We need to translate the keys here because the dynamic form stands on its own
  public translatedKeys$ = combineLatest(
    this.userService.currentUser$,
    this.productService.riskKeys$,
  ).pipe(filter(([user]) => user !== null),
    map(([user, keys]) => {
      // Set translations for form keys
      const translatedKeys: LanguageKeys = {};
      keys.forEach(x => {
        if (x[user.language]) {
          translatedKeys[x.key] = x[user.language];
        } else {
          translatedKeys[x.key] = x.key;
        }
      });
      return translatedKeys;
    }));

  public riskAutoCompleteList$ = this.productService.ths$
    .pipe(
      map(ths => ({
        fire_contactWithWater: Object.values(ths.waterExposition).map(x => x.name),
        environment_contactWithWaterAcid: Object.values(ths.waterExposition).map(x => x.name),
        environment_productOrWaste: Object.values(ths.envProduitDechet).map(x => x.name),
        solid_descriptionSolid: Object.values(ths.toxPoudre).map(x => x.name),
        // contactWithProduct: Object.values(ths.productContact).map(x => x.name),
        workplaceVentilation: Object.values(ths.toxEspace).map(x => x.name),
        proces: Object.values(ths.toxProcess).map(x => x.name),
        ignitionSource: Object.values(ths.explIgnitionSource).map(x => x.name),
        cmrToxicRisk_contactWithSkin: Object.values(ths.toxContactWithSkin).map(x => x.name)
      })));


  public setRiskForm(form: RiskDb, indexRisk: number) {
    this.risks[indexRisk] = new RiskDb(form);
  }

  public validateRiskForm(status: boolean, indexRisk: number) {
    this.riskFormValidatedArray[indexRisk] = status;
    this.riskFormValidated = this.riskFormValidatedArray.every(x => x === true);
  }

  public async saveProduct() {
    this.product.metaData.plants[this.plantIndex].risk = this.risks;
    await this.product.saveProduct();
    this.snackbar.snackbarSuccess('Product saved');
  }

  public addRisk(risk?: RiskDb) {
    this.riskFormValidatedArray.push(false);

    const removePhysicalState = this.product.physicalState !== 'gas liquified under pressure';
    const newRisk = new RiskDb(risk);
    if (removePhysicalState) { newRisk.physicalStateRisk = ''; }

    this.riskForms.push(newRisk);
  }

  public removeRisk(indexRisk: number) {

    const dialogContent = {
      title: 'Remove risk?',
      body: 'Are you sure you want to remove this risk?',
      button: 'Cancel',
      button2: 'Remove risk'
    };

    const dialogData: DialogContent = {
      dialogData: dialogContent
    };

    this._subs.add(
      this.matDialog.open(DialogComponent, {
        data: dialogData,
        disableClose: true
      }).afterClosed().subscribe(response => {
        if (response) { return; }

        this.riskForms.splice(indexRisk, 1);
        this.risks.splice(indexRisk, 1);
        this.riskFormValidatedArray.splice(indexRisk, 1);
        if (!this.riskFormValidatedArray.length) { this.riskFormValidated = true; }
        this.changeDetector.detectChanges();
      })
    );
  }


  // Helper methods
  public customTrackBy(_index: number, item: any) { return item; }

  constructor(
    private changeDetector: ChangeDetectorRef,
    private userService: UsersService,
    private validators: CustomValidators,
    private productService: ProductService,
    private snackbar: SnackbarComponent,
    private matDialog: MatDialog,
    private languageClassPipe: LanguageClassPipe
  ) { }

  ngOnInit() {
    this._subs.add(
      this.userService.currentUser$.subscribe(user => {
        this.user = user;
        if (this.product && !this.plant) {
          const clonedProduct = cloneDeep(this.product);
          const indexPlant = clonedProduct.metaData.plants.findIndex(x => x.metaDataPlant.plant === this.user.plant);
          if (indexPlant === -1) { throw new Error('Plant not found'); }
          this.plantIndex = indexPlant;
          this.plant = clonedProduct.metaData.plants[indexPlant];
          this.plant.risk.forEach(x => this.addRisk(x));
          this.risks = this.plant.risk;
          this.changeDetector.detectChanges();
          this.riskFormComponents.forEach(x => x.validateForm());
        }
      }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['product'] && this.product && this.user) {
      const clonedProduct = cloneDeep(this.product);
      const indexPlant = clonedProduct.metaData.plants.findIndex(x => x.metaDataPlant.plant === this.user.plant);
      if (indexPlant === -1) { throw new Error('Plant not found'); }
      this.plantIndex = indexPlant;
      this.plant = clonedProduct.metaData.plants[indexPlant];
      this.plant.risk.forEach(x => this.addRisk(x));
      this.risks = this.plant.risk;
      this.changeDetector.detectChanges();
      this.riskFormComponents.forEach(x => x.validateForm());
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

}
