import { ComponentType } from '@angular/cdk/overlay/index';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { Pnumber } from '../../../../classes/h-and-p.class';
import { ApiService } from '../../../../services/api.service';
import { DialogComponent, DialogContent } from '../../../../_shared/_components/dialog/dialog.component';
import { InfoTableService } from '../../../../_shared/_components/info-table/info-table.service';
import { RecursiveFormComponent, Validator } from '../../../../_shared/_components/recursive-form/recursive-form.component';
import { SnackbarComponent } from '../../../../_shared/_components/snackbar/snackbar.component';

@Component({
  selector: 'app-p-number-edit',
  templateUrl: './p-number-edit.component.html',
  styleUrls: ['./p-number-edit.component.css']
})
export class PnumberEditComponent implements OnInit, OnDestroy, AfterViewInit {

  private subscriptions = new Subscription;

  // Variables
  public pNumber: Pnumber;
  public editable: boolean;
  public validated = false;
  public pNumberForm: Pnumber;
  public validators: Validator[] = [{
    keyName: 'code',
    validators: [Validators.required],
    error: '',
    message: ''
  }];
  public filter = ['pbmOptions', 'suggestions'];

  @ViewChild('recursiveForm') recursiveForm: RecursiveFormComponent;

  // Methods
  public getPNumber(pNumber: Pnumber) {
    this.pNumberForm = new Pnumber(this.api, pNumber);
  }

  public pNumberValidate(validated: boolean) {
    this.validated = validated;
  }

  public closeDialog() {
    this.matDialog.closeAll();
  }

  public async saveData() {

    switch (this.dialogData.state) {
      case 'new': {
        await this.pNumberForm.addPnrDb()
          .then(() => this.snackbar.snackbarSuccess('Saved p-number'))
          .catch(error => this.snackbar.snackbarError(error.error));
        this.closeDialog();
        break;
      }
      case 'edit': {
        await this.pNumberForm.savePnrDb()
          .then(() => this.snackbar.snackbarSuccess('Saved p-number'))
          .catch(error => this.snackbar.snackbarError(error.error));
        this.closeDialog();
        break;
      }
    }

  }

  public deleteEntry() {

    const data: DialogContent = {
      dialogData: {
        title: 'Confirm',
        body: `Are you sure you want to delete this entry?`,
        button: 'Delete',
        button2: 'Cancel'
      }
    };
    const dialog = this.matDialog.open(DialogComponent, { data, disableClose: true });

    dialog.afterClosed().subscribe(async response => {
      if (!response) { return; }
      await this.pNumberForm.deletePnrDb();
      this.snackbar.snackbarSuccess('Deleted p-number');
      this.matDialog.closeAll();
    });
  }

  // Life cycle methods
  private subscribeToObservables() {
    const rowDataSub = this.infoTableService.rowData$.subscribe((pNumber: Pnumber) => this.pNumber = pNumber);

    this.subscriptions.add(rowDataSub);
  }

  // Life cycle
  constructor(
    private infoTableService: InfoTableService,
    private matDialog: MatDialog,
    private api: ApiService,
    private snackbar: SnackbarComponent,
    @Inject(MAT_DIALOG_DATA) public dialogData: { component: ComponentType<any>, state: 'new' | 'edit' }
  ) { }

  ngOnInit() {
    this.subscribeToObservables();
    if (this.dialogData.state === 'edit') { this.filter.push('code'); }
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  ngAfterViewInit() {
    this.recursiveForm.validateForm();
  }
}
