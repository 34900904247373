import { Component, Input, OnInit } from '@angular/core';
import { Hnumber } from '../../../classes/h-and-p.class';
import { ApiService } from '../../../services/api.service';
import { HnumberEditComponent } from './h-number-edit/h-number-edit.component';
import { NewEntry } from '../../../_shared/_components/info-table/info-table.component';

@Component({
  selector: 'app-h-list',
  templateUrl: './h-list.component.html',
  styleUrls: ['./h-list.component.css']
})
export class HListComponent implements OnInit {

  public newEntry: NewEntry = { class: Hnumber, dependency: this.api };
  public filterColumnsOut = ['pbm', 'pbmOptions'];

  // Variables
  @Input() public hNumberList: Hnumber[];

  public editComponent = HnumberEditComponent;
  public infoRow: string[] = [];

  constructor(
    private api: ApiService,
  ) { }

  ngOnInit() {
  }

}
