import { animate, state, style, transition, trigger } from '@angular/animations';
import { ComponentType } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { Zzs } from '../../../classes/zzs.class';
import { UsersService } from '../../../services/users.service';
import { Languages } from '../../../_types/app';
import { DialogComponent } from '../dialog/dialog.component';
import { InfoTableService } from './info-table.service';
import { LanguageClass } from '../../../classes/language.class';

export interface NewEntry { class: Type<any>; dependency: any; }

@Component({
  selector: 'app-info-table',
  templateUrl: './info-table.component.html',
  styleUrls: ['./info-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class InfoTableComponent implements OnInit, OnDestroy, OnChanges {
  private subscriptions = new Subscription;

  // Variables
  public dataSource = new MatTableDataSource();
  public displayedColumns: string[];
  public language: Languages = 'english';
  public expandedElement: any;

  @ViewChild(MatPaginator) private paginator: MatPaginator;
  @ViewChild(MatSort) private sort: MatSort;

  @Input() public tableData: Zzs[];
  @Input() public editComponent: ComponentType<any>;
  @Input() public infoRow: string[] = [];
  @Input() public newEntry: NewEntry;
  @Input() public filterColumnsOut: string[] = [];

  // Table Methods
  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public editElement(element: any) {
    this.matDialog.open(DialogComponent, {
      data: { component: this.editComponent, state: 'edit' },
      height: '95vh'
    });
    this.infoTableService.setRowData(element);
  }

  public infoRowIsEmpty(row: Zzs, info: any[]) {
    return info.every(x => !row[x]);
  }

  public addEntry() {
    this.matDialog.open(DialogComponent, {
      data: { component: this.editComponent, state: 'new' },
      height: '95vh'
    });
    this.infoTableService.setRowData(new this.newEntry.class(this.newEntry.dependency));
  }

  // Helper methods
  public valueIsDate(value: any) { return value instanceof Date; }
  public valueIsLanguageClass(value: any) { return value instanceof LanguageClass; }

  // Life cycle methods
  private subscribeToObservables() {

    const userSub = this.userService.currentUser$.subscribe(user => {
      if (!user) { return; }
      this.language = user.language;
    });

    this.subscriptions.add(userSub);
  }

  // Life cycle
  constructor(
    private userService: UsersService,
    private matDialog: MatDialog,
    private infoTableService: InfoTableService
  ) { }
  ngOnInit() {
    this.subscribeToObservables();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator.pageSize = 50;
  }
  ngOnChanges() {
    if (this.tableData && this.tableData.length > 0) {
      this.displayedColumns = Object.keys(this.tableData[0])
        .filter(x => !this.infoRow.some(y => y === x) && x !== 'dbRef' && !this.filterColumnsOut.some(y => y === x));
      this.dataSource.data = this.tableData;
    }
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
