import { Pipe, PipeTransform } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { UsersService } from '../../services/users.service';
import { map } from 'rxjs/operators';
import { DropdownService } from '../../services/dropdown.service';

@Pipe({
    name: 'dropdownTranslation'
})
export class DropdownTranslationPipe implements PipeTransform {

    constructor(
        private userService: UsersService,
        private dropdownService: DropdownService
    ) { }

    transform(value: string): Observable<string> {
        return combineLatest(
            this.userService.currentUser$,
            this.dropdownService.dropdownKeys$
        ).pipe(
            map(([user, keys]) => {
                const language = user.language || 'english';

                const findTranslation = keys.find(key => key.key === value);
                if (!findTranslation || !findTranslation[language]) { return value; }

                return findTranslation[language];
            })
        );
    }

}
