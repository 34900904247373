import { frequenty } from '../../../server/src/routes/product/types';

interface RiskMaxTotalPlantsPerPlant {
  maxAmmountPerPostPerYearKg: number;
  maxAmmountPerPostPerYearL: number;

  totalCmrToxicRisk_potential_Score_HRP: number;
  totalCmrToxicRisk_inhalation_Score_HRP: number;
  totalCmrToxicRisk_contact_Score_HRP: number;

  totalFireExplosion_potential_Score_HRP: number;

  totalEnvironment_potential_water_Score_HRP: number;
  totalEnvironment_potential_air_Score_HRP: number;
  totalEnvironment_potential_soil_Score_HRP: number;
}

/**
 * Class as THS template, values are in the DB. Class is used for risk analysis
 */
export class ThsDb {

  toxEspace = {
    outdoor: { name: 'Outdoor', key: 1, value: 0.001 },
    exhaustHood: { name: 'Exhaust hood', key: 2, value: 0.1 },
    indoorMechanicalVentilation: { name: 'Indoor - Mechanical ventilation', key: 3, value: 0.7 },
    indoorNoVentilation: { name: 'Indoor - No ventilation', key: 4, value: 1 }
  };
  toxProcess = {
    dispersive: { name: 'Dispersive', key: 4, value: 1 },
    open: { name: 'Open', key: 3, value: 0.5 },
    closedRegularlyOpend: { name: 'Closed / Regularly opend', key: 2, value: 0.05 },
    permanentlyClosed: { name: 'Permanently closed', key: 1, value: 0.001 }
  };
  toxPrio = {
    high: { name: 'High', key: 'high', value: 10000 },
    medium: { name: 'Medium', key: 'medium', value: 100 },
    low: { name: 'Low', key: 'low', value: 0 }
  };
  toxInhalation = {
    high: { name: 'High', key: 'high', value: 1000 },
    medium: { name: 'Medium', key: 'medium', value: 100 },
    low: { name: 'Low', key: 'low', value: 0 }
  };
  toxContact = {
    high: { name: 'High', key: 'high', value: 1000 },
    medium: { name: 'Medium', key: 'medium', value: 100 },
    low: { name: 'Low', key: 'low', value: 0 }
  };
  toxContactWithSkin = {
    hand1: { name: '1 Hand', keys: 1, value: 1 },
    hands2Forearm: { name: '2 Hands  / forearm', key: 2, value: 2 },
    wholeArm: { name: 'Whole arm', key: 3, value: 3 },
    bodyLegs: { name: 'Body -> legs', key: 4, value: 10 }
  };
  toxFrequence = {
    zelden: { name: 'Zelden', key: 0, value: 0 },
    occasioneel: { name: 'Occasioneel', key: 1, value: 1 },
    gemiddeld: { name: 'Gemiddeld', key: 2, value: 2 },
    frequent: { name: 'Frequent', key: 3, value: 3 },
    permanent: { name: 'Permanent', key: 4, value: 4 }
  };

  explScore = {
    extremelyHigh: { name: 'Extremely high', key: 'extremelyHigh', value: 10000 },
    high: { name: 'High', key: 'high', value: 1000 },
    medium: { name: 'Medium', key: 'high', value: 10 },
    low: { name: 'Low', key: 'high', value: 0 },
  };

  explIgnitionSource = {
    permanent: { name: 'Permanent', key: 5, value: 5 },
    occasional: { name: 'Occasional', key: 4, value: 4 },
    rarely: { name: 'Rarely', key: 3, value: 3 },
    veryRarely: { name: 'Very rarely', key: 2, value: 2 },
    incidentally: { name: 'Incidentally', key: 1, value: 1 }
  };

  envScore = {
    extremelyHigh: { name: 'Extremely high', key: 'extremelyHigh', value: 10000 },
    high: { name: 'High', key: 'high', value: 1000 },
    medium: { name: 'Medium', key: 'high', value: 10 },
    low: { name: 'Low', key: 'high', value: 0 },
  };

  envProduitDechet = {
    product: { name: 'Product' },
    toxicWaste: { name: 'Toxic waste' },
    waste: { name: 'Waste' },
  };

  toxPoudre = {
    finePowderDust: { name: 'Fine powder/dust', value: 1 },
    powder: { name: 'Powder (1-2mm)', value: 2 },
    granulate: { name: 'Granulate (>>1-2 mm)', value: 3 },
  };

  waterExposition = {
    permanent: { name: 'Permanent', fire: 5, env1: 4, env2: 5 },
    occasional: { name: 'Occasional', fire: 4, env1: 3, env2: 4 },
    accidental: { name: 'Accidental', fire: 2, env1: 2, env2: 3 }
  };

  // productContact = {
  //   none: { name: 'None', key: 'None', value: 1 },
  //   irregular: { optionValue: 'irregular', name: 'Irregular', key: 'irregular', value: 2 },
  //   low: { name: 'Low', key: 'low', value: 3 },
  //   mediocre: { name: 'Mediocre', key: 'mediocre', value: 4 },
  //   high: { name: 'High', key: 'high', value: 5 }
  // };

  constructor(input: ThsDb) {
    if (input) {
      Object.keys(input).forEach(key => {
        if (this[key] !== undefined) { this[key] = input[key]; }
      });
    }
  }

}

export class Ths extends ThsDb {

  constructor(input: ThsDb) {
    super(input);
  }

}

/**
 * Base class for Risk analysis. These values are saved in the DB.
 */
export class RiskDb {
  workplaceIdentification = '';
  workplaceVentilation = '';
  liquid_temperatureUsage = 25;
  frequencyClass04CfrTable1 = 0;
  proces = '';
  solid_descriptionSolid = '';
  ammountPerPostPerYearKg = 0;
  ammountPerPostPerYearL = 0;
  // cmrToxicRisk_actions_lowerRisk = '';
  cmrToxicRisk_contactWithSkin = '';
  // environment_actions_lowerRisk = '';
  ignitionSource = '';
  fire_contactWithWater = '';
  environment_productOrWaste = '';
  environment_contactWithWaterAcid = '';
  // fireExplosion_actions_lowerRisk = '';
  physicalStateRisk: 'gas' | 'liquid' | 'solid' | '' = '';
  contactWithProduct: frequenty = '';
  // contactWithProductValue: frequenty = ''
  peopleInContactWithSubstance = 0;


  constructor(input?: RiskDb | RiskAnalysis) {
    if (input) {
      Object.keys(input).forEach(key => {
        if (this[key] !== undefined) { this[key] = input[key]; }
      });
    }
  }

}

/**
 * This class calculates the nescessary values for the Risk analysis and the total values per plant.
 * The values per product are set on the product class.
*/
export class RiskAnalysis extends RiskDb {

  // Totals on product class
  riskAnalysisTotals: RiskMaxTotalPlantsPerPlant;

  cmrToxic_Class_SDS = 1;
  cmrToxic_TWA_8_hmgM3 = 1;
  cmrToxic_Class_TWA = 1;
  cmrToxic_Class_max = 1;

  fire_Class_SDS = 1;
  fire_Class_Danger = 1;

  environment_Class_SDS = 1;
  environment_Class_Danger = 1;

  cmrToxicRisk_potential_Score_HRP = 0;
  cmrToxicRisk_potential_perc = 0;
  cmrToxicRisk_potential_Priority = '';

  cmrToxicRisk_inhalation_Score_HRP = 0;
  cmrToxicRisk_inhalation_perc = 0;
  cmrToxicRisk_inhalation_Priority = '';

  cmrToxicRisk_contact_Score_HRP = 0;
  cmrToxicRisk_contact_perc = 0;
  cmrToxicRisk_contact_Priority = '';

  fireExplosion_potential_Score_HRP = 0;
  fireExplosion_potential_perc = 0;
  fireExplosion_potential_Priority = '';

  environment_potential_water_Score_HRP = 0;
  environment_potential_water_perc = 0;
  environment_potential_water_Priority = '';

  environment_potential_air_Score_HRP = 0;
  environment_potential_air_perc = 0;
  environment_potential_air_Priority = '';

  environment_potential_soil_Score_HRP = 0;
  environment_potential_soil_perc = 0;
  environment_potential_soil_Priority = '';

  constructor(input?: RiskAnalysis | RiskDb) {
    super(input);

    if (input) {
      Object.keys(input).forEach(key => {
        if (this[key] !== undefined) { this[key] = input[key]; }
      });
    }
  }

}
