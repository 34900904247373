import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { CurrentUser } from '../../classes/user.class';
import { FireAuthService } from '../../services/fireAuth.service';
import { UsersService } from '../../services/users.service';
import { AppService } from '../../_shared/_services/app.service';
import { Language, Languages } from '../../_types/app';

@Component({
  selector: 'app-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.css']
})
export class ToolbarUserComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription;

  // Variables
  private user: CurrentUser;
  public isLoggedIn = false;
  public language: Languages = 'english';
  public languages = this.appService.languages;

  // Methods
  public login() { this.fireAuthService.signInWithGoogle(); }
  public logout() { this.fireAuthService.signOut(); }

  public setLanguage(language: Language) {
    this.user.updateLanguage(language.value);
  }

  // Life cycle methods
  private subscribeToObservables() {
    const userSub = this.userService.currentUser$.subscribe(user => {
      if (!user) { this.isLoggedIn = false; return; }
      this.user = user;
      this.isLoggedIn = true;
      this.language = user.language;
    });

    this.subscriptions.add(userSub);
  }

  // Life cycle
  constructor(
    private fireAuthService: FireAuthService,
    private userService: UsersService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private appService: AppService
  ) {
    this.iconRegistry.addSvgIconSet(
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg-icons/national-flags.svg'));
  }

  ngOnInit() {
    this.subscribeToObservables();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
