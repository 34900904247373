import { Pipe, PipeTransform } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductService } from '../../services/product.service';
import { UsersService } from '../../services/users.service';

@Pipe({
  name: 'pbmCategoryTranslation'
})
export class PbmCategoryTranslationPipe implements PipeTransform {

  constructor(
    private userService: UsersService,
    private productService: ProductService
  ) { }

  transform(value: string, getTooltip = false): Observable<string> {
    
    return combineLatest(
      this.userService.currentUser$,
      this.productService.pbmKeys$
    ).pipe(
      map(([user, keys]) => {
        const language = user.language || 'english';
        const findTranslation = keys.find(key => key.key === value);
        if (!findTranslation || !findTranslation[language]) { return value; }

        if (getTooltip) {
          return findTranslation.tooltip[language];
        }

        return findTranslation[language];
      })
    );
  }

}
