export class Vlarem {
  _6_4 = false;
  _17_2 = false;
  _17_1_1 = false;
  _17_1_2_1 = false;
  _17_1_2_2 = false;
  _17_3_1_Ghs01 = false;
  _17_3_2_1_1_Ghs02 = false;
  _17_3_2_1_2_Ghs02 = false;
  _17_3_2_2_Ghs02 = false;
  _17_3_2_3_Ghs02 = false;
  _17_3_3_Ghs03 = false;
  _17_3_4_Ghs05 = false;
  _17_3_5_Ghs06 = false;
  _17_3_6_Ghs07 = false;
  _17_3_7_Ghs08 = false;
  _17_3_8_Ghs09 = false;
  _17_4 = false;

  constructor(input?: Vlarem) {
    if (input) {
      Object.keys(input).forEach(key => {
        if (this[key] !== undefined) { this[key] = input[key]; }
      });
    }
  }

}
