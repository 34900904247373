import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import {
  buffer, map, debounceTime, flatMap, share
} from 'rxjs/operators';
import { ApiService } from './api.service';

interface TranslationItem {
  text: string;
  fromLang: string;
  toLang: string;
  id: string;
}

interface TranslationResult {
  translatedText: string;
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private translationRequestSource = new Subject<TranslationItem>();
  public translationRequest$ = this.translationRequestSource.asObservable();

  private _translationResult$ = this.translationRequest$.pipe(
    buffer(this.translationRequest$.pipe(debounceTime(500))),
    flatMap(bufferArray => {

      const toLang = bufferArray[0].toLang;

      const calls = bufferArray.reduce((acc, item) => {

        const langKey = item.fromLang;

        if (!acc[langKey]) { acc[langKey] = []; }
        acc[langKey].push({ text: item.text, id: item.id });
        return acc;
      }, {} as { [key: string]: { text: string, id: string }[] });

      const res = Object.keys(calls).reduce(async (acc, fromLang) => {
        const coll = await acc;
        const translations = await this.api.translate(calls[fromLang].map(c => c.text), fromLang, toLang);

        const _res = [...coll, ...translations.map((t, i) => ({ translatedText: t, id: calls[fromLang][i].id }))];

        return _res;
      }, Promise.resolve([]) as Promise<TranslationResult[]>);

      return res;

    }),
    share()
  );

  private genID() {
    return Math.random().toString(16).slice(11);
  }

  private setNextOnRequestSource(text: string, fromLang: string, toLang: string, id: string) {
    this.translationRequestSource.next({ text, fromLang, toLang, id });
  }

  translationResult$ = (text: string, fromLang: string, toLang: string): Observable<string> => {
    const id = this.genID();
    this.setNextOnRequestSource(text, fromLang, toLang, id);
    return this._translationResult$
      .pipe(
        map((translationResults: TranslationResult[]) => {
          const matchingResult = translationResults.find(result => result.id === id);
          const responseText = matchingResult ? matchingResult.translatedText : 'translation not found';
          return responseText;
        })
      );
  }

  constructor(private api: ApiService) { }

}
