// tslint:disable: no-use-before-declare
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AutoCompleteList } from '../_components/recursive-form/recursive-form.component';
import { LanguageClass } from '../../classes/language.class';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public _disableRequestFormSource = new BehaviorSubject<boolean>(false);
  public disableRequestForm$ = this._disableRequestFormSource.asObservable();

  public productTextAreaFilter: string[] = ['properShippingName'];
  public zzsTextAreaFilter: string[] = ['nederlandsStofnaam', 'engelseStofnaam', 'voetnoot1', 'voetnoot2', 'voetnoot3', 'voetnoot4'];
  public sevesoTextAreaFilter: string[] = ['gevarencategorieën', 'gevaarlijkeStoffen'];
  public unCategoryTextAreaFilter: string[] = ['stofnaam'];
  public cmrTextAreaFilter: string[] = [];
  public hAndPTextAreaFilter: string[] = ['dutch', 'english', 'french', 'german'];

  public productAutoCompleteList: AutoCompleteList;

  public filterKeysDb: string[] = ['metaData', 'dutch', 'english', 'french', 'german'];

  public languages = [
    { value: 'dutch', label: 'Dutch' },
    { value: 'english', label: 'English' },
    { value: 'french', label: 'French' },
    { value: 'german', label: 'German' },
  ];

  public filterRequest: string[] = ['metaData'];

  public filterProduct: string[] = ['metaData'];

  public requestTableHeaders = [
    'username',
    'email',
    'plant',
    'status',
    'productName',
  ];

  /**
 * Function to facilitate generic typing of null or undefined in observables
 * @param val Value for a typed return of null or undefined
 */
  public static notNullOrUndefined<T>(val: T | undefined | null): val is T {
    return val !== undefined && val !== null;
  }

  public static checkType(input: any, x: string, classInput: any): any & never {
    let output;
    if (typeof input[x] !== 'boolean' && !input[x]) { output = undefined; }
    else if (typeof classInput[x] === 'string') { output = input[x].toString().trim(); }
    else if (typeof classInput[x] === 'number') {
      if (input[x]) {
        output = Number(input[x].toString().replace(/[a-zA-Z]/g, '').replace(',', '.'));
      } else { output = Number(0); }
    }
    else if (typeof classInput[x] === 'boolean') { output = input[x] ? true : false; }
    else if (classInput[x] instanceof Date) {
      if (input[x] instanceof Date && input[x].valueOf() === 0) { output = new Date(''); }
      else if (input[x] instanceof Date) { output = input[x]; }
      else { output = new Date(input[x]); }
    }
    else if (classInput[x] instanceof LanguageClass) { output = new LanguageClass(input[x]); }
    else if (Array.isArray(classInput[x])) { output = input[x].map((y: any) => y.toString().trim()); }
    else if (Object.keys(classInput).includes(x)) { output = input[x]; }
    else { output = undefined; }

    return output as any & never;
  }

  public static sortArray(array: string[]) {
    return array.sort((a, b) => {
      if (a < b) { return -1; }
      if (a > b) { return 1; }
      return 0;
    });
  }

  public static sortArrayWithObjects(array: { [prop: string]: string | number | Date }[], sortKey: string) {
    return array.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) { return -1; }
      if (a[sortKey] > b[sortKey]) { return 1; }
      return 0;
    });
  }

  public static sortArrayWithClasses<T>(array: T[], sortByProperty: keyof T) {
    return array.sort((a, b) => {
      if (a[sortByProperty] < b[sortByProperty]) { return -1; }
      if (a[sortByProperty] > b[sortByProperty]) { return 1; }
      return 0;
    });
  }

  public static convertNestedArrayToObject(array: any[][]): { [prop: string]: any[] } {
    return array.reduce((acc, item, i) => {
      acc[i] = item;
      return acc;
    }, {});
  }

  public static encodeBase64(data: any) {
    const string = JSON.stringify(data);
    return btoa(unescape(encodeURIComponent(string)));
  }
  public static decodeBase64(data: string) {
    const replacedData = data.replace(/\s/g, '');
    return decodeURIComponent(escape(window.atob(replacedData)));
  }

  // Life cycle
  constructor() { }

}

export function notNullOrUndefined<T>(val: T | undefined | null): val is T {
  return val !== undefined && val !== null;
}
