import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, OnChanges, Input } from '@angular/core';

@Directive({
  selector: '[appColorValue]'
})
export class ColorValueDirective implements OnChanges, AfterViewInit {

  @Input() private appColorValue: string;

  private addColor() {
    const element = this.elementRef.nativeElement;
    if (!this.appColorValue) { return; }
    if (typeof this.appColorValue !== 'string') { throw new Error('Input is not a string'); }

    element.innerText = this.appColorValue;

    switch (this.appColorValue.toLowerCase().trim()) {
      case 'low': { element.style.color = 'green'; break; }
      case 'medium': { element.style.color = 'orange'; break; }
      case 'high': { element.style.color = 'red'; break; }
      default: { }
    }
  }

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.addColor();
  }

  ngOnChanges() {
    this.addColor();
  }
  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

}
