import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  filter,
  flatMap,
  map,
  shareReplay,
  skip,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { Key } from '../classes/language.class';
import { Product } from '../classes/product.class';
import { ProductService } from '../services/product.service';
import { UsersService } from '../services/users.service';
import { ExpandedDetail } from '../_shared/_components/product-table/product-table.component';
import { ProductTableService } from '../_shared/_components/product-table/product-table.service';
import { UiTranslationPipe } from '../_shared/_pipes/ui-translation.pipe';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllProductsComponent implements OnInit, OnDestroy {
  private _subs = new Subscription();

  public routerState: string;

  // Data streams
  public productsInUse$ = this.productService.productsUsed$.pipe(
    map((products) => this.addExpandedDetailsRow(products))
  );

  public productsInUseCurrentPlant$ = this.productService.productsUsedCurrentPlant$.pipe(
    map((products) => this.addExpandedDetailsRow(products))
  );

  public productsArchived$ = this.productService.productsArchived$.pipe(
    map((products) => this.addExpandedDetailsRow(products))
  );

  public productsCurrentPlantArchived$ = this.productService.productsCurrentPlantArchived$.pipe(
    map((products) => this.addExpandedDetailsRow(products))
  );

  public productsWithPbm$ = this.productService.productsWithPbm$.pipe(
    map((products) => this.addExpandedDetailsRow(products))
  );

  public productsCurrentPlantWithPbm$ = this.productService.productsCurrentPlantWithPbm$.pipe(
    map((products) => this.addExpandedDetailsRow(products))
  );

  public productRequests$ = this.productService.productRequests$;
  public productRequestsCurrentPlant$ = this.productService
    .productsRequestCurrentPlant$;

  public productsCurrentPlantWithRisks$ = this.productService.productsUsedCurrentPlantWithRisks$.pipe(
    map((products) => this.addExpandedDetailsRow(products))
  );

  public productsCurrentPlantWithSeveso$ = this.productService.productsCurrentPlantWithSeveso$.pipe(
    map((products) => this.addExpandedDetailsRow(products)),
    shareReplay(1)
  );

  /**
   * Sevesotype of current plant
   */
  public sevesoScore = this.productService.productsCurrentPlantWithSeveso$.pipe(
    switchMap((products) => {
      const coefficients = products.reduce(
        (acc, product) => {
          acc.coefficientLaagFysisch += product.coefficientLaagFysisch;
          acc.coefficientHoogFysisch += product.coefficientHoogFysisch;
          acc.coefficientLaagGezondheid += product.coefficientLaagGezondheid;
          acc.coefficientHoogGezondheid += product.coefficientHoogGezondheid;
          acc.coefficientLaagMilieu += product.coefficientLaagMilieu;
          acc.coefficientHoogMilieu += product.coefficientHoogMilieu;

          return acc;
        },
        {
          coefficientLaagFysisch: 0,
          coefficientHoogFysisch: 0,
          coefficientLaagGezondheid: 0,
          coefficientHoogGezondheid: 0,
          coefficientLaagMilieu: 0,
          coefficientHoogMilieu: 0,
        }
      );

      const sevesoScore = Object.entries(coefficients).reduce(
        (acc, [key, value]) => {
          if (acc === 'high_seveso') {
            return acc;
          }
          if (key.includes('Hoog') && value >= 1) {
            return 'high_seveso';
          }
          if (key.includes('Laag') && value >= 1) {
            return 'low_seveso';
          }
          return acc;
        },
        'no_seveso'
      );

      return this.uiTranslationPipe.transform(sevesoScore);
    })
  );

  public productsCurrentPlantWithVlarem$ = this.productService.productsCurrentPlantWithVlarem$.pipe(
    map((products) => this.addExpandedDetailsRow(products))
  );

  public productKeys$ = this.productService.activeProductKeys$.pipe(
    map((keys) => keys.filter((a) => a.key !== 'dateArchived'))
  );
  public archivedProductKeys$ = this.productService.activeProductKeys$;
  public pbmKeys$ = this.productService.activePbmKeys$;
  public requestKeys$ = this.productService.activeProductRequestKeys$;
  public riskKeys$ = this.productService.activeRiskKeys$;
  public sevesoKeys$ = this.productService.activeSevesoKeys$;
  public vlaremKeys$ = this.productService.activeVlaremKeys$;

  public location$ = this.productTableService.selectedLocation$;

  // Variables
  public overviewKeys: Key[];
  public pbmKeys: Key[];

  // Methods
  private addExpandedDetailsRow<T>(products: (Product & T)[]): (Product & T)[] {
    products.forEach((product) => {
      const expandedDetail = {} as ExpandedDetail;

      if (product.noodnummer) {
        expandedDetail['Emergency numbers'] = product.noodnummer;
      }
      if (product.hAndPSentences.hSentences.length) {
        expandedDetail[
          'H sentences'
        ] = product.hAndPSentences.hSentences.map((y) => ({ [y.code]: y }));
      }
      if (product.hAndPSentences.pSentences.length) {
        expandedDetail[
          'P sentences'
        ] = product.hAndPSentences.pSentences.map((y) => ({ [y.code]: y }));
      }

      // Add extra information for Risk row
      if (product['riskAnalysisTotals']) {
        expandedDetail['Risk max'] = [
          {
            'Max amount used (Kg)':
              product['riskAnalysisTotals'].maxAmmountPerPostPerYearKg,
            'Max amount used (L)':
              product['riskAnalysisTotals'].maxAmmountPerPostPerYearL,
          },
        ];
        expandedDetail['Risk totals'] = [
          {
            'Toxic CMR potential risk score HRP':
              product['riskAnalysisTotals']
                .totalCmrToxicRisk_potential_Score_HRP,
            'Toxic CMR inhalation risk score HRP':
              product['riskAnalysisTotals']
                .totalCmrToxicRisk_inhalation_Score_HRP,
            'Toxic CMR contact risk score HRP':
              product['riskAnalysisTotals'].totalCmrToxicRisk_contact_Score_HRP,
            'Fire explosion potential risk score HRP':
              product['riskAnalysisTotals']
                .totalFireExplosion_potential_Score_HRP,
            'Environment water potential risk score HRP':
              product['riskAnalysisTotals']
                .totalEnvironment_potential_water_Score_HRP,
            'Environment air potential risk score HRP':
              product['riskAnalysisTotals']
                .totalEnvironment_potential_air_Score_HRP,
          },
        ];
      }

      return (product.expandedDetail = expandedDetail);
    });
    return products;
  }

  // Life cycle
  constructor(
    private productService: ProductService,
    private userService: UsersService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private productTableService: ProductTableService,
    private uiTranslationPipe: UiTranslationPipe
  ) {
    let url = this.router.url;
    if (url.includes('?')) {
      url = url.split('?')[0];
    }
    if (url.startsWith('/')) {
      url = url.substring(1);
    }
    this.routerState = url;
  }

  ngOnInit() {
    if (this.routerState === 'all-products') {
      // Remove queryparams
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {},
      });
    }

    // Set router
    this._subs.add(
      this.userService.currentUser$
        .pipe(
          filter(() => this.routerState === 'plant-products'),
          withLatestFrom(
            this.activatedRoute.queryParamMap.pipe(map((x) => x.get('plant'))),
            this.productService.locationsActive$
          ),

          // Only on init and on plant-products
          flatMap(async ([user, plant, locations], i) => {
            if (i) {
              return user;
            }

            const isValid = locations.some(
              (location) => location.dbRef === plant
            );
            const firstLocation = locations[0].dbRef || '';

            if (!user.plant) {
              await user.updatePlant(firstLocation);
            }

            if (plant && isValid && user.plant !== plant) {
              await user.updatePlant(plant);
            } else if (!plant && user.plant) {
              await this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: { plant: user.plant },
              });
            }

            return user;
          }),
          skip(1)

          // Then for later emissions
        )
        .subscribe(async (user) => {
          await this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { plant: user.plant },
          });
        })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
