import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductService } from '../../services/product.service';

@Pipe({
  name: 'plantName'
})
export class PlantNamePipe implements PipeTransform {

  constructor(
    private productService: ProductService
  ) { }

  transform(value: string): Observable<string> {
    return this.productService.locations$.pipe(
      map(locations => {
        const findPlant = locations.find(location => location.dbRef === value);
        if (!findPlant) { throw new Error('Location not found in PlantName Pipe'); }
        return findPlant.lokatie + ' - ' + findPlant.description;
      })
    );

  }

}
