import { LanguageClass } from './language.class';
import { ProductComponentServer } from '../../../server/src/routes/product/types';

export class ProductComponentDb implements ProductComponentServer {
    casNummer = '';
    egNummer = '';
    indexNummer = '';
    name = new LanguageClass;
    weightPercentage = 0;
    classification: string[] = [];
    lc50ppm1h = '';
    lc50 = ''; // this is LC50 - inademing PPM /4h
    lc50mgl1h = '';
    lc50mgl4h = '';

    constructor(input?: ProductComponentDb) {
        if (input) {
            Object.keys(input).forEach(key => {
                switch (key) {
                    case 'name': this[key] = new LanguageClass(input[key]); break;
                    case 'indexNummer': this[key] = input[key].toString(); break;
                    default: if (this[key] !== undefined) { this[key] = input[key]; }
                }
            });
        }
    }
}

export class ProductComponent extends ProductComponentDb {
    constructor(input?: ProductComponentDb) {
        if (input) {
            super(input);
        } else {
            super();
        }
    }
}
