import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ProductForm } from '../classes/product-sections.class';
import { Product } from '../classes/product.class';
import { RiskDb } from '../classes/risk.class';
import { Validator } from '../_shared/_components/recursive-form/recursive-form.component';
import { dateValidator, productNameLanguageValidator, productNameValidator, supplierValidator } from './product-request.validators';

@Injectable({
  providedIn: 'root',
}) export class CustomValidators {

  productFormValidators = (
    formOutput$: Observable<ProductForm>, products$: Observable<Product[]>, accepted?: string[]
  ): Validator[] => [
      ...Object.keys(new ProductForm().section1.name)
        .map(language => {
          const errorMessage = language + ' existingNameSupplier';
          return {
            keyName: language,
            parentKey: 'name',
            validatorsAsync: [
              productNameValidator(products$, formOutput$, errorMessage, accepted),
              productNameLanguageValidator(formOutput$, errorMessage)
            ],
            error: errorMessage,
            message: 'min_one_name_or_prod_sup_exists'
          };
        }),
      {
        keyName: 'supplier',
        validatorsAsync: [supplierValidator(products$, formOutput$, 'supplier existingNameSupplier', accepted)],
        validators: [Validators.required],
        error: 'supplier existingNameSupplier',
        message: 'prod_sup_exists'
      },
      {
        keyName: 'datum',
        validators: [dateValidator('requiredDate')],
        error: 'requiredDate',
        message: 'validation_required',
      },
      {
        keyName: 'vibNr',
        validators: [Validators.required],
        error: 'vibNr',
        message: 'validation_required'
      },
      {
        keyName: 'versie',
        validators: [Validators.required],
        error: 'versie',
        message: 'validation_required'
      },
      {
        keyName: 'noodnummer',
        validators: [Validators.required],
        error: 'noodnummer',
        message: 'validation_required'
      },
      {
        keyName: 'suiteableExtinguishingMedia',
        validators: [Validators.required],
        error: 'suiteableExtinguishingMedia',
        message: 'validation_required'
      },
      {
        keyName: 'unsuiteableExtinguishingMedia',
        validators: [Validators.required],
        error: 'unsuiteableExtinguishingMedia',
        message: 'validation_required'
      },
      {
        keyName: 'physicalState',
        validators: [Validators.required],
        error: 'physicalState',
        message: 'validation_required'
      },
      {
        keyName: 'signalword',
        validators: [Validators.pattern(/(warning|danger|^$)/)],
        error: 'signalword',
        message: 'validation_required'
      }
    ]

  productPlantFormValidators = (): Validator[] => {
    return [
      {
        keyName: 'internalLocation',
        validators: [Validators.required],
        error: 'internalLocation',
        message: 'validation_required'
      },
      {
        keyName: 'beschrijving',
        validators: [Validators.required],
        error: 'beschrijving',
        message: 'validation_required'
      },
    ];
  }

  riskFormValidators = (): Validator[] => {

    const keys = Object.keys(new RiskDb);
    const validKeys = keys.filter(x => !['frequencyClass04CfrTable1', 'peopleInContactWithSubstance'].includes(x));

    const allValidators =
      [
        // {
        //   keyName: 'contactWithProductValue',
        //   validators: [Validators.pattern('^[1-5]$')],
        //   error: 'pattern',
        //   message: 'only_1_to_5'
        // },
        {
          keyName: 'frequencyClass04CfrTable1',
          validators: [Validators.pattern('^[0-4]$')],
          error: 'pattern',
          message: 'only_0_to_4'
        },
        {
          keyName: 'peopleInContactWithSubstance',
          validators: [Validators.min(0)],
          error: 'pattern',
          message: 'minimum_0'
        },
        ...validKeys.map(x => {
          return {
            keyName: x,
            validators: [Validators.required],
            error: 'ReplacementReason',
            message: 'validation_required'
          };
        })
      ];
    return allValidators;
  }


  constructor() { }

}
