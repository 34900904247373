import { ProductRequestDb } from '../../../server/src/routes/product/types';
import { LanguageClass } from './language.class';
import { ProductPlant } from './product-plant.class';
import { UserDb } from './user.class';

class Replacement {
  replacing = '';
  reason = '';

  constructor(input?: Replacement) {
    if (input) {
      Object.keys(input).forEach(key => {
        if (this[key] !== undefined) { this[key] = input[key]; }
      });
    }
  }
}
class Use {
  goal = '';
  when = new Date('');
  specificLocation_use = '';

  constructor(input?: Use) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          case 'when': this[key] = new Date(input[key]); break;
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }
}
class Storage {
  specificLocation_storage = '';
  quantity = '';
  packaging = '';

  constructor(input?: Storage) {
    if (input) {
      Object.keys(input).forEach(key => {
        if (this[key] !== undefined) { this[key] = input[key]; }
      });
    }
  }
}
export class RequestFormBasicInfo {

  replacement = new Replacement;
  use = new Use;
  storage = new Storage;
  extraProduct = '';

  constructor(input?: RequestFormBasicInfo) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          case 'replacement': this[key] = new Replacement(input[key]); break;
          case 'use': this[key] = new Use(input[key]); break;
          case 'storage': this[key] = new Storage(input[key]); break;
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }

}

export class RequestForm {

  basicInfo = new RequestFormBasicInfo();
  plantInfo = new ProductPlant();
  user = new UserDb();
  sdsFiles = new LanguageClass();
  tdsFiles = new LanguageClass();
  foodcrtfFiles = new LanguageClass();
  image = '';
  newProduct = false;

  constructor(input?: RequestForm) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          case 'basicInfo': this[key] = new RequestFormBasicInfo(input[key]); break;
          case 'plantInfo': this[key] = new ProductPlant(input[key]); break;
          case 'user': this[key] = new UserDb(input[key]); break;
          case 'sdsFiles': this[key] = new LanguageClass(input[key]); break;
          case 'tdsFiles': this[key] = new LanguageClass(input[key]); break;
          case 'foodcrtfFiles': this[key] = new LanguageClass(input[key]); break;
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }

}

class Approval {
  signed = false;
  user = new UserDb();
  date = new Date('');

  constructor(input?: Approval) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          case 'date': this[key] = new Date(input[key]); break;
          case 'user': this[key] = new UserDb(input[key]); break;
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }

}
export class RequestApproval {
  product = new Approval;
  plant = new Approval;

  constructor(input?: RequestApproval) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          case 'product': this[key] = new Approval(input[key]); break;
          case 'plant': this[key] = new Approval(input[key]); break;
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }

}

export class ProductRequest implements ProductRequestDb {

  status: 'Requested' | 'Approved' | 'Pending' = 'Requested';
  approvedBy: UserDb | null = null;
  approvedOn: Date | null = null;
  approval = new RequestApproval();
  requestForm = new RequestForm();

  constructor(input?: Partial<ProductRequest>) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          case 'approvedBy': if (input[key]) { this[key] = new UserDb(input[key]); } break;
          case 'approvedOn': if (input[key]) { this[key] = new Date(input[key] || ''); } break;
          case 'approval': this[key] = new RequestApproval(input[key]); break;
          case 'requestForm': this[key] = new RequestForm(input[key]); break;
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }

}
