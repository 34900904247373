import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'coefficientCheck'
})
export class CoefficientCheckPipe implements PipeTransform {

  transform(number: number): boolean {
    if (isNaN(number)) { return false; }
    if (number >= 1) { return true; }
    return false;
  }

}
