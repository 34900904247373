import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { LanguageClass, TranslationDb } from '../../classes/language.class';

interface TranslationsConvert extends LanguageClass {
  column: string;
}

@Component({
  selector: 'app-translation-list',
  templateUrl: './translation-list.component.html',
  styleUrls: ['./translation-list.component.css']
})
export class TranslationListComponent implements OnInit, OnChanges {

  // Variables
  @Input() public translationList: TranslationDb;

  public dataSource: MatTableDataSource<TranslationsConvert>;
  public displayedColumns: string[];
  public editable = false;

  @Output() public updatedTranslations = new EventEmitter<TranslationDb>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  // Table Methods
  public saveData() {
    const data = this.dataSource.data;
    const convertObject: TranslationDb = {};
    data.forEach(x => {
      if (!convertObject[x.column]) { convertObject[x.column] = []; }
      convertObject[x.column] = [...convertObject[x.column], new LanguageClass(x)];
    });
    this.updatedTranslations.emit(convertObject);
  }

  // Life cycle
  constructor() {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.pageSize = 50;
  }
  ngOnChanges() {
    if (this.translationList) {
      this.displayedColumns = ['column', 'english'].concat(Object.keys(new LanguageClass()).filter(x => x !== 'english'));

      this.dataSource.data = Object.keys(this.translationList)
        .reduce((acc: TranslationsConvert[], x) => {
          const addColumnProp = this.translationList[x].map(a => ({ ...a, column: x }));

          return [...acc, ...addColumnProp];
        }, []);
    }
  }

}
