import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { CmrDb } from '../../../classes/cmr.class';
import { ApiService } from '../../../services/api.service';
import { DialogComponent, DialogContent } from '../../../_shared/_components/dialog/dialog.component';
import { InfoTableService } from '../../../_shared/_components/info-table/info-table.service';
import { SnackbarComponent } from '../../../_shared/_components/snackbar/snackbar.component';

@Component({
  selector: 'app-cmr-edit',
  templateUrl: './cmr-edit.component.html',
  styleUrls: ['./cmr-edit.component.css']
})
export class CmrEditComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription;

  // Variables
  public cmr: CmrDb;
  public editable: boolean;
  public validated = false;
  public cmrForm: CmrDb;

  // Methods
  public getCmr(cmr: CmrDb) {
    this.cmrForm = new CmrDb(this.api, cmr);
  }

  public cmrValidate(validated: boolean) {
    this.validated = validated;
  }

  public closeDialog() {
    this.matDialog.closeAll();
  }

  public async saveData() {
    await this.cmrForm.saveCmrDb();
    this.snackbar.snackbarSuccess('Cmr saved');
  }

  public deleteEntry() {

    const data: DialogContent = {
      dialogData: {
        title: 'Confirm',
        body: `Are you sure you want to delete this entry?`,
        button: 'Delete',
        button2: 'Cancel'
      }
    };
    const dialog = this.matDialog.open(DialogComponent, { data, disableClose: true });

    dialog.afterClosed().subscribe(async response => {
      if (!response) { return; }
      await this.cmrForm.deleteCmrDb();
      this.snackbar.snackbarSuccess('Cmr deleted');
      this.matDialog.closeAll();
    });
  }

  // Life cycle methods
  private subscribeToObservables() {
    const rowDataSub = this.infoTableService.rowData$.subscribe((cmr: CmrDb) => this.cmr = cmr);

    this.subscriptions.add(rowDataSub);
  }

  // Life cycle
  constructor(
    private infoTableService: InfoTableService,
    private matDialog: MatDialog,
    private api: ApiService,
    private snackbar: SnackbarComponent
  ) { }

  ngOnInit() {
    this.subscribeToObservables();
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
