import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Key } from 'readline';
import { combineLatest, Subscription } from 'rxjs';
import { UserDbServer } from '../../../../server/src/routes/user/types';
import { Location } from '../../classes/location.class';
import { Product } from '../../classes/product.class';
import { ApiService } from '../../services/api.service';
import { ProductService } from '../../services/product.service';
import { UsersService } from '../../services/users.service';
import { RequestHandlerComponent } from './request-handler/request-handler.component';

export type ProductRequestMap = ReturnType<ProductRequestsComponent['mapProducts']>[number];

@Component({
  selector: 'app-product-requests',
  templateUrl: './product-requests.component.html',
  styleUrls: ['./product-requests.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductRequestsComponent implements OnInit, OnChanges, OnDestroy {

  private subscriptions = new Subscription();

  // Variables
  @Input() private products: Product[];
  @Input() public showPlantSelect: boolean;
  @Input() public disableRowClick: boolean;

  @Output() activeRequestCount = new EventEmitter<number>(true);

  public tableData: { [prop: string]: any }[];
  public rowClickComponent = RequestHandlerComponent;
  public displayedKeys: Key[];

  private user: UserDbServer;
  private locations: Location[];

  // Methods
  private mapProducts(products: Product[]) {
    if (!this.products || !this.user || !this.locations) { return []; }
    const requestsTotal = products.reduce((acc, x) => {
      const requests = x.getActiveRequests();
      if (requests) {

        // Set on main object to show in tabel
        const requestsMap = requests.map(y => {
          const location = this.locations
            .find(a => a.dbRef === y.request.requestForm.user.plant) || new Location(this.api);
          const plant = location.lokatie + ' - ' + location.description;
          return {
            ...y,
            username: y.request.requestForm.user.username,
            email: y.request.requestForm.user.email,
            plant: y.request.requestForm.user.plant,
            status: y.request.status,
            plantName: plant,
            productName: y.productRef.name[this.user.language],
            productSupplier: y.productRef.supplier
          };
        });
        return [...acc, ...requestsMap];
      }
      return acc;
    }, []);
    this.activeRequestCount.emit(requestsTotal.length);
    return requestsTotal;
  }

  // Life cycle
  constructor(
    private changeDetector: ChangeDetectorRef,
    private userService: UsersService,
    public productService: ProductService,
    private api: ApiService
  ) { }

  ngOnInit() {
    const userLocationsSub = combineLatest(
      this.userService.currentUser$,
      this.productService.locationsActive$,
    ).subscribe(([user, locations]) => {
      if (!user || !locations) { return; }
      this.locations = locations;
      this.user = user;
      this.tableData = this.mapProducts(this.products);
      this.changeDetector.detectChanges();
    });

    this.subscriptions.add(userLocationsSub);
  }

  ngOnChanges() {
    if (
      this.products && this.products.length > 0
    ) {
      this.tableData = this.mapProducts(this.products);
      this.changeDetector.detectChanges();
    } else {
      this.tableData = [];
      this.changeDetector.detectChanges();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
