// tslint:disable: no-use-before-declare

import { IsBoolean, IsDateString, IsNotEmpty, IsNumber, IsString, Matches, Max, Min, ValidateIf, ValidateNested } from 'class-validator';
import { UserDbServer } from '../user/types';

export class LanguageClassServer {

  @IsString()
  dutch = '';

  @IsString()
  english = '';

  @IsString()
  french = '';

  @IsString()
  german = '';

  constructor(input?: LanguageClassServer) {
    if (input) {
      Object.keys(input).forEach(x => {
        if (this[x] !== undefined && input[x] !== undefined) { this[x] = input[x]; }
      });
    }
  }
}

export class KeyDBCategoryServer extends LanguageClassServer {
  @IsBoolean()
  visible = true;

  constructor(input?: KeyDBCategoryServer) {
    super(input);

    if (input) {
      Object.keys(input).forEach(x => {
        if (this[x] !== undefined && input[x] !== undefined) { this[x] = input[x]; }
      });
    }
  }
}

export class KeyDbServer extends LanguageClassServer {

  @IsString()
  key = '';

  @IsBoolean()
  visible = true;

  @ValidateNested()
  tooltip = new KeyDBCategoryServer();

  @ValidateNested()
  description = new KeyDBCategoryServer();

  constructor(key?: string, input?: KeyDbServer) {
    super(input);
    if (key) { this.key = key; }

    if (input) {
      Object.keys(input).forEach(x => {
        switch (x) {
          case 'tooltip': this.tooltip = new KeyDBCategoryServer(input[x]); break;
          case 'description': this.description = new KeyDBCategoryServer(input[x]); break;
          default: if (this[x] !== undefined && input[x] !== undefined) { this[x] = input[x]; }
        }
      });
    }

  }
}

export class ZzsDbServer {

  @IsString()
  casNummer = '';

  @IsDateString()
  datumToevoeging: string | Date = '';

  @IsString()
  egNummer = '';

  @IsString()
  emissiegrenswaarde = '';

  @IsString()
  engelseStofnaam = '';

  @IsString()
  grensmassastroom = '';

  @IsString()
  nederlandsStofnaam = '';

  @IsString()
  stofklasseVoorLuchtemissies = '';

  @IsString()
  voetnoot1 = '';

  @IsString()
  voetnoot2 = '';

  @IsString()
  voetnoot3 = '';

  @IsString()
  voetnoot4 = '';

  @IsString()
  dbRef = '';

  constructor(input?: ZzsDbServer) {
    if (input) {
      Object.keys(input).forEach(x => {
        if (this[x] !== undefined && input[x] !== undefined) { this[x] = input[x]; }
      });
    }
  }
}

export class PzzsDbServer {
  @IsString()
  casNummer = "";

  @IsDateString()
  datumToevoeging: string | Date = "";

  @IsString()
  egNummer = "";

  @IsString()
  emissiegrenswaarde = "";

  @IsString()
  engelseStofnaam = "";

  @IsString()
  grensmassastroom = "";

  @IsString()
  nederlandsStofnaam = "";

  @IsString()
  stofklasseVoorLuchtemissies = "";

  @IsString()
  voetnoot1 = "";

  @IsString()
  voetnoot2 = "";

  @IsString()
  voetnoot3 = "";

  @IsString()
  voetnoot4 = "";

  @IsString()
  dbRef = "";

  constructor(input?: PzzsDbServer) {
    if (input) {
      Object.keys(input).forEach((x) => {
        if (this[x] !== undefined && input[x] !== undefined) {
          this[x] = input[x];
        }
      });
    }
  }
}

export class ProductPlantVlaremStockDbServer {
  @IsNumber()
  solidTanksMaxStockKG = 0;
  @IsNumber()
  solidTanksMaxStockL = 0;
  @IsNumber()
  movableRecipientsMaxStockKG = 0;
  @IsNumber()
  movableRecipientsMaxStockL = 0;
}

export class ProductPlantDbServer {

  @IsBoolean()
  vlarem_17_4 = false;

  @IsNumber()
  maximumStockKG = 0;

  @IsNumber()
  maximumStockL = 0;

  @ValidateNested()
  metaDataPlant = new MetaDataProductPlantServer();

  @ValidateNested()
  vlaremStock = new ProductPlantVlaremStockDbServer();

  @ValidateNested({ each: true })
  risk: RiskDb[] = [];

  @IsString({ each: true })
  pbm = new PbmOptionsDbServer();

  constructor(input?: ProductPlantDbServer) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key as keyof ProductPlantDbServer) {
          case 'metaDataPlant': this.metaDataPlant = new MetaDataProductPlantServer(input[key]); break;

          default: if (this[key] !== undefined && input[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }
}

export class SevesoDbServer {

  @IsString()
  dbRef = '';

  @IsString()
  reference = '';

  @IsString()
  gevaarlijkeStoffen = '';

  @IsString()
  gevarencategorieën = '';

  @IsNumber()
  lagedrempelinrichtingen = 0;

  @IsNumber()
  hogedrempelinrichtingen = 0;

  @IsString()
  indeling = '';

  constructor(input?: SevesoDbServer) {
    if (input) {
      Object.keys(input).forEach(x => {
        if (this[x] !== undefined && input[x] !== undefined) { this[x] = input[x]; }
      });
    }
  }
}

export class UnSubstanceCategoryDbServer {

  @IsString()
  vn = '';

  @IsString()
  con1999b = '';

  @IsString()
  gevi = '';

  @IsString()
  gtWaarde = '';

  @IsString()
  stofcategorie = '';

  @IsString()
  stofnaam = '';

  constructor(input?: UnSubstanceCategoryDbServer) {
    if (input) {
      Object.keys(input).forEach(x => {
        if (this[x] !== undefined && input[x] !== undefined) { this[x] = input[x]; }
      });
    }
  }
}

class Replacement {

  @IsString()
  replacing = '';

  @IsString()
  reason = '';

  constructor(input?: Replacement) {
    if (input) {
      Object.keys(input).forEach(x => {
        if (this[x] !== undefined && input[x] !== undefined) { this[x] = input[x]; }
      });
    }
  }
}
class Use {

  @IsString()
  goal = '';

  @ValidateIf(use => use.when)
  @IsDateString()
  when: Date | string = '';

  @IsString()
  specificLocation_use = '';

  constructor(input?: Use) {
    if (input) {
      Object.keys(input).forEach(x => {
        if (this[x] !== undefined && input[x] !== undefined) { this[x] = input[x]; }
      });
    }
  }
}
class Storage {

  @IsString()
  specificLocation_storage = '';

  @IsString()
  quantity = '';

  @IsString()
  packaging = '';

  constructor(input?: Storage) {
    if (input) {
      Object.keys(input).forEach(x => {
        if (this[x] !== undefined && input[x] !== undefined) { this[x] = input[x]; }
      });
    }
  }
}
export class RequestFormBasicInfo {

  @ValidateNested()
  replacement = new Replacement;

  @ValidateNested()
  use = new Use;

  @ValidateNested()
  storage = new Storage;

  @IsString()
  extraProduct = '';

  constructor(input?: RequestFormBasicInfo) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key as keyof RequestFormBasicInfo) {
          case 'replacement': this.replacement = new Replacement(input[key]); break;
          case 'use': this.use = new Use(input[key]); break;
          case 'storage': this.storage = new Storage(input[key]); break;

          default: if (this[key] !== undefined && input[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }
}

class RequestForm {

  // @ValidateNested()
  // basicInfo = new RequestFormBasicInfo();

  @ValidateNested()
  plantInfo = new ProductPlantDbServer;

  @ValidateNested()
  user = new UserDbServer();

  @ValidateNested()
  sdsFiles = new LanguageClassServer();

  @ValidateNested()
  tdsFiles = new LanguageClassServer();

  @ValidateNested()
  foodcrtfFiles = new LanguageClassServer();

  @IsString()
  image = '';

  @IsBoolean()
  newProduct = false;

  constructor(input?: RequestForm) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          // case 'basicInfo': this.basicInfo = new RequestFormBasicInfo(input[key]); break;
          case 'plantInfo': this.plantInfo = new ProductPlantDbServer(input[key]); break;
          case 'user': this.user = new UserDbServer(undefined, input[key]); break;
          case 'sdsFiles': this.sdsFiles = new LanguageClassServer(input[key]); break;
          // case 'tdsFiles': this.tdsFiles = new LanguageClassServer(input[key]); break;
          // case 'foodcrtfFiles': this.foodcrtfFiles = new LanguageClassServer(input[key]); break;

          default: if (this[key] !== undefined && input[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }

}

export class Approval {

  @IsBoolean()
  signed = false;

  @ValidateNested()
  user = new UserDbServer();

  @ValidateIf(approval => approval.date)
  @IsDateString()
  date: Date | string = '';

  constructor(input?: Approval) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          case 'user': this.user = new UserDbServer(undefined, input[key]); break;

          default: if (this[key] !== undefined && input[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }
}
export class RequestApproval {

  @ValidateNested()
  product: Approval | null = null;

  @ValidateNested()
  plant: Approval | null = null;

  constructor(input?: RequestApproval) {
    if (input) {
      Object.keys(input).forEach(key => {
        if (this[key] !== undefined && input[key]) { this[key] = new Approval(input[key]); }
      });
    }
  }
}

export class ProductRequestDb {

  @Matches(/(Requested|Approved|Pending)/)
  status: 'Requested' | 'Approved' | 'Pending' = 'Requested';

  @ValidateIf(request => request.approvedBy !== null)
  @ValidateNested()
  approvedBy: UserDbServer | null = null;

  @ValidateIf(request => request.approvedOn !== null)
  @IsDateString()
  approvedOn: Date | string | null = null;

  @ValidateIf(request => request.approvedBy !== null)
  @ValidateNested()
  approval: RequestApproval = new RequestApproval();

  @ValidateNested()
  requestForm: RequestForm = new RequestForm;

  constructor(input?: ProductRequestDb) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          case 'approvedBy': if (input[key]) { this.approvedBy = new UserDbServer(undefined, input[key] as UserDbServer); } break;
          case 'approval': if (input[key]) { this.approval = new RequestApproval(input[key]); } break;
          case 'requestForm': this.requestForm = new RequestForm(input[key]); break;

          default: if (this[key] !== undefined && input[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }
}

export class MetaDataProductServer {

  @ValidateNested()
  sdsLinks = new LanguageClassServer();

  @ValidateNested()
  tdsLinks = new LanguageClassServer();

  @ValidateNested()
  foodcrtfLinks = new LanguageClassServer();

  @ValidateNested({ each: true })
  plants: ProductPlantDbServer[] = [];

  @ValidateNested({ each: true })
  requests: ProductRequestDb[] = [];

  @IsString()
  dbRef = '';

  @IsString()
  image = '';

  @IsBoolean()
  archived = false;

  @IsString()
  dateArchived = '';

  @IsDateString()
  lastChange: Date | string = '';

  @ValidateNested()
  lastChangedBy: UserDbServer | null = null;

  @IsBoolean()
  approved = false;

  constructor(input?: MetaDataProductServer) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key as keyof MetaDataProductServer) {
          case 'sdsLinks': this.sdsLinks = new LanguageClassServer(input[key]); break;
          // case 'tdsLinks': this.tdsLinks = new LanguageClassServer(input[key]); break;
          // case 'foodcrtfLinks': this.foodcrtfLinks = new LanguageClassServer(input[key]); break;
          case 'plants': this.plants = input.plants.map(plant => new ProductPlantDbServer(plant)); break;
          case 'requests': this.requests = input.requests.map(request => new ProductRequestDb(request)); break;
          case 'lastChangedBy': if (input.lastChangedBy) { this[key] = new UserDbServer(undefined, input[key]); } break;
          default: if (this[key] !== undefined && input[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }
}

export class NoteDbServer {

  @IsString()
  note = '';

  @ValidateNested()
  submittedBy = new UserDbServer;

  @ValidateIf(note => note.submittedOn)
  @IsDateString()
  submittedOn: Date | string = '';

  constructor(input?: NoteDbServer) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          case 'submittedBy': this[key] = new UserDbServer(undefined, input[key]); break;

          default: if (this[key] !== undefined && input[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }

}

export class MetaDataProductPlantServer {

  @IsString()
  plant = '';

  @IsBoolean()
  archived = false;

  @IsString()
  dateArchived = '';

  @ValidateNested({ each: true })
  notes: NoteDbServer[] = [];

  constructor(input?: MetaDataProductPlantServer) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key as keyof MetaDataProductPlantServer) {
          case 'notes': this[key] = input.notes.map(note => new NoteDbServer(note)); break;

          default: if (this[key] !== undefined && input[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }

}

export class CmrDbServer {

  @IsString()
  hNr = '';

  @Min(1)
  @Max(5)
  toxic: 1 | 2 | 3 | 4 | 5 = 1;

  @Min(1)
  @Max(5)
  fire: 1 | 2 | 3 | 4 | 5 = 1;

  @Min(1)
  @Max(5)
  environment: 1 | 2 | 3 | 4 | 5 = 1;

  constructor(input?: CmrDbServer) {
    if (input) {
      Object.keys(input).forEach(x => {
        if (this[x] !== undefined && input[x] !== undefined) { this[x] = input[x]; }
      });
    }
  }

}

class HServer {

  @IsString()
  dutch = '';

  @IsString()
  english = '';

  @IsString()
  french = '';

  @IsString()
  german = '';

  @IsString()
  @IsNotEmpty()
  code = '';

  @IsString()
  indeling = '';

  @IsString()
  number = '';

  @IsNumber()
  value = 0;

  constructor(input?: HServer) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }
}

class PServer {

  @IsString()
  dutch = '';

  @IsString()
  english = '';

  @IsString()
  french = '';

  @IsString()
  german = '';

  @IsString()
  @IsNotEmpty()
  code = '';

  @IsString()
  indeling = '';

  @IsString()
  number = '';

  constructor(input?: PServer) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }
}


export class HnumberServer extends HServer {

  @IsNotEmpty()
  @ValidateNested()
  pbm = new PbmOptionsDbServer();

  constructor(input?: HnumberServer) {
    super(input);

    if (input) {
      Object.keys(input).forEach(key => {
        switch (key as keyof HnumberServer) {
          case 'pbm': this.pbm = new PbmOptionsDbServer(input[key]); break;
          default: if (this[key] !== undefined && input[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }

  }

}
export class PnumberServer extends PServer {

  constructor(input?: PnumberServer) {
    super(input);
  }

}

class RiskDb {

  @IsString()
  fire_contactWithWater = '';

  @IsString()
  environment_contactWithWaterAcid = '';

  @IsString()
  environment_productOrWaste = '';

  @IsNumber()
  liquid_temperatureUsage = 0;

  @IsString()
  solid_descriptionSolid = '';

  @IsString()
  workplaceIdentification = '';

  @IsString()
  workplaceVentilation = '';

  @IsString()
  proces = '';

  @IsString()
  ignitionSource = '';

  @IsNumber()
  ammountPerPostPerYearL = 0;

  @IsNumber()
  ammountPerPostPerYearKg = 0;

  @IsNumber()
  frequencyClass04CfrTable1 = 0;

  @IsNumber()
  peopleInContactWithSubstance = 0;

  @IsString()
  cmrToxicRisk_contactWithSkin = '';

  // @IsString()
  // cmrToxicRisk_actions_lowerRisk = '';

  // @IsString()
  // fireExplosion_actions_lowerRisk = '';

  // @IsString()
  // environment_actions_lowerRisk = '';
  @Matches(/(none|irragular|low|mediocre|high^$)/)
  contactWithProduct: frequenty = '';

  @Matches(/(liquid|gas|solid^$)/)
  physicalStateRisk: 'gas' | 'liquid' | 'solid' | '' = '';

  constructor(input?: RiskDb) {
    if (input) {
      Object.keys(input).forEach(x => {
        if (this[x] !== undefined && input[x] !== undefined) { this[x] = input[x]; }
      });
    }
  }
}

export class Vlarem {

  @IsBoolean()
  _6_4 = false;

  @IsBoolean()
  _17_2 = false;

  @IsBoolean()
  _17_1_1 = false;

  @IsBoolean()
  _17_1_2_1 = false;

  @IsBoolean()
  _17_1_2_2 = false;

  @IsBoolean()
  _17_3_1_Ghs01 = false;

  @IsBoolean()
  _17_3_2_1_1_Ghs02 = false;

  @IsBoolean()
  _17_3_2_1_2_Ghs02 = false;

  @IsBoolean()
  _17_3_2_2_Ghs02 = false;

  @IsBoolean()
  _17_3_2_3_Ghs02 = false;

  @IsBoolean()
  _17_3_3_Ghs03 = false;

  @IsBoolean()
  _17_3_4_Ghs05 = false;

  @IsBoolean()
  _17_3_5_Ghs06 = false;

  @IsBoolean()
  _17_3_6_Ghs07 = false;

  @IsBoolean()
  _17_3_7_Ghs08 = false;

  @IsBoolean()
  _17_3_8_Ghs09 = false;

  @IsBoolean()
  _17_4 = false;

  constructor(input?: Vlarem) {
    if (input) {
      Object.keys(input).forEach(x => {
        if (this[x] !== undefined && input[x] !== undefined) { this[x] = input[x]; }
      });
    }
  }

}

export class PbmOptionsDbServer {

  @IsString()
  gelaatsBescherming = '';

  @IsString()
  nooddoucheOogspoeling = '';

  @IsString()
  hoofdBescherming = '';

  @IsString()
  gasDetectors = '';

  @IsString()
  voetBescherming = '';

  @IsString()
  valbeveiliging = '';

  @IsString()
  handBescherming = '';

  @IsString()
  kleding = '';

  @IsString()
  gehoorBescherming = '';

  @IsString()
  ademhalingsBescherming0 = '';

  @IsString()
  ademhalingsBescherming1 = '';

  @IsString()
  ademhalingsBescherming2 = '';

  constructor(input?: PbmOptionsDbServer) {
    if (input) {
      Object.keys(input).forEach(x => {
        if (this[x] !== undefined && input[x] !== undefined) { this[x] = input[x]; }
      });
    }
  }

}

export type physicalState = 'solid' | 'liquid' | 'gas' | 'aerosol' | 'gas liquified under pressure' | 'pasta' | 'fine powder' | 'granulate' | 'powder' | '';

export type frequenty = 'none' | 'irregular' | 'low' | 'mediocre' | 'high' | '';

export class ProductComponentServer {
  @IsString()
  casNummer = '';

  @IsString()
  egNummer = '';

  indexNummer = '';

  @ValidateNested()
  name = new LanguageClassServer();

  @IsNumber()
  weightPercentage = 0;

  @IsString({ each: true })
  classification: string[] = [];

  @IsString()
  lc50ppm1h = '';

  @IsString()
  lc50 = '';

  @IsString()
  lc50mgl1h = '';

  @IsString()
  lc50mgl4h = '';

  constructor(input?: ProductComponentServer) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          case 'name': this[key] = new LanguageClassServer(input[key]); break;
          case 'indexNummer': this[key] = input[key].toString(); break;
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }

}

export class ProductDbServer {

  @ValidateNested()
  name = new LanguageClassServer();

  @IsString()
  artikelnummerLev = '';

  @ValidateNested()
  beschrijving = new LanguageClassServer();

  @IsString()
  @IsNotEmpty()
  supplier = '';

  // tslint:disable-next-line: max-line-length
  @Matches(/(solid|liquid|gas|aerosol|gas liquified under pressure|pasta|fine powder|granulate|powder|^$)/)
  physicalState: physicalState = '';

  @IsString()
  casNummer = '';

  @IsString()
  egNummer = '';

  @IsString({ each: true })
  hNr: string[] = [];

  @Matches(/(warning|danger|^$)/)
  signalword: 'warning' | 'danger' | '' = '';

  @IsString({ each: true })
  ghs: string[] = [];

  @IsString({ each: true })
  adr: string[] = [];

  @IsString({ each: true })
  hazard: string[] = [];

  @IsString({ each: true })
  classification: string[] = [];

  @IsString({ each: true })
  pNr: string[] = [];

  @IsString()
  un = '';

  @IsString()
  vibNr = '';

  @ValidateIf(product => product.datum)
  @IsDateString()
  datum: Date | string = '';

  @ValidateIf(product => product.controlledDate)
  @IsDateString()
  controlledDate: Date | string = '';

  @IsString({ each: true })
  noodnummer: string[] = [];

  @IsString()
  versie = '';

  @ValidateIf(product => product.vorige)
  @IsDateString()
  vorige: Date | string = '';

  @ValidateNested()
  properShippingName = new LanguageClassServer();

  @ValidateNested()
  eNummer = new LanguageClassServer();

  indexNummer = '';

  @IsString()
  ufiCode = '';

  @IsString()
  formule = '';

  @IsNumber()
  grenswaarde8uur = 0;

  @IsNumber()
  grenswaarde8uurPpm = 0;

  @IsNumber()
  grenswaarde15minuten = 0;

  @IsNumber()
  grenswaarde15minutenPpm = 0;

  @IsString()
  lc50ppm1h = '';

  @IsString()
  lc50 = '';

  @IsString()
  lc50mgl1h = '';

  @IsString()
  lc50mgl4h = '';

  @IsNumber()
  vapourDensity = 0;

  @IsNumber()
  flashpoint = 0;
  @IsNumber()
  dichtheidWater = 0;
  @IsNumber()
  pH = 0;
  @IsNumber()
  meltingPoint = 0;
  @IsString()
  vosRegistration = '';

  @IsNumber()
  @Min(0)
  @Max(100)
  vosPercentage = 0;

  @ValidateNested({ each: true })
  productComponents: ProductComponentServer[] = [];

  @ValidateNested()
  colourProduct = new LanguageClassServer();

  @ValidateNested()
  odourProduct = new LanguageClassServer();

  @IsString()
  dichtheidGas = '';

  @IsString()
  classificationCode = '';

  @IsString()
  molecularWeight = '';

  @IsString()
  registrationNr = '';

  @ValidateNested()
  inhalation = new LanguageClassServer();

  @ValidateNested()
  skinContact = new LanguageClassServer();

  @ValidateNested()
  eyeContact = new LanguageClassServer();

  @ValidateNested()
  ingestion = new LanguageClassServer();

  @IsString()
  explosiveLimits = '';

  @ValidateNested()
  pbm = new PbmOptionsDbServer();

  @ValidateNested()
  suiteableExtinguishingMedia = new LanguageClassServer();

  @ValidateNested()
  unsuiteableExtinguishingMedia = new LanguageClassServer();

  @IsNumber()
  liquid_temperatureBoilingPoint = 0;

  @IsString()
  opmerkingenSeveso = '';

  @IsBoolean()
  foodCompliant = false;

  @IsNumber()
  wgkClass = 0;

  @IsNumber()
  waterSolubility = 0;

  @ValidateNested()
  otherDamagingEffects = new LanguageClassServer();

  @ValidateNested()
  ozonLayerEffects = new LanguageClassServer();

  @ValidateNested()
  globalWarmingEffects = new LanguageClassServer();

  @IsString()
  packingGroup = '';

  @ValidateNested()
  vlarem = new Vlarem();

  @ValidateNested()
  metaData = new MetaDataProductServer();

  constructor(input?: { [prop: string]: any }) {
    if (input) {
      Object.keys(input).forEach(key => {

        switch (key as keyof ProductDbServer) {
          case 'metaData': this[key] = new MetaDataProductServer(input[key]); break;
          case 'pbm': this[key] = new PbmOptionsDbServer(input[key]); break;
          case 'name': this[key] = new LanguageClassServer(input[key]); break;
          case 'properShippingName': this[key] = new LanguageClassServer(input[key]); break;
          case 'eNummer': this[key] = new LanguageClassServer(input[key]); break;
          case 'vlarem': this[key] = new Vlarem(input[key]); break;
          case 'productComponents': this[key] = (input[key] as ProductComponentServer[]).map(c => new ProductComponentServer(c)); break;
          case 'indexNummer': this[key] = input[key].toString(); break;

          default: if (this[key] !== undefined && input[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }
}
