import { Routes } from '@angular/router';
import { AdminGuard } from './admin.guard';
import { AdminComponent } from './admin/admin.component';
import { AllProductsComponent } from './products/products.component';
import { MapComponent } from './map/map.component';
import { UsefulLinksComponent } from './useful-links/useful-links.component';

export const appRoutes: Routes = [
  { path: '', redirectTo: 'map', pathMatch: 'full' }, // Default route
  { path: 'map', component: MapComponent, data: { state: 'map' } },
  { path: 'all-products', component: AllProductsComponent, data: { state: 'all-products' } },
  { path: 'plant-products', component: AllProductsComponent, data: { state: 'plant-products' } },
  { path: 'useful-links', component: UsefulLinksComponent, data: { state: 'useful-links' } },
  { path: 'admin', component: AdminComponent, data: { state: 'admin' }, canActivate: [AdminGuard] },
  { path: '**', redirectTo: '/map', pathMatch: 'full' }, // All other routes
];
