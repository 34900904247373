import { Pipe, PipeTransform } from '@angular/core';
import { FireAuthService } from '../../services/fireAuth.service';

@Pipe({
  name: 'storageSds'
})
export class StorageSdsPipe implements PipeTransform {

  constructor(
    private fireAuthService: FireAuthService
  ) { }

  async transform(storageId: string) {
    const path = "/api/storage/sds/";
    const id = encodeURIComponent(storageId);
    const token = await this.fireAuthService.getAuthToken();
    return path + id + '?token=' + token;
    // return this.http
    //   .get(url, { responseType: 'blob' })
    //   .pipe(
    //     map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val)))
    //   );

  }

}
