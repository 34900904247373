import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PDFProgressData } from 'pdfjs-dist';
import { LanguageClass } from '../../../classes/language.class';
import { Product } from '../../../classes/product.class';
import { UsersService } from '../../../services/users.service';

@Component({
  selector: 'app-sds-viewer',
  templateUrl: './sds-viewer.component.html',
  styleUrls: ['./sds-viewer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SdsViewerComponent implements OnInit, OnChanges {

  // Variables
  @Input() public product: Product;
  @Input() public enableEdit: boolean;
  @Input() public type: string;
  @Output() public languageSelect = new EventEmitter<string>(true);

  public user$ = this.userService.currentUser$;
  // public sdsLinks = new LanguageClass;

  public showSDS = true;
  public progressBar = false;
  public uploading = false;

  public typeLabels = {
    'download': '',
    'upload_new': '',
    'not_found': ''
  };

  public currentTypeLinks = new LanguageClass;

  public deleteSheet(language: string) {
    switch (this.type) {
      case 'sds':
        this.product.metaData.sdsLinks[language] = '';
        break;
      case 'tds':
        this.product.metaData.tdsLinks[language] = '';
        break;
      case 'foodcrtf':
        this.product.metaData.foodcrtfLinks[language] = '';
        break;
    }

    this.product.saveProduct();
  }


  public onProgress(progressData: PDFProgressData) {
    this.progressBar = true;
    if (progressData.loaded === progressData.total) { this.progressBar = false; }
  }

  public async uploadSDS(file: File, language: keyof LanguageClass) {
    this.progressBar = true;
    this.uploading = true;
    this.showSDS = false;

    await this.product.saveSDS([{ file, language }], this.type);
    this.currentTypeLinks[language] = `${this.type}/${this.product.metaData.dbRef}-${language}.pdf`;
    this.showSDS = true;
    this.progressBar = false;
    this.uploading = false;
    this.changeDetector.detectChanges();
  }

  // Life cycle
  constructor(
    private userService: UsersService,
    private changeDetector: ChangeDetectorRef,
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['product'] && changes.product.currentValue) {
      switch (this.type) {
        case 'sds':
          this.currentTypeLinks = this.product.metaData.sdsLinks;
          break;
        case 'tds':
          this.currentTypeLinks = this.product.metaData.tdsLinks;
          break;
        case 'foodcrtf':
          this.currentTypeLinks = this.product.metaData.foodcrtfLinks;
          break;
        default: this.currentTypeLinks = this.product.metaData.sdsLinks;
      }

      for (const key in this.typeLabels) {
        if (key) {
          this.typeLabels[key] = key + '_' + this.type;
        }
      }
      this.changeDetector.detectChanges();
    }
  }

}
