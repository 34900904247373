import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-pbm-options',
  templateUrl: './pbm-options.component.html',
  styleUrls: ['./pbm-options.component.css']
})
export class PbmOptionsComponent implements OnInit {

  public pbmOptionsBycategory$ = this.productService.pbmOptionsByCategory$.pipe(take(1));

  // Life cycle
  constructor(
    private productService: ProductService,
  ) { }

  ngOnInit() { }

}
