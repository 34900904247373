import { Component, Input } from '@angular/core';
import { Hnumber, Pnumber } from '../../classes/h-and-p.class';
import { User } from '../../classes/user.class';

@Component({
  selector: 'app-h-and-p-list',
  templateUrl: './h-and-p-list.component.html',
  styleUrls: ['./h-and-p-list.component.css']
})
export class HAndPListComponent {

  // Variables
  @Input() public hNumberList: Hnumber[];
  @Input() public pNumberList: Pnumber[];

  public hPbmList: { code: string, translated: string }[];
  public user: User;

  constructor() { }

}
