import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../_shared/shared.module';
import { MaterialModule } from '../_shared/_modules/material.module';
import { PlantInfoComponent } from './plant-info/plant-info.component';
import { ProductBasicComponent } from './product-basic/product-basic.component';
import { ProductInfoComponent } from './product-info.component';
import { ProductNotesComponent } from './product-notes/product-notes.component';
import { ProductPhotoComponent } from './product-photo/product-photo.component';
import { RiskAnalysisComponent } from './risk-analysis/risk-analysis.component';
import { VikInfoComponent } from './vik-info/vik-info.component';
import { OrderHazardsPipe } from './vik-info/order-hazards.pipe';

@NgModule({
  declarations: [
    ProductInfoComponent,
    ProductBasicComponent,
    ProductPhotoComponent,
    ProductNotesComponent,
    PlantInfoComponent,
    RiskAnalysisComponent,
    VikInfoComponent,
    OrderHazardsPipe,
  ],
  exports: [],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule
  ],
  entryComponents: [ProductInfoComponent]
})
export class ProductInfoModule { }
