import { Injectable } from '@angular/core';
import { orderBy } from 'lodash';
import { combineLatest } from 'rxjs';
import { debounceTime, filter, flatMap, map, shareReplay } from 'rxjs/operators';
import { Classification } from '../classes/classifications.class';
import { Cmr } from '../classes/cmr.class';
import { HAndPSentences, Hnumber, Pnumber } from '../classes/h-and-p.class';
import { Key, KeyDb, KeyDbCategory, LanguageClass, Translations } from '../classes/language.class';
import { Location } from '../classes/location.class';
import { PbmOption, PbmOptions, PbmOptionsDb } from '../classes/pbm.class';
import { ProductRequest } from '../classes/product-request.class';
import { Product, ProductDb } from '../classes/product.class';
import { RiskAnalysis, Ths } from '../classes/risk.class';
import { Seveso } from '../classes/seveso.class';
import { UnSubstanceCategory } from '../classes/un.class';
import { Vlarem } from '../classes/vlarem.class';
import { Zzs } from '../classes/zzs.class';
import { OptionsSelection } from '../_shared/_components/recursive-form/recursive-form.component';
import { LanguageClassPipe } from '../_shared/_pipes/language-class.pipe';
import { notNullOrUndefined } from '../_shared/_services/app.service';
import { ApiService } from './api.service';
import { DatabaseService } from './database.service';
import { RiskService } from './risk.service';
import { UsersService } from './users.service';
import { Pzzs } from "../classes/pzzs.class";


interface KeyObject {
  product: Key[];
  plant: Key[];
  vlarem: Key[];
  seveso: Key[];
  zzs: Key[];
  risk: Key[];
  pbm: Key[];
  request: Key[];
  requestBasicInfo: Key[];
  ghs: Key[];
  adr: Key[];
  hazard: Key[];
  dropdown: Key[];
}
export type KeyPath = keyof KeyObject;
interface ProductAsRequest {
  username: string;
  email: string;
  plant: string;
  status: 'Requested' | 'Approved' | 'Pending';
  plantName: string;
  productName: LanguageClass;
  productSupplier: string;
  request: ProductRequest;
  productRef: Product;
}

/**
 * This service acts like a data store. We handle the incoming data here so the view components can use them as necessary.
 */
@Injectable({
  providedIn: "root",
})
export class ProductService {
  constructor(
    private database: DatabaseService,
    private userService: UsersService,
    private api: ApiService,
    private risk: RiskService
  ) {}

  public pbmOptions$ = this.database.pbmOptions$.pipe(
    map((pbmOptions) => pbmOptions.map((option) => new PbmOption(option))),
    shareReplay(1)
  );

  public hNumbers$ = combineLatest(
    this.database.hNumbers$,
    this.pbmOptions$
  ).pipe(
    map(([hNumbers, pbmOptions]) =>
      hNumbers.map((hNumber) => new Hnumber(this.api, hNumber, pbmOptions))
    ),
    shareReplay(1)
  );

  public pNumbers$ = this.database.pNumbers$.pipe(
    map((pNumbers) =>
      pNumbers.map((pNumber) => new Pnumber(this.api, pNumber))
    ),
    shareReplay(1)
  );

  public classification$ = this.database.classification$.pipe(
    map((classification) =>
      classification.map((x) => new Classification(this.api, x))
    ),
    shareReplay(1)
  );

  /**
   * Translation keys / headers mapped to an easy to use object.
   */
  public keys$ = this.database.keys$.pipe(
    map((keys) => {
      const keysObj: KeyObject = keys.reduce(
        (obj, keyObject) => ({ ...obj, ...keyObject }),
        {} as KeyObject
      );

      Object.keys(keysObj).forEach((prop) => {
        keysObj[prop] = keysObj[prop].map(
          (key: KeyDb) => new Key(undefined, key)
        );
      });

      return keysObj;
    }),
    shareReplay(1)
  );

  /**
   * All product keys
   */
  public productKeys$ = this.keys$.pipe(
    map((keys) => keys["product"]),
    shareReplay(1)
  );

  /**
   * Active product keys
   */
  public activeProductKeys$ = this.productKeys$.pipe(
    map((keys) => keys.filter((key) => key.visible)),
    shareReplay(1)
  );

  /**
   * All plant keys
   */
  public plantKeys$ = this.keys$.pipe(
    map((keys) => keys["plant"]),
    shareReplay(1)
  );

  /**
   * Active plant keys
   */
  public activePlantKeys$ = this.plantKeys$.pipe(
    map((keys) => keys.filter((key) => key.visible)),
    shareReplay(1)
  );

  /**
   * All pbm keys
   */
  public pbmKeys$ = this.keys$.pipe(
    map((keys) => {
      const pbmColumns = Object.keys(new PbmOptionsDb());
      // set all PBM keys to hideInUi
      keys["pbm"].forEach(
        (k) => (k.hideInUi = pbmColumns.indexOf(k.key) !== -1)
      );

      // add the grouped PBM key, only if it not exist yet
      if (!keys["pbm"].find((k) => k.key === "pbmGrouped")) {
        keys["pbm"].push(new Key("", this.pbmKeysGrouped()));
      }

      return keys["pbm"];
    }),
    shareReplay(1)
  );

  /**
   * Active pbm keys
   */
  public activePbmKeys$ = this.pbmKeys$.pipe(
    map((keys) => keys.filter((key) => key.visible)),
    shareReplay(1)
  );

  /**
   * All zzs keys
   */
  public zzsKeys$ = this.keys$.pipe(
    map((keys) => keys["zzs"]),
    shareReplay(1)
  );

  /**
   * Active zzs keys
   */
  public activeZzsKeys$ = this.zzsKeys$.pipe(
    map((keys) => keys.filter((x) => x.visible)),
    shareReplay(1)
  );

  /**
   * All zzs keys
   */
  public pzzsKeys$ = this.keys$.pipe(
    map((keys) => keys["pzzs"]),
    shareReplay(1)
  );

  /**
   * Active pzzs keys
   */
  public activePzzsKeys$ = this.pzzsKeys$.pipe(
    map((keys: Key[]) => keys.filter((x) => x.visible)),
    shareReplay(1)
  );

  /**
   * All product request keys
   */
  public productRequestKeys$ = this.keys$.pipe(
    map((keys) => keys["request"]),
    shareReplay(1)
  );

  /**
   * Active product request keys
   */
  public activeProductRequestKeys$ = this.productRequestKeys$.pipe(
    map((keys) => keys.filter((key) => key.visible)),
    shareReplay(1)
  );

  /**
   * All risk keys
   */
  public riskKeys$ = this.keys$.pipe(
    map((keys) => keys["risk"]),
    shareReplay(1)
  );

  /**
   * Active risk keys
   */
  public activeRiskKeys$ = this.riskKeys$.pipe(
    map((keys) => keys.filter((x) => x.visible)),
    shareReplay(1)
  );

  /**
   * All seveso keys
   */
  public sevesoKeys$ = this.keys$.pipe(
    map((keys) => keys["seveso"]),
    shareReplay(1)
  );

  /**
   * Active seveso keys
   */
  public activeSevesoKeys$ = this.sevesoKeys$.pipe(
    map((keys) => keys.filter((x) => x.visible)),
    shareReplay(1)
  );

  /**
   * All vlarem keys
   */
  public vlaremKeys$ = this.keys$.pipe(
    map((keys) => keys["vlarem"]),
    shareReplay(1)
  );

  /**
   * Active vlarem keys
   */
  public activeVlaremKeys$ = this.vlaremKeys$.pipe(
    map((keys) => keys.filter((x) => x.visible)),
    shareReplay(1)
  );

  /**
   * All ghs keys
   */
  public ghsKeys$ = this.keys$.pipe(
    map((keys) => keys["ghs"]),
    shareReplay(1)
  );

  /**
   * All adr keys
   */
  public adrKeys$ = this.keys$.pipe(
    map((keys) => keys["adr"]),
    shareReplay(1)
  );

  /**
   * All adr keys
   */
  public hazardKeys$ = this.keys$.pipe(
    map((keys) => keys["hazard"]),
    shareReplay(1)
  );

  public translations$ = this.database.translations$.pipe(
    map((translations) => new Translations(translations)),
    shareReplay(1)
  );

  public productAutoCompleteList$ = combineLatest(
    this.hNumbers$,
    this.pNumbers$,
    this.classification$,
    this.userService.currentUser$
  ).pipe(
    map(([hNumberList, pNumberList]) => ({
      hNr: hNumberList.map((x) => x.code),
      pNr: pNumberList.map((x) => x.code),
    })),
    shareReplay(1)
  );

  public pbmOptionsByCategory$ = this.pbmOptions$.pipe(
    map((options) =>
      options.reduce((obj, option) => {
        if (!obj[option.category]) {
          obj[option.category] = [];
        }
        obj[option.category].push(option);

        return obj;
      }, {} as { [prop: string]: PbmOption[] })
    )
  );

  public cmrList$ = this.database.cmrs$;

  public unList$ = this.database.unSubstanceCategories$;

  public ths$ = this.database.thsDoc$.pipe(
    filter(notNullOrUndefined),
    map((thsDoc) => new Ths(thsDoc)),
    shareReplay(1)
  );

  public zzsList$ = this.database.zzsList$.pipe(
    map((zzsList) => zzsList.map((zzs) => new Zzs(this.api, zzs))),
    shareReplay(1)
  );

  public pzzsList$ = this.database.pzzsList$.pipe(
    map((pzzsList) => pzzsList.map((pzzs) => new Pzzs(this.api, pzzs))),
    shareReplay(1)
  );

  public locations$ = this.database.locations$.pipe(
    map((locations) =>
      locations.map((location) => new Location(this.api, location))
    ),
    shareReplay(1)
  );

  public locationsActive$ = this.locations$.pipe(
    map((locations) =>
      orderBy(
        locations.filter((location) => location.active),
        ["lokatie"],
        ["asc"]
      )
    ),
    shareReplay(1)
  );

  public sevesos$ = this.database.sevesos$.pipe(shareReplay(1));

  /**
   * All products with calculated properties.
   */
  public products$ = combineLatest(
    this.database.products$,
    this.cmrList$,
    this.unList$,
    this.hNumbers$,
    this.pNumbers$,
    this.zzsList$,
    this.pzzsList$,
    this.pbmOptions$,
    this.sevesos$,
    this.ths$,
    this.locationsActive$,
    this.classification$
  ).pipe(
    map(
      ([
        productsDb,
        cmrList,
        unList,
        hNumbers,
        pNumbers,
        zzsList,
        pzzsList,
        pbmOptions,
        sevesoList,
        ths,
      ]) => {
        // Calculated properties
        const products = productsDb.map((productDb: ProductDb) => {
          const product = new Product(this.api, productDb);

          product.cmr = new Cmr(product, cmrList);
          product.stofcategorieUn = new UnSubstanceCategory(
            this.api,
            undefined,
            product,
            unList
          );
          product.hAndPSentences = new HAndPSentences(
            product,
            hNumbers,
            pNumbers
          );
          product.pbmOptions = new PbmOptions(
            this.api,
            product.pbm,
            pbmOptions,
            product.hAndPSentences.hSentences
          );

          product.zzs =
            zzsList.find((y: Zzs) => this.findZZS(product, y)) || null;
          product.pzzs =
            pzzsList.find((y: Pzzs) => this.findPZZS(product, y)) || null;

          product.ghs = product.ghs.sort((a, b) => a.localeCompare(b));

          product.metaData.plants = product.metaData.plants.map((plant) => {
            plant.seveso = new Seveso(plant, product, sevesoList);
            plant.pbmOptions = new PbmOptions(
              this.api,
              plant.pbm,
              pbmOptions,
              product.hAndPSentences.hSentences
            );
            return plant;
          });

          product.dateArchived = product.metaData.dateArchived
            ? new Date(product.metaData.dateArchived)
            : "";
          return product;
        });

        // Calculate risks after calculating dependencies
        const productsRiskTotals = this.risk.calculateRiskAnalysisTotals(
          products,
          ths
        );

        return productsRiskTotals;
      }
    ),
    shareReplay(1)
  );

  /**
   * All current used products. No archived products.
   */
  public productsUsed$ = this.products$.pipe(
    map((products) =>
      products.filter(
        (product) => !product.metaData.archived && product.metaData.approved
      )
    ),
    shareReplay(1)
  );

  /**
   * All archived products.
   */
  public productsArchived$ = this.products$.pipe(
    map((products) => products.filter((product) => product.metaData.archived)),
    shareReplay(1)
  );

  /**
   * All products with no locations and not archived.
   */
  public productsOrphans$ = this.productsUsed$.pipe(
    map((products) =>
      products.filter((product) => !product.metaData.plants.length)
    ),
    shareReplay(1)
  );

  /**
   * All products with active requests. Including archived.
   */
  public productRequests$ = combineLatest(
    this.products$,
    this.locationsActive$
  ).pipe(
    map(([products, locations]) => {
      return products.reduce((acc: ProductAsRequest[], x) => {
        const requests = x.getActiveRequests();
        if (requests) {
          // Set on main object to show in tabel
          const requestsMap: ProductAsRequest[] = requests.map((y) => {
            const location =
              locations.find(
                (a) => a.dbRef === y.request.requestForm.user.plant
              ) || new Location(this.api);
            const plant = location.lokatie + " - " + location.description;
            return {
              ...y,
              username: y.request.requestForm.user.username,
              email: y.request.requestForm.user.email,
              plant: y.request.requestForm.user.plant,
              status: y.request.status,
              plantName: plant,
              productName: y.productRef.name,
              productSupplier: y.productRef.supplier,
            } as ProductAsRequest;
          });
          return [...acc, ...requestsMap];
        }
        return acc;
      }, []);
    }),
    shareReplay(1)
  );

  /**
   * Active requests for the current plant. Including archived.
   */
  public productsRequestCurrentPlant$ = combineLatest(
    this.productRequests$,
    this.userService.currentUser$
  ).pipe(
    debounceTime(200),
    map(([requests, user]) =>
      requests.filter((request) => request.plant === user.plant)
    ),
    shareReplay(1)
  );

  /**
   * All products for the current plant.
   * Current plant is determined by the user db doc.
   */
  public productsCurrentPlant$ = combineLatest(
    this.products$,
    this.userService.currentUser$,
    this.locationsActive$
  ).pipe(
    debounceTime(200),
    map(([products, user, locations]) => {
      const firstLocation = locations[0].dbRef;
      const checkIfValid = locations.some(
        (location) => location.dbRef === user.plant
      );
      if (!checkIfValid) {
        user.updatePlant(firstLocation);
        return [];
      }

      return products.filter((product) =>
        product.metaData.plants.some(
          (x) => x.metaDataPlant.plant === user.plant
        )
      );
    }),
    shareReplay(1)
  );

  /**
   * All used products for the current plant.
   * Current plant is determined by the query param plant.
   * Excluded archived and archived on plant.
   */
  public productsUsedCurrentPlant$ = combineLatest(
    this.productsUsed$,
    this.userService.currentUser$
  ).pipe(
    debounceTime(200),
    map(([products, user]) =>
      products.filter((product) => {
        return product.metaData.plants.some(
          (x) =>
            x.metaDataPlant.plant === user.plant && !x.metaDataPlant.archived
        );
      })
    ),
    shareReplay(1)
  );

  /**
   * Archived products on the current plant.
   */
  public productsCurrentPlantArchived$ = combineLatest(
    this.productsCurrentPlant$,
    this.userService.currentUser$
  ).pipe(
    debounceTime(200),
    map(([products, user]) =>
      products
        .map((p) => {
          const search = p.metaData.plants.find(
            (plant) => plant.metaDataPlant.plant === user.plant
          );
          if (search) {
            if (search.metaDataPlant.dateArchived) {
              p.dateArchived = new Date(search.metaDataPlant.dateArchived);
            }
          }
          return p;
        })
        .filter((product) =>
          product.metaData.plants.some(
            (x) =>
              x.metaDataPlant.plant === user.plant && x.metaDataPlant.archived
          )
        )
    ),
    shareReplay(1)
  );

  /**
   * Products in use with pbm and not archived.
   */
  public productsWithPbm$ = this.productsUsed$.pipe(
    map((products) => {
      return products.map((product) => {
        const newProduct = new Product(this.api, product);
        Object.entries(newProduct.pbmOptions).forEach(
          ([key, option]: [string, PbmOption | null]) => {
            if (key === "suggestions") {
              return;
            }
            newProduct[key] = option
              ? option.translations
              : new LanguageClass();
          },
          {} as { [prop: string]: LanguageClass }
        );

        newProduct.pbmGrouped = true;

        return newProduct;
      }) as (Product & { [prop: string]: LanguageClass })[];
    }),
    shareReplay(1)
  );

  /**
   * Products in use on the current plant with pbm and not archived.
   */
  public productsCurrentPlantWithPbm$ = combineLatest(
    this.productsUsedCurrentPlant$,
    this.userService.currentUser$
  ).pipe(
    debounceTime(200),
    map(([products, user]) => {
      return products.map((product) => {
        const newProduct = new Product(this.api, product);

        const findPlant = newProduct.metaData.plants.find(
          (plant) => plant.metaDataPlant.plant === user.plant
        );
        if (!findPlant) {
          throw new Error("Could not find plant from query param");
        }

        Object.entries(findPlant.pbmOptions).forEach(
          ([key, option]: [string, PbmOption | null]) => {
            if (key === "suggestions") {
              return;
            }
            newProduct[key] = option
              ? option.translations
              : new LanguageClass();
          },
          {} as { [prop: string]: LanguageClass }
        );

        newProduct.pbmGrouped = true;

        return newProduct;
      }) as (Product & { [prop: string]: LanguageClass })[];
    }),
    shareReplay(1)
  );

  /**
   * All non-archived products for the current plant duplicated for each risk.
   * Products are copied for each risk.
   */
  public productsUsedCurrentPlantWithRisks$ = combineLatest(
    this.productsUsedCurrentPlant$,
    this.userService.currentUser$
  ).pipe(
    debounceTime(200),
    map(
      ([products, user]) =>
        products.reduce((acc, product) => {
          product.metaData.plants.forEach((plant) => {
            if (plant.metaDataPlant.plant !== user.plant) {
              return;
            }

            plant.riskAnalysis.forEach((riskAnalysis) => {
              const newProduct = new Product(this.api, product) as Product &
                RiskAnalysis;
              Object.entries(riskAnalysis).forEach(([key, value]) => {
                newProduct[key] = value;
              });
              acc = [...acc, newProduct];
            });
          });

          return acc;
        }, [] as (Product & RiskAnalysis)[]),
      shareReplay(1)
    )
  );

  /**
   * Products in use with seveso and not archived.
   */
  public productsCurrentPlantWithSeveso$ = combineLatest(
    this.productsUsedCurrentPlant$,
    this.userService.currentUser$
  ).pipe(
    debounceTime(200),
    map(([products, user]) => {
      return products.map((product) => {
        const findPlant = product.metaData.plants.find(
          (plant) => plant.metaDataPlant.plant === user.plant
        );

        Object.entries(
          findPlant ? findPlant.seveso || new Seveso() : new Seveso()
        ).forEach(([key, value]) => {
          product[key] = value;
        });

        return product;
      }) as (Product & Seveso)[];
    }),
    shareReplay(1)
  );

  /**
   * Products in use with vlarem and not archived.
   */
  public productsCurrentPlantWithVlarem$ = combineLatest(
    this.productsUsedCurrentPlant$,
    this.userService.currentUser$
  ).pipe(
    debounceTime(200),
    map(([products, user]) => {
      return products.map((product) => {
        const newProduct = new Product(this.api, product);

        const findPlant = newProduct.metaData.plants.find(
          (plant) => plant.metaDataPlant.plant === user.plant
        );
        if (!findPlant) {
          throw new Error("Could not find plant from query param");
        }

        Object.entries(findPlant).forEach(([key, value]) => {
          newProduct[key] = value;
        });

        Object.entries(product.vlarem).forEach(([key, value]) => {
          newProduct[key] = value;
        });

        if (findPlant.vlarem_17_4) {
          newProduct["_17_4"] = true;
        } else {
          newProduct["_17_4"] = false;
        }

        return newProduct;
      }) as (Product & Vlarem)[];
    }),
    shareReplay(1)
  );

  /**
   * Sevesos list filtered on no gevaarlijkeStoffen and when reference is not a CAS number.
   */
  public sevesosFilteredOnDangerous$ = this.sevesos$.pipe(
    // Remove if no gevaarlijkeStoffen en if CAS number
    map((sevesos) =>
      sevesos.filter(
        (seveso) =>
          seveso.gevaarlijkeStoffen &&
          isNaN(Number(seveso.reference.replace(/\-/g, "")))
      )
    ),
    shareReplay(1)
  );
  /**
   * All product additions to be displayed in the UI. It wil mach the product key with the text addition.
   */
  public productfieldSuffixes$ = this.userService.currentUser$.pipe(
    map((_user) => {
      return {
        explosiveLimits: "vol %",
      } as { [key: string]: string };
    }),
    shareReplay(1)
  );

  /**
   * Key for grouped PBM keys
   */
  public pbmKeysGrouped = () => {
    const keyDb = new KeyDb();

    // @TODO could this string be defined at a more generic place?
    keyDb.key = "pbmGrouped";
    keyDb.description = new KeyDbCategory();
    keyDb.tooltip = new KeyDbCategory();
    keyDb.visible = true;
    keyDb.english = "ISO 7010 icons EN";
    keyDb.dutch = "ISO 7010 icons NL";
    keyDb.french = "ISO 7010 icons FR";
    keyDb.german = "ISO 7010 icons DE";

    return keyDb;
  };

  private findZZS(product: Product, y: Zzs) {
    if (product.egNummer && product.egNummer === y.egNummer) {
      return true;
    }
    if (product.productComponents) {
      for (const comp of product.productComponents) {
        if (
          comp.casNummer &&
          comp.casNummer === y.casNummer &&
          comp.weightPercentage &&
          comp.weightPercentage > 1
        ) {
          return true;
        }
      }
    }
    return product.casNummer && product.casNummer === y.casNummer;
  }

  private findPZZS(product: Product, y: Pzzs) {
    if (product.egNummer && product.egNummer === y.egNummer) {
      return true;
    }
    if (product.productComponents) {
      for (const comp of product.productComponents) {
        if (
          comp.casNummer &&
          comp.casNummer === y.casNummer &&
          comp.weightPercentage &&
          comp.weightPercentage > 1
        ) {
          return true;
        }
      }
    }
    return product.casNummer && product.casNummer === y.casNummer;
  }

  /**
   * PbmOptions object translated for option select view
   */
  public pbmOptionSelections = (languageClassPipe: LanguageClassPipe) =>
    combineLatest(this.pbmOptions$, this.userService.currentUser$).pipe(
      flatMap(async ([pbmOptions, user]) => {
        // Set categories and add empty option
        const categories = Object.keys({ ...new PbmOptions(this.api) });
        const optionsObject = {};

        categories.forEach((category) => {
          optionsObject[category] = [{ optionView: "---", optionValue: "" }];
        });

        return await Promise.all(
          pbmOptions.map(async (option) => ({
            optionView: await languageClassPipe.setLanguage(
              option.translations,
              user.language
            ),
            optionValue: option.id,
            category: option.category,
          }))
        ).then((opts) =>
          opts.reduce((obj, option) => {
            if (option.category === "ademhalingsBescherming0") {
              obj["ademhalingsBescherming0"].push(option);
              obj["ademhalingsBescherming1"].push(option);
              obj["ademhalingsBescherming2"].push(option);
            } else {
              obj[option.category].push(option);
            }

            return obj;
          }, optionsObject as OptionsSelection)
        );
      }),
      shareReplay(1)
    );

  /**
   * All product option selection for the UI. It will match the product key with the options.
   */
  public productOptionSelections = (_languageClassPipe: LanguageClassPipe) =>
    combineLatest(
      this.userService.currentUser$,
      this.sevesosFilteredOnDangerous$,
      this.ghsKeys$,
      this.adrKeys$,
      this.hazardKeys$,
      this.classification$,
      this.userService.currentUser$
    ).pipe(
      map(([_user, sevesos, ghs, adr, hazard, classificationList, user]) => {
        const options = {
          physicalState: [
            { optionView: "Gas", optionValue: "gas" },
            {
              optionView: "Gas liquified under pressure",
              optionValue: "gas liquified under pressure",
            },
            { optionView: "Liquid", optionValue: "liquid" },
            { optionView: "Solid", optionValue: "solid" },
            { optionView: "Aerosol", optionValue: "aerosol" },
            { optionView: "Pasta", optionValue: "pasta" },
            { optionView: "Fine powder", optionValue: "fine powder" },
            { optionView: "Soligranulate", optionValue: "soligranulate" },
            { optionView: "Granulate", optionValue: "granulate" },
          ],
          signalword: [
            { optionView: "Warning", optionValue: "warning" },
            { optionView: "Danger", optionValue: "danger" },
            { optionView: "N/A", optionValue: "" },
          ],
          opmerkingenSeveso: [
            { optionView: "N/A", optionValue: "" },
            ...sevesos.map((seveso: { indeling: any; reference: any }) => ({
              optionView: seveso.indeling,
              optionValue: seveso.reference,
            })),
          ],
          ghs: ghs.map((x: { key: any }) => ({
            optionView: x.key,
            optionValue: x.key,
          })),
          adr: adr.map((x: { key: any }) => ({
            optionView: x.key,
            optionValue: x.key,
          })),
          hazard: hazard.map((x: { key: any }) => ({
            optionView: x.key,
            optionValue: x.key,
          })),
          vosRegistration: [
            { optionView: "Yes", optionValue: "yes" },
            { optionView: "No", optionValue: "no" },
            { optionView: "N/A", optionValue: "" },
          ],
          packingGroup: [
            { optionView: "N/A", optionValue: "yes" },
            {
              optionView: "veryDangerousSubstance",
              optionValue: "very dangerous substance",
            },
            {
              optionView: "dangerousSubstance",
              optionValue: "dangerous substance",
            },
            {
              optionView: "lessDangerousSubstance",
              optionValue: "less dangerous substance",
            },
          ],
          classification: classificationList
            .reduce(function (
              result: { optionView: string; optionValue: string }[],
              obj: Classification
            ) {
              obj.hazard_statement
                ? result.push({
                    optionView:
                      obj.hazard_statement + " - " + obj[user.language],
                    optionValue: obj.Code,
                  })
                : result.push({
                    optionView: obj[user.language],
                    optionValue: obj.Code,
                  });
              return result;
            },
            [])
            .sort((a: OptionsSelection, b: OptionsSelection) =>
              a.optionView.toString().localeCompare(b.optionView.toString())
            )
            .reduce(
              (
                acc: { set: (arg0: any, arg1: any) => void },
                cur: any,
                idx: any
              ) => {
                acc.set(idx, cur);
                return acc;
              },
              new Map([])
            ),
        } as OptionsSelection;
        return options;
      }),
      shareReplay(1)
    );

  /**
   * All risk option selection for the UI. It wil mach the risk key with the options.
   */
  public riskOptionSelections = (_languageClassPipe: LanguageClassPipe) =>
    combineLatest(
      this.userService.currentUser$,
      this.sevesosFilteredOnDangerous$
    ).pipe(
      map(([_user]) => {
        return {
          frequencyClass04CfrTable1: [
            { optionView: 0, optionValue: 0 },
            { optionView: 1, optionValue: 1 },
            { optionView: 2, optionValue: 2 },
            { optionView: 3, optionValue: 3 },
            { optionView: 4, optionValue: 4 },
          ],
          physicalStateRisk: [
            { optionView: "Gas", optionValue: "gas" },
            { optionView: "Liquid", optionValue: "liquid" },
          ],
          peopleInContactWithSubstance: [{ optionView: 0, optionValue: 0 }],
          contactWithProduct: [
            { optionView: "None", optionValue: "none" },
            { optionView: "Irregular", optionValue: "irregular" },
            { optionView: "Low", optionValue: "low" },
            { optionView: "Mediocre", optionValue: "mediocre" },
            { optionView: "High", optionValue: "high" },
          ],
        } as OptionsSelection;
      }),
      shareReplay(1)
    );
}
