import { Pipe, PipeTransform } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { Key } from '../../../classes/language.class';

@Pipe({
  name: 'getFooter'
})
export class GetFooterPipe implements PipeTransform {

  private sumKg(data: any[], column: Key): string {
    const filterRecords = data.filter(x => x[column.key]);
    const sumKg = filterRecords.reduce((a, b) => a += b['maximumStockKG'], 0);
    return `${sumKg} Kg`;
  }

  private sumL(data: any[], column: Key): string {
    const filterRecords = data.filter(x => x[column.key]);
    const sumL = filterRecords.reduce((a, b) => a += b['maximumStockL'], 0);
     return `${sumL} L`;
  }

  private combinedValue(data: any[], column: Key): string {
    if (column.key.includes('coefficient')) {
      const numberArray = data.filter(x => x[column.key] > 0);
      const result = numberArray.reduce((a, b) => a += b[column.key], 0);
      return (Math.round(result * 1000) / 1000).toFixed(3);
    }

    // Custom sum if boolean;
    if (
      data.length > 0 &&
      data[0][column.key] !== undefined &&
      typeof data[0][column.key] === 'boolean'
    ) {

      const filterRecords = data.filter(x => x[column.key]);
      const sumKg = filterRecords.reduce((a, b) => a += b['maximumStockKG'], 0);
      const sumLiters = filterRecords.reduce((a, b) => a += b['maximumStockL'], 0);
      const sumKgString = sumKg ? `${sumKg} Kg` : '';
      const sumLString = sumLiters ? `${sumLiters} L` : '';
      const combinedString = `${sumKgString} ${sumLString}`;

      return combinedString;
    }

    return '';
  }

  transform(column: Key, dataSource: MatTableDataSource<any>): Observable<string | number> {

    return dataSource.connect().pipe(
      skipWhile(x => !x),
      map(() => {

        const data = dataSource.data;

        switch (column.key) {
          case '_6_4':
          case '_17_1_1':
          case '_17_1_2_1':
          case '_17_1_2_2': return this.sumL(data, column);
          case '_17_2':
          case '_17_3_1_Ghs01':
          case '_17_3_2_1_1_Ghs02':
          case '_17_3_2_1_2_Ghs02':
          case '_17_3_2_2_Ghs02':
          case '_17_3_2_3_Ghs02':
          case '_17_3_3_Ghs03':
          case '_17_3_4_Ghs05':
          case '_17_3_5_Ghs06':
          case '_17_3_6_Ghs07':
          case '_17_3_7_Ghs08':
          case '_17_3_8_Ghs09': return this.sumKg(data, column);
          case '_17_4': return `${this.sumKg(data, column)}\r\n${this.sumL(data, column)}`;
          default: {
            return this.combinedValue(data, column);
          }
        }

      })
    );

  }

}
