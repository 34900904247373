import { Injectable } from '@angular/core';
import { filter, flatMap, map, shareReplay } from 'rxjs/operators';
import { CustomClaims } from '../../../server/src/types';
import { CurrentUser } from '../classes/user.class';
import { notNullOrUndefined } from '../_shared/_services/app.service';
import { ApiService } from './api.service';
import { DatabaseService } from './database.service';
import { FireAuthService } from './fireAuth.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  public currentUser$ = this.database.currentUser$.pipe(
    flatMap(user => this.auth.getTokenPayload()
      .then(payload => new CurrentUser(user, this.api, (payload.claims as CustomClaims).admin))),
    shareReplay(1)
  );

  public admins$ = this.database.adminDoc$.pipe(
    filter(notNullOrUndefined),
    map(admins => admins['admin'])
  );

  constructor(
    private database: DatabaseService,
    private api: ApiService,
    private auth: FireAuthService
  ) { }
}
