import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ComponentType } from '@angular/cdk/portal';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { delay, flatMap, skipWhile, take, tap } from 'rxjs/operators';
import { Key, LanguageClass } from '../../../classes/language.class';
import { Location } from '../../../classes/location.class';
import { Product } from '../../../classes/product.class';
import { CurrentUser } from '../../../classes/user.class';
import { ProductEditComponent } from '../../../product-edit/product-edit.component';
import { ProductInfoComponent } from '../../../product-info/product-info.component';
import { ProductRequestComponent } from '../../../product-request/product-request.component';
import { UsersService } from '../../../services/users.service';
import { Languages } from '../../../_types/app';
import { LanguageClassPipe } from '../../_pipes/language-class.pipe';
import {
  ExportTableService,
  ProductExportType,
} from '../../_services/export-table.service';
import { DialogComponent } from '../dialog/dialog.component';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { ProductTableService } from './product-table.service';
import { PbmOptionsDb } from '../../../classes/pbm.class';

export interface TableDataRowInput {
  [prop: string]: any;
  expandedDetail?: ExpandedDetail;
}
export interface ExpandedDetail {
  [prop: string]:
  | string
  | string[]
  | number
  | number[]
  | { [prop: string]: string | number | LanguageClass }[];
}

@Component({
  selector: 'app-product-table',
  templateUrl: './product-table.component.html',
  styleUrls: ['./product-table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
      transition(
        'expanded <=> void',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ProductTableComponent implements OnInit, OnDestroy, OnChanges {
  private _subs = new Subscription();

  public tableChanged$: Observable<boolean>;

  // Variables
  public dataSource = new MatTableDataSource<Product>();
  public rowData: TableDataRowInput;
  public displayedColumns: string[] = [];
  public language: Languages = 'english';
  public pageSizeOptions = [15, 25, 50, 100];
  public expandedElement: any;
  public defaultSheetUrl: string | undefined;
  public registerSheetUrl: string | undefined;
  public product_logSheetUrl: string | undefined;
  public defaultExportRunning: boolean;
  public registerExportRunning = false;
  public product_logExportRunning = false;
  public routerState: string;

  @ViewChild(MatPaginator) private paginator: MatPaginator;
  @ViewChild(MatSort) private sort: MatSort;

  @Input() public displayedProductKeys: Key[];
  @Input() public tableData: Product[] = [];
  @Input() public showPlantSelect = true;
  @Input() public plantSelect: string;
  @Input() public rowClickComponent: ComponentType<any>;
  @Input() public showFooter = false;
  @Input() public expandedDetail = false;
  @Input() public showDetailsButton = false;
  @Input() public textBelowLocationSelection = '';
  @Input() public displayedPbmColumns: string[];
  @Input() public isPbmGroupedVisible: boolean;
  @Input() public colSpan: number;

  public user: CurrentUser | null;

  public selectedLocation(location: Location) {
    this.productTableService.setSelectedLocation(location);
  }

  // Request
  public productRequest() {
    this.matDialog.open(DialogComponent, {
      data: { component: ProductRequestComponent },
      height: '95%',
      disableClose: true,
    });
  }
  public async exportTableDataToSheet(
    exportType: ProductExportType,
    productDbRef?: string,
    productNames?: {}
  ) {
    if (!this.user) {
      return;
    }

    let productName = '';
    if (productNames) {
      productName = productNames[this.user.language];
    }

    const currentPlant = await this.productTableService.selectedLocation$
      .pipe(take(1))
      .toPromise();
    const runner = `${exportType}ExportRunning`;
    const sheet = `${exportType}SheetUrl`;
    this[runner] = true;
    const d = new Date();
    const userEmail = this.user.email;

    let headers = [
      'Action',
      'Field',
      'Value before',
      'Value after',
      'Plant',
      'Changed by',
      'Change date',
      'TS TO SORT'// this will be removed in the export script
    ];

    if (exportType !== 'product_log') {
      headers = await this.exportTable.getHeadersForType(exportType, this);
    }

    const sheetName = this.exportTable.getSheetNameType(exportType, d, currentPlant, productName);
    const sheetData = await this.exportTable.getSheetDataForType(exportType, this, currentPlant, productDbRef);
    const response = await this.exportTable.exportTableToSheet(userEmail, sheetName, sheetData, headers, exportType);

    if (response) {
      this[runner] = false;
      if (response['error']) {
        this.snackbar.snackbarError(response['error']['message']);
      } else {
        this[sheet] = response.url;
        this.snackbar.snackbarSuccess('Created sheet');
      }
    }
  }

  // Table Methods
  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  private runSort() {
    if (!this.dataSource.sort) {
      return;
    }

    this.dataSource.sort.direction = '';
    this.dataSource.sort.disableClear = true;
    const start = 'asc';

    this.dataSource.sort.sort({
      start,
      id: this.displayedColumns[0],
      disableClear: false,
    });
  }

  // Product info / edit
  public openProductDialog(row: Product) {
    this.productTableService.setActiveProduct(row);

    this.matDialog.open(DialogComponent, {
      data: { component: ProductInfoComponent },
      height: '95vh',
      width: '95vw',
      disableClose: true,
    });
  }

  public openProductEditDialog(row: Product) {
    this.productTableService.setActiveProduct(row);

    this.matDialog.open(DialogComponent, {
      data: { component: ProductEditComponent },
      height: '95vh',
      width: '95vw',
      disableClose: true,
    });
  }

  public openrowClickComponentDialog(rowData: any) {
    this.product_logSheetUrl = '';
    if (!this.rowClickComponent) {
      return;
    }
    if (!rowData) {
      throw new Error('No row data!');
    }
    this.productTableService.setRowData(rowData);

    this.matDialog.open(DialogComponent, {
      data: { component: this.rowClickComponent },
      height: '95vh',
      disableClose: true,
    });
  }

  // Helper methods
  public customTrackBy(index: number, _item: any) {
    return index;
  }

  // Life cycle methods
  private subscribeToObservables() {
    const userSub = this.userService.currentUser$.subscribe((user) => {
      if (!user) {
        return;
      }
      this.user = user;
      this.language = user.language;
    });

    this._subs.add(userSub);
  }

  public multiLineToolTip(v1: string, v2?: string, v3?: string) {
    if (v2) {
      return `${v1} / ${v2} / ${v3}`;
    } else {
      return `${v1} / ${v3}`;
    }
  }

  public getPbmIconPath(icon: string) {
    return ` ../../../../../../assets/pbmNew/${icon}.png`;
  }

  // Life cycle
  constructor(
    private userService: UsersService,
    private matDialog: MatDialog,
    private productTableService: ProductTableService,
    private exportTable: ExportTableService,
    public languageClassPipe: LanguageClassPipe,
    private snackbar: SnackbarComponent,
    private router: Router
  ) { }

  ngOnInit() {
    this.subscribeToObservables();

    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = this.productTableService.filterMethod; // Custom filter method
    this.dataSource.sortingDataAccessor = this.productTableService.sortingMethod; // Custom sort method

    // Set the default page size
    this.dataSource.paginator.pageSize = 50;

    // Get router state
    let url = this.router.url;
    if (url.includes('?')) {
      url = url.split('?')[0];
    }
    if (url.startsWith('/')) {
      url = url.substring(1);
    }
    this.routerState = url;

    // Run sorting on first time data
    this.tableChanged$ = this.dataSource.connect().pipe(
      skipWhile(() => !this.tableData),
      delay(2000),
      take(1),
      flatMap(
        () =>
          new Promise((resolve) =>
            setTimeout(() => resolve(true))
          ) as Promise<boolean>
      ),
      // After table ngIf === true
      tap(() => {
        setTimeout(() => {
          this.dataSource.sort = this.sort;
          this.runSort();
        });
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.displayedProductKeys &&
      changes.displayedProductKeys.currentValue &&
      changes.displayedProductKeys.currentValue.length > 0
    ) {
      if (this.displayedProductKeys.some((x) => !(x instanceof Key))) {
        console.error('Not an key class provided');
      }
      this.displayedColumns = this.displayedProductKeys.map((x) => x.key);

      // Flag if pbmGrouped is visible
      this.isPbmGroupedVisible =
        this.displayedProductKeys.find(
          (k) => k.key === 'pbmGrouped' && k.visible === true
        ) !== undefined;
      // the PBM columns displayed
      this.displayedPbmColumns = Object.keys(new PbmOptionsDb()).filter(
        (col) => this.displayedColumns.indexOf(col) !== -1
      );
      // the colspan, if on PBM Matrix tab, substract number of PBM columns
      this.colSpan = !this.displayedPbmColumns.length
        ? this.displayedColumns.length
        : this.displayedColumns.length - this.displayedPbmColumns.length + 1;
    }

    if (
      changes.tableData &&
      changes.tableData.currentValue &&
      changes.tableData.currentValue.length
    ) {
      this.dataSource.data = this.tableData;
    } else {
      this.dataSource.data = [];
    }

    if (changes.tableData && changes.tableData.currentValue) {
      this.defaultSheetUrl = undefined;
      this.registerSheetUrl = undefined;
      this.product_logSheetUrl = undefined;
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  sortPBM(unsorted: {}) {
    const arr = Object.keys(unsorted).map((k) => ({ ...unsorted[k], key: k })).filter(a => a != null);
    const sortedArr = arr.sort((a, b) => a.order - b.order);

    // console.log(sortedArr);

    return sortedArr;

    const sortedObj = {};
    sortedArr.forEach(a => {
      sortedObj[a.key] = a;
    });

    return sortedObj;
  }
}
