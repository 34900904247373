// Set environment configs here that can be shared between client and backend

/**
 * Environment setting. Package.json contains tests on deploy for this value.
 */
type environments = 'dev' | 'prod';

export const env = "dev" as environments;

export const googleAPIKey = 'AIzaSyCpwVjtRjOBA0cy2FhM_s-4r6YbZP5u0A4';

export const appRoot = "/usr/src/";
