import { LocationServer } from '../../../server/src/routes/location/types';
import { SuccessReturn } from '../../../server/src/types';
import { ApiService } from '../services/api.service';

export class LocationDb implements LocationServer {

  adres = '';
  description = '';
  land = '';
  latitude = 0;
  lokatie = '';
  longitude = 0;
  nr = '';
  plaats = '';
  postcode = '';
  active = false;
  dbRef = '';
  vlarem = false;
  seveso = false;

  constructor(input: LocationDb | undefined) {
    if (input) {
      Object.keys(input).forEach(key => {
        if (this[key] !== undefined) { this[key] = input[key]; }
      });
    }
  }

}

export class Location extends LocationDb {

  static api: ApiService;

  static updateAllLocations: (locations: LocationDb[]) => Promise<SuccessReturn>;

  public saveLocation() {
    if (!this.dbRef) { return Location.api.addLocation(this); }
    return Location.api.updateLocation(this);
  }

  public deleteLocation() {
    if (!this.dbRef) { throw new Error('No dbRef present'); }
    return Location.api.deleteLocation(this.dbRef);
  }

  constructor(api: ApiService, input?: LocationDb) {
    super(input);

    Location.api = api;

    if (!Location.updateAllLocations) {
      Location.updateAllLocations = (locations: LocationDb[]) => {
        return Location.api.updateLocationsAll(locations);
      };
    }
  }


}
