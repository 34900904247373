import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ProductService } from '../services/product.service';
import { UsersService } from '../services/users.service';
import { LanguageKeys } from '../_shared/_components/recursive-form/language-key.pipe';
import { combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root',
}) export class ProductRequestService {

  public translatedKeys$ = combineLatest(
    this.userService.currentUser$,
    this.productService.locationsActive$,
    this.productService.keys$
  ).pipe(
    map(([user, locations, keys]) => {
      const location = locations.find(x => x.dbRef === user.plant);
      if (!location) { throw new Error('Location not found'); }

      const plantKeys = keys['plant'];
      const basicInfoKeys = keys['requestBasicInfo'];

      const translatedKeys: LanguageKeys = {};
      // Set translations for form keys
      [...basicInfoKeys, ...plantKeys].forEach(x => {
        if (x[user.language]) {
          translatedKeys[x.key] = x[user.language];
        } else {
          translatedKeys[x.key] = x.key;
        }
      });
      translatedKeys['username'] = 'Username';
      translatedKeys['email'] = 'E-mail';

      return translatedKeys;
    }));

    constructor(
      private productService: ProductService,
      private userService: UsersService,
    ) {}

}
