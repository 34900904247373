import { Pipe, PipeTransform } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { UsersService } from '../../services/users.service';
import { map } from 'rxjs/operators';
import { UiService } from '../../services/ui.service';

@Pipe({
  name: 'uiTranslation'
})
export class UiTranslationPipe implements PipeTransform {

  constructor(
    private userService: UsersService,
    private uiService: UiService
  ) { }

  transform(value: string): Observable<string> {
    return combineLatest(
      this.userService.currentUser$,
      this.uiService.uiKeys$
    ).pipe(
      map(([user, keys]) => {
        const language = user.language || 'english';

        const findTranslation = keys.find(key => key.key === value);
        if (!findTranslation || !findTranslation[language]) { return value; }

        return findTranslation[language];
      })
    );
  }

}
