import { Injectable } from '@angular/core';
import { shareReplay, map } from 'rxjs/operators';
import { ProductService } from './product.service';
import { Key } from '../classes/language.class';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  constructor(private productService: ProductService) { }

  /**
  * All ui keys
  */
  public uiKeys$: Observable<Key[]> = this.productService.keys$.pipe(
    map(keys => keys['ui']),
    shareReplay(1)
  );

  /**
   * Active ui keys
   */
  public activeUiKeys$ = this.uiKeys$.pipe(
    map(keys => keys.filter(key => key.visible)),
    shareReplay(1)
  );
}
