import { Pipe, PipeTransform } from '@angular/core';
import { PbmOptionsDb, PbmOptionDb } from '../../classes/pbm.class';
import { ProductService } from '../../services/product.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'orderPbm'
})
export class OrderPbmPipe implements PipeTransform {

  transform(value: PbmOptionsDb): Observable<PbmOptionDb> {
    return this.productService.pbmKeys$.pipe(
      map(pbmKeys => {
        return pbmKeys.reduce((acc, pbmKey) => {
          if (pbmKey.key && value[pbmKey.key] !== undefined) {
            acc[pbmKey.key] = value[pbmKey.key];
          }
          return acc;
        }, {} as PbmOptionDb);
      })
    );
  }

  constructor(private productService: ProductService) {
  }

}
