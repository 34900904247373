import { IsEmail, IsString, Matches, IsNotEmpty } from 'class-validator';
import * as admin from 'firebase-admin';

export class UserDbServer {

  @IsString()
  @IsNotEmpty()
  userId = '';

  @IsString()
  username = '';

  @Matches(/(dutch|english|french|german)/)
  language: 'dutch' | 'english' | 'french' | 'german' = 'english';

  @IsEmail()
  @IsNotEmpty()
  email = '';

  @IsString()
  plant = '';

  @IsString()
  dbRef = '';

  constructor(auth?: admin.auth.UserInfo, input?: UserDbServer) {
    if (auth) {
      this.userId = auth.uid;
      this.username = auth.displayName;
      this.language = 'english';
      this.email = auth.email;
      this.plant = '';
      this.dbRef = auth.uid;
    }

    if (input) {
      Object.keys(input).forEach(x => {
        if (this[x] !== undefined && input[x] !== undefined) { this[x] = input[x]; }
      });
    }

  }

}
