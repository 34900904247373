import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { DialogContent, DialogComponent } from '../../../_shared/_components/dialog/dialog.component';
import { InfoTableService } from '../../../_shared/_components/info-table/info-table.service';
import { SnackbarComponent } from '../../../_shared/_components/snackbar/snackbar.component';
import { SevesoDb } from '../../../classes/seveso.class';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-seveso-edit',
  templateUrl: './seveso-edit.component.html',
  styleUrls: ['./seveso-edit.component.css']
})
export class SevesoEditComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription;

  // Variables
  public seveso: SevesoDb;
  public editable: boolean;
  public validated = false;
  public sevesoForm: SevesoDb;
  private dbRef: string;

  // Methods
  public getSeveso(seveso: SevesoDb) {
    this.sevesoForm = new SevesoDb(this.api, seveso);
    this.sevesoForm.dbRef = this.dbRef;
  }

  public sevesoValidate(validated: boolean) {
    this.validated = validated;
  }

  public closeDialog() {
    this.matDialog.closeAll();
  }

  public async saveData() {
    await this.sevesoForm.saveSeveso();
    this.snackbar.snackbarSuccess('Seveso saved');
  }

  public deleteEntry() {

    const data: DialogContent = {
      dialogData: {
        title: 'Confirm',
        body: `Are you sure you want to delete this entry?`,
        button: 'Delete',
        button2: 'Cancel'
      }
    };
    const dialog = this.matDialog.open(DialogComponent, { data, disableClose: true });

    dialog.afterClosed().subscribe(async response => {
      if (!response) { return; }
      await this.sevesoForm.deleteSeveso();
      this.snackbar.snackbarSuccess('Seveso deleted');
      this.matDialog.closeAll();
    });
  }


  // Life cycle methods
  private subscribeToObservables() {
    const rowDataSub = this.infoTableService.rowData$.subscribe(seveso => {
      this.dbRef = seveso.dbRef;
      this.seveso = seveso;
    });

    this.subscriptions.add(rowDataSub);
  }

  // Life cycle
  constructor(
    private infoTableService: InfoTableService,
    private matDialog: MatDialog,
    private api: ApiService,
    private snackbar: SnackbarComponent
  ) { }

  ngOnInit() {
    this.subscribeToObservables();
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
