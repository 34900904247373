import { Pipe, PipeTransform } from '@angular/core';

export interface LanguageKeys {
  [key: string]: string;
}

@Pipe({
  name: 'languageKey'
})
export class LanguageKeyPipe implements PipeTransform {

  upperCaseIfLanguage(value: any) {
    if (typeof value === 'string' && value ===
      'dutch' ||
      'english' ||
      'french' ||
      'german'
    ) {
      return value.charAt(0).toUpperCase() + value.substring(1);
    }
    return value;
  }

  transform(value: any, translations: LanguageKeys): any {
    if (value && translations) {
      if (translations[value]) { return translations[value]; }
    }
    return this.upperCaseIfLanguage(value);
  }

}
