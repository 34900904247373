import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProductPlant } from '../../classes/product-plant.class';
// import { RequestFormBasicInfo } from '../../classes/product-request.class';
import { CurrentUser } from '../../classes/user.class';
import { UsersService } from '../../services/users.service';
import { VlaremService } from '../../services/vlarem.service';
import { RecursiveFormComponent } from '../../_shared/_components/recursive-form/recursive-form.component';
import { ProductRequestService } from '../product-request.service';
import { CustomValidators } from '../validators.service';

@Component({
  selector: 'app-request-plant-info',
  templateUrl: './request-plant-info.component.html',
  styleUrls: ['./request-plant-info.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestPlantInfoComponent implements OnInit, AfterViewInit, OnDestroy {

  private _subs = new Subscription();

  public user: CurrentUser;
  public userFormFilter: (keyof CurrentUser)[] = ['dbRef', 'language', 'userId', 'plant', 'isAdmin', 'isDeveloper'];

  @Output() private validatedPlantInfo = new EventEmitter<boolean>();
  // @Output() private validatedBasicInfo = new EventEmitter<boolean>();
  @Output() private plantInfo = new EventEmitter<ProductPlant>();
  // @Output() private basicInfo = new EventEmitter<RequestFormBasicInfo>();

  @ViewChild('plantInformationFromElement') private plantInformationFromElement: RecursiveFormComponent;
  // @ViewChild('basicInfoFormFromElement') private basicInfoFormFromElement: RecursiveFormComponent;

  public translatedKeys$ = this.productRequestService.translatedKeys$;

  public productPlantObject = new ProductPlant();
  public productPlantFormValidators = this.validators.productPlantFormValidators();
  public productPlantFormFilter = ['metaDataPlant', 'risk', 'vlarem', 'pbm', 'seveso'];

  // Field control
  public readOnlyFields = ['maximumStockKG', 'maximumStockL'];

  public validateProductPlantForm(status: boolean) { this.validatedPlantInfo.emit(status); }
  public setProductPlantForm(form: ProductPlant) { this.plantInfo.emit(new ProductPlant(form)); }

  public validateForm() {
    this.plantInformationFromElement.validateForm();
  }

  constructor(
    private productRequestService: ProductRequestService,
    private userService: UsersService,
    private validators: CustomValidators,
    private vlaremService: VlaremService
  ) { }

  ngOnInit() {
    this._subs.add(
      this.userService.currentUser$.subscribe(user => {
        this.user = user;
        this.productPlantObject.metaDataPlant.plant = user.plant;
      })
    );
  }

  ngAfterViewInit() {
    this._subs.add(
      this.vlaremService.setVlaremListener(this.plantInformationFromElement).subscribe()
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

}
