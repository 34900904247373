import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valueType'
})
export class ValueTypePipe implements PipeTransform {

  transform(value: any): string | null {
    if (value instanceof Date) { return 'date'; }
    if (Array.isArray(value)) { return 'array'; }
    if (typeof value === 'boolean') { return 'boolean'; }
    if (typeof value === 'number') { return 'number'; }
    if (typeof value === 'object' && value !== null) { return 'object'; }
    return null;
  }

}
