import { CmrDbServer } from '../../../server/src/routes/product/types';
import { ApiService } from '../services/api.service';
import { ProductDb } from './product.class';

export class Cmr {

  toxic: { max: number, reason: string[] } = { max: 1, reason: [] };
  fire: { max: number, reason: string[] } = { max: 1, reason: [] };
  environment: { max: number, reason: string[] } = { max: 1, reason: [] };

  private calculateCmr(product: ProductDb, cmrList: CmrDb[]) {
    const cmrInfo = cmrList.filter(x => product.hNr.some(y => x.hNr === y));
    if (cmrInfo.length === 0) { return; }

    const toxicMax = Math.max(...cmrInfo.map(x => x.toxic));

    const fireMax = Math.max(...cmrInfo.map(x => x.fire));
    const environmentMax = Math.max(...cmrInfo.map(x => x.environment));

    const toxicMaxHnumbers = cmrInfo
      .filter(x => x.toxic === toxicMax && x.toxic > 0)
      .map(x => x.hNr);
    const fireMaxHnumbers = cmrInfo
      .filter(x => x.fire === fireMax && x.fire > 0)
      .map(x => x.hNr);
    const environmentMaxHnumbers = cmrInfo
      .filter(x => x.environment === environmentMax && x.environment > 0)
      .map(x => x.hNr);

    this.toxic = { max: toxicMax, reason: toxicMaxHnumbers };
    this.fire = { max: fireMax, reason: fireMaxHnumbers };
    this.environment = { max: environmentMax, reason: environmentMaxHnumbers };
  }


  constructor(product?: ProductDb, cmrList?: CmrDb[], input?: Cmr) {
    if (product && cmrList) {
      this.calculateCmr(product, cmrList);
    }

    if (input) {
      Object.keys(input).forEach(key => {
        if (this[key] !== undefined) { this[key] = input[key]; }
      });
    }

  }

}

export class CmrDb implements CmrDbServer {

  static api: ApiService;

  hNr = '';
  toxic: 1 | 2 | 3 | 4 | 5 = 1;
  fire: 1 | 2 | 3 | 4 | 5 = 1;
  environment: 1 | 2 | 3 | 4 | 5 = 1;

  public saveCmrDb() {
    if (!this.hNr) { throw new Error('No h-number speciefied. This is the dbRef'); }
    return CmrDb.api.updateCmr(this);
  }

  public deleteCmrDb() {
    if (!this.hNr) { throw new Error('No dbRef present'); }
    return CmrDb.api.deleteCmr(this.hNr);
  }

  constructor(api: ApiService, input?: CmrDb) {
    if (input) {
      Object.keys(input).forEach(key => {
        if (this[key] !== undefined) { this[key] = input[key]; }
      });
    }

    CmrDb.api = api;
  }

}
