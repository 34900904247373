import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isPercentage'
})
export class IsPercentagePipe implements PipeTransform {

  transform(key: string): boolean {
    const split = key.split('_');
    return split[split.length - 1] === 'perc';
  }

}
