import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { ProductForm } from '../../classes/product-sections.class';
import { Product } from '../../classes/product.class';
import { CurrentUser } from '../../classes/user.class';
import { ApiService } from '../../services/api.service';
import { ProductService } from '../../services/product.service';
import { UsersService } from '../../services/users.service';
import { RecursiveFormComponent } from '../../_shared/_components/recursive-form/recursive-form.component';
import { ProductRequestService } from '../product-request.service';
import { CustomValidators } from '../validators.service';
import { LanguageClassPipe } from '../../_shared/_pipes/language-class.pipe';
import { cloneDeep } from 'lodash';
import { newAppUrl } from "../../../../server/src/config";

@Component({
  selector: "app-request-product",
  templateUrl: "./request-product.component.html",
  styleUrls: ["./request-product.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestProductComponent implements OnInit, OnDestroy {
  private _subs = new Subscription();

  @Output() private newProduct = new EventEmitter<boolean>();
  @Output() private validated = new EventEmitter<boolean>();
  @Output() private product = new EventEmitter<Product>();

  @ViewChild("optionSupplier") private optionSupplier: MatSelect;
  @ViewChild("productFormFromElement")
  private productFormFromElement: RecursiveFormComponent;

  private _product: Product;

  private products: Product[];
  public user: CurrentUser;

  public searchProductControl = new FormControl();

  public productExistsOnPlant: boolean;
  public productAlreadyRequestedOnPlant: boolean;

  public productObject = new ProductForm();
  public productFormDisabled = false;
  private productFormValidated = false;
  public formOutput$ = new Subject<ProductForm>();
  public productFormValidators = this.validators.productFormValidators(
    this.formOutput$,
    this.productService.products$
  );
  public productAutoCompleteList$ =
    this.productService.productAutoCompleteList$;
  public translatedKeys$ = this.productRequestService.translatedKeys$;
  public productOptionSelection$ = this.productService.productOptionSelections(
    this.languageClassPipe
  );

  // UI
  public showProduct = false;

  private searchProductValue$ = this.searchProductControl.valueChanges.pipe(
    debounceTime(200),
    startWith("")
  );

  private productSearchList$ = this.productService.products$.pipe(
    map((x) =>
      x.reduce((acc, y) => {
        if (y) {
          return [
            ...new Set([...acc, ...Object.values(y.name).filter((z) => z)]),
          ];
        }
        return acc;
      }, [] as Product[])
    )
  );

  public productSearchListFiltered$ = combineLatest(
    this.searchProductValue$,
    this.productSearchList$
  ).pipe(
    map(([value, productSearchList]) => {
      return productSearchList.filter((product) =>
        product.toLowerCase().includes(value.toLowerCase())
      );
    })
  );

  public supplierSearchListFiltered$ = combineLatest(
    this.searchProductValue$,
    this.productService.products$
  ).pipe(
    map(([value, products]) => {
      if (value) {
        const supplierSearchList = products
          .filter((x) => Object.values(x.name).some((y) => y === value))
          .map((x) => x.supplier);
        if (supplierSearchList.length === 0) {
          this.selectedSupplier();
        }
        this.productFormDisabled = supplierSearchList.length > 0;
        return supplierSearchList;
      } else {
        this.selectedSupplier();
        return [];
      }
    })
  );

  public openNewApp(facility:string) {
    window.open(`${newAppUrl}?facility=${facility}`, "_blank");
  }

  public resetSearch() {
    this.searchProductControl.setValue("");
    this.optionSupplier.value = "";
  }

  public createNewProduct() {
    this.productObject = new ProductForm();
    this.resetSearch();
    this.productFormDisabled = false;
    this.showProductForm();
  }

  public selectedSupplier(productName?: string, supplier?: string) {
    const product = this.products.find(
      (x) =>
        Object.values(x.name).some((y) => y === productName) &&
        x.supplier === supplier
    );

    if (product) {
      this._product = cloneDeep(product);
      const testIfAlreadyOnCurrentPlant = product.metaData.plants.some(
        (x) => x.metaDataPlant.plant === this.user.plant
      );
      const testIfAlreadyRequestedForThisPlant = product.metaData.requests.some(
        (x) =>
          x.requestForm.plantInfo.metaDataPlant.plant === this.user.plant &&
          (x.status === "Pending" || x.status === "Requested")
      );

      this.newProduct.emit(false);

      if (!testIfAlreadyOnCurrentPlant) {
        this.product.emit(product);
        this.productExistsOnPlant = false;
        this.productObject = new ProductForm(product);
      } else {
        this.productExistsOnPlant = true;
        this.hideProductForm();
      }
      if (testIfAlreadyRequestedForThisPlant) {
        this.productAlreadyRequestedOnPlant = true;
        this.productExistsOnPlant = true;
        this.hideProductForm();
      }
    } else {
      this._product = new Product(this.api);
      this.productExistsOnPlant = false;
      this.product.emit(this._product);
      this.productObject = new ProductForm();
      this.newProduct.emit(true);
    }
  }

  public validateProductForm(status: boolean) {
    if (this.productExistsOnPlant) {
      this.productFormValidated = false;
    } else {
      this.productFormValidated = status;
    }

    this.validateAll();
  }

  public setProductForm(form: ProductForm) {
    this._product.updateProductWithProductForm(form);
    this.product.emit(this._product);
  }

  private validateAll() {
    const isValid =
      (this.productFormValidated &&
        !this.productExistsOnPlant &&
        !this.productFormDisabled) ||
      (this.productFormDisabled &&
        this.optionSupplier.value &&
        !this.productExistsOnPlant);

    this.validated.emit(isValid);
  }

  public validateForm() {
    if (!this.productFormFromElement) {
      return;
    }
    this.productFormFromElement.validateForm();
  }

  // UI
  public showProductForm() {
    this.showProduct = false;
    this.changeDetector.detectChanges();
    this.showProduct = true;
    this.changeDetector.detectChanges();
  }
  public hideProductForm() {
    this.showProduct = false;
    this.changeDetector.detectChanges();
  }

  constructor(
    private productService: ProductService,
    private changeDetector: ChangeDetectorRef,
    private userService: UsersService,
    private api: ApiService,
    private validators: CustomValidators,
    private productRequestService: ProductRequestService,
    private languageClassPipe: LanguageClassPipe
  ) {}

  ngOnInit() {
    this._subs.add(
      this.productService.products$.subscribe(
        (products) => (this.products = products)
      )
    );
    this._subs.add(
      this.userService.currentUser$.subscribe((user) => (this.user = user))
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
