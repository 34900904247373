import { Component, Input, OnInit } from '@angular/core';
import { SevesoDbServer } from '../../../../server/src/routes/product/types';
import { SevesoEditComponent } from './seveso-edit/seveso-edit.component';
import { NewEntry } from '../../_shared/_components/info-table/info-table.component';

@Component({
  selector: 'app-seveso-list',
  templateUrl: './seveso-list.component.html',
  styleUrls: ['./seveso-list.component.css']
})
export class SevesoListComponent implements OnInit {

  // Variables
  public newEntry: NewEntry = { class: SevesoDbServer, dependency: undefined };
  @Input() public sevesoList: SevesoDbServer[];

  public editComponent = SevesoEditComponent;

  constructor() { }

  ngOnInit() {
  }

}
