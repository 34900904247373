// tslint:disable: no-use-before-declare
import { HnumberServer, PnumberServer } from '../../../server/src/routes/product/types';
import { ApiService } from '../services/api.service';
import { PbmOption, PbmOptions, PbmOptionsDb } from './pbm.class';
import { Product } from './product.class';

export class HAndPSentences {

  hSentences: Hnumber[] = [];
  pSentences: Pnumber[] = [];

  constructor(product?: Product, hNumbers?: Hnumber[], pNumbers?: Pnumber[], input?: HAndPSentences) {
    if (hNumbers && product) { this.hSentences = hNumbers.filter(x => product.hNr.some(y => x.code === y)); }
    if (pNumbers && product) { this.pSentences = pNumbers.filter(x => product.pNr.some(y => x.code === y)); }

    if (input) {
      Object.keys(input).forEach(key => {
        if (this[key] !== undefined) { this[key] = input[key]; }
      });
    }
  }

}

class H {
  code = '';
  dutch = '';
  english = '';
  french = '';
  german = '';
  indeling = '';
  number = '';
  value = 0;

  constructor(input?: H) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }
}

class P {
  code = '';
  dutch = '';
  english = '';
  french = '';
  german = '';
  indeling = '';
  number = '';

  constructor(input?: P) {
    if (input) {
      Object.keys(input).forEach(key => {
        switch (key) {
          default: if (this[key] !== undefined) { this[key] = input[key]; }
        }
      });
    }
  }
}


export class HnumberDb extends H implements HnumberServer {

  pbm = new PbmOptionsDb();

  constructor(input: HnumberDb | undefined) {
    super(input);
    if (input) {
      this.pbm = new PbmOptionsDb(input.pbm);
    }
  }

}

export class Hnumber extends HnumberDb {

  static api: ApiService;

  pbmOptions: PbmOptions;

  public addHnrDb() {
    if (!this.code) { throw new Error('No code specified. This is the dbRef'); }
    return Hnumber.api.addHnr(this);
  }

  public saveHnrDb() {
    if (!this.code) { throw new Error('No code specified. This is the dbRef'); }
    return Hnumber.api.updateHnr(this);
  }

  public deleteHnrDb() {
    if (!this.code) { throw new Error('No code present'); }
    return Hnumber.api.deleteHnr(this.code);
  }

  constructor(api: ApiService, input?: HnumberDb, pbmOptions?: PbmOption[]) {
    super(input);

    if (input && pbmOptions) {
      this.pbmOptions = new PbmOptions(api, this.pbm, pbmOptions);
    }

    Hnumber.api = api;
  }

}

export class PnumberDb extends P implements PnumberServer {

  constructor(input: PnumberDb | undefined) {
    super(input);
  }

}

export class Pnumber extends PnumberDb {

  static api: ApiService;

  public addPnrDb() {
    if (!this.code) { throw new Error('No code specified. This is the dbRef'); }
    return Hnumber.api.addPnr(this);
  }

  public savePnrDb() {
    if (!this.code) { throw new Error('No code speciefied. This is the dbRef'); }
    return Pnumber.api.updatePnr(this);
  }

  public deletePnrDb() {
    if (!this.code) { throw new Error('No code present'); }
    return Pnumber.api.deletePnr(this.code);
  }

  constructor(api: ApiService, input?: H | P) {
    super(input);

    Pnumber.api = api;
  }

}
