import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../classes/product.class';

@Component({
  selector: 'app-plant-info',
  templateUrl: './plant-info.component.html',
  styleUrls: ['./plant-info.component.css'],
})
export class PlantInfoComponent implements OnInit {

  @Input() public product: Product;

  constructor() { }

  ngOnInit() { }

}
