import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LanguageClass } from '../../classes/language.class';
import { Product } from '../../classes/product.class';
import { ConfigService } from '../../config.service';
import { DatabaseService } from '../../services/database.service';
import { ProductTableComponent } from '../_components/product-table/product-table.component';
import { SubstanceRegisterExportService } from './substance-register-export.service';
import { Location } from '../../classes/location.class';
import _ from 'lodash';

interface SheetResponse {
  rows: number;
  export: string;
  url: string;
}

export type ProductExportType = 'register' | 'default' | 'product_log';

@Injectable({
  providedIn: 'root',
})
export class ExportTableService {
  async exportTableToSheet(
    currentUserEmail: string,
    sheetName: string,
    data: any[][],
    headers: string[],
    exportType: ProductExportType
  ) {
    const doc = await this.database
      .addExportToDatabase(data, headers)
      .catch(() => {
        throw new Error('Setting export in database failed');
      });
    if (!doc || !doc.docRef || !doc.collectionRef) {
      return;
    }

    const response = await this.http
      .get<SheetResponse>(ConfigService.spreadsheetUrl, {
        params: {
          userto: currentUserEmail,
          docRef: doc.docRef,
          sheetName: sheetName,
          callback: '',
          exportType,
        },
      })
      .toPromise();

    await this.database.deleteExportToDatabase(doc.docRef);
    return response;
  }

  getSheetNameType(
    exportType: ProductExportType,
    d: Date,
    plant: Location,
    productName?: string
  ) {
    switch (exportType) {
      case 'default':
        return `Export SDS-App - ${d.getDate()}/${d.getMonth() + 1
          }/${d.getFullYear()}`;
      case 'register':
        return `Wettelijk Stoffenregister - ${plant.lokatie} - ${d.getDate()}/${d.getMonth() + 1
          }/${d.getFullYear()}`;
      case 'product_log':
        return `Change log ${productName} - ${d.getDate()}/${d.getMonth() + 1
          }/${d.getFullYear()}`;
      default:
        return `Export SDS-App - ${d.getDate()}/${d.getMonth() + 1
          }/${d.getFullYear()}`;
    }
  }

  async getHeadersForType(
    exportType: ProductExportType,
    table: ProductTableComponent
  ) {
    let keys;
    switch (exportType) {
      case 'register':
        keys = await this.registerExport.registerKeys.toPromise();
        break;
      case 'product_log':
        keys = table.displayedProductKeys;
        break;
      case 'default':
      default:
        keys = table.displayedProductKeys;
        break;
    }

    // correct columns for pbmGrouped
    keys = keys.filter((k) => k.key !== 'pbmGrouped');

    return await Promise.all(
      keys.map(
        async (header) =>
          await table.languageClassPipe.setLanguage(header, table.language)
      )
    );
  }

  async getSheetDataForType(
    exportType: ProductExportType,
    table: ProductTableComponent,
    plant: Location,
    productDbRef?: string
  ) {
    switch (exportType) {
      case 'register':
        return await this.registerExport.formatRegisterSheetData(table, plant);
      case 'product_log':
        return await this.getProductLogData(productDbRef || '');
      case 'default':
      default:
        return await this.formatDefaultSheetData(table);
    }
  }

  private async getProductLogData(productRef: string) {
    const usableData: any[][] = [];

    if (!productRef) {
      return usableData;
    }

    const data = await this.database.getRelevantLogData(productRef);
    data.forEach((d) => {
      try {
        if (d.length) {
          const action = d[0]._entry.action;
          const general = d[0]._entry.changeData;
          const before = d[0].before;
          const after = d[0].after;
          let plantName = '';
          let lastChange = '';
          if (general) {
            plantName = general.plantName;
            lastChange = new Date(general.lastChange.seconds * 1000).toLocaleDateString('nl-NL')
              + ' ' + new Date(general.lastChange.seconds * 1000).toLocaleTimeString('nl-NL');
          }
          if (action === 'updated') {
            for (const changedKey in before) {
              if (before[changedKey]) {
                usableData.push([
                  action,
                  changedKey,
                  this.visualiseJsonInCell(before[changedKey]),
                  this.visualiseJsonInCell(after[changedKey]),
                  plantName,
                  general.username,
                  lastChange,
                  general.lastChange.seconds * 1000 // for sorting only, remove after sort (Apps Script does that)
                ]);
              }
            }
          } else if (action === 'added') {
            // TO DO?
          }
        }
      } catch (error) { }
    });
    // usableData = usableData.sort((a, b) => new Date(a[a.length - 1]).getTime() - new Date(b[a.length - 1]).getTime());
    // usableData.map(a => a.pop()); // remove ts in last column
    return usableData;
  }

  visualiseJsonInCell(json: any) {
    if (!json || typeof json !== 'object') { return json; }
    let result = '';
    for (const key in json) {
      if (json[key]) {

        if (typeof json[key] !== 'string') {
          json[key] = JSON.stringify(json[key]);
        }

        result += key + ': ' + json[key] + '\n';
      }
    }

    return result.replace(/\n$/, ''); // replace last newline
  }

  private async formatDefaultSheetData(table: ProductTableComponent) {
    return await table.dataSource.data.reduce(async (acc, x: Product) => {
      const accumulator = await acc;

      const newData = await Promise.all(
        table.displayedColumns
          .filter((c) => c !== 'pbmGrouped')
          .map(async (y) => {
            if (x[y] instanceof LanguageClass) {
              return await table.languageClassPipe.setLanguage(
                x[y],
                table.language
              );
            }
            if (x[y] instanceof Date) {
              const date = x[y] as Date;
              if (isNaN(date.getTime())) {
                return '';
              }
              return (x[y] as Date).toUTCString();
            }
            if (Array.isArray(x[y])) {
              return x[y].join('; ');
            }
            if (x[y] instanceof Date) {
              return x[y];
            }
            if (!x[y]) {
              return null;
            }
            return x[y];
          })
      );

      return Promise.resolve([...accumulator, newData]);
    }, Promise.resolve([]));
  }

  constructor(
    private http: HttpClient,
    private database: DatabaseService,
    private registerExport: SubstanceRegisterExportService
  ) { }
}
