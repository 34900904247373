import { Pipe, PipeTransform } from '@angular/core';
import { PbmOptionsDb } from '../../../classes/pbm.class';

@Pipe({
  name: 'isPbm'
})
export class IsPbmPipe implements PipeTransform {

  transform(value: string): string {
    const keys = Object.keys(new PbmOptionsDb);

    if (keys.some(k => k === value)) {
      return value;
    } else {
      return '';
    }
  }
}
