import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Note } from '../../classes/product-plant.class';
import { Product } from '../../classes/product.class';
import { CurrentUser } from '../../classes/user.class';
import { UsersService } from '../../services/users.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-product-notes-edit',
  templateUrl: './product-notes-edit.component.html',
  styleUrls: ['./product-notes-edit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductNotesEditComponent implements OnInit, OnChanges, OnDestroy {

  private _subs = new Subscription();

  @Input() product: Product;

  private user: CurrentUser;
  public notes: Note[];

  public asyncOperation = false;

  public addNote() {
    if (!this.user) { throw new Error('User not present'); }
    this.notes.push(new Note(undefined, this.user));
  }

  public removeNote(i: number) {
    this.notes.splice(i, 1);
  }

  public async saveNotes() {
    this.asyncOperation = true;
    await this.product.saveNotes(this.notes, this.user.plant).catch(() => void 0);
    this.asyncOperation = false;
    this.changeDetector.detectChanges();
  }

  constructor(
    private changeDetector: ChangeDetectorRef,
    private userService: UsersService
  ) { }

  ngOnInit() {
    this._subs.add(
      this.userService.currentUser$.subscribe(user => {
        this.user = user;
        if (this.product && !this.notes) {
          const clonedProduct = cloneDeep(this.product);
          this.notes = clonedProduct.getNotes(this.user.plant);
        }
      }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['product'] && this.product && this.user) {
      const clonedProduct = cloneDeep(this.product);
      this.notes = clonedProduct.getNotes(this.user.plant);
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

}
