import { Pipe, PipeTransform } from '@angular/core';
import { combineLatest } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { ProductService } from '../../services/product.service';
import { UsersService } from '../../services/users.service';
import { LanguageClassPipe } from '../../_shared/_pipes/language-class.pipe';

@Pipe({
  name: 'pbmCategoryLanguage'
})
export class PbmCategoryLanguagePipe implements PipeTransform {

  constructor(
    private userService: UsersService,
    private productService: ProductService,
    private languageClassPipe: LanguageClassPipe
  ) { }

  transform(value: string) {

    return combineLatest(
      this.userService.currentUser$,
      this.productService.pbmOptions$,
      this.productService.pbmKeys$
    ).pipe(
      flatMap(([user, pbmOptions, keys]) => {

        const findOption = pbmOptions.find(x => x.category === value);
        if (!findOption) { return value; }

        const findKey = keys.find(key => findOption.category === key.key);
        if (!findKey) { return value; }

        return this.languageClassPipe.setLanguage(findKey, user.language);
      })
    );

  }

}
