// tslint:disable: no-use-before-declare
import {
  MetaDataProductServer,
  ProductDbServer,
} from "../../../server/src/routes/product/types";
import { ApiService } from "../services/api.service";
import { ExpandedDetail } from "../_shared/_components/product-table/product-table.component";
import { Cmr } from "./cmr.class";
import { HAndPSentences } from "./h-and-p.class";
import { LanguageClass } from "./language.class";
import { PbmOptions, PbmOptionsDb } from "./pbm.class";
import { Note, ProductPlant } from "./product-plant.class";
import {
  ProductRequest,
  RequestForm,
  RequestFormBasicInfo,
} from "./product-request.class";
import { ProductForm } from "./product-sections.class";
import { UnSubstanceCategory } from "./un.class";
import { UserDb } from "./user.class";
import { Zzs } from "./zzs.class";
import { Vlarem } from "./vlarem.class";
import { ProductComponentDb } from "./product-component.class";
import { Pzzs } from "./pzzs.class";

export class MetaDataProduct implements MetaDataProductServer {
  sdsLinks = new LanguageClass();
  tdsLinks = new LanguageClass();
  foodcrtfLinks = new LanguageClass();
  plants: ProductPlant[] = [];
  requests: ProductRequest[] = [];
  dbRef = "";
  image = "";
  archived = false;
  dateArchived: string = "";
  lastChange: Date | string = "";
  lastChangedBy: UserDb | null = null;
  approved = false;

  constructor(input?: MetaDataProduct) {
    if (input) {
      Object.keys(input).forEach((key) => {
        switch (key) {
          case "sdsLinks":
            this[key] = new LanguageClass(input[key]);
            break;
          case "tdsLinks":
            this[key] = new LanguageClass(input[key]);
            break;
          case "foodcrtfLinks":
            this[key] = new LanguageClass(input[key]);
            break;
          case "plants":
            this[key] = input[key].map((x) => new ProductPlant(x));
            break;
          case "requests":
            this[key] = input[key].map((x) => new ProductRequest(x));
            break;
          case "lastChange":
            this[key] = new Date(input[key] || "");
            break;
          default:
            if (this[key] !== undefined) {
              this[key] = input[key];
            }
        }
      });
    }
  }
}

export type physicalState =
  | "solid"
  | "liquid"
  | "gas"
  | "aerosol"
  | "gas liquified under pressure"
  | "pasta"
  | "fine powder"
  | "granulate"
  | "powder"
  | "";

export class ProductDb implements ProductDbServer {
  adr: string[] = [];
  artikelnummerLev = "";
  beschrijving = new LanguageClass();
  casNummer = "";
  classification: string[] = [];
  classificationCode = "";
  colourProduct = new LanguageClass();
  controlledDate = new Date("");
  datum = new Date("");
  dichtheidGas = "";
  dichtheidWater = 0;
  egNummer = "";
  eNummer = new LanguageClass();
  explosiveLimits = "";
  eyeContact = new LanguageClass();
  flashpoint = 0;
  formule = "";
  ghs: string[] = [];
  globalWarmingEffects = new LanguageClass();
  grenswaarde15minuten = 0;
  grenswaarde15minutenPpm = 0;
  grenswaarde8uur = 0;
  grenswaarde8uurPpm = 0;
  hazard: string[] = [];
  hNr: string[] = [];
  indexNummer = "";
  ingestion = new LanguageClass();
  inhalation = new LanguageClass();
  lc50 = "";
  lc50mgl1h = "";
  lc50mgl4h = "";
  lc50ppm1h = "";
  liquid_temperatureBoilingPoint = 0;
  meltingPoint = 0;
  metaData = new MetaDataProduct();
  molecularWeight = "";
  name = new LanguageClass();
  noodnummer: string[] = [];
  odourProduct = new LanguageClass();
  opmerkingenSeveso = "";
  foodCompliant = false;
  otherDamagingEffects = new LanguageClass();
  ozonLayerEffects = new LanguageClass();
  packingGroup = "";
  pbm = new PbmOptionsDb();
  pH = 0;
  physicalState: physicalState = "";
  pNr: string[] = [];
  productComponents: ProductComponentDb[] = [];
  properShippingName = new LanguageClass();
  registrationNr = "";
  signalword: "warning" | "danger" | "" = "";
  skinContact = new LanguageClass();
  suiteableExtinguishingMedia = new LanguageClass();
  supplier = "";
  ufiCode = "";
  un = "";
  unsuiteableExtinguishingMedia = new LanguageClass();
  vapourDensity = 0;
  versie = "";
  vibNr = "";
  vlarem = new Vlarem();
  vorige = new Date("");
  vosPercentage = 0;
  vosRegistration = "";
  waterSolubility = 0;
  wgkClass = 0;
  dateArchived: Date | string = "";

  constructor(input?: ProductDb) {
    if (input) {
      Object.keys(input).forEach((key) => {
        switch (key) {
          case "name":
            this[key] = new LanguageClass(input[key]);
            break;
          case "beschrijving":
            this[key] = new LanguageClass(input[key]);
            break;
          case "datum":
            this[key] = new Date(input[key]);
            break;
          case "controlledDate":
            this[key] = new Date(input[key]);
            break;
          case "vorige":
            this[key] = new Date(input[key] || "");
            break;
          case "properShippingName":
            this[key] = new LanguageClass(input[key]);
            break;
          case "eNummer":
            this[key] = new LanguageClass(input[key]);
            break;
          case "eyeContact":
            this[key] = new LanguageClass(input[key]);
            break;
          case "inhalation":
            this[key] = new LanguageClass(input[key]);
            break;
          case "ingestion":
            this[key] = new LanguageClass(input[key]);
            break;
          case "colourProduct":
            this[key] = new LanguageClass(input[key]);
            break;
          case "odourProduct":
            this[key] = new LanguageClass(input[key]);
            break;
          case "skinContact":
            this[key] = new LanguageClass(input[key]);
            break;
          case "suiteableExtinguishingMedia":
            this[key] = new LanguageClass(input[key]);
            break;
          case "unsuiteableExtinguishingMedia":
            this[key] = new LanguageClass(input[key]);
            break;
          case "otherDamagingEffects":
            this[key] = new LanguageClass(input[key]);
            break;
          case "ozonLayerEffects":
            this[key] = new LanguageClass(input[key]);
            break;
          case "globalWarmingEffects":
            this[key] = new LanguageClass(input[key]);
            break;
          case "metaData":
            this[key] = new MetaDataProduct(input[key]);
            break;
          case "pbm":
            this[key] = new PbmOptionsDb(input[key]);
            break;
          case "vlarem":
            this[key] = new Vlarem(input[key]);
            break;
          case "indexNummer":
            this[key] = input[key].toString();
            break;
          case "productComponents": {
            this[key] = input[key].map((c) => new ProductComponentDb(c));
            break;
          }
          default:
            if (this[key] !== undefined) {
              this[key] = input[key];
            }
        }
      });
    }
  }
}

export class Product extends ProductDb {
  static api: ApiService;

  cmr: Cmr;
  hAndPSentences: HAndPSentences;
  // classification: ClassificationC;
  // We only show the key that gets translated alike
  // the other Product keys(e.g.{ emergencyNumberStdText: 'translatedValue'}).
  emergencyNumberStdText: string;
  expandedDetail?: ExpandedDetail;
  pbmOptions: PbmOptions;
  pbmGrouped = false;
  stofcategorieUn: UnSubstanceCategory;
  zzs: Zzs | undefined;
  pzzs: Pzzs | undefined;

  public setProduct(input: ProductDb) {
    Object.keys(input).forEach((x) => {
      if (this[x] !== undefined) {
        this[x] = input[x];
      } else {
        return;
      }
    });
    return this;
  }

  public setPbm(pbmForm: PbmOptionsDb) {
    this.pbm = new PbmOptionsDb(pbmForm);
    return this;
  }

  public setPbmPlant(pbmForm: PbmOptionsDb, plantRef: string) {
    const plantIndex = this.metaData.plants.findIndex(
      (plant) => plant.metaDataPlant.plant === plantRef
    );
    if (plantIndex === -1) {
      throw new Error("Plant not found");
    }

    this.metaData.plants[plantIndex].pbm = new PbmOptionsDb(pbmForm);
    return this;
  }

  public async archiveProduct() {
    return Product.api.archiveProduct(this.metaData.dbRef);
  }

  public async unArchiveProduct() {
    return Product.api.unArchiveProduct(this.metaData.dbRef);
  }

  public async archivePlant(plantIndex: number) {
    if (plantIndex === -1) {
      throw new Error("Plant not found");
    }
    await Product.api.archivePlant(this.metaData.dbRef, plantIndex);
    return this;
  }

  public async unArchivePlant(plantIndex: number) {
    if (plantIndex === -1) {
      throw new Error("Plant not found");
    }
    await Product.api.unArchivePlant(this.metaData.dbRef, plantIndex);
    return this;
  }

  public getNotes(plant: string) {
    const findPlant = this.metaData.plants.find(
      (x) => x.metaDataPlant.plant === plant
    );
    if (!findPlant) {
      throw new Error("Plant not found");
    }

    return findPlant.metaDataPlant.notes;
  }

  public async saveNotes(notes: Note[], plant: string) {
    const findPlant = this.metaData.plants.find(
      (x) => x.metaDataPlant.plant === plant
    );
    if (!findPlant) {
      throw new Error("Plant not found");
    }

    findPlant.metaDataPlant.notes = notes;
    await this.saveProduct();
    return this;
  }

  public async saveSDS(
    sds: { file: File; language: keyof LanguageClass }[],
    type: string
  ) {
    // Create multipart form data
    let fileCount = 0;
    const formData = new FormData();
    formData.append("product", JSON.stringify(new ProductDb(this)));

    sds.forEach((file) => {
      const name = file.language + ".pdf";
      formData.append("sds", file.file, name);
      fileCount++;
    });

    const result = await Product.api
      .updateProduct(formData, this.metaData.dbRef, fileCount, type)
      .catch();
    if (!result) {
      return;
    }

    return this;
  }

  public async saveProduct(
    sds?: { file: File; language: keyof LanguageClass }[],
    productImage?: File | null
  ) {
    // Create multipart form data
    let fileCount = 0;
    const formData = new FormData();
    formData.append("product", JSON.stringify(new ProductDb(this)));

    if (sds) {
      sds.forEach((file) => {
        const name = file.language + ".pdf";
        formData.append("sds", file.file, name);
        fileCount++;
      });
    }
    if (productImage) {
      formData.append("image", productImage, productImage.name);
      fileCount++;
    }

    const result = await Product.api
      .updateProduct(formData, this.metaData.dbRef, fileCount, "")
      .catch();
    if (!result) {
      return;
    }

    return this;
  }

  public getActiveRequests() {
    const activeRequests = this.metaData.requests.reduce((acc, x) => {
      if (x.status === "Requested" || x.status === "Pending") {
        return [...acc, { request: x, productRef: this }];
      }
      return acc;
    }, []);

    return activeRequests;
  }

  public getProductWithPlantData(user: UserDb) {
    const propertiesThis = {} as Product;
    Object.keys(this).forEach((x) => (propertiesThis[x] = this[x]));

    const findPlant = this.metaData.plants.find(
      (y) => y.metaDataPlant.plant === user.plant
    );

    if (findPlant) {
      const productPlant = { ...propertiesThis, ...findPlant };
      return productPlant;
    } else {
      return;
    }
  }

  public updateProductWithProductForm(productForm: ProductForm) {
    Object.values(productForm).forEach((value) => {
      Object.keys(value).forEach((key) => {
        if (this[key] !== undefined) {
          this[key] = value[key];
        }
      });
    });

    return this;
  }

  // Request
  public async addRequest(
    sds: { file: File | Blob; language: keyof LanguageClass }[],
    productImage: File | undefined,
    basicInfo: RequestFormBasicInfo,
    plantInfo: ProductPlant,
    user: UserDb,
    newProduct: boolean
  ) {
    // Add the request to product
    const requestForm = new RequestForm({
      sdsFiles: new LanguageClass(),
      tdsFiles: new LanguageClass(),
      foodcrtfFiles: new LanguageClass(),
      image: "",
      basicInfo,
      user,
      plantInfo,
      newProduct,
    });

    const productRequest = new ProductRequest({ requestForm });

    // Create multipart form data
    const formData = new FormData();

    if (newProduct) {
      formData.append("product", JSON.stringify(this));
    }
    formData.append("request", JSON.stringify(productRequest));

    if (sds) {
      sds.forEach((file) => {
        const name = file.language + ".pdf";
        formData.append("sds", file.file, name);
      });
    }
    if (productImage) {
      formData.append("image", productImage, productImage.name);
    }

    return Product.api
      .productRequest(
        formData,
        this.metaData.dbRef,
        productRequest.requestForm.newProduct
      )
      .then(() => this)
      .catch(() => void 0);
  }
  public async removeRequest(requestIndex: number, plant: string) {
    return Product.api
      .denyRequest(this.metaData.dbRef, requestIndex, plant)
      .then(() => this)
      .catch(() => void 0);
  }

  // Approval
  public async approveProduct(
    sds: { file: File | Blob; language: keyof LanguageClass }[],
    productImage: File | undefined,
    requestIndex: number,
    plant: string
  ) {
    // Create multipart form data
    const formData = new FormData();
    formData.append("product", JSON.stringify(new ProductDb(this)));

    if (sds) {
      sds.forEach((file) => {
        const name = file.language + ".pdf";
        formData.append("sds", file.file, name);
      });
    }
    if (productImage) {
      formData.append("image", productImage, productImage.name);
    }

    await Product.api
      .productApproval(formData, this.metaData.dbRef, requestIndex, plant)
      .then(() => this)
      .catch(() => void 0);

    return this;
  }
  public async approvePlant(
    requestIndex: number,
    plant: string,
    request: ProductRequest
  ) {
    return Product.api
      .plantApproval(this.metaData.dbRef, requestIndex, plant, request)
      .then(() => this)
      .catch(() => void 0);
  }
  public async approveRequest(requestIndex: number) {
    await Product.api
      .approveRequest(this.metaData.dbRef, requestIndex)
      .then(() => this)
      .catch(() => void 0);

    return this;
  }

  constructor(api: ApiService, input?: ProductDb | Product) {
    super(input);

    if (input instanceof Product) {
      Object.keys(input).forEach((key) => {
        switch (key) {
          case "stofcategorieUn":
            this[key] = new UnSubstanceCategory(Product.api, input[key]);
            break;
          case "cmr":
            this[key] = new Cmr(undefined, undefined, input[key]);
            break;
          case "hAndPSentences":
            this[key] = new HAndPSentences(
              undefined,
              undefined,
              undefined,
              input[key]
            );
            break;
          case "pbmOptions":
            this[key] = new PbmOptions(
              api,
              undefined,
              undefined,
              undefined,
              input[key]
            );
            break;
          case "zzs":
            this[key] = new Zzs(Product.api, input[key]);
            break;
          case "pzzs":
            this[key] = new Pzzs(Product.api, input[key]);
            break;
          default:
            if (this[key] !== undefined) {
              this[key] = input[key];
            }
        }
      });
    }

    Product.api = api;
  }
}
