import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { flatMap, map, take } from 'rxjs/operators';
import { Product } from '../classes/product.class';
import { ProductForm } from '../classes/product-sections.class';

export function productNameValidator(products$: Observable<Product[]>, formOutput$: Observable<ProductForm>, errorMessage: string, accepted?: string[]): AsyncValidatorFn {
  return function validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return products$.pipe(
      take(1),
      flatMap(products => formOutput$.pipe(map(formOutput => ({ products, formOutput })))),
      take(1),
      map(({ products, formOutput }) => {

        if (
          accepted &&
          Object.values(formOutput.section1.name).some(x => accepted.includes(x)) &&
          accepted.includes(formOutput.section1.supplier)
        ) { return null; }

        const filterProducts = products.find(product => {
          return Object.values(product.name)
            .some(name => {
              if (!name || !control.value) { return false; }
              return name.toLowerCase().trim() === control.value.toLowerCase().trim();
            }) && product.supplier.toLowerCase().trim() === formOutput.section1.supplier.toLowerCase().trim();
        });

        if (filterProducts) { return { [errorMessage]: { value: control.value } }; }
        return null;
      })
    );
  };
}

export function supplierValidator(products$: Observable<Product[]>, formOutput$: Observable<ProductForm>, errorMessage: string, accepted?: string[]): AsyncValidatorFn {
  return function validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return products$.pipe(
      take(1),
      flatMap((products) => formOutput$.pipe(map(formOutput => ({ products, formOutput })))),
      take(1),
      map(({ products, formOutput }) => {

        if (!formOutput || !formOutput.section1.name) { return null; }
        if (
          accepted &&
          Object.values(formOutput.section1.name).some(x => accepted.includes(x)) &&
          accepted.includes(formOutput.section1.supplier)
        ) { return null; }

        const namesFormOutput = Object.values(formOutput.section1.name);
        const findProduct = products.find(product => namesFormOutput.map(x => Object.values(product.name).some(y => {
          if (!x || !y) { return false; }
          return y.toLowerCase().trim() === x.toLowerCase().trim();
        })).some(x => x === true) && product.supplier.toLowerCase().trim() === control.value.toLowerCase().trim());

        if (findProduct) { return { [errorMessage]: { value: control.value } }; }
        return null;
      })
    );
  };
}

export function dateValidator(errorMessage: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const test = isNaN(control.value ? control.value.getTime() : '');
    return test ? { [errorMessage]: { value: control.value } } : null;
  };
}

export function productNameLanguageValidator(formOutput$: Observable<ProductForm>, errorMessage: string): AsyncValidatorFn {
  return function validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return formOutput$.pipe(
      take(1),
      map(formOutput => {

        if (!formOutput || !formOutput.section1 || !formOutput.section1.name) { return null; }
        const namesFormOutput = Object.values(formOutput.section1.name);

        const allNamesAreEmpty = namesFormOutput.every((x: string) => !x || x.trim() === '');

        if (allNamesAreEmpty) { return { [errorMessage]: { value: control.value } }; }
        return null;
      })
    );
  };
}
