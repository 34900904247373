// tslint:disable: no-use-before-declare
import { PbmOptionDbServer } from '../../../server/src/routes/pbm/types';
import { PbmOptionsDbServer } from '../../../server/src/routes/product/types';
import { ApiService } from '../services/api.service';
import { Hnumber } from './h-and-p.class';
import { LanguageClass } from './language.class';


export class PbmOptionDb implements PbmOptionDbServer {
  id = "";
  translations = new LanguageClass();
  category = "";
  icon = "";
  order = -1;
  isSuggestion = false;

  pbmOrder = [
    "M014",
    "M003",
    "M013",
    "xxxx",
    "M004",
    "M016",
    "M017",
    "M047",
    "M048",
    "M015",
    "M010",
    "M053",
    "M020",
    "M009",
    "M008",
    "E011",
    "E012",
    "M018",
    "M046",
  ];

  constructor(input?: PbmOptionDb) {
    if (input) {
      Object.keys(input).forEach((key) => {
        switch (key) {
          case "pbmOrder":
            this[key] = this.pbmOrder;
            break;
          case "translations":
            this[key] = new LanguageClass(input[key]);
            break;
          default:
            if (this[key] !== undefined) {
              this[key] = input[key];
            }
        }
      });

      this.order = this.pbmOrder.indexOf(this.icon);
    }
  }
}

export class PbmOption extends PbmOptionDb {
  // we need the originalOptionKey to be able to map the key back to where this option is attached to.
  originalKey: string;
  static savePbmOptions(pbmOptions: PbmOption[]) {
    return PbmOptions.api.updatePbmOptions(pbmOptions);
  }

  async deletePbmOption() {
    if (!this.id) { return; }
    return PbmOptions.api.deletePbmOption(this.id);
  }

  constructor(input?: PbmOptionDb | undefined, category?: string) {
    super(input);
    if (!category && !input) { throw new Error('No input and no category in pbm option'); }
    if (category) { this.category = category; }
  }
}

type PbmSuggestion = {
  pbmOption: PbmOption;
  hNumber: Hnumber;
}[];

export class PbmOptionsDb implements PbmOptionsDbServer {

  gelaatsBescherming = '';
  nooddoucheOogspoeling = '';
  hoofdBescherming = '';
  gasDetectors = '';
  voetBescherming = '';
  valbeveiliging = '';
  ademhalingsBescherming2 = '';
  handBescherming = '';
  kleding = '';
  gehoorBescherming = '';
  ademhalingsBescherming0 = '';
  ademhalingsBescherming1 = '';

  constructor(input?: PbmOptionsDb) {
    if (input) {
      Object.keys(input).forEach(key => {
        if (this[key] !== undefined) { this[key] = input[key]; }
      });
    }
  }

}


/**
 * Class contains Pbm categories with the product references.
 * May contain suggestions based on H-numbers
 */
export class PbmOptions {

  static api: ApiService;

  gelaatsBescherming: PbmOption | null = null;
  nooddoucheOogspoeling: PbmOption | null = null;
  hoofdBescherming: PbmOption | null = null;
  gasDetectors: PbmOption | null = null;
  voetBescherming: PbmOption | null = null;
  valbeveiliging: PbmOption | null = null;
  handBescherming: PbmOption | null = null;
  kleding: PbmOption | null = null;
  gehoorBescherming: PbmOption | null = null;
  ademhalingsBescherming0: PbmOption | null = null;
  ademhalingsBescherming1: PbmOption | null = null;
  ademhalingsBescherming2: PbmOption | null = null;

  suggestions: PbmSuggestion;

  async savePbmOptions(pbmOptions: PbmOption[]) {
    PbmOptions.api.updatePbmOptions(pbmOptions);
    return this;
  }

  constructor(api: ApiService, input?: PbmOptionsDb, pbmOptions?: PbmOption[], hNumbersProduct?: Hnumber[], pbmOptionsClass?: PbmOptions) {

    if (input && pbmOptions) {
      
      Object.keys(input).forEach(key => {

      
        if (this[key] !== undefined) {
          
          const findPbm = pbmOptions.find(option => option.id === input[key]);

          if (findPbm) {
            
            this[key] = JSON.parse(JSON.stringify(findPbm));
            this[key].originalKey = key;                  
          }
        }

      });
    }

    if (pbmOptions && hNumbersProduct) {
      this.suggestions = pbmOptions.reduce((acc, pbmOption) => {
        const hNumber = hNumbersProduct.find(y => Object.values(y.pbm).some(z => z === pbmOption.id));
        if (hNumber) { return [...acc, { pbmOption, hNumber }]; }
        return acc;
      }, [] as { pbmOption: PbmOption, hNumber: Hnumber }[]);
    }

    if (pbmOptionsClass) {
      Object.keys(pbmOptionsClass).forEach(key => {
        if (this[key] !== undefined) { this[key] = pbmOptionsClass[key]; }
      });
    }


    PbmOptions.api = api;
  }

}
