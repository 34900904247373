import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Location } from '../../../classes/location.class';
import { RequestApproval } from '../../../classes/product-request.class';
import { ProductForm } from '../../../classes/product-sections.class';
import { Product } from '../../../classes/product.class';
import { CurrentUser, UserDb } from '../../../classes/user.class';
import { CustomValidators } from '../../../product-request/validators.service';
import { ProductService } from '../../../services/product.service';
import { UsersService } from '../../../services/users.service';
import { ProductTableService } from '../../../_shared/_components/product-table/product-table.service';
import { LanguageKeys } from '../../../_shared/_components/recursive-form/language-key.pipe';
import { Validator } from '../../../_shared/_components/recursive-form/recursive-form.component';
import { SnackbarComponent } from '../../../_shared/_components/snackbar/snackbar.component';
import { AppService } from '../../../_shared/_services/app.service';
import { ProductRequestMap } from '../product-requests.component';

@Component({
  selector: 'app-request-handler',
  templateUrl: './request-handler.component.html',
  styleUrls: ['./request-handler.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * @TODO Refactor this component!!!
 */
export class RequestHandlerComponent implements OnInit, OnDestroy {

  private _subs = new Subscription;

  public product: Product;
  public requestIndex: number;

  public productSignedOff = false;
  public plantSignedOff = false;



  // Observables
  public formOutputSource = new Subject<ProductForm>();

  public disableRequestForm$: Observable<boolean>;

  @Input() public disabled: boolean;


  public userFormFilter: (keyof CurrentUser)[] = ['dbRef', 'language', 'userId', 'isAdmin', 'isDeveloper', 'plant'];

  public plantFormValidators: Validator[];

  public translatedKeys$: Observable<LanguageKeys>;
  public approval: RequestApproval;

  // Signoff
  public allSigned = false;

  public approved = false;
  public approvedBy: UserDb | null;
  public approvedOn: Date | null;

  // Form validated
  public productFormValidated = false;
  public basicInfoFormValidated = false;
  public productPlantFormValidated = false;
  public sdsValidated = false;

  // Variables
  public productRequestMap: ProductRequestMap;
  public languages = this.appService.languages;
  public sdsFiles: { [prop: string]: File | null } = {};
  public newProductImage: File | undefined;
  public imageLink: string;
  public user: UserDb;
  public plant: string;
  public location: Location | undefined;
  public progressSpinner = false;
  public progressStatus: string;

  // Listeners
  @HostListener('window:beforeunload', ['$event'])
  public doSomething(event: BeforeUnloadEvent) {
    event.preventDefault();
    return false;
  }

  // Signoff
  public async signoffComplete() {
    const indexRequest = this.findRequestIndex();
    if (indexRequest === -1) { throw new Error('Could not find the request'); }

    this.product.metaData.requests[indexRequest].status = 'Approved';
    this.product.metaData.requests[indexRequest].approvedBy = this.user;
    this.product.metaData.requests[indexRequest].approvedOn = new Date;

    const product = await this.product.approveRequest(indexRequest)
      .catch(() => {
        this.matDialog.closeAll();
        this.snackbar.snackbarError('Something went wrong');
      });
    if (!product) { this.matDialog.closeAll(); this.snackbar.snackbarError('Something went wrong'); }

    this.approved = true;
    this.approvedBy = this.user;
    this.approvedOn = new Date;

    this.changeDetector.detectChanges();
  }

  // Helper methods
  public closeDialog() {
    this.matDialog.closeAll();
  }
  public findRequestIndex() {
    return this.product.metaData.requests.findIndex(x => {
      return x.requestForm.user.plant === this.productRequestMap.plant;
    });
  }
  public customTrackBy(_index: number, item: any) { return item; }

  // Life cycle
  constructor(
    private appService: AppService,
    public productTableService: ProductTableService,
    private matDialog: MatDialog,
    private validators: CustomValidators,
    private changeDetector: ChangeDetectorRef,
    private snackbar: SnackbarComponent,
    private productService: ProductService,
    private userService: UsersService,
  ) { }

  ngOnInit() {

    this.appService._disableRequestFormSource.next(false);

    const productRequestMapSub = this.productTableService.rowData$.subscribe((productRequestMap: ProductRequestMap) => {

      this.product = productRequestMap.productRef;
      this.requestIndex = this.product.metaData.requests
        .findIndex(request =>
          request.requestForm.plantInfo.metaDataPlant.plant ===
          productRequestMap.request.requestForm.plantInfo.metaDataPlant.plant);

      this.productRequestMap = productRequestMap;
      this.plant = productRequestMap.request.requestForm.user.plant;
      this.approval = this.product.metaData.requests[this.findRequestIndex()].approval;
      this.imageLink = this.product.metaData.requests[this.findRequestIndex()].requestForm.image;

      if (Object.values(this.product.metaData.requests[this.findRequestIndex()].approval).every(x => x.signed)) {
        this.product.metaData.requests[this.findRequestIndex()].status = 'Pending';
      }

      this.productSignedOff = this.product.metaData.requests[this.findRequestIndex()].approval.product.signed;
      this.plantSignedOff = this.product.metaData.requests[this.findRequestIndex()].approval.plant.signed;

      this.allSigned = this.product.metaData.requests[this.findRequestIndex()].status === 'Pending';
      this.approved = this.product.metaData.requests[this.findRequestIndex()].status === 'Approved';
      this.approvedBy = this.product.metaData.requests[this.findRequestIndex()].approvedBy;

      this.plantFormValidators = this.validators.productPlantFormValidators();
    });

    this.translatedKeys$ = combineLatest(
      this.productService.keys$,
      this.userService.currentUser$
    ).pipe(
      filter(([_, user]) => user !== null),
      map(([keys, user]) => {
        if (!user) { return {}; }

        // Set translations for form keys
        const translatedKeys: LanguageKeys = {};
        [...keys['plant'], ...keys['requestBasicInfo']].forEach(x => {
          if (x[user.language]) {
            translatedKeys[x.key] = x[user.language];
          } else {
            translatedKeys[x.key] = x.key;
          }
        });
        translatedKeys['email'] = 'E-mail';
        translatedKeys['username'] = 'Username';
        this.appService.languages.forEach(x => translatedKeys[x.value] = x.label);
        return translatedKeys;
      }));

    this.disableRequestForm$ = this.appService.disableRequestForm$;

    this._subs.add(productRequestMapSub);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

}
