import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductForm } from '../../classes/product-sections.class';
import { Product } from '../../classes/product.class';
import { ProductService } from '../../services/product.service';
import { UsersService } from '../../services/users.service';
import { LanguageKeys } from '../../_shared/_components/recursive-form/language-key.pipe';
import { LanguageClassPipe } from '../../_shared/_pipes/language-class.pipe';

@Component({
  selector: 'app-product-basic',
  templateUrl: './product-basic.component.html',
  styleUrls: ['./product-basic.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductBasicComponent implements OnInit, OnChanges {

  public productForm: { [prop: string]: any };
  public productOptionSelection$ = this.productService.productOptionSelections(this.languageClassPipe);


  // We need to translate the keys here because the dynamic form stands on its own.
  public productKeys$ = combineLatest(
    this.productService.productKeys$,
    this.userService.currentUser$
  ).pipe(
    map(([keys, user]) => {
      const language = user.language;
      return keys.reduce((obj, x) => {
        obj[x.key] = x[language] || x.key;
        return obj;
      }, {} as LanguageKeys);
    })
  );

  @Input() public product: Product;

  constructor(
    private productService: ProductService,
    private userService: UsersService,
    private changeDetector: ChangeDetectorRef,
    private languageClassPipe: LanguageClassPipe
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['product'] && changes['product'].currentValue) {
      new ProductForm(this.product).getAsView(this.languageClassPipe)
        .then(view => {
          this.productForm = view;
          this.changeDetector.detectChanges();
        });
    }

  }

}
