import { AgmCoreModule } from '@agm/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule, MatListModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { CmrEditComponent } from './admin/cmr-list/cmr-edit/cmr-edit.component';
import { CmrListComponent } from './admin/cmr-list/cmr-list.component';
import { HAndPListComponent } from './admin/h-and-p-list/h-and-p-list.component';
import { HListComponent } from './admin/h-and-p-list/h-list/h-list.component';
import { HnumberEditComponent } from './admin/h-and-p-list/h-list/h-number-edit/h-number-edit.component';
import { PListComponent } from './admin/h-and-p-list/p-list/p-list.component';
import { PnumberEditComponent } from './admin/h-and-p-list/p-list/p-number-edit/p-number-edit.component';
import { LocationListComponent } from './admin/location-list/location-list.component';
import { PbmOptionsListComponent } from './admin/pbm-options/pbm-options-list/pbm-options-list.component';
import { PbmOptionsComponent } from './admin/pbm-options/pbm-options.component';
import { ProductRequestsComponent } from './admin/product-requests/product-requests.component';
import { RequestHandlerComponent } from './admin/product-requests/request-handler/request-handler.component';
import { SevesoEditComponent } from './admin/seveso-list/seveso-edit/seveso-edit.component';
import { SevesoListComponent } from './admin/seveso-list/seveso-list.component';
import { TranslationListComponent } from './admin/translation-list/translation-list.component';
import { UnEditComponent } from './admin/un-list/un-edit/un-edit.component';
import { UnListComponent } from './admin/un-list/un-list.component';
import { UserTableComponent } from './admin/user-table/user-table.component';
import { ZzsEditComponent } from './admin/zzs-list/zzs-edit/zzs-edit.component';
import { ZzsListComponent } from './admin/zzs-list/zzs-list.component';
import { AllProductsComponent } from './products/products.component';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { ConfigService } from './config.service';
import { MapModule } from './map/map.module';
import { ProductEditModule } from './product-edit/product-edit.module';
import { ProductInfoModule } from './product-info/product-info.module';
import { ProductRequestComponent } from './product-request/product-request.component';
import { SidenavModule } from './sidenav/sidenav.module';
import { SharedModule } from './_shared/shared.module';
import { RequestProductComponent } from './product-request/request-product/request-product.component';
import { RequestPlantInfoComponent } from './product-request/request-plant-info/request-plant-info.component';
import { RequestSDSComponent } from './product-request/request-sds/request-sds.component';
import { RequestHandlerProductComponent } from './admin/product-requests/request-handler/request-handler-product/request-handler-product.component';
import { RequestHandlerRequestComponent } from './admin/product-requests/request-handler/request-handler-request/request-handler-request.component';
import { VlaremOptionsComponent } from './admin/vlarem-options/vlarem-options.component';
import { EditKeyListComponent } from './admin/edit-key-list/edit-key-list.component';
import { UiTranslationPipe } from './_shared/_pipes/ui-translation.pipe';
import { UsefulLinksComponent } from './useful-links/useful-links.component';
import { ApiInterceptor } from "./_shared/_services/api.interceptor";
import { PzzsListComponent } from "./admin/pzzs-list/pzzs-list.component";
import { PzzsEditComponent } from "./admin/pzzs-list/pzzs-edit/pzzs-edit.component";

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    ProductRequestsComponent,
    ProductRequestComponent,
    RequestHandlerComponent,
    UserTableComponent,
    ZzsListComponent,
    ZzsEditComponent,
    PzzsListComponent,
    PzzsEditComponent,
    SevesoListComponent,
    SevesoEditComponent,
    UnListComponent,
    UnEditComponent,
    CmrListComponent,
    CmrEditComponent,
    HAndPListComponent,
    HListComponent,
    PListComponent,
    LocationListComponent,
    TranslationListComponent,
    AllProductsComponent,
    HnumberEditComponent,
    PnumberEditComponent,
    PbmOptionsComponent,
    PbmOptionsListComponent,
    RequestProductComponent,
    RequestPlantInfoComponent,
    RequestSDSComponent,
    RequestHandlerProductComponent,
    RequestHandlerRequestComponent,
    VlaremOptionsComponent,
    EditKeyListComponent,
    UsefulLinksComponent,
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(ConfigService.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    MatNativeDateModule,
    MatListModule,
    RouterModule.forRoot(appRoutes),
    AgmCoreModule.forRoot({
      apiKey: ConfigService.googleMapsApi,
      libraries: ["places"],
    }),
    BrowserAnimationsModule,
    SidenavModule,
    MapModule,
    ProductInfoModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    ProductEditModule,
  ],
  providers: [
    UiTranslationPipe,
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
  ],
  entryComponents: [
    ProductRequestComponent,
    RequestHandlerComponent,
    ZzsEditComponent,
    SevesoEditComponent,
    UnEditComponent,
    CmrEditComponent,
    PnumberEditComponent,
    HnumberEditComponent,
    PzzsEditComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
