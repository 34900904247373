import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { CurrentUser } from '../../../classes/user.class';
import { Location } from '../../../classes/location.class';
import { ProductService } from '../../../services/product.service';
import { UsersService } from '../../../services/users.service';

@Component({
  selector: 'app-select-plant',
  templateUrl: './select-plant.component.html',
  styleUrls: ['./select-plant.component.css']
})
export class SelectPlantComponent implements OnInit, OnDestroy {

  private _subs = new Subscription;

  // Variables
  public selectedPlant: Location;
  public user: CurrentUser;
  public locations: Location[];

  @Output() selectedLocation = new EventEmitter<Location>();

  // Methods
  public async userSelectedPlant(selectedPlant: Location) {

    if (typeof selectedPlant === 'string') {
      await this.user.updatePlant(selectedPlant);
    } else {
      const locationUser = selectedPlant.dbRef;
      await this.user.updatePlant(locationUser);
      this.selectedLocation.emit(selectedPlant);
    }
  }

  // Life cycle
  private subscribeToObservables() {
    const locationsUserSub = combineLatest(
      this.productService.locationsActive$,
      this.userService.currentUser$
    ).subscribe(([locations, user]) => {
      if (!user) { return; }
      this.user = user;
      this.locations = locations;

      const location = locations.find(x => x.dbRef === user.plant);
      const firstLocation = locations[0] || '';

      if (location) { this.selectedPlant = location; }
      else { this.selectedPlant = firstLocation; }

      this.selectedLocation.emit(this.selectedPlant);
    });

    this._subs.add(locationsUserSub);
  }

  constructor(
    private productService: ProductService,
    private userService: UsersService
  ) { }

  ngOnInit() {
    this.subscribeToObservables();
  }
  ngOnDestroy() {
    this._subs.unsubscribe();
  }

}
