import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { Location } from '../../classes/location.class';
import { ApiService } from '../../services/api.service';
import { ProductService } from '../../services/product.service';
import { DialogComponent, DialogContent } from '../../_shared/_components/dialog/dialog.component';
import { SnackbarComponent } from '../../_shared/_components/snackbar/snackbar.component';

@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.css']
})
export class LocationListComponent implements OnInit, OnDestroy {

  private _subs = new Subscription;

  // Variables
  public locations: Location[];

  public dataSource: MatTableDataSource<Location>;
  public displayedColumns: (keyof Location)[];
  public editable: boolean;

  public headerTooltipPosition = 'above';
  public headerTooltipShowDelay = '500';

  @ViewChild(MatPaginator) private paginator: MatPaginator;
  @ViewChild(MatSort) private sort: MatSort;

  public addEntry() {
    const data = this.dataSource.data;
    data.unshift(new Location(this.api));
    this.dataSource.data = data;
  }

  public removeEntry(row: Location) {

    const data: DialogContent = {
      dialogData: {
        title: 'Confirm',
        body: `Are you sure you want to delete this entry?`,
        button: 'Delete',
        button2: 'Cancel'
      }
    };
    const dialog = this.matDialog.open(DialogComponent, { data, disableClose: true });

    dialog.afterClosed().subscribe(async response => {
      if (!response) { return; }
      if (row.dbRef) {
        await row.deleteLocation();
        this.snackbar.snackbarSuccess('Deleted location');
      } else {
        const index = this.dataSource.data.findIndex(x => x === row);
        if (index === -1) { this.matDialog.closeAll(); return; }
        const dataTable = this.dataSource.data;
        dataTable.splice(index, 1);
        this.dataSource.data = dataTable;
        this.snackbar.snackbarSuccess('Deleted location');
        this.matDialog.closeAll();
      }
    });
  }

  // Table Methods
  async saveData() {
    await Location.updateAllLocations(this.dataSource.data);
    this.snackbar.snackbarSuccess('Saved locations');
  }

  // Life cycle
  constructor(
    private matDialog: MatDialog,
    private api: ApiService,
    private snackbar: SnackbarComponent,
    private productService: ProductService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator.pageSize = 50;

    const locationsSub = this.productService.locations$.subscribe(locations => {
      this.displayedColumns = [
        'active',
        'vlarem',
        'seveso',
        'lokatie',
        'description',
        'adres',
        'nr',
        'plaats',
        'postcode',
        'land',
        'longitude',
        'latitude'
      ];

      this.locations = locations;
      this.dataSource.data = locations;
    });

    this._subs.add(locationsSub);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

}
