import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatStep, MatStepper } from '@angular/material';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserDb } from 'src/app/classes/user.class';
import { LanguageClass } from '../classes/language.class';
import { ProductPlant } from '../classes/product-plant.class';
import { RequestFormBasicInfo } from '../classes/product-request.class';
import { Product } from '../classes/product.class';
import { ApiService } from '../services/api.service';
import { UsersService } from '../services/users.service';
import { DialogComponent } from '../_shared/_components/dialog/dialog.component';
import { PlantNamePipe } from '../_shared/_pipes/plant-name.pipe';
import { RequestPlantInfoComponent } from './request-plant-info/request-plant-info.component';
import { RequestProductComponent } from './request-product/request-product.component';

@Component({
  selector: 'app-product-request',
  templateUrl: './product-request.component.html',
  styleUrls: ['./product-request.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * Request handle component.
 */
export class ProductRequestComponent implements OnInit, OnDestroy, AfterViewInit {

  private _subs = new Subscription;

  public isNewProduct: boolean;
  public user: UserDb;

  public validatedProduct = false;
  public validatedBasicInfo = false;
  public validatedPlantInfo = false;

  public product = new Product(this.api);
  public basicInfo: RequestFormBasicInfo;
  public plantInfo: ProductPlant;
  public sdsFiles: { [prop: string]: File | null } = {};
  public productImage: File;

  @ViewChild(RequestProductComponent) private productComponent: RequestProductComponent;
  @ViewChild(RequestPlantInfoComponent) private plantComponent: RequestPlantInfoComponent;

  // Variables
  public progressSpinner = false;
  public uploadStatus: string;

  @ViewChild('stepper') private stepper: MatStepper;
  @ViewChild('scrollElement') private scrollElement: ElementRef<HTMLDivElement>;

  // Listeners
  @HostListener('window:beforeunload', ['$event'])
  public doSomething(event: BeforeUnloadEvent) {
    event.preventDefault();
    return false;
  }

  // Set validation from event
  public validateUploadFiles() {
    const testSdsFiles = Object.values(this.sdsFiles).some(x => !!x);
    const testSdsLinks = Object.values(this.product.metaData.sdsLinks).some(x => !!x);
    return testSdsFiles || testSdsLinks;
  }

  public async sendRequest() {
    this.progressSpinner = true;
    this.stepper._steps.forEach(x => x.completed = false);
    this.uploadStatus = 'Saving product';

    // SDS
    const sdsFileArray = Object.entries(this.sdsFiles)
      .filter(entry => entry[1])
      .map(entry => ({ file: entry[1], language: entry[0] } as { file: File, language: keyof LanguageClass }));

    try {
      const productPbm = this.product.pbm;
      this.plantInfo.pbm = productPbm;
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.log(err);
    }

    // Add the request
    this.uploadStatus = 'Uploading files';
    const result = await this.product.addRequest(
      sdsFileArray,
      this.productImage,
      this.basicInfo,
      this.plantInfo,
      this.user,
      this.isNewProduct,
    ).catch(() => void 0);
    if (!result) {
      this.uploadStatus = 'Error making new request, please try again';
      this.progressSpinner = false;
      this.stepper._steps.forEach(x => x.completed = true);
      this.changeDetector.detectChanges();
    } else {
      this.uploadStatus = 'Done';
      this.closeDialog();
      this.openSuccesDialog();
    }
  }

  // Helper methods
  public closeDialog() { this.matDialog.closeAll(); }
  public async openSuccesDialog() {
    const plant = await this.plantNamePipe.transform(this.user.plant).pipe(take(1)).toPromise();

    this.matDialog.open(DialogComponent, {
      data: {
        dialogData: {
          title: 'Success!',
          body: `Succesfully requested new product <b>${this.product.name[this.user.language]}</b> for plant ${plant}.`,
          button: 'Ok',
        }
      },
    });
  }

  // Helper methods
  private addValidatorCheckToStep(object: MatStep) {
    const oldSelect = object.select;
    object.select = () => {
      const oldStep = this.stepper.selected;
      oldSelect.call(object);
      if (oldStep === this.stepper.selected && !this.stepper.selected.completed) {
        this.pushValidation();
      }
    };
  }
  public onStepSelectionChange() {
    this.scrollElement.nativeElement.scrollIntoView({ block: 'start', inline: 'start' });
  }
  public pushValidation(whatForm?: string) {
    switch (whatForm) {
      case 'productForm': { this.productComponent.validateForm(); break; }
      case 'plantForm': { this.plantComponent.validateForm(); break; }

      default: {
        this.productComponent.validateForm();
        this.plantComponent.validateForm();
      }
    }
  }

  // Life cycle
  constructor(
    private matDialog: MatDialog,
    private userService: UsersService,
    private changeDetector: ChangeDetectorRef,
    private api: ApiService,
    private plantNamePipe: PlantNamePipe
  ) { }

  ngOnInit() {
    this._subs.add(
      this.userService.currentUser$.subscribe(user => {
        if (!user) { this.matDialog.closeAll(); return; }
        this.user = user;
      })
    );

  }

  ngAfterViewInit() {
    // Hack to validate form if step is not completed
    this.stepper._steps.forEach(step => this.addValidatorCheckToStep(step));
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

}
