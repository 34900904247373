import { ComponentType } from '@angular/cdk/portal';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { combineLatest, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Hnumber } from '../../../../classes/h-and-p.class';
import { ApiService } from '../../../../services/api.service';
import { ProductService } from '../../../../services/product.service';
import { UsersService } from '../../../../services/users.service';
import { DialogComponent, DialogContent } from '../../../../_shared/_components/dialog/dialog.component';
import { InfoTableService } from '../../../../_shared/_components/info-table/info-table.service';
import { LanguageKeys } from '../../../../_shared/_components/recursive-form/language-key.pipe';
import { RecursiveFormComponent, Validator } from '../../../../_shared/_components/recursive-form/recursive-form.component';
import { SnackbarComponent } from '../../../../_shared/_components/snackbar/snackbar.component';
import { LanguageClassPipe } from '../../../../_shared/_pipes/language-class.pipe';

@Component({
  selector: 'app-h-number-edit',
  templateUrl: './h-number-edit.component.html',
  styleUrls: ['./h-number-edit.component.css']
})
export class HnumberEditComponent implements OnInit, OnDestroy, AfterViewInit {

  private subscriptions = new Subscription;

  public pbmOptionsSelection$ = this.productService.pbmOptionSelections(this.languageClassPipe);

  // Variables
  public hNumber: Hnumber;
  public editable: boolean;
  public validated = false;
  public hNumberForm: Hnumber;
  public validators: Validator[] = [{
    keyName: 'code',
    validators: [Validators.required],
    error: '',
    message: ''
  }];
  public filter = ['pbmOptions', 'suggestions'];

  // We need to translate the keys here because the dynamic form stands on its own
  public translatedKeys$ = combineLatest(
    this.userService.currentUser$,
    this.productService.pbmKeys$
  ).pipe(filter(([user]) => user !== null),
    map(([user, keys]) => {
      // Set translations for form keys
      const translatedKeys: LanguageKeys = {};
      keys.forEach(x => {
        if (x[user.language]) {
          translatedKeys[x.key] = x[user.language];
        } else {
          translatedKeys[x.key] = x.key;
        }
      });
      // this.appService.languages.forEach(x => translatedKeys[x.value] = x.label);
      return translatedKeys;
    })
  );


  @ViewChild('recursiveForm') recursiveForm: RecursiveFormComponent;

  // Methods
  public getHNumber(hNumber: Hnumber) {
    this.hNumberForm = new Hnumber(this.api, hNumber);
  }

  public hNumberValidate(validated: boolean) {
    this.validated = validated;
  }

  public closeDialog() {
    this.matDialog.closeAll();
  }

  public async saveData() {

    switch (this.dialogData.state) {
      case 'new': {
        await this.hNumberForm.addHnrDb()
          .then(() => this.snackbar.snackbarSuccess('Saved h-number'))
          .catch(error => this.snackbar.snackbarError(error.error));
        this.closeDialog();
        break;
      }
      case 'edit': {
        await this.hNumberForm.saveHnrDb()
          .then(() => this.snackbar.snackbarSuccess('Saved h-number'))
          .catch(error => this.snackbar.snackbarError(error.error));
        this.closeDialog();
        break;
      }
    }

  }

  public deleteEntry() {

    const data: DialogContent = {
      dialogData: {
        title: 'Confirm',
        body: `Are you sure you want to delete this entry?`,
        button: 'Delete',
        button2: 'Cancel'
      }
    };
    const dialog = this.matDialog.open(DialogComponent, { data, disableClose: true });

    dialog.afterClosed().subscribe(async response => {
      if (!response) { return; }
      await this.hNumberForm.deleteHnrDb();
      this.snackbar.snackbarSuccess('Deleted h-number');
      this.matDialog.closeAll();
    });
  }

  // Life cycle methods
  private subscribeToObservables() {
    const rowDataSub = this.infoTableService.rowData$.subscribe((hNumber: Hnumber) => this.hNumber = hNumber);

    this.subscriptions.add(rowDataSub);
  }

  // Life cycle
  constructor(
    private infoTableService: InfoTableService,
    private productService: ProductService,
    private userService: UsersService,
    private matDialog: MatDialog,
    private api: ApiService,
    private snackbar: SnackbarComponent,
    private languageClassPipe: LanguageClassPipe,
    @Inject(MAT_DIALOG_DATA) public dialogData: { component: ComponentType<any>, state: 'new' | 'edit' }
  ) { }

  ngOnInit() {
    this.subscribeToObservables();
    if (this.dialogData.state === 'edit') { this.filter.push('code'); }
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  ngAfterViewInit() {
    this.recursiveForm.validateForm();
  }
}
