import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CmrClassificationComponent } from './_components/cmr-classification/cmr-classification.component';
import { DialogComponent } from './_components/dialog/dialog.component';
import { EditProductImageComponent } from './_components/edit-product-image/edit-product-image.component';
import { InfoTableComponent } from './_components/info-table/info-table.component';
import { KeysTableComponent } from './_components/keys-table/keys-table.component';
import { LoadingComponent } from './_components/loading/loading.component';
import { CheckGhsPipe } from './_components/product-table/check-ghs.pipe';
import { CoefficientCheckPipe } from './_components/product-table/coefficient-check.pipe';
import { GetFooterPipe } from './_components/product-table/get-footer.pipe';
import { IsPercentagePipe } from './_components/product-table/is-percentage.pipe';
import { ProductTableComponent } from './_components/product-table/product-table.component';
import { ValueColorPipe } from './_components/product-table/value-color.pipe';
import { ZzsTooltipPipe } from './_components/product-table/zzs-tooltip.pipe';
import { LanguageKeyPipe } from './_components/recursive-form/language-key.pipe';
import { RecursiveFormComponent, RecursiveFormRecursiveComponent } from './_components/recursive-form/recursive-form.component';
import { SdsViewerComponent } from './_components/sds-viewer/sds-viewer.component';
import { SelectPlantComponent } from './_components/select-plant/select-plant.component';
import { SnackbarComponent } from './_components/snackbar/snackbar.component';
import { ZzsTableComponent } from './_components/zzs-table/zzs-table.component';
import { ColorValueDirective } from './_directives/color-value.directive';
import { PreviewImageDirective } from './_directives/preview-image.directive';
import { SvgFlagDirective } from './_directives/svg-flags.directive';
import { SvgStateDirective } from './_directives/svg-state.directive';
import { MaterialModule } from './_modules/material.module';
import { CapitalizePipe } from './_pipes/capitalize.pipe';
import { ConsoleLogPipe } from './_pipes/console-log.pipe';
import { InvalidDateCheckPipe } from './_pipes/invalid-date-check.pipe';
import { LanguageClassPipe } from './_pipes/language-class.pipe';
import { LocationToStringPipe } from './_pipes/location-to-string.pipe';
import { PbmCategoryTranslationPipe } from './_pipes/pbm-category-translation.pipe';
import { PercentagePipe } from './_pipes/percentage.pipe';
import { PlantNamePipe } from './_pipes/plant-name.pipe';
import { SecurePipe } from './_pipes/secure.pipe';
import { SortByPipe } from './_pipes/sort-by.pipe';
import { StorageImgPipe } from './_pipes/storage-img.pipe';
import { StorageSdsPipe } from './_pipes/storage-sds.pipe';
import { TransFormValuePipe } from './_pipes/transformValue.pipe';
import { UiTranslationPipe } from './_pipes/ui-translation.pipe';
import { ValueTypePipe } from './_pipes/value-type.pipe';
import { ErrorInterceptorProvider } from './_services/error.interceptor';
import { FirebaseAuthInterceptorProvider } from './_services/firebase-token.interceptor';
import { GhsTooltipPipe } from './_components/product-table/ghs-tooltip.pipe';
import { AdrTooltipPipe } from './_components/product-table/adr-tooltip.pipe';
import { IsCoefficientKeyPipe } from './_components/product-table/is-coefficient-key.pipe';
import { HazardTooltipPipe } from './_components/product-table/hazard-tooltip.pipe';
import { DropdownTranslationPipe } from './_pipes/dropdown.pipe';
import { ProductComponentNamePipe } from './_components/recursive-form/product-component-name.pipe';
import { IsPbmPipe } from './_components/product-table/is-pbm.pipe';
import { PmbColToImagePipe } from './_components/product-table/pbm-col-to-image.pipe';
import { CheckHideUiPipe } from './_components/product-table/check-hide-ui.pipe';
import { PzzsTableComponent } from "./_components/pzzs-table/pzzs-table.component";
import { PzzsTooltipPipe } from "./_components/product-table/pzzs-tooltip.pipe";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PdfViewerModule,
    ReactiveFormsModule,
  ],
  declarations: [
    DialogComponent,
    SelectPlantComponent,
    PreviewImageDirective,
    SnackbarComponent,
    RecursiveFormComponent,
    RecursiveFormRecursiveComponent,
    KeysTableComponent,
    InfoTableComponent,
    CmrClassificationComponent,
    CapitalizePipe,
    LanguageKeyPipe,
    TransFormValuePipe,
    LanguageClassPipe,
    LocationToStringPipe,
    SdsViewerComponent,
    ColorValueDirective,
    PercentagePipe,
    SvgFlagDirective,
    SvgStateDirective,
    SecurePipe,
    StorageImgPipe,
    StorageSdsPipe,
    InvalidDateCheckPipe,
    TransFormValuePipe,
    ProductTableComponent,
    ZzsTooltipPipe,
    ZzsTableComponent,
    PzzsTooltipPipe,
    PzzsTableComponent,
    EditProductImageComponent,
    PlantNamePipe,
    LoadingComponent,
    ConsoleLogPipe,
    PbmCategoryTranslationPipe,
    ValueColorPipe,
    ValueTypePipe,
    CheckGhsPipe,
    GetFooterPipe,
    CoefficientCheckPipe,
    IsPercentagePipe,
    UiTranslationPipe,
    DropdownTranslationPipe,
    SortByPipe,
    GhsTooltipPipe,
    AdrTooltipPipe,
    IsCoefficientKeyPipe,
    HazardTooltipPipe,
    ProductComponentNamePipe,
    IsPbmPipe,
    PmbColToImagePipe,
    CheckHideUiPipe,
  ],
  exports: [
    MaterialModule,
    PdfViewerModule,
    DialogComponent,
    SelectPlantComponent,
    PreviewImageDirective,
    SnackbarComponent,
    RecursiveFormComponent,
    KeysTableComponent,
    InfoTableComponent,
    CmrClassificationComponent,
    CapitalizePipe,
    LocationToStringPipe,
    SdsViewerComponent,
    ColorValueDirective,
    PercentagePipe,
    LanguageClassPipe,
    LanguageKeyPipe,
    SvgFlagDirective,
    SvgStateDirective,
    SecurePipe,
    StorageImgPipe,
    StorageSdsPipe,
    InvalidDateCheckPipe,
    TransFormValuePipe,
    ProductTableComponent,
    ZzsTooltipPipe,
    PzzsTooltipPipe,
    ZzsTableComponent,
    PzzsTableComponent,
    EditProductImageComponent,
    PlantNamePipe,
    LoadingComponent,
    ConsoleLogPipe,
    PbmCategoryTranslationPipe,
    ValueTypePipe,
    UiTranslationPipe,
    DropdownTranslationPipe,
    SortByPipe,
  ],
  providers: [
    SnackbarComponent,
    FirebaseAuthInterceptorProvider,
    ErrorInterceptorProvider,
    LanguageClassPipe,
    PlantNamePipe,
    IsPbmPipe,
  ],
  entryComponents: [DialogComponent],
})
export class SharedModule {}
