import { ComponentType } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { KeyDb } from '../../../classes/language.class';
import { ProductService } from '../../../services/product.service';
import { TableDataRowInput } from '../product-table/product-table.component';

@Component({
  selector: 'app-zzs-table',
  templateUrl: './zzs-table.component.html',
  styleUrls: ['./zzs-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZzsTableComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription;

  public zzsList$ = this.productService.zzsList$.pipe(
    map(zzsList => {
      return zzsList.map(zzs => {

        const expandedDetail: TableDataRowInput['expandedDetail'] = {};
        // Voetnoten
        if (zzs.voetnoot1) { expandedDetail['Voetnoot 1:'] = zzs.voetnoot1; }
        if (zzs.voetnoot2) { expandedDetail['Voetnoot 2:'] = zzs.voetnoot2; }
        if (zzs.voetnoot3) { expandedDetail['Voetnoot 3:'] = zzs.voetnoot3; }
        if (zzs.voetnoot4) { expandedDetail['Voetnoot 4:'] = zzs.voetnoot4; }

        return { ...zzs, expandedDetail };

      });
    })
  );
  public zzsKeys$ = this.productService.activeZzsKeys$;

  @Input() public showPlantSelect: boolean;
  @Input() public rowClickComponent: ComponentType<any>;
  @Input() public showFooter: boolean;
  @Input() public expandedDetail: boolean;

  public tableKeys: KeyDb[];
  public tableData: TableDataRowInput[];

  constructor(
    private productService: ProductService
  ) { }

  ngOnInit() { }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
