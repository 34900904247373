import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CmrDb } from '../../../classes/cmr.class';

interface Classification { classification: number; hNr: string; }
interface NewCmr { all: Classification[]; highest: Classification[]; }
interface CombinedObject {
  toxic: NewCmr;
  fire: NewCmr;
  environment: NewCmr;
}

@Component({
  selector: 'app-cmr-classification',
  templateUrl: './cmr-classification.component.html',
  styleUrls: ['./cmr-classification.component.css']
})
export class CmrClassificationComponent implements OnInit, OnChanges {

  // Variables
  @Input() private cmrList: CmrDb[];

  public combinedObject: CombinedObject;
  public formObject: any;

  // Methods
  private highestCalculation() {
    if (!this.cmrList) { return; }

    const combinedObject: CombinedObject = {
      toxic: { all: [], highest: [] },
      fire: { all: [], highest: [] },
      environment: { all: [], highest: [] },
    };

    this.cmrList.forEach(x => {
      combinedObject.toxic.all.push({ classification: x.toxic, hNr: x.hNr });
      combinedObject.fire.all.push({ classification: x.fire, hNr: x.hNr });
      combinedObject.environment.all.push({ classification: x.environment, hNr: x.hNr });
    });

    Object.keys(combinedObject).forEach((x: keyof CombinedObject) => {

      const highestclassification: Classification[] = combinedObject[x].all
        .filter((y: Classification) => y.classification === Math.max(...combinedObject[x].all
          .map((a: Classification) => a.classification)));

      combinedObject[x].highest = highestclassification.some(b => b.classification === 0) ? [] : highestclassification;
    });

    this.combinedObject = combinedObject;
  }

  // Life cycle
  constructor() { }

  ngOnInit() { }
  ngOnChanges() {
    this.highestCalculation();
  }

}
