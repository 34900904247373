import { UnSubstanceCategoryDbServer } from '../../../server/src/routes/product/types';
import { ApiService } from '../services/api.service';
import { Product } from './product.class';

export class UnSubstanceCategoryDb implements UnSubstanceCategoryDbServer {

  vn = '';
  con1999b = '';
  gevi = '';
  gtWaarde = '';
  stofcategorie = '';
  stofnaam = '';

  constructor(input?: UnSubstanceCategoryDb) {
    if (input) {
      Object.keys(input).forEach(key => {
          if (this[key] !== undefined) { this[key] = input[key]; }
      });
    }
  }

}

export class UnSubstanceCategory extends UnSubstanceCategoryDb {

  static api: ApiService;

  getUnSubstanceCategorieData(unSubstanceCategoryList: UnSubstanceCategoryDb[], product: Product) {
    const category = unSubstanceCategoryList.find(x => x.vn === product.un);
    if (category) {
      Object.keys(category).forEach(x => {
        if (this[x]) { this[x] = category[x]; }
      });
    }
  }

  public saveUn() {
    if (!this.vn) { throw new Error('No vn specified. This is the dbRef'); }
    return UnSubstanceCategory.api.updateUn(new UnSubstanceCategoryDb(this));
  }

  public deleteUn() {
    if (!this.vn) { throw new Error('No dbRef present'); }
    return UnSubstanceCategory.api.deleteUn(this.vn);
  }

  constructor(
    api: ApiService,
    input?: UnSubstanceCategoryDb,
    product?: Product,
    unList?: UnSubstanceCategoryDb[],
  ) {
    super(input);

    if (unList && product) {
      this.getUnSubstanceCategorieData(unList, product);
    }

    UnSubstanceCategory.api = api;

  }

}
