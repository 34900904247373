import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { DialogComponent, DialogContent } from 'src/app/_shared/_components/dialog/dialog.component';
import { InfoTableService } from 'src/app/_shared/_components/info-table/info-table.service';
import { SnackbarComponent } from 'src/app/_shared/_components/snackbar/snackbar.component';
import { Zzs } from '../../../classes/zzs.class';

@Component({
  selector: 'app-zzs-edit',
  templateUrl: './zzs-edit.component.html',
  styleUrls: ['./zzs-edit.component.css']
})
export class ZzsEditComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription;

  // Variables
  public zzs: Zzs;
  public zzsvalidated = false;
  public zzsForm: Zzs;
  public zzsFormOutput: Zzs;

  // Methods
  public zzsValidate(validated: boolean) {
    setTimeout(() => this.zzsvalidated = validated);
  }

  public closeDialog() {
    this.matDialog.closeAll();
  }

  public async saveData() {
    this.zzs.updateFromForm(this.zzsFormOutput);
    await this.zzs.saveZzs();
    this.snackBar.snackbarSuccess('Zzs saved');
  }

  public deleteEntry() {

    const data: DialogContent = {
      dialogData: {
        title: 'Confirm',
        body: `Are you sure you want to delete this entry?`,
        button: 'Delete',
        button2: 'Cancel'
      }
    };
    const dialog = this.matDialog.open(DialogComponent, { data, disableClose: true });

    dialog.afterClosed().subscribe(async response => {
      if (!response) { return; }

      await this.zzs.deleteZzs();
      this.snackBar.snackbarSuccess('Zzs deleted');
      this.matDialog.closeAll();
    });
  }

  // Life cycle methods
  private subscribeToObservables() {
    const rowDataSub = this.infoTableService.rowData$.subscribe(zzs => {
      this.zzs = zzs;
      this.zzsForm = { ...zzs };
    });

    this.subscriptions.add(rowDataSub);
  }

  // Life cycle
  constructor(
    private infoTableService: InfoTableService,
    private matDialog: MatDialog,
    private snackBar: SnackbarComponent
  ) { }

  ngOnInit() {
    this.subscribeToObservables();
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
