import { Injectable } from '@angular/core';
import { RecursiveFormComponent } from '../_shared/_components/recursive-form/recursive-form.component';
import { FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Product } from '../classes/product.class';
import { Vlarem } from '../classes/vlarem.class';

@Injectable({
  providedIn: 'root'
})
export class VlaremService {

  public setVlaremListener(plantFormComponent: RecursiveFormComponent) {
    // we force values to update relationally with this listener.
    const vlarem = (plantFormComponent.form.controls['vlaremStock'] as FormGroup);

    return vlarem.valueChanges.pipe(
      map(change => {
        const totalMaxStockKG = change.movableRecipientsMaxStockKG + change.solidTanksMaxStockKG;
        const totalMaxStockL = change.movableRecipientsMaxStockL + change.solidTanksMaxStockL;

        // sum of the parts set to the target field.
        plantFormComponent.form.controls.maximumStockKG.setValue(totalMaxStockKG, { emitEvent: false });
        plantFormComponent.form.controls.maximumStockL.setValue(totalMaxStockL, { emitEvent: false });
      }));
  }

  public autoSetVlarem(product: Product) {

    const hNrs = product.hNr;
    const appliedRules = [];

    const allVlaremKeys = Object.keys(product.vlarem);
    const vlaremSettings = allVlaremKeys.reduce((obj, item) => { obj[item] = false; return obj; }, {}) as Vlarem;

    /*REGEL 1*/
    const r1 = this.containsElement(hNrs, ['H222', 'H223', 'H229', 'H222 + H229']);
    if (r1) {
      appliedRules.push(1);
      // product.vlarem._17_1_1 = true;
      vlaremSettings._17_1_1 = true;
    } else {
      /*EINDE REGEL 1*/
      const GHS = product.ghs;
      /*REGEL 2*/
      const r2 = this.containsElement(hNrs, ['H220', 'H221', 'H230', 'H231', 'H270', 'H280']);
      //  || this.containsElement(GHS, ['GHS_02', 'GHS_03']);
      if (r2) {
        appliedRules.push(2);
        vlaremSettings._17_1_2_1 = true;
      }
      /*EINDE REGEL 2*/

      /*REGEL 3*/
      const r3 = this.containsElement(hNrs, ['H281']);
      if (r3) {
        appliedRules.push(3);
        vlaremSettings._17_1_2_2 = true;
      }
      /*EINDE REGEL 3*/

      /*REGEL 4*/
      const r4 = this.containsElement(hNrs, ['H271', 'H272']);
      if (r4) {
        appliedRules.push(4);
        vlaremSettings._17_3_3_Ghs03 = true;
      }
      /*EINDE REGEL 4*/

      /*REGEL 5*/
      const r5 = this.containsElement(hNrs, ['H224', 'H225', 'H250']);
      if (r5) {
        appliedRules.push(5);
        vlaremSettings._17_3_2_2_Ghs02 = true;
      }
      /*EINDE REGEL 5*/

      /*REGEL 6*/
      const r6 = this.containsElement(hNrs, ['H226']);

      if (r6) {
        appliedRules.push(6);

        let isBrandstof = false;
        if (product.physicalState === 'liquid' && !this.containsElement(GHS, ['GHS02']) && product.flashpoint >= 55) {
          isBrandstof = true;
        }

        if (isBrandstof) {
          vlaremSettings._17_3_2_1_1_Ghs02 = true;
        } else {
          vlaremSettings._17_3_2_1_2_Ghs02 = true;
        }
      }
      /*EINDE REGEL 6*/

      /*REGEL 7*/
      const r7 = this.containsElement(GHS, ['GHS05']);
      if (r7) {
        appliedRules.push(7);
        vlaremSettings._17_3_4_Ghs05 = true;
      }
      /*EINDE REGEL 7*/

      /*REGEL 8*/
      const r8 = this.containsElement(GHS, ['GHS06']);
      if (r8) {
        appliedRules.push(8);
        vlaremSettings._17_3_5_Ghs06 = true;
      }
      /*EINDE REGEL 8*/

      /*REGEL 9*/
      const r9 = this.containsElement(GHS, ['GHS07']);
      if (r9) {
        appliedRules.push(9);
        vlaremSettings._17_3_6_Ghs07 = true;
      }
      /*EINDE REGEL 9*/

      /*REGEL 10*/
      const r10 = this.containsElement(GHS, ['GHS08']);
      if (r10) {
        appliedRules.push(10);
        vlaremSettings._17_3_7_Ghs08 = true;
      }
      /*EINDE REGEL 10*/

      /*REGEL 11*/
      const r11 = this.containsElement(GHS, ['GHS09']);
      if (r11) {
        appliedRules.push(11);
        vlaremSettings._17_3_8_Ghs09 = true;
      }
      /*EINDE REGEL 11*/
    }

    // tslint:disable-next-line:no-console
    console.log(appliedRules, vlaremSettings);
    // tslint:disable-next-line:no-console
    // console.log(product.vlarem);

    // return product.vlarem;
    return vlaremSettings;

  }

  private containsElement(productVals: string[], matchVals: string[]) {
    return productVals.filter(element => matchVals.includes(element)).length > 0;
  }


  constructor() { }
}
