import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { combineLatest, ReplaySubject, Subscription } from 'rxjs';
import { filter, map, take, debounceTime } from 'rxjs/operators';
import { ProductForm } from '../../classes/product-sections.class';
import { Product } from '../../classes/product.class';
import { CustomValidators } from '../../product-request/validators.service';
import { ProductService } from '../../services/product.service';
import { UsersService } from '../../services/users.service';
import { LanguageKeys } from '../../_shared/_components/recursive-form/language-key.pipe';
import { RecursiveFormComponent, Validator } from '../../_shared/_components/recursive-form/recursive-form.component';
import { SnackbarComponent } from '../../_shared/_components/snackbar/snackbar.component';
import { LanguageClassPipe } from '../../_shared/_pipes/language-class.pipe';
import { CurrentUser } from '../../classes/user.class';
import { Router } from '@angular/router';
import { FormGroup, FormArray } from '@angular/forms';
import { VlaremService } from '../../services/vlarem.service';

@Component({
  selector: 'app-product-basic-edit',
  templateUrl: './product-basic-edit.component.html',
  styleUrls: ['./product-basic-edit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductBasicEditComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  private _subs = new Subscription;

  @Input() product: Product;

  public productForm: ProductForm;
  public formOutput$ = new ReplaySubject<ProductForm>(1);
  public productFormValidated: boolean;
  public routerState: string;

  private user: CurrentUser;
  public plantIndex: number;
  public productIsInUseOnPlants: boolean;
  public disabledFields: string[] = [];

  public productfieldSuffixes$ = this.productService.productfieldSuffixes$;
  public productAutocomplete$ = this.productService.productAutoCompleteList$;
  public productOptionSelection$ = this.productService.productOptionSelections(this.languageClassPipe);

  @ViewChild(RecursiveFormComponent) private productFormFromElement: RecursiveFormComponent;

  // We need to translate the keys here because the dynamic form stands on its own
  public translatedKeys$ = combineLatest(
    this.userService.currentUser$,
    this.productService.productKeys$,
  ).pipe(filter(([user]) => user !== null),
    map(([user, keys]) => {

      // Set translations for form keys
      const translatedKeys: LanguageKeys = {};
      keys.forEach(x => {
        if (x[user.language]) {
          translatedKeys[x.key] = x[user.language];
        } else {
          translatedKeys[x.key] = x.key;
        }
      });
      return translatedKeys;
    }));

  public activeTranslatedDescriptions$ = combineLatest(
    this.userService.currentUser$,
    this.productService.productKeys$,
  ).pipe(filter(([user]) => user !== null),
    map(([user, keys]) => {

      // Set translations for form keys
      const translatedDescriptions: LanguageKeys = {};
      keys.forEach(x => {
        if (x.description && x.description.visible && x.description[user.language]) {
          translatedDescriptions[x.key] = x.description[user.language];
        }
      });
      return translatedDescriptions;
    }));


  public productFormValidators: Validator[];

  public async saveProduct() {
    const formOutput = await this.formOutput$.pipe(take(1)).toPromise();
    this.vlaremService.autoSetVlarem(this.product);

    const newForm = new ProductForm(undefined, formOutput);
    this.product.updateProductWithProductForm(newForm);

    await this.product.saveProduct();
    this.snackbar.snackbarSuccess('Product saved');
  }
  public async archiveProduct() {
    if (this.product.metaData.archived) {
      await this.product.unArchiveProduct();
      this.product.metaData.archived = false;
      this.product.metaData.dateArchived = '';
    } else {
      await this.product.archiveProduct();
      this.product.metaData.archived = true;
      this.product.metaData.dateArchived = new Date().toISOString();
    }
    this.changeDetector.detectChanges();
  }

  public async archiveProductOnPlant() {
    if (this.product.metaData.plants[this.plantIndex].metaDataPlant.archived) {
      await this.product.unArchivePlant(this.plantIndex);
      this.product.metaData.plants[this.plantIndex].metaDataPlant.archived = false;
      this.product.metaData.plants[this.plantIndex].metaDataPlant.dateArchived = '';
    } else {
      await this.product.archivePlant(this.plantIndex);
      this.product.metaData.plants[this.plantIndex].metaDataPlant.archived = true;
      this.product.metaData.plants[this.plantIndex].metaDataPlant.dateArchived = new Date().toISOString();

    }
    this.changeDetector.detectChanges();
  }

  constructor(
    private productService: ProductService,
    private userService: UsersService,
    private changeDetector: ChangeDetectorRef,
    private snackbar: SnackbarComponent,
    private validators: CustomValidators,
    private languageClassPipe: LanguageClassPipe,
    private router: Router,
    private vlaremService: VlaremService
  ) {
    let url = this.router.url;
    if (url.includes('?')) { url = url.split('?')[0]; }
    if (url.startsWith('/')) { url = url.substring(1); }
    this.routerState = url;
  }

  ngOnInit() {
    this._subs.add(
      this.userService.currentUser$.subscribe(user => {
        this.user = user;
        if (this.product && this.routerState === 'plant-products') {
          const indexPlant = this.product.metaData.plants.findIndex(x => x.metaDataPlant.plant === this.user.plant);
          if (indexPlant === -1) { throw new Error('Plant not found'); }
          this.plantIndex = indexPlant;
        }
      }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['product'] && changes['product'].currentValue) {
      const clonedProduct = cloneDeep(this.product);

      const acceptedProductFormValues = [...Object.values(clonedProduct.name), clonedProduct.supplier].filter(x => x);
      this.productFormValidators = this.validators.productFormValidators(
        this.formOutput$, this.productService.products$, acceptedProductFormValues
      );
      this.productForm = new ProductForm(clonedProduct);
      this.productIsInUseOnPlants = this.product.metaData.plants.some(x => !x.metaDataPlant.archived);

      if (this.user && this.routerState === 'plant-products') {
        const indexPlant = this.product.metaData.plants.findIndex(x => x.metaDataPlant.plant === this.user.plant);
        if (indexPlant === -1) { throw new Error('Plant not found'); }
        this.plantIndex = indexPlant;
      }
    }
  }

  attachVosSubscribers() {
    const form = this.productFormFromElement.form;
    const section9 = (form.controls['section9'] as FormGroup);
    const vosReg = section9.controls['vosRegistration'];
    const vosPercentage = section9.controls['vosPercentage'];

    const registrationValueChange = vosReg.valueChanges.subscribe(val => {
      if (val === 'yes') {
        vosPercentage.enable();
      } else {
        vosPercentage.disable();
        vosPercentage.setValue(0);
      }
    });

    const percentageValueChange = vosPercentage.valueChanges.subscribe(val => {
      if (val > 100) {
        vosPercentage.setValue(100);
      } else if (val < 0) {
        vosPercentage.setValue(0);
      }
    });

    this._subs.add(registrationValueChange);
    this._subs.add(percentageValueChange);
  }

  attachCasSubscribers() {
    const form = this.productFormFromElement.form;
    const section1 = (form.controls['section1'] as FormGroup);
    const section3 = (form.controls['section3'] as FormGroup);
    const casNummer = section1.controls['casNummer'];
    const productComponents = (section3.controls['productComponents'] as FormArray);

    const productComponentsChanged = productComponents.valueChanges.pipe(debounceTime(100)).subscribe((compontents: any[]) => {
      if (compontents.length > 0) {
        const disableCasNummer = compontents.some(comp => comp.casNummer > '');
        if (disableCasNummer) {
          casNummer.setValue('');
          casNummer.disable();
        } else {
          casNummer.enable();
        }
      }
    });

    this._subs.add(productComponentsChanged);
  }

  ngAfterViewInit() {

    this.attachVosSubscribers();
    this.attachCasSubscribers();

    this.productFormFromElement.validateForm();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

}
