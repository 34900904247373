import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LanguageClassServer, SevesoDbServer } from '../../../server/src/routes/product/types';
import { SendMessage, SuccessReturn } from '../../../server/src/types';
import { CmrDb } from '../classes/cmr.class';
import { HnumberDb, Pnumber } from '../classes/h-and-p.class';
import { Key } from '../classes/language.class';
import { LocationDb } from '../classes/location.class';
import { PbmOption } from '../classes/pbm.class';
import { ProductRequest } from '../classes/product-request.class';
import { UnSubstanceCategoryDb } from '../classes/un.class';
import { Zzs } from '../classes/zzs.class';
import { FireAuthService } from './fireAuth.service';
import { Pzzs } from "../classes/pzzs.class";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  public async setCustomClaims() {
    await this.http
      .get<SuccessReturn>("/setCustomClaims")
      .toPromise()
      .catch((error) => {
        throw new Error(error.error);
      });

    await this.fireAuth.refreshAuthToken().catch((error) => {
      throw new Error(error.error);
    });
    return;
  }

  public async translate(
    text: string | string[],
    fromLang: string,
    toLang: string
  ) {
    return this.http
      .post<string[]>("/translate", { text, fromLang, toLang })
      .toPromise()
      .catch((error) => {
        throw new Error(error.error);
      });
  }

  public sendErrorMail(body: SendMessage) {
    return this.http.post<SuccessReturn>(`/sendErrorReport`, body);
  }

  // Product
  updateProduct(
    formData: FormData,
    dbRef: string,
    fileCount: number,
    type: string
  ) {
    const params = { dbRef, fileCount: fileCount.toString(), type };
    return this.http
      .put<SuccessReturn>("/product/product", formData, { params })
      .toPromise();
  }

  archiveProduct(dbRef: string) {
    const params = { dbRef };
    return this.http
      .put<SuccessReturn>("/product/product-archive", {}, { params })
      .toPromise();
  }
  archivePlant(dbRef: string, plantIndex: number) {
    const params = { dbRef, plantIndex: plantIndex.toString() };
    return this.http
      .put<SuccessReturn>("/product/product-plant-archive", {}, { params })
      .toPromise();
  }
  unArchiveProduct(dbRef: string) {
    const params = { dbRef };
    return this.http
      .put<SuccessReturn>("/product/product-unarchive", {}, { params })
      .toPromise();
  }
  unArchivePlant(dbRef: string, plantIndex: number) {
    const params = { dbRef, plantIndex: plantIndex.toString() };
    return this.http
      .put<SuccessReturn>("/product/product-plant-unarchive", {}, { params })
      .toPromise();
  }

  productRequest(formData: FormData, dbRef: string, newProduct: boolean) {
    const params = { dbRef, newProduct: newProduct.toString() };
    return this.http
      .post<SuccessReturn>("/product/request", formData, { params })
      .toPromise();
  }
  approveRequest(dbRef: string, requestIndex: number) {
    const params = { dbRef, requestIndex: requestIndex.toString() };
    return this.http
      .post<SuccessReturn>("/product/approve-request", {}, { params })
      .toPromise();
  }
  denyRequest(dbRef: string, requestIndex: number, plant: string) {
    const params = { dbRef, requestIndex: requestIndex.toString(), plant };
    return this.http
      .post<SuccessReturn>("/product/deny-request", {}, { params })
      .toPromise();
  }
  productApproval(
    formData: FormData,
    dbRef: string,
    requestIndex: number,
    plant: string
  ) {
    const params = { dbRef, requestIndex: requestIndex.toString(), plant };
    return this.http
      .post<SuccessReturn>("/product/product-approval", formData, { params })
      .toPromise();
  }
  plantApproval(
    dbRef: string,
    requestIndex: number,
    plant: string,
    request: ProductRequest
  ) {
    const params = { dbRef, requestIndex: requestIndex.toString(), plant };
    return this.http
      .post<SuccessReturn>("/product/request-approval", { request }, { params })
      .toPromise();
  }

  // Pbm options
  updatePbmOptions(pbmOptions: PbmOption[]) {
    return this.http.put("/pbm/update", { pbmOptions }).toPromise();
  }
  deletePbmOption(id: string) {
    return this.http.delete("/pbm/delete", { params: { id } }).toPromise();
  }

  // Translations
  keys(keys: Key[], keyDocument: string) {
    return this.http.post("/keys/update", { keys, keyDocument }).toPromise();
  }
  translations(translations: { [prop: string]: LanguageClassServer[] }) {
    return this.http.post("/translations/update", { translations }).toPromise();
  }

  // Zzs
  addZzs(zzs: Zzs) {
    return this.http.post("/zzs/add", { zzs }).toPromise();
  }

  deleteZzs(dbRef: string) {
    const params = { dbRef };
    return this.http.delete("/zzs/delete", { params }).toPromise();
  }

  updateZzs(zzs: Zzs) {
    return this.http.put("/zzs/update", { zzs }).toPromise();
  }

  // Pzzs
  addPzzs(pzzs: Pzzs) {
    return this.http.post("/pzzs/add", { pzzs }).toPromise();
  }

  deletePzzs(dbRef: string) {
    const params = { dbRef };
    return this.http.delete("/pzzs/delete", { params }).toPromise();
  }

  updatePzzs(pzzs: Pzzs) {
    return this.http.put("/pzzs/update", { pzzs }).toPromise();
  }

  // Seveso
  addSeveso(seveso: SevesoDbServer) {
    return this.http.post("/seveso/add", { seveso }).toPromise();
  }

  deleteSeveso(dbRef: string) {
    const params = { dbRef };
    return this.http.delete("/seveso/delete", { params }).toPromise();
  }

  updateSeveso(seveso: SevesoDbServer) {
    return this.http.put("/seveso/update", { seveso }).toPromise();
  }

  // Un
  addUn(un: UnSubstanceCategoryDb) {
    return this.http.post("/un/add", { un }).toPromise();
  }

  deleteUn(dbRef: string) {
    const params = { dbRef };
    return this.http.delete("/un/delete", { params }).toPromise();
  }

  updateUn(un: UnSubstanceCategoryDb) {
    return this.http.put("/un/update", { un }).toPromise();
  }

  // Cmr
  addCmr(cmr: CmrDb) {
    return this.http.post("/cmr/add", { cmr }).toPromise();
  }

  deleteCmr(dbRef: string) {
    const params = { dbRef };
    return this.http.delete("/cmr/delete", { params }).toPromise();
  }

  updateCmr(cmr: CmrDb) {
    return this.http.put("/cmr/update", { cmr }).toPromise();
  }

  // Hnumbers
  addHnr(hNr: HnumberDb) {
    return this.http.post("/h-number/add", { hNr }).toPromise();
  }

  deleteHnr(code: string) {
    const params = { code };
    return this.http.delete("/h-number/delete", { params }).toPromise();
  }

  updateHnr(hNr: HnumberDb) {
    return this.http.put("/h-number/update", { hNr }).toPromise();
  }

  // Pnumbers
  addPnr(pNr: Pnumber) {
    return this.http.post("/p-number/add", { pNr }).toPromise();
  }

  deletePnr(code: string) {
    const params = { code };
    return this.http.delete("/p-number/delete", { params }).toPromise();
  }

  updatePnr(pNr: Pnumber) {
    return this.http.put("/p-number/update", { pNr }).toPromise();
  }

  // Location
  addLocation(location: LocationDb) {
    return this.http.post("/location/add", { location }).toPromise();
  }

  deleteLocation(dbRef: string) {
    const params = { dbRef };
    return this.http.delete("/location/delete", { params }).toPromise();
  }

  updateLocation(location: LocationDb) {
    return this.http.put("/location/update", { location }).toPromise();
  }

  updateLocationsAll(locations: LocationDb[]) {
    return this.http
      .put<SuccessReturn>("/location/update-all", { locations })
      .toPromise();
  }

  createNewUser(userAuth: firebase.User) {
    return this.http
      .put<SuccessReturn>("/user/create", { userAuth })
      .toPromise();
  }

  // User
  updateUserPlant(plant: string) {
    return this.http.put<SuccessReturn>("/user/plant/" + plant, {}).toPromise();
  }

  updateUserLanguage(language: string) {
    return this.http
      .put<SuccessReturn>("/user/language/" + language, {})
      .toPromise();
  }

  promoteUserToAdmin(email: string) {
    return this.http
      .put<SuccessReturn>("/user/promote-admin/" + email, {})
      .toPromise();
  }

  demoteUserToAdmin(email: string) {
    return this.http
      .delete<SuccessReturn>("/user/demote-user/" + email, {})
      .toPromise();
  }

  constructor(private http: HttpClient, private fireAuth: FireAuthService) {}
}
