import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentage'
})
export class PercentagePipe implements PipeTransform {

  transform(value: any): string | null {
    if (typeof value !== 'number') { return value; }

    const number = Math.round(value * 1000) / 1000;

    return `${number.toString()} %`;
  }

}
