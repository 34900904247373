import { Component, Input, OnInit } from '@angular/core';
import { CmrDb } from '../../classes/cmr.class';
import { ApiService } from '../../services/api.service';
import { CmrEditComponent } from './cmr-edit/cmr-edit.component';
import { NewEntry } from '../../_shared/_components/info-table/info-table.component';

@Component({
  selector: 'app-cmr-list',
  templateUrl: './cmr-list.component.html',
  styleUrls: ['./cmr-list.component.css']
})
export class CmrListComponent implements OnInit {

  public newEntry: NewEntry = { class: CmrDb, dependency: this.api };

  // Variables
  @Input() public cmrList: CmrDb[];

  public editComponent = CmrEditComponent;
  public infoRow: string[] = [];

  constructor(
    private api: ApiService
  ) { }

  ngOnInit() {
  }

}
