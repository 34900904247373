import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { UsersService } from '../../services/users.service';
import { SnackbarComponent } from '../../_shared/_components/snackbar/snackbar.component';

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.css']
})
export class UserTableComponent implements OnInit {

  private _subs = new Subscription;

  // Variables
  public dataSource: MatTableDataSource<string>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // Methods
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async addAdmin(row: string) {
    const res = await this.api.promoteUserToAdmin(row).catch(() => void 0);
    if (!res) { this.snackbar.snackbarError('Something went wrong'); }
    this.snackbar.snackbarSuccess('Admin created');
  }

  async deleteAdmin(row: string) {
    const res = await this.api.demoteUserToAdmin(row).catch(() => void 0);
    if (!res) { this.snackbar.snackbarError('Something went wrong'); }
    this.snackbar.snackbarSuccess('Admin removed');
  }

  // Life cycle
  constructor(
    private api: ApiService,
    private snackbar: SnackbarComponent,
    private userService: UsersService
  ) {
    this.dataSource = new MatTableDataSource([]);

    this.dataSource.filterPredicate = (data, filterValue) =>
      data.trim().toLowerCase().indexOf(filterValue) !== -1;
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator.pageSize = 50;

    const adminsSub = this.userService.admins$.subscribe(adminList => {
      if (!adminList) { this.dataSource.data = []; }
      this.dataSource.data = adminList;
    });

    this._subs.add(adminsSub);
  }

  ngOndestroy() {
    this._subs.unsubscribe();
  }

}

