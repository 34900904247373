import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Product } from '../../classes/product.class';
import { SnackbarComponent } from '../../_shared/_components/snackbar/snackbar.component';

@Component({
  selector: 'app-product-image-edit',
  templateUrl: './product-image-edit.component.html',
  styleUrls: ['./product-image-edit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductImageEditComponent implements OnInit {

  @Input() product: Product;

  public newImage: File | null;
  public ImageToRemove: File | null;

  public async saveProduct() {
    if (!this.newImage) { this.snackbar.snackbarError('No new image found'); }
    await this.product.saveProduct(undefined, this.newImage);
    this.newImage = null;
    this.changeDetector.detectChanges();
    this.snackbar.snackbarSuccess('Image saved');
  }

  public async deleteImage() {
    this.product.metaData.image = '';
    await this.product.saveProduct();
  }

  constructor(
    private snackbar: SnackbarComponent,
    private changeDetector: ChangeDetectorRef,
  ) { }

  ngOnInit() { }

}
