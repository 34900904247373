import { PzzsDbServer } from "../../../server/src/routes/product/types";
import { ApiService } from "../services/api.service";

export class PzzsDb implements PzzsDbServer {
  casNummer = "";
  datumToevoeging: string | Date = "";
  egNummer = "";
  emissiegrenswaarde = "";
  engelseStofnaam = "";
  grensmassastroom = "";
  nederlandsStofnaam = "";
  stofklasseVoorLuchtemissies = "";
  voetnoot1 = "";
  voetnoot2 = "";
  voetnoot3 = "";
  voetnoot4 = "";
  pzzsVolgensEuGevaarsindeling: "";
  pzzsVolgensEuPopVerordening: "";
  pzzsVolgensKrw: "";
  pzzsVolgensOspar: "";
  pzzsVolgensReach: "";
  dbRef = "";

  constructor(input: PzzsDb | undefined) {
    if (input) {
      Object.keys(input).forEach((key) => {
        switch (key) {
          case "datumToevoeging":
            this[key] = new Date(input[key]);
            break;
          default:
            if (this[key] !== undefined) {
              this[key] = input[key];
            }
        }
      });
    }
  }
}
export class Pzzs extends PzzsDb {
  static api: ApiService;

  datumToevoeging = new Date("");

  public updateFromForm(pzzsForm: Pzzs) {
    Object.keys(pzzsForm).forEach((key) => {
      if (this[key] !== undefined) {
        this[key] = pzzsForm[key];
      }
    });
  }

  public savePzzs() {
    if (!this.dbRef) {
      return Pzzs.api.addPzzs(this);
    }
    return Pzzs.api.updatePzzs(this);
  }

  public deletePzzs() {
    if (!this.dbRef) {
      throw new Error("No dbRef present");
    }
    return Pzzs.api.deletePzzs(this.dbRef);
  }

  constructor(api: ApiService, input?: PzzsDb) {
    super(input);

    if (input && input.datumToevoeging) {
      this.datumToevoeging = new Date(input.datumToevoeging);
    }

    Pzzs.api = api;
  }
}
