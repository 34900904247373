// tslint:disable: max-line-length
import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appSvgState]'
})
export class SvgStateDirective implements OnInit, OnChanges {

  @Input() appSvgState: string;

  private svgStyle: string;

  gas: string;
  liquid: string;
  solid: string;

  public loadSVG() {

    const element = this.elementRef.nativeElement;

    this.svgStyle = `
      display: flex;
      flex-flow: column;
      justify-content: space-around;
      align-items: center;
      width: inherit;
      height: inherit;
      padding-left: 7px;
      padding-right: 7px;
    `;

    this.gas = `<svg style="${this.svgStyle}" class="state-svg-color" id="gas" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="260.576px" height="260.576px" viewBox="0 0 260.576 260.576" style="enable-background:new 0 0 260.576 260.576;"
      xml:space="preserve">
      <g>
        <g>
          <path d="M30.808,65.876C13.81,65.93,0,79.772,0,96.781V206.15c0,17.042,13.868,30.915,30.907,30.915h109.377
            c17.042,0,30.91-13.873,30.91-30.915V96.786c0-17.009-13.817-30.851-30.809-30.902V43.206
            c45.763,2.539,64.404,57.932,110.405,66.445c9.831,1.818,14.025-13.213,4.144-15.044c-48.159-8.909-63.972-65.259-114.549-67.072
            V3.479h-44.1v7.8c0,5.898-4.796,10.691-10.687,10.691s-10.687-4.793-10.687-10.691v-7.8h-44.1v62.398H30.808z M155.589,96.786
            v109.375c0,8.439-6.865,15.305-15.305,15.305H30.902c-8.44,0-15.308-6.865-15.308-15.305V96.786c0-8.439,6.868-15.31,15.308-15.31
            h109.382C148.724,81.471,155.589,88.347,155.589,96.786z M46.408,19.078h14.081c3.334,10.702,13.322,18.491,25.106,18.491
            c11.783,0,21.776-7.79,25.105-18.491h14.081v44.485H46.408V19.078z"/>
          <path d="M27.825,241.498c-4.311,0-7.799,3.494-7.799,7.8c0,4.302,3.488,7.8,7.799,7.8h115.537c4.316,0,7.8-3.498,7.8-7.8
            c0-4.306-3.483-7.8-7.8-7.8H27.825z"/>
        </g>
      </g>
    </svg>`;

    this.liquid = `<svg style="${this.svgStyle}" class="state-svg-color" id="liquid" viewBox="0 0 16 16">
      <g>
          <path d="M8 0c0 0-5 8.2-5 11s2.2 5 5 5 5-2.2 5-5-5-11-5-11zM8.9 14.9l-0.2-1c1.4-0.3 2.4-1.7 2.4-3.2 0-0.3-0.1-1.1-0.8-2.6l0.9-0.4c0.6 1.4 0.8 2.4 0.8 3 0 2-1.3 3.8-3.1 4.2z"></path>
      </g>
    </svg>`;

    this.solid = `<svg style="${this.svgStyle}" class="state-svg-color" id="solid" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="380.999px" height="380.999px" viewBox="0 0 380.999 380.999" style="enable-background:new 0 0 380.999 380.999;"
      xml:space="preserve">
      <g>
        <g>
          <g>
            <path d="M315.018,71.913C312.952,30.916,259.42,0,190.497,0C121.605,0,68.095,30.916,66.029,71.913l-0.054,242.397
              c0,0,10.969,66.688,124.521,66.688c113.551,0,124.52-66.688,124.52-66.688l0.008-242.122L315.018,71.913z M259.243,333.276
              c-18.095,9.7-42.509,15.045-68.746,15.045s-50.651-5.344-68.745-15.044c-14.438-7.743-23.057-17.689-23.057-26.607
              c0-8.919,8.619-18.865,23.057-26.604c18.09-9.701,42.504-15.046,68.745-15.046s50.654,5.345,68.746,15.048
              c14.438,7.737,23.058,17.686,23.058,26.604C282.297,315.588,273.678,325.534,259.243,333.276z M121.752,47.724
              c18.094-9.702,42.508-15.045,68.745-15.045s50.651,5.343,68.746,15.044C273.681,55.466,282.3,65.412,282.3,74.33
              c0,8.919-8.619,18.865-23.058,26.605c-18.091,9.702-42.505,15.046-68.746,15.046s-50.654-5.344-68.746-15.047
              c-14.437-7.739-23.056-17.686-23.056-26.604C98.696,65.412,107.315,55.466,121.752,47.724z M283.088,124.704v131.592
              c-23.217-15.323-56.228-23.956-92.591-23.956c-36.365,0-69.376,8.633-92.593,23.957V124.703
              c23.217,15.324,56.228,23.957,92.593,23.957S259.872,140.027,283.088,124.704z"/>
          </g>
        </g>
      </g>
    </svg>`;

    if (this[this.appSvgState]) {
      element.innerHTML = this[this.appSvgState];
    } else { }


  }

  // Life cycle
  constructor(
    private elementRef: ElementRef<HTMLElement>,
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['appSvgState'] && changes['appSvgState'].currentValue !== changes['appSvgState'].previousValue) {
      this.loadSVG();
    }
  }

}
