import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { LanguageClass } from '../../../classes/language.class';
import { Location } from '../../../classes/location.class';
import { Product } from '../../../classes/product.class';
import { ApiService } from '../../../services/api.service';
import { UsersService } from '../../../services/users.service';
// import { UsersService } from '../../../services/users.service';
// import { LanguageClassPipe } from '../../_pipes/language-class.pipe';

@Injectable({
  providedIn: 'root'
})
export class ProductTableService {

  private userLanguage: keyof LanguageClass;

  private rowDataSource = new ReplaySubject<any>(1);
  private productFormRowSource = new ReplaySubject<Product>(1);
  private activeProductSource = new ReplaySubject<Product>(1);
  private selectedLocationSource = new BehaviorSubject<Location>(new Location(this.api));

  public rowData$ = this.rowDataSource.asObservable();
  public productFormRow$ = this.productFormRowSource.asObservable();
  public selectedLocation$ = this.selectedLocationSource.asObservable();

  public activeProduct$ = this.activeProductSource.asObservable();

  public setRowData(rowData: any) {
    this.rowDataSource.next(rowData);
  }

  public setProductRow(product: Product) {
    this.productFormRowSource.next(product);
  }

  public setActiveProduct(product: Product) {
    this.activeProductSource.next(product);
  }

  public setSelectedLocation(location: Location) {
    this.selectedLocationSource.next(location);
  }

  public sortingMethod = (item: any, property: string) => {
    if (item[property]) {
      switch (item[property].constructor) {

        case LanguageClass: {
          const itemLanguage = item[property] as LanguageClass;
          const text: string = itemLanguage[this.userLanguage] || itemLanguage['english'] || Object.values(itemLanguage).find(x => x) || '';
          return text.toLowerCase();
        }

        case Date: return item[property];

        default: {
          if (Array.isArray(item[property]) && Array.isArray(item[property])[0]) { return item[property][0].toLowerCase(); }
          else if (typeof item[property] === 'string') { return item[property].toLowerCase(); }
          else { return ''; }
        }
      }
    } else { return ''; }
  }

  public filterMethod(data: any, _filter: string) {
    const columnsToBeSearched = ['name', 'formule', 'casNummer', 'egNummer', 'un', 'engelseStofnaam', 'nederlandsStofnaam', 'vibNr'];

    const componentsCasNumbers = (components: { casNummer: string }[]) => {
      let str = '';
      if (components.length > 0) {
        components.forEach(component => str += component.casNummer + '◬');
      }
      return str;
    };

    const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
      if (key === 'productComponents') {
        currentTerm += componentsCasNumbers(data[key]);
      }
      // Custom edit
      if (!columnsToBeSearched.includes(key)) { return currentTerm; }
      if (data[key] instanceof LanguageClass) {
        Object.values(data[key]).forEach(x => { if (x) { currentTerm += x + '◬'; } });
      }
      // Default from material
      else { currentTerm += (data as { [key: string]: any })[key] + '◬'; }

      return currentTerm;
    }, '').toLowerCase();
    const transformedFilter = _filter.trim().toLowerCase();

    return dataStr.indexOf(transformedFilter) !== -1;
  }

  constructor(
    private userService: UsersService,
    private api: ApiService
  ) {

    this.userService.currentUser$.subscribe(user => {
      this.userLanguage = user.language;
    });
  }
}
