import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, OnInit, ViewChild, Input, SimpleChanges, OnChanges } from '@angular/core';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Note } from '../../classes/product-plant.class';
import { ProductService } from '../../services/product.service';
import { UsersService } from '../../services/users.service';
import { Product } from '../../classes/product.class';

@Component({
  selector: 'app-product-notes',
  templateUrl: './product-notes.component.html',
  styleUrls: ['./product-notes.component.css']
})
export class ProductNotesComponent implements OnInit, OnChanges {

  @Input() public product: Product;

  private product$ = new ReplaySubject<Product>(1);
  public productNotes$: Observable<Note[]>;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  constructor(
    private productsService: ProductService,
    private userService: UsersService
  ) { }

  ngOnInit() {
    this.productNotes$ = combineLatest(
      this.product$,
      this.userService.currentUser$,
      this.productsService.products$
    ).pipe(
      map(([product, user, products]) => {
        const findProduct = products.find(x => x.metaData.dbRef === product.metaData.dbRef);

        if (!findProduct || !user) {
          return [];
        }

        const plant = findProduct.metaData.plants.find(x => x.metaDataPlant.plant === user.plant);
        if (!plant) {
          return [];
        }

        const notes = plant.metaDataPlant.notes;

        return notes;
      }),
      take(1)
    );
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['product'] && changes['product'].currentValue) {
      this.product$.next(this.product);
    }

  }

}
