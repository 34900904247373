import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { Subscription } from "rxjs";
import {
  DialogComponent,
  DialogContent,
} from "src/app/_shared/_components/dialog/dialog.component";
import { InfoTableService } from "src/app/_shared/_components/info-table/info-table.service";
import { SnackbarComponent } from "src/app/_shared/_components/snackbar/snackbar.component";
import { Pzzs } from "../../../classes/pzzs.class";

@Component({
  selector: "app-pzzs-edit",
  templateUrl: "./pzzs-edit.component.html",
  styleUrls: ["./pzzs-edit.component.css"],
})
export class PzzsEditComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  // Variables
  public pzzs: Pzzs;
  public pzzsvalidated = false;
  public pzzsForm: Pzzs;
  public pzzsFormOutput: Pzzs;

  // Methods
  public pzzsValidate(validated: boolean) {
    setTimeout(() => (this.pzzsvalidated = validated));
  }

  public closeDialog() {
    this.matDialog.closeAll();
  }

  public async saveData() {
    this.pzzs.updateFromForm(this.pzzsFormOutput);
    await this.pzzs.savePzzs();
    this.snackBar.snackbarSuccess("Pzzs saved");
  }

  public deleteEntry() {
    const data: DialogContent = {
      dialogData: {
        title: "Confirm",
        body: `Are you sure you want to delete this entry?`,
        button: "Delete",
        button2: "Cancel",
      },
    };
    const dialog = this.matDialog.open(DialogComponent, {
      data,
      disableClose: true,
    });

    dialog.afterClosed().subscribe(async (response) => {
      if (!response) {
        return;
      }

      await this.pzzs.deletePzzs();
      this.snackBar.snackbarSuccess("Pzzs deleted");
      this.matDialog.closeAll();
    });
  }

  // Life cycle methods
  private subscribeToObservables() {
    const rowDataSub = this.infoTableService.rowData$.subscribe((pzzs) => {
      this.pzzs = pzzs;
      this.pzzsForm = { ...pzzs };
    });

    this.subscriptions.add(rowDataSub);
  }

  // Life cycle
  constructor(
    private infoTableService: InfoTableService,
    private matDialog: MatDialog,
    private snackBar: SnackbarComponent
  ) {}

  ngOnInit() {
    this.subscribeToObservables();
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
