import { HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FireAuthService } from '../../services/fireAuth.service';
import { Observable, from } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable()
export class FirebaseAuthInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Use only on requests to cloud functions / app-engine
    if (req.url.startsWith('http')) { return next.handle(req); }

    // Get the auth token (promise) from the service and turn into an observable
    return from(this.fireAuth.getAuthToken()).pipe(
      take(1),
      switchMap(token => {
        // Clone the request and replace the original headers with
        // cloned headers, updated with the authorization.
        const authReq = req.clone({
          headers: req.headers.set('x-token', token)
        });
        // send cloned request with header to the next handler.
        return next.handle(authReq);
      }));
  }

  constructor(
    private fireAuth: FireAuthService
  ) { }

}

export const FirebaseAuthInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: FirebaseAuthInterceptor,
  multi: true,
};
