import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { UserDb } from '../classes/user.class';
import { SnackbarComponent } from '../_shared/_components/snackbar/snackbar.component';
import { Product } from '../classes/product.class';
import { PbmOptions } from '../classes/pbm.class';
import { Zzs } from '../classes/zzs.class';
import { UnSubstanceCategoryDb } from '../classes/un.class';
import { CmrDb } from '../classes/cmr.class';
import { Hnumber, Pnumber } from '../classes/h-and-p.class';
import { DatabaseService } from '../services/database.service';
import { Key, LanguageClass, TranslationDb, Translations } from '../classes/language.class';
import { SevesoDb } from '../classes/seveso.class';
import { ApiService } from '../services/api.service';
import { ProductService, KeyPath } from '../services/product.service';
import { Location } from '../classes/location.class';
import { Pzzs } from "../classes/pzzs.class";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.css"],
})
export class AdminComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  // Variables
  public activeRequestCounter: number;
  public products: Product[];
  public users: UserDb[];
  public locations: Location[];

  public productKeys: Key[];
  public plantKeys: Key[];
  public requestFormKeys: Key[];
  public vlaremKeys: Key[];
  public sevesoKeys: Key[];
  public riskKeys: Key[];
  public pbmKeys: Key[];
  public requestKeys: Key[];
  public uiKeys: Key[];
  public ghsKeys: Key[];
  public adrKeys: Key[];
  public hazardKeys: Key[];
  public classificationKeys: Key[];
  public dropdownKeys: Key[];

  public pbmOptionsTranslated: { [prop: string]: LanguageClass[] };
  public pbmOptions: PbmOptions;

  public zzsList: Zzs[];
  public pzzsList: Pzzs[];
  public sevesoList: SevesoDb[];
  public unList: UnSubstanceCategoryDb[];
  public cmrList: CmrDb[];
  public hNumberList: Hnumber[];
  public pNumberList: Pnumber[];

  public translationList: Translations;

  // Methods
  public activeRequestCount(count: number) {
    this.activeRequestCounter = count;
  }

  public async updateKeys(keys: Key[], path: KeyPath) {
    await this.api.keys(keys, path);
    this.snackbarComponent.snackbarSuccess("Document updated");
  }
  public async updateTranslations(translations: TranslationDb) {
    await this.api.translations(translations);
    this.snackbarComponent.snackbarSuccess("Document updated");
  }
  public async updataPbmOptions(_pbmOptions: PbmOptions) {
    // await pbmOptions.savePbmOptions();
    console.warn("Update pbm does nothing");
    this.snackbarComponent.snackbarSuccess("Document updated");
  }

  private subscribeToObservables() {
    const productsSub = this.productService.products$
      .pipe(filter((x) => !!x && x.length > 0))
      .subscribe((products) => {
        this.products = products;
      });

    const productKeysSub = this.productService.keys$
      .pipe(take(1))
      .subscribe((keys) => {
        this.productKeys = keys["product"];
        this.plantKeys = keys["plant"];
        this.riskKeys = keys["risk"];
        this.pbmKeys = keys["pbm"];
        this.vlaremKeys = keys["vlarem"];
        this.sevesoKeys = keys["seveso"];
        this.requestKeys = keys["request"];
        this.uiKeys = keys["ui"];
        this.ghsKeys = keys["ghs"];
        this.adrKeys = keys["adr"];
        this.hazardKeys = keys["hazard"];
        this.classificationKeys = keys["classifications"];
        this.dropdownKeys = keys["dropdown"];
      });

    const zzsListSub = this.productService.zzsList$
      .pipe(filter((x) => !!x))
      .subscribe((zzsList) => {
        this.zzsList = zzsList;
      });
    const pzzsListSub = this.productService.pzzsList$
      .pipe(filter((x) => !!x))
      .subscribe((pzzsList) => {
        this.pzzsList = pzzsList;
      });
    const sevesoListSub = this.databaseService.sevesos$
      .pipe(filter((x) => !!x))
      .subscribe((sevesoList) => {
        this.sevesoList = sevesoList;
      });
    const unSubstanceCategorySub = this.databaseService.unSubstanceCategories$
      .pipe(filter((x) => !!x))
      .subscribe((unList) => {
        this.unList = unList;
      });
    const cmrListSub = this.databaseService.cmrs$
      .pipe(filter((x) => !!x))
      .subscribe((cmrList) => {
        this.cmrList = cmrList;
      });
    const hNumberListSub = this.productService.hNumbers$
      .pipe(filter((x) => !!x))
      .subscribe((hNumberList) => {
        this.hNumberList = hNumberList;
      });
    const pNumberListSub = this.productService.pNumbers$
      .pipe(filter((x) => !!x))
      .subscribe((pNumberList) => {
        this.pNumberList = pNumberList;
      });
    const locationsListSub = this.productService.locations$
      .pipe(filter((x) => !!x))
      .subscribe((locations) => {
        this.locations = locations;
      });
    const translationsListSub = this.productService.translations$
      .pipe(filter((x) => !!x))
      .subscribe((translations) => {
        this.translationList = translations;
      });

    this.subscriptions.add(productsSub);
    this.subscriptions.add(productKeysSub);
    this.subscriptions.add(zzsListSub);
    this.subscriptions.add(pzzsListSub);
    this.subscriptions.add(sevesoListSub);
    this.subscriptions.add(unSubstanceCategorySub);
    this.subscriptions.add(cmrListSub);
    this.subscriptions.add(pNumberListSub);
    this.subscriptions.add(hNumberListSub);
    this.subscriptions.add(locationsListSub);
    this.subscriptions.add(translationsListSub);
  }

  // Life cycle
  constructor(
    private databaseService: DatabaseService,
    private productService: ProductService,
    private api: ApiService,
    private snackbarComponent: SnackbarComponent
  ) {}

  ngOnInit() {
    this.subscribeToObservables();
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
