import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Subscription } from "rxjs";
import { ApiService } from "../../services/api.service";
import { ProductService } from "../../services/product.service";
import { Key } from "../../classes/language.class";
import { NewEntry } from "../../_shared/_components/info-table/info-table.component";
import { Pzzs } from "../../classes/pzzs.class";
import { PzzsEditComponent } from "./pzzs-edit/pzzs-edit.component";

@Component({
  selector: "app-pzzs-list",
  templateUrl: "./pzzs-list.component.html",
  styleUrls: ["./pzzs-list.component.css"],
})
export class PzzsListComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  // Variables
  public newEntry: NewEntry = { class: Pzzs, dependency: this.api };
  @Input() public pzzsList: Pzzs[];

  public editComponent = PzzsEditComponent;
  public infoRow = ["voetnoot1", "voetnoot2", "voetnoot3", "voetnoot4"];
  public pzzsTableKeys: Key[];

  @Output() private updatedKeys = new EventEmitter<Key[]>();

  public updatePzzsKeys(keys: Key[]) {
    this.updatedKeys.emit(keys);
  }

  constructor(
    private api: ApiService,
    private productService: ProductService
  ) {}

  ngOnInit() {
    const keysSub = this.productService.keys$.subscribe((keys) => {
      this.pzzsTableKeys = keys["pzzs"];
    });

    this.subscriptions.add(keysSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
