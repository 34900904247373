import { ComponentType } from "@angular/cdk/portal";
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { KeyDb } from "../../../classes/language.class";
import { ProductService } from "../../../services/product.service";
import { TableDataRowInput } from "../product-table/product-table.component";

@Component({
  selector: "app-pzzs-table",
  templateUrl: "./pzzs-table.component.html",
  styleUrls: ["./pzzs-table.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PzzsTableComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  public pzzsList$ = this.productService.pzzsList$.pipe(
    map((pzzsList) => {
      return pzzsList.map((pzzs) => {
        const expandedDetail: TableDataRowInput["expandedDetail"] = {};
        // Voetnoten
        if (pzzs.voetnoot1) {
          expandedDetail["Voetnoot 1:"] = pzzs.voetnoot1;
        }
        if (pzzs.voetnoot2) {
          expandedDetail["Voetnoot 2:"] = pzzs.voetnoot2;
        }
        if (pzzs.voetnoot3) {
          expandedDetail["Voetnoot 3:"] = pzzs.voetnoot3;
        }
        if (pzzs.voetnoot4) {
          expandedDetail["Voetnoot 4:"] = pzzs.voetnoot4;
        }

        return { ...pzzs, expandedDetail };
      });
    })
  );
  public pzzsKeys$ = this.productService.activePzzsKeys$;

  @Input() public showPlantSelect: boolean;
  @Input() public rowClickComponent: ComponentType<any>;
  @Input() public showFooter: boolean;
  @Input() public expandedDetail: boolean;

  public tableKeys: KeyDb[];
  public tableData: TableDataRowInput[];

  constructor(private productService: ProductService) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
