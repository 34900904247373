import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { ReplaySubject, Subscription } from 'rxjs';

interface AuthResponse {
  access_token: string;
  id_token: string;
  login_hint: string;
  scope: string;
  expires_in: number;
  first_issued_at: number;
  expires_at: number;
}

@Injectable({
  providedIn: 'root',
})
export class FireAuthService {

  private subscriptions = new Subscription;

  // Observables
  private userSource = new ReplaySubject<firebase.User | null>(1);

  /**
   * The firebase user
   */
  public user$ = this.userSource.asObservable();

  private _user: firebase.User | null;

  /**
   * Sign-in to firebase project with the user from Gapi Google Sign-in.
   *
   * Set the client ID from Gapi as whitelist in Firebase Console Google Loginprovider settings.
   * @param authResponse from gapi login.
   */
  public signInWithGoogleSignInToken(authResponse: AuthResponse) {

    const credential = auth.GoogleAuthProvider.credential(
      authResponse.id_token,
      authResponse.access_token
    );

    return this.firebaseAuth.auth.signInAndRetrieveDataWithCredential(credential);
  }

  /**
   * Sign in with social media and popup
   */
  public async signInWithGoogle() {

    const provider = new auth.GoogleAuthProvider().setCustomParameters({ prompt: 'select_account' });

    return this.firebaseAuth.auth.signInWithRedirect(provider)
      .catch(error => { throw new Error(error); });
  }

  /**
   * Logout firebase
   */
  public async signOut() {
    return this.firebaseAuth.auth.signOut()
      .catch(error => { throw new Error(error); });
  }

  /**
 * Get the user's firebase auth token.
 */
  public async getAuthToken() {
    if (!this._user) { return Promise.reject('User object not found in fireAuth service'); }
    return this._user.getIdToken();
  }
  /**
  * Get the user's firebase auth token payload.
  */
  public async getTokenPayload() {
    if (!this._user) { return Promise.reject('User object not found in fireAuth service'); }
    return this._user.getIdTokenResult();
  }
  /**
 * Refresh the user's firebase auth token.
 */
  public async refreshAuthToken() {
    if (!this._user) { return Promise.reject('User object not found in fireAuth service'); }
    return this._user.getIdToken(true);
  }

  private initializeObservables() {

    const userAuthSub = this.firebaseAuth.authState.subscribe(async userAuth => {
      this._user = userAuth;
      if (!userAuth) { this.userSource.next(null); } else {
        this.userSource.next(userAuth);
      }
    });

    this.subscriptions.add(userAuthSub);
  }

  constructor(
    private firebaseAuth: AngularFireAuth,
  ) {
    this.initializeObservables();
  }

}
