import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UsersService } from '../services/users.service';
import { ProductTableService } from '../_shared/_components/product-table/product-table.service';

@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.css']
})
export class ProductInfoComponent implements OnInit, OnDestroy {

  private _subs = new Subscription;

  public product$ = this.productTableService.activeProduct$;
  public editable: boolean;
  public routerState: string;

  // Methods
  public closeDialog() {
    this.matDialog.closeAll();
  }

  // Life cycle
  constructor(
    private matDialog: MatDialog,
    private productTableService: ProductTableService,
    private userService: UsersService,
    private router: Router
  ) { }

  ngOnInit() {
    const userSub = this.userService.currentUser$.subscribe(user => {
      if (!user) { this.editable = false; return; }
      this.editable = user.isAdmin;
    });

    let url = this.router.url;
    if (url.includes('?')) { url = url.split('?')[0]; }
    if (url.startsWith('/')) { url = url.substring(1); }
    this.routerState = url;

    this._subs.add(userSub);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

}
