import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from '../../../classes/product.class';

@Component({
  selector: 'app-edit-product-image',
  templateUrl: './edit-product-image.component.html',
  styleUrls: ['./edit-product-image.component.css']
})
export class EditProductImageComponent implements OnInit {

  // Variables
  @Input() public imageLink: string;
  @Input() public disabled: boolean;
  @Input() public product?: Product;
  @Input() public disableSave?: boolean;

  @Output() public image = new EventEmitter<File | null>(true);
  @Output() public imageDelete = new EventEmitter<null>();

  // Methods
  public async emitImage(file?: File) {
    if (!file) { this.image.emit(null); return; }
    this.image.emit(file);
  }

  public deleteImage() {
    if (this.imageLink) {
      this.imageDelete.emit();
    }
  }

  constructor() { }

  ngOnInit() { }

}
