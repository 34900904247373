import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../_shared/shared.module';
import { ProductBasicEditComponent } from './product-basic-edit/product-basic-edit.component';
import { ProductEditComponent } from './product-edit.component';
import { ProductImageEditComponent } from './product-image-edit/product-image-edit.component';
import { PbmCategoryLanguagePipe } from './product-pbm-edit/pbm-category-language.pipe';
import { ProductPbmEditComponent } from './product-pbm-edit/product-pbm-edit.component';
import { ProductNotesEditComponent } from './product-notes-edit/product-notes-edit.component';
import { ProductPlantEditComponent } from './product-plant-edit/product-plant-edit.component';
import { ProductRiskEditComponent } from './product-risk-edit/product-risk-edit.component';
import { OrderPbmPipe } from './product-pbm-edit/order-pbm.pipe';
import { OrderByPlantNamePipe } from './product-pbm-edit/order-by-plant-name.pipe';
import { ProductVlaremComponent } from './product-vlarem/product-vlarem.component';

@NgModule({
  declarations: [
    ProductEditComponent,
    ProductBasicEditComponent,
    PbmCategoryLanguagePipe,
    ProductImageEditComponent,
    ProductPbmEditComponent,
    ProductNotesEditComponent,
    ProductPlantEditComponent,
    ProductRiskEditComponent,
    OrderPbmPipe,
    OrderByPlantNamePipe,
    ProductVlaremComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
  entryComponents: [ProductEditComponent]
})
export class ProductEditModule { }
