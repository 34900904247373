import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';
import { take } from 'rxjs/operators';
import { ProductPlant } from '../../classes/product-plant.class';
import { PlantNamePipe } from '../../_shared/_pipes/plant-name.pipe';

@Pipe({
  name: 'orderByPlantName'
})
export class OrderByPlantNamePipe implements PipeTransform {

  constructor(
    private plantNamePipe: PlantNamePipe
  ) { }

  async transform(array: ProductPlant[]): Promise<ProductPlant[]> {
    const fillWithNames = await Promise.all(
      array.map(async x => {
        const newArray = x as ProductPlant & { tempName?: string };
        newArray.tempName = await this.plantNamePipe.transform(x.metaDataPlant.plant).pipe(take(1)).toPromise();
        return newArray;
      })
    );

    const ordered = orderBy(fillWithNames, 'tempName');
    return ordered.map(x => {
      delete x.tempName;
      return x;
    });
  }

}
