import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ZzsEditComponent } from './zzs-edit/zzs-edit.component';
import { Zzs } from '../../classes/zzs.class';
import { ApiService } from '../../services/api.service';
import { ProductService } from '../../services/product.service';
import { Key } from '../../classes/language.class';
import { NewEntry } from '../../_shared/_components/info-table/info-table.component';

@Component({
  selector: 'app-zzs-list',
  templateUrl: './zzs-list.component.html',
  styleUrls: ['./zzs-list.component.css']
})
export class ZzsListComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription;

  // Variables
  public newEntry: NewEntry = { class: Zzs, dependency: this.api };
  @Input() public zzsList: Zzs[];

  public editComponent = ZzsEditComponent;
  public infoRow = ['voetnoot1', 'voetnoot2', 'voetnoot3', 'voetnoot4'];
  public zzsTableKeys: Key[];

  @Output() private updatedKeys = new EventEmitter<Key[]>();

  public updateZzsKeys(keys: Key[]) { this.updatedKeys.emit(keys); }

  constructor(
    private api: ApiService,
    private productService: ProductService
  ) { }

  ngOnInit() {
    const keysSub = this.productService.keys$.subscribe(keys => {
      this.zzsTableKeys = keys['zzs'];
    });

    this.subscriptions.add(keysSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
