import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InfoTableService {

  private rowDataSource = new ReplaySubject<any>(1);

  public rowData$ = this.rowDataSource.asObservable();

  public setRowData(rowData: any) {
    this.rowDataSource.next(rowData);
  }

  constructor() { }
}
