import { Injectable } from '@angular/core';
import { shareReplay, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { DatabaseService } from './database.service';
import { UsefulLinksPerSection } from '../classes/useful-links.class';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class UsefulLinksService {

  constructor(
    private database: DatabaseService,
    private userService: UsersService
  ) { }

  public usefulLinksSections$ = this.database.usefulLinksSections$;
  public usefulLinksLinks$ = this.database.usefulLinksLinks$;

  public usefulLinksPerSection$ = combineLatest(
    this.usefulLinksSections$,
    this.usefulLinksLinks$
  ).pipe(
    map(([sections, links]) => {
      const linksPerSection: UsefulLinksPerSection[] = [];

      if (sections && links) {
        for (const section in sections) {
          if (sections[section] && links[section]) {
            linksPerSection.push(
              new UsefulLinksPerSection(sections[section], links[section])
            );
          }
        }
      }

      return linksPerSection;
    }),
    shareReplay(1)
  );

  public usefulLinksPerSectionForLanguage$ = combineLatest(
    this.usefulLinksPerSection$,
    this.userService.currentUser$
  ).pipe(
    map(([sections, user]) => {
      return sections.map(section => {
        // section title for current user language
        section.activeLanguage = section[user.language];
        section.links = section.links.map(link => {
          // parsed link for current user language
          link.activeLanguage = link.parsedLink[user.language];

          return link;
        });

        return section;
      });
    }),
    shareReplay(1)
  );

}
