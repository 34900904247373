import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatStep, MatStepper } from '@angular/material';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { arrayLengthValidator, optionsValidator, zeroNumberValidator } from './custom.validator';
import { LanguageKeys } from './language-key.pipe';
import { RecursiveFormService } from './recursive-form.service';
import { cloneAbstractControl, defaultValueForTypeOfControl } from './util';

export interface Validator {
  keyName: string;
  parentKey?: string;
  validators?: ValidatorFn[];
  validatorsAsync?: AsyncValidatorFn[];
  error: string;
  message: string;
}
export interface AutoCompleteList {
  [prop: string]: string[];
}
export interface OptionsSelection {
  [prop: string]: { optionValue: string | number, optionView: string | number }[];
}


@Component({
  selector: 'app-recursive-form',
  templateUrl: './recursive-form.component.html',
  styleUrls: ['./recursive-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecursiveFormComponent implements OnInit, OnChanges, OnDestroy {

  private _initialised: boolean;

  /**
   * Internal use. Counter to keep track of the created components for stepper.
   */
  public _recursiveCounter = 0;

  public _changeDetectorFormSource = new Subject<null>();
  private _stepperResetSource = new Subject<null>();

  /**
   * Observable that emits if change is detected within the forms. For internal usage.
   */
  public _changeDetectorForm = this._changeDetectorFormSource.pipe(debounceTime(0));
  /**
   * Internal observable to reset the stepper. For internal usage.
   */
  public _stepperReset = this._stepperResetSource.pipe(debounceTime(0));

  // Variables
  public form: FormGroup = new FormGroup({});

  private formChangeSub: Subscription;


  @Input() private formObject: { [prop: string]: any };
  @Input() public filter: string[];
  @Input() public stepLayers: number;
  @Input() public disabledForm: boolean;
  @Input() public disabledFields: string[];
  @Input() public readOnlyFields: string[];
  @Input() public validators: Validator[];
  @Input() public allRequired: boolean;
  @Input() public readonly: boolean;
  @Input() public autoCompleteList: AutoCompleteList;
  @Input() public languageKeys: LanguageKeys;
  @Input() public explanationFields: LanguageKeys;
  @Input() public optionSelection: OptionsSelection;
  @Input() public fieldSuffixes: { [key: string]: string };

  @Output() public validated = new EventEmitter<boolean>();
  @Output() public formOutput = new EventEmitter<{ [prop: string]: any }>();

  /**
   * Force form validation
   */
  public validateForm() {

    const recursiveMarkAsTouched = (form: AbstractControl) => {
      form.markAsTouched();
      form.markAsDirty();
      form.updateValueAndValidity();

      if (form instanceof FormGroup) {
        Object.keys(form.controls).forEach(x => {
          form.controls[x].markAsTouched();
          form.controls[x].markAsDirty();

          if (form.controls[x] instanceof FormArray) {
            (form.controls[x] as FormArray).controls.forEach(y => { recursiveMarkAsTouched(y); });
          }

          if (form.controls[x] instanceof FormGroup) { recursiveMarkAsTouched(form.controls[x]); }

          if (form.controls[x] instanceof FormControl) {
            form.controls[x].markAsTouched();
            form.controls[x].markAsDirty();
            form.controls[x].updateValueAndValidity();
          }
        });
      }

      if (form instanceof FormArray) { form.controls.forEach(x => recursiveMarkAsTouched(x)); }
    };

    recursiveMarkAsTouched(this.form);
    this.changeDetector.detectChanges();
    this._changeDetectorFormSource.next(null);
  }

  /**
   * Force a reset of the validators
   */
  public resetValidation() {

    const resetForm = (form: AbstractControl) => {
      form.reset();

      if (form instanceof FormGroup) {
        Object.keys(form.controls).forEach(x => {
          form.controls[x].reset();

          if (form.controls[x] instanceof FormArray) {
            (form.controls[x] as FormArray).controls.forEach(y => { resetForm(y); });
          }

          if (form.controls[x] instanceof FormGroup) { resetForm(form.controls[x]); }

          if (form.controls[x] instanceof FormControl) { form.controls[x].reset(); }
        });
      }

      if (form instanceof FormArray) { form.controls.forEach(x => resetForm(x)); }
    };

    resetForm(this.form);
    this._changeDetectorFormSource.next(null);
  }

  /**
   * Reset the stepper to it's initial state. Note that this clears form data.
   */
  public resetStepper() { this._stepperResetSource.next(null); }

  // Form methods
  private buildNewForm() {
    const baseObject = this.formObject;

    delete baseObject['_17_4'];

    const form = new FormGroup({});
    const filter = this.filter ? this.filter : [];
    const disabledForm = this.disabledForm ? this.disabledForm : false;
    const disabledFields = this.disabledFields ? this.disabledFields : [];
    const readonly = this.readonly ? this.readonly : false;
    const validators = this.validators ? this.validators : [];

    const validatorsToUse = (
      input: any, isFiltered: string | undefined, filtered: boolean | undefined, key: string | undefined, parentKey: string | undefined
    ) => {
      let validatorsBuild: ValidatorFn[] = [];

      const requiredAll = this.allRequired;
      const validatorsInput = validators.find(x => {
        if (parentKey && x.parentKey) { return x.keyName === key && x.parentKey === parentKey; }
        return x.keyName === key;
      });
      const optionsInput = Object.keys(this.optionSelection || {}).some(x => x === key);

      const isNumber = typeof input === 'number';

      if (requiredAll) { validatorsBuild.push(Validators.required); }
      if ((requiredAll || validatorsInput) && isNumber && !optionsInput) { validatorsBuild.push(zeroNumberValidator()); }
      if (validatorsInput && validatorsInput.validators) {
        validatorsBuild = validatorsBuild.concat(validatorsInput.validators);
      }
      if (Array.isArray(input)) {
        validatorsBuild.push(arrayLengthValidator());
      }
      if (key && this.autoCompleteList && this.autoCompleteList[key] && !Array.isArray(input)) {
        validatorsBuild.push(optionsValidator(this.autoCompleteList[key]));
      }

      if (isFiltered || filtered) { validatorsBuild = []; }
      if (disabledForm || readonly) { validatorsBuild = []; }

      return validatorsBuild;
    };

    const asyncValidatorsToUse = (
      isFiltered: string | undefined, filtered: boolean | undefined, key: string | undefined, parentKey: string | undefined
    ) => {
      let validatorsBuild: AsyncValidatorFn[] = [];

      const validatorsInput = validators.find(x => {
        if (parentKey && x.parentKey) { return x.keyName === key && x.parentKey === parentKey; }
        return x.keyName === key;
      });

      if (validatorsInput && validatorsInput.validatorsAsync) {
        validatorsBuild = validatorsBuild.concat(validatorsInput.validatorsAsync);
      }
      if (isFiltered || filtered) { validatorsBuild = []; }
      if (disabledForm || readonly) { validatorsBuild = []; }

      return validatorsBuild;
    };

    // Build the form
    const recursiveFormBuild = (
      input: any,
      inputForm: FormGroup | FormArray,
      key?: string | undefined,
      parentKey?: string,
      filtered?: boolean | undefined,
    ) => {
      const isFiltered = filter.find(z => z === key);

      if (typeof input === 'object' &&
        input !== null &&
        Object.keys(input).length !== 0 &&
        !Array.isArray(input)
      ) {

        const keys = Object.keys(input);

        if (key && inputForm instanceof FormGroup) {
          inputForm.addControl(key, new FormGroup({}));

          keys.forEach(x => {
            recursiveFormBuild(input[x], key ? inputForm.get(key) as FormGroup : inputForm, x, key, isFiltered ? true : filtered);
          });

        } else if (key && inputForm instanceof FormArray) {
          inputForm.push(new FormGroup({}));
          const index = inputForm.controls.length - 1;

          keys.forEach(x => {
            recursiveFormBuild(input[x], key ? inputForm.controls[index] as FormGroup : inputForm, x, key, isFiltered ? true : filtered);
          });

        } else {
          keys.forEach(x => {
            recursiveFormBuild(input[x], inputForm, x, key, isFiltered ? true : filtered);
          });
        }

      } else if (Array.isArray(input) && inputForm instanceof FormGroup && key) {
        inputForm.addControl(key, new FormArray([], {
          validators: validatorsToUse(input, isFiltered, filtered, key, parentKey),
          asyncValidators: asyncValidatorsToUse(isFiltered, filtered, key, parentKey),
          updateOn: 'change'
        }));

        input.forEach(x => {
          recursiveFormBuild(x, inputForm.get(key) as FormArray, key, key, isFiltered ? true : filtered);
        });

      } else { // Must be control

        const isDisabled = (_key: string | undefined) => {
          let disabled = false;
          if (_key && disabledFields.includes(_key)) {
            disabled = true;
          }
          else {
            if (disabledForm) { disabled = true; }
            if (readonly && typeof input === 'boolean') { disabled = true; }
            if (readonly && Object.keys(this.optionSelection || {}).some(x => x === key)) { disabled = true; }
          }

          return disabled;
        };

        const addControl = () => {
          const formControl = new FormControl(
            { value: input, disabled: isDisabled(key) },
            {
              validators: validatorsToUse(
                input, isFiltered, filtered, key, parentKey
              ),
              asyncValidators: asyncValidatorsToUse(
                isFiltered, filtered, key, parentKey
              ),
              updateOn: 'change'
            },
          );

          if (inputForm instanceof FormArray && key) {
            inputForm.push(formControl);
          } else if (inputForm instanceof FormGroup && key) {
            inputForm.addControl(key, formControl);
          }

          inputForm.updateValueAndValidity();
        };

        if (key === 'dbRef') { return; }
        addControl();
      }
    };

    recursiveFormBuild(baseObject, form);
    this.form = form;
    // Initial form output to set value on parent on next tick
    setTimeout(() => {
      this.validated.emit(this.form.status === 'VALID' || this.form.status === 'DISABLED' ? true : false);
      this.formOutput.emit(this.form.getRawValue());
    });

    // Keep outputting the form on user input so parent can act on changes
    if (this.formChangeSub) { this.formChangeSub.unsubscribe(); }
    this.formChangeSub = this.form.statusChanges.pipe(
      debounceTime(100)
    ).subscribe(status => {
      if (status !== 'PENDING') {
        this.validated.emit(status === 'VALID' || status === 'DISABLED' ? true : false);
      }
      this.formOutput.emit(this.form.getRawValue());
    });
  }

  // Life cycle
  constructor(
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() { }
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['formObject'] && changes['formObject'].currentValue && !this._initialised
    ) {
      this._recursiveCounter = 0;
      this.buildNewForm();
      this._initialised = true;
      this.changeDetector.detectChanges();
    }
    if ((changes['disabledForm'])) {
      if (this.disabledForm) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    }
  }
  ngOnDestroy() {
    if (this.formChangeSub) { this.formChangeSub.unsubscribe(); }
  }

}

// Recursive component
@Component({
  selector: 'app-recursive-form-recursive',
  templateUrl: './recursive-form-recursive.component.html',
  styleUrls: ['./recursive-form.component.css']
})
export class RecursiveFormRecursiveComponent implements OnInit, OnDestroy, AfterViewInit {

  private subscriptions = new Subscription;

  // Variables
  @Input() public base: FormGroup;
  @Input() public parentKey: string;
  @Input() public filter: string[];
  @Input() public stepLayers: number;
  @Input() public validators: Validator[];
  @Input() public disabledForm: boolean;
  @Input() public disabledFields: string[];
  @Input() public readOnlyFields: string[];
  @Input() public allRequired: boolean;
  @Input() public readonly: boolean;
  @Input() public autoCompleteList: AutoCompleteList;
  @Input() public optionSelection: OptionsSelection;
  @Input() public fieldSuffixes: { [key: string]: string };
  @Input() public languageKeys: LanguageKeys;
  @Input() public explanationFields: LanguageKeys;
  @Input() public _changeDetectorForm: Observable<null>;
  @Input() public _changeDetectorFormSource: Subject<null>;
  @Input() public _stepperReset: Observable<null>;
  @Input() public _recursiveCounter: number;

  @ViewChild('stepper') private stepper: MatStepper;
  @ViewChild('matStep') private matStep: MatStep;

  public filteredOptions = new Subject();

  public isReadOnly(key: string) {
    return this.readOnlyFields && this.readOnlyFields.includes(key);
  }

  public filterOptions(value: string, controlName: string) {

    if (!this.autoCompleteList || !this.autoCompleteList[controlName]) {
      this.filteredOptions.next([]);
      return;
    }
    const options = this.autoCompleteList[controlName];

    if (options) {
      this.filteredOptions.next(this._filter(value, options));
    } else {
      this.filteredOptions.next([]);
    }
  }

  private _filter(value: string, options: string[]): string[] {
    const filterValue = value.toLowerCase();
    return options.filter(option => option.toLowerCase().includes(filterValue));
  }

  // Methods
  public objectKeysOfFormGroup(formGroup: FormGroup) {
    if (!formGroup.controls) { return []; }
    return Object.keys(formGroup.controls);
  }

  public formControlTypeIs(formControl: FormControl | FormGroup | FormArray) {
    if (formControl instanceof FormControl) { return 'FormControl'; }
    if (formControl instanceof FormGroup) { return 'FormGroup'; }
    if (formControl instanceof FormArray) { return 'FormArray'; }
    return null;
  }
  public formControlValueIs(formControlValue: FormControl['value']) {
    if (formControlValue instanceof Date) { return 'date'; }
    if (typeof formControlValue === 'string') { return 'string'; }
    if (typeof formControlValue === 'number') { return 'number'; }
    if (typeof formControlValue === 'boolean') { return 'boolean'; }
    return null;
  }

  public removeFromFormArray(formArray: FormArray, index: number) {
    if (formArray.controls.length === 1 && formArray.controls[0] instanceof FormGroup) {
      formArray.controls[0].setValue(defaultValueForTypeOfControl(formArray.controls[0]), { emitEvent: false });
    } else {
      formArray.removeAt(index);
    }

    formArray.updateValueAndValidity();
  }

  public addToFormArray(event: Event, formArray: FormArray, controlName: string) {
    event.stopPropagation();

    const validators: ValidatorFn[] = [];
    if (this.autoCompleteList && this.autoCompleteList[controlName]) {
      validators.push(optionsValidator(this.autoCompleteList[controlName]));
    }

    if (formArray.controls.length > 0 && formArray.controls[0] instanceof FormGroup) {
      const clone = cloneAbstractControl(formArray.controls[0]) as FormGroup;
      for (const key in clone.controls) {
        if (clone.controls.hasOwnProperty(key)) {
          clone.controls[key].setValue(defaultValueForTypeOfControl(clone.controls[key]), { emitEvent: false });
        }
      }
      formArray.push(clone);
    } else {
      formArray.push(new FormControl('',
        { validators: validators, updateOn: 'change' }
      ));
    }
    formArray.updateValueAndValidity();
  }

  public keyExistsInFilter(key: string) {
    const filter = this.filter ? this.filter : [];
    return filter.some(x => {
      return x === key;
    });
  }

  public keyInActiveExplanations(key: string) {
    const explanations = Object.keys(this.explanationFields || {});
    return explanations.includes(key);
  }

  public clearDate(control: AbstractControl, dateInput: any) {
    control.setValue(new Date(''));
    dateInput.value = '';
  }

  // Helper methods
  public validateForm() {
    const recursiveMarkAsTouched = (form: AbstractControl) => {
      form.markAsTouched();
      form.updateValueAndValidity();

      if (form instanceof FormGroup) {
        Object.keys(form.controls).forEach(x => {
          form.controls[x].markAsTouched();
          form.controls[x].updateValueAndValidity();

          if (form.controls[x] instanceof FormArray) {
            (form.controls[x] as FormArray).controls.forEach(y => { recursiveMarkAsTouched(y); });
          }

          if (form.controls[x] instanceof FormGroup) { recursiveMarkAsTouched(form.controls[x]); }

          if (form.controls[x] instanceof FormControl) { form.controls[x].markAsTouched(); }
        });
      }

      if (form instanceof FormArray) { form.controls.forEach(x => recursiveMarkAsTouched(x)); }
      this.changeDetector.detectChanges();
    };

    recursiveMarkAsTouched(this.base);
    this.recursiveFormService.recursiveRemoveMatDateError(this.base);
    this.matStep.interacted = false;
    this.changeDetector.detectChanges();
    this._changeDetectorFormSource.next(null);
  }

  private addValidatorCheckToStep(object: MatStep) {
    const oldSelect = object.select;
    object.select = () => {
      const oldStep = this.stepper.selected;
      oldSelect.call(object);
      if (oldStep === this.stepper.selected && !this.stepper.selected.completed) {
        this.validateForm();
      }
    };
  }

  public lastOfArray(array: string[], i: number) {
    const filter = this.filter ? this.filter : [];
    const filtered = array.filter(x => !filter.some(a => a === x));
    return i !== filtered.length - 1;
  }

  public detectChanges(control: AbstractControl) {
    control.updateValueAndValidity();
    this.changeDetector.detectChanges();
  }

  public trackBy(index: number) { return index; }

  // Life cycle
  constructor(
    private changeDetector: ChangeDetectorRef,
    public recursiveFormService: RecursiveFormService
  ) { }

  ngOnInit() {
    this._recursiveCounter++;

    const detectChangesSub = this._changeDetectorForm.subscribe(() => {
      this.recursiveFormService.recursiveRemoveMatDateError(this.base);
      this.changeDetector.detectChanges();
    });
    const resetStepperSub = this._stepperReset.subscribe(() => { if (this.stepper) { this.stepper.selectedIndex = 0; } });

    this.subscriptions.add(detectChangesSub);
    this.subscriptions.add(resetStepperSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    // Hack to validate form if step is not completed
    if (this.stepper) {
      this.stepper._steps.forEach(step => this.addValidatorCheckToStep(step));
    }

    this.recursiveFormService.recursiveRemoveMatDateError(this.base);
  }

}

