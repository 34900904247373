import { Pipe, PipeTransform } from '@angular/core';
import { Hnumber } from '../../classes/h-and-p.class';

@Pipe({
  name: 'orderHazards'
})
export class OrderHazardsPipe implements PipeTransform {

  transform(value: { key: string, value: { [prop: string]: Hnumber[] } }[]): any[] {

    if (!Array.isArray(value)) { return value; }

    value.sort((a, _) => {
      switch (a.key) {
        case 'hazard_statements': return -50;
        case 'additional_hazard_info': return -40;
        case 'precautionary_measures': return -30;

        default: return 0;
      }
    });

    return value;
  }

}
