import { Pipe, PipeTransform } from '@angular/core';
// import { IsPbmPipe } from './is-pbm.pipe';
// import { LanguageClass } from '../../../classes/language.class';

@Pipe({
  name: 'pmbColToImage'
})
export class PmbColToImagePipe implements PipeTransform {

  // private map: Object = {
  //   'gelaatsBescherming': 'M013_Gelaatsbescherming verlicht.png',
  //   'nooddoucheOogspoeling': 'ISO_7010_E011.png',
  //   'hoofdBescherming': 'M014_Veiligheidshelm verplicht.png',
  //   'gasDetectors': 'M048_Gebruik gasdetector.png',
  //   'voetBescherming': 'M008_Veiligheidsschoenen verplicht.png',
  //   'valbeveiliging': 'M018_Individueel veiligheidsharnas verplicht.png',
  //   'ademhalingsBescherming2': 'M016_(lichte) ademhalingsbesch verplicht.png',
  //   'handBescherming': 'M009_Veiligheidshandschoenen verplicht.png',
  //   'kleding': 'M010_Beschermkledij verplicht.png',
  //   'gehoorBescherming': 'M003_Gehoorbescherming.png',
  //   'ademhalingsBescherming0': 'M017_Ademhalingsbescherming.png',
  //   'ademhalingsBescherming1': 'M047_Autonoom ademhalingstoestel.png'
  // };

  constructor(
    // private isPbmPipe: IsPbmPipe
  ) { }

  // transform(colName: string, colValue: LanguageClass, _row: any): string {
  // if (this.isPbmPipe.transform(colName) !== '' && colValue.english !== '' && this.map.hasOwnProperty(colName)) {
  //   return 'assets/images/pbm/' + this.map[colName];
  // }
  // return '';

  transform(data1: any, data2: any) {

    if (data1.key) {
      if (data2[data1.key]) {
        if (data2[data1.key].english) {
          return true;
        }
      }
    }

    return false;
  }
}
