import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LazyScriptsService {

  private scriptStore: { [prop: string]: string } = {
    gapi: 'https://apis.google.com/js/api.js',
    html2pdf: 'assets/scripts/html2pdf.bundle.min.js'
  };

  private scripts: { [prop: string]: { loaded: boolean, src: string } } = {};

  public loadScript(name: string) {
    return new Promise(resolve => {

      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
      else {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;

        script.onload = () => {
          this.scripts[name].loaded = true;
          resolve({ script: name, loaded: true, status: 'Loaded' });
        };
        script.onerror = () => resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }

  constructor() {
    Object.keys(this.scriptStore).forEach((key) => {
      this.scripts[key] = {
        loaded: false,
        src: this.scriptStore[key]
      };
    });
  }

}
