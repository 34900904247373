import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatExpansionPanel, MatSidenav } from '@angular/material';
import { RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { env } from '../../../environment';
import { ConfigService } from '../config.service';
import { UsersService } from '../services/users.service';
import { notNullOrUndefined } from '../_shared/_services/app.service';
import { fadeAnimation } from './animations.service';
import { OverflowHideDirective } from './overflow-hide.directive';
import { SidenavService } from './sidenav.service';
import { SidenavContent } from './sidenav.types';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css'],
  animations: [fadeAnimation()],
})
export class SidenavComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription;

  // Get elements
  @ViewChild('drawer') private drawer: MatSidenav;
  @ViewChild('expPageNav') private expPageNav: MatExpansionPanel;
  @ViewChild('navInView') private navInView: OverflowHideDirective;
  @ViewChildren('expRouteNav') private expRoutedNav: QueryList<MatExpansionPanel>;

  public env = env;

  public appTitle = 'Safety Data Sheets - Air Liquide';
  public version = ConfigService.version;

  // Sidenav content
  public pageNav: SidenavContent[] = [{
    title: 'navigation',
    type: 'routerLink',
    items: [
      { path: '/map', label: 'map' },
      { path: '/all-products', label: 'all_products' },
      { path: '/plant-products', label: 'plant_products' },
      { path: '/useful-links', label: 'useful_links' },
    ]
  }];

  // Variables
  public sidenavContent: SidenavContent[];
  public isLoggedIn = false;
  public showMenuIcon = true;
  public isHandset: boolean;
  public isAdmin: boolean;

  // Methods
  public onRouterActivate() {
    const sidenavContent = document.getElementById('sidenav-content');
    if (!sidenavContent) { return; }

    sidenavContent.scrollTo({ top: 0, });
  }

  public scrollTo(element: string) {
    if (!element) { throw new Error('Could not find element'); }
    if (this.isHandset) { this.drawer.close(); }
    this.sidenavService.scrollIntoView(element);
  }

  public getState(outlet: RouterOutlet) { return outlet.activatedRouteData.state; }

  private observeBreakpoints() {
    const breakpointObserver = this.breakpointObserver.observe(Breakpoints.Handset)
      .pipe(map(result => result.matches)).subscribe((matches) => {
        this.isHandset = matches;
      });
    this.subscriptions.add(breakpointObserver);
  }

  // Sidenav options
  private sideNavContentChange() {
    const subscription = this.sidenavService.sidenavContent$.subscribe(sidenavPassedContent => {
      setTimeout(() => {
        this.sidenavContent = sidenavPassedContent;
      });
    });
    this.subscriptions.add(subscription);
  }

  private toggleSidenav() {
    const subscription = this.sidenavService.sidenavToggle$.subscribe(sidenavToggle => {

      if (!this.isHandset) {
        switch (sidenavToggle) {
          case 'open': this.drawer.open(); break;
          case 'close': this.drawer.close(); break;
          case 'toggle': this.drawer.toggle(); break;
          default: this.drawer.open();
        }
      } else { this.drawer.close(); }
    });
    this.subscriptions.add(subscription);
  }

  private toggleExpansions() {
    const subscription = this.sidenavService.expansionToggle$.subscribe(expansionToggle => {

      if (expansionToggle === 'open') {
        this.expRoutedNav.forEach((child) => { child.open(); return; });
      }
      if (expansionToggle === 'close') {
        this.expRoutedNav.forEach((child) => { child.close(); return; });
      }
      if (expansionToggle === 'toggle') {
        this.expRoutedNav.forEach((child) => { child.toggle(); return; });
      }
      if (expansionToggle === 'home' || this.isHandset) {
        this.expPageNav.open();
      }
    });
    this.subscriptions.add(subscription);
  }

  private toggleMenuIcon() {
    const subscription = this.sidenavService.showMenuIcon$.subscribe(showMenuIcon => {
      setTimeout(() => {
        this.showMenuIcon = showMenuIcon;
      });
    });
    this.subscriptions.add(subscription);
  }

  private checkToOpenDrawerOnResize() {
    const subscription = this.navInView.isInView$.subscribe(inView => {
      if (inView && !this.showMenuIcon) { this.drawer.open(); }
    });

    this.subscriptions.add(subscription);
  }

  // Fix tabs underline positioning
  public triggerWindowResize() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 200);
  }

  // Life cycle
  constructor(
    private breakpointObserver: BreakpointObserver,
    private sidenavService: SidenavService,
    private userService: UsersService
  ) { }

  ngOnInit() {
    this.observeBreakpoints();
    this.sideNavContentChange();
    this.toggleSidenav();
    this.toggleExpansions();
    this.toggleMenuIcon();
    this.checkToOpenDrawerOnResize();

    this.sidenavService.passExpansionToggle('home');
    this.sidenavService.passMenuIconToggle(true);
    this.sidenavService.passSidenavToggle('close');

    this.userService.currentUser$.pipe(filter(notNullOrUndefined), take(1)).subscribe(user => {
      const isAdmin = user.isAdmin;
      if (isAdmin) { this.pageNav[0].items.push({ path: '/admin', label: 'admin' }); }
      if (user.isDeveloper) {
        this.pageNav[0].items = [
          ...this.pageNav[0].items,
          { path: '/admin', label: 'Admin' },
          { path: '/test-functions', label: 'Test functions' }
        ];
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
