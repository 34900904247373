import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { UsefulLinksService } from '../services/useful-links.service';

@Component({
  selector: 'app-useful-links',
  templateUrl: './useful-links.component.html',
  styleUrls: ['./useful-links.component.css'],
})

export class UsefulLinksComponent {

  sections$ = this.usefulLinksService.usefulLinksPerSectionForLanguage$.pipe(
    map(sections => sections.sort((a, b) => (a.orderId < b.orderId) ? -1 : 0))
  );

  constructor(
    private usefulLinksService: UsefulLinksService
  ) { }

}
