import { Pipe, PipeTransform } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductService } from '../../../services/product.service';
import { UsersService } from '../../../services/users.service';

@Pipe({
  name: 'hazardTooltip'
})
export class HazardTooltipPipe implements PipeTransform {

  constructor(
    private productService: ProductService,
    private userService: UsersService
  ) { }

  transform(value: any): Observable<string> {

    return combineLatest(
      this.productService.hazardKeys$,
      this.userService.currentUser$
    ).pipe(
      map(([keys, user]) => {
        const findKey = keys.find(x => x.key === value);
        if (!findKey) { throw new Error('Key not found for HAZARD tooltip'); }
        if (findKey.tooltip[user.language]) {
          return findKey.tooltip[user.language];
        } else if (findKey.tooltip.english) {
          return findKey.tooltip.english;
        } else {
          return findKey.key;
        }
      })
    );

  }

}
