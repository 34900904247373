import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';
import { filter, flatMap, shareReplay, switchMap, take } from 'rxjs/operators';
import { UserDbServer } from '../../../server/src/routes/user/types';
import { CmrDb } from '../classes/cmr.class';
import { HnumberDb, PnumberDb } from '../classes/h-and-p.class';

import { KeyDb, TranslationDb } from '../classes/language.class';
import { LocationDb } from '../classes/location.class';
import { PbmOptionDb } from '../classes/pbm.class';
import { ProductDb } from '../classes/product.class';
import { ThsDb } from '../classes/risk.class';
import { SevesoDb } from '../classes/seveso.class';
import { UnSubstanceCategoryDb } from '../classes/un.class';
import { UsefulLinksLinkDb, UsefulLinksSectionDb } from '../classes/useful-links.class';
import { ZzsDb } from '../classes/zzs.class';
import { SnackbarComponent } from '../_shared/_components/snackbar/snackbar.component';
import { notNullOrUndefined } from '../_shared/_services/app.service';
import { ApiService } from './api.service';
import { FireAuthService } from './fireAuth.service';
import { ClassificationDb } from '../classes/classifications.class';
import { PzzsDb } from '../classes/pzzs.class';

interface ExportData {
  serverTimestamp: firestore.FieldValue;
  headers: string[];
  docRef?: string;
  collectionRef?: string;
}

@Injectable({
  providedIn: "root",
})
export class DatabaseService {
  // Collections / Docs
  private userCollection = this.database.collection<UserDbServer>("users");
  private productCollection = this.database.collection<ProductDb>("products");
  private keyCollection = this.database.collection<{ [prop: string]: KeyDb[] }>(
    "keys"
  );
  private locationCollection =
    this.database.collection<LocationDb>("locations");
  private zzsCollection = this.database.collection<ZzsDb>("zzs");
  private pzzsCollection = this.database.collection<PzzsDb>("pzzs");
  private sevesoCollection = this.database.collection<SevesoDb>("seveso");
  private unSubstanceCategoryCollection =
    this.database.collection<UnSubstanceCategoryDb>("unSubstanceCategory");
  private cmrCollection = this.database.collection<CmrDb>("cmr");
  private hNumberCollection = this.database.collection<HnumberDb>("hNumbers");
  private pNumberCollection = this.database.collection<PnumberDb>("pNumbers");
  private translationCollection =
    this.database.collection<TranslationDb>("translations");
  private thsDoc = this.database.collection("ths").doc<ThsDb>("ths");
  private pbmOptionsCollection =
    this.database.collection<PbmOptionDb>("pbmOptions");
  private usefulLinksLinkCollection = this.database
    .collection("usefullLinks")
    .doc<{ [section: string]: UsefulLinksLinkDb[] }>("links");
  private usefulLinksSectionCollection = this.database
    .collection("usefullLinks")
    .doc<{ [section: string]: UsefulLinksSectionDb }>("sections");
  private adminDoc = this.database
    .collection("authentication")
    .doc<{ admin: string[] }>("userAuth");

  private exportDataCollection =
    this.database.collection<ExportData>("exportData");

  private classificationsCollection =
    this.database.collection<ClassificationDb>("classifications");

  private productLogCollection = this.database.collection<any>("logEntries");

  // Observables
  public users$ = this.userCollection.valueChanges();
  public products$ = this.productCollection.valueChanges();
  public keys$ = this.keyCollection.valueChanges();
  public locations$ = this.locationCollection.valueChanges();
  public zzsList$ = this.zzsCollection.valueChanges();
  public pzzsList$ = this.pzzsCollection.valueChanges();
  public sevesos$ = this.sevesoCollection.valueChanges();
  public unSubstanceCategories$ =
    this.unSubstanceCategoryCollection.valueChanges();
  public cmrs$ = this.cmrCollection.valueChanges();
  public hNumbers$ = this.hNumberCollection.valueChanges();
  public pNumbers$ = this.pNumberCollection.valueChanges();
  public translations$ = this.translationCollection.valueChanges();
  public thsDoc$ = this.thsDoc.valueChanges();
  public pbmOptions$ = this.pbmOptionsCollection.valueChanges();
  public usefulLinksLinks$ = this.usefulLinksLinkCollection.valueChanges();
  public usefulLinksSections$ =
    this.usefulLinksSectionCollection.valueChanges();
  public adminDoc$ = this.adminDoc.valueChanges();

  public classification$ = this.classificationsCollection.valueChanges();
  public currentUser$ = this.auth.user$.pipe(
    filter(notNullOrUndefined),
    flatMap(async (fireUser) => {
      const userDocExists = await this.userCollection
        .doc<UserDbServer>(fireUser.uid)
        .get()
        .pipe(take(1))
        .toPromise()
        .then((snap) => snap.exists);
      if (!userDocExists) {
        await this.api.createNewUser(fireUser);
      }
      return fireUser;
    }),
    switchMap((fireUser) =>
      this.userCollection.doc<UserDbServer>(fireUser.uid).valueChanges()
    ),
    shareReplay(1)
  );

  public async getRelevantLogData(productDbRef: string) {
    const data = await this.productLogCollection.ref
      .where("productRef", "==", productDbRef)
      .get();
    const resultArr: any[][] = [[]];
    data.docs.forEach((logEntry) => {
      resultArr.push([logEntry.data()]);
    });
    return resultArr;
  }

  // Export to sheets
  public async addExportToDatabase(dataSet: string[][], headers: string[]) {
    const dbObject = {
      serverTimestamp: firestore.FieldValue.serverTimestamp(),
      headers,
    };

    return this.exportDataCollection
      .add(dbObject)
      .then(async (docRef) => {
        await Promise.all([
          docRef.update({ docRef: docRef.path }),
          docRef.update({ collectionRef: docRef.collection("data").path }),
        ]);
        return docRef;
      })
      .then(async (docRef) => {
        const batchArray: firestore.WriteBatch[] = dataSet.reduce(
          (acc: firestore.WriteBatch[], x, i) => {
            const accIndex = Math.floor(i / 500);
            if (!acc[accIndex]) {
              acc[accIndex] = this.database.firestore.batch();
            }

            const data = JSON.parse(
              JSON.stringify(
                x.reduce((obj, value, j) => ({ ...obj, [j]: value }), {})
              )
            );
            acc[accIndex].set(docRef.collection("data").doc(), data);

            return acc;
          },
          []
        );

        await Promise.all(batchArray.map((x) => x.commit())).catch((error) =>
          this.errorHandler(error)
        );

        return docRef.get();
      })
      .then(async (snapshot) => snapshot.data() as ExportData)
      .catch((error) => {
        this.errorHandler(error);
      });
  }

  public async deleteExportToDatabase(dbPath: string) {
    let docRef: firestore.DocumentReference;

    if (dbPath.includes("/")) {
      docRef = this.exportDataCollection.doc(dbPath.split("/")[1]).ref;
    } else {
      docRef = this.exportDataCollection.doc(dbPath).ref;
    }

    await docRef
      .collection("data")
      .get()
      .then(async (data) => {
        const batchArray: firestore.WriteBatch[] = data.docs.reduce(
          (acc: firestore.WriteBatch[], x, i) => {
            const accIndex = Math.floor(i / 500);
            if (!acc[accIndex]) {
              acc[accIndex] = this.database.firestore.batch();
            }

            acc[accIndex].delete(x.ref);

            return acc;
          },
          []
        );

        await Promise.all(batchArray.map((x) => x.commit())).catch((error) =>
          this.errorHandler(error)
        );
      })
      .catch((error) => this.errorHandler(error));

    return docRef.delete().catch((error) => this.errorHandler(error));
  }

  // ---------- Helper methods --------------
  private errorHandler(error: firebase.firestore.FirestoreError | Error) {
    this.snackbar.snackbarError(error.message);

    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error(error.code ? error.code : "" + ": " + error.message);
    }
  }

  constructor(
    private database: AngularFirestore,
    private auth: FireAuthService,
    private snackbar: SnackbarComponent,
    private api: ApiService
  ) {}
}
