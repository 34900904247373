import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { Key } from '../../../classes/language.class';
import { ProductService } from '../../../services/product.service';

@Component({
  selector: 'app-keys-table',
  templateUrl: './keys-table.component.html',
  styleUrls: ['./keys-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeysTableComponent implements OnInit, OnChanges, OnDestroy {

  private _subs = new Subscription();

  // Variables
  @Input() public keysInput: Key[];
  @Input() public editable: boolean;
  @Input() public hideCheckboxes: boolean;
  @Input() public hideOrder: boolean;
  @Input() public isProductKeys: boolean;
  @Input() private subKey: string;

  public dataSource: MatTableDataSource<Key>;
  public displayedColumns: string[];
  public keys: Key[];
  private productKeys: Key[] = [];
  private clonedKeys: Key[];

  public headerTooltipPosition = 'above';
  public headerTooltipShowDelay = '500';

  private _headers: (keyof Key)[] = ['visible', 'key', 'english', 'dutch', 'french', 'german'];

  @Output() public updatedKeys = new EventEmitter<Key[]>();
  @Output() public orderChange = new EventEmitter<Key[]>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  // Table Methods
  private _updateClonedKeys() {
    this.clonedKeys = this.clonedKeys.map((x, i) => {
      if (this.subKey) {
        x[this.subKey] = this.dataSource.data[i];
      } else {
        x = this.dataSource.data[i];
      }
      return x;
    });
  }

  public saveData() {
    this._updateClonedKeys();
    this.updatedKeys.emit(this.clonedKeys);
  }

  public moveItemDown(row: Key) {
    const array = this.dataSource.data;

    const index = array.findIndex(x => x.key === row.key);
    if (index === -1) { return; }

    const newArray = this.swapIndexArray(array, index + 1, index);
    this.dataSource.data = newArray;
  }

  public moveItemUp(row: Key) {
    const array = this.dataSource.data;

    const index = array.findIndex(x => x.key === row.key);
    if (index === -1) { return; }

    const newArray = this.swapIndexArray(array, index, index - 1);
    this.dataSource.data = newArray;
  }

  public isProductKey(key: Key) {
    return this.productKeys.some(productKey => productKey.key === key.key);
  }

  // Helper methods
  public swapIndexArray(array: any[], indexA: number, indexB: number) {
    if (indexA === 0 || indexB === array.length - 1) { return array; }
    const temp = array[indexA];
    array[indexA] = array[indexB];
    array[indexB] = temp;

    this._updateClonedKeys();
    this.orderChange.emit(this.clonedKeys);
    return array;
  }

  // Life cycle
  constructor(
    private productService: ProductService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.pageSize = 50;

    const productKeysSub = this.productService.keys$.subscribe(keys => {
      this.productKeys = keys['product'];
    });

    this._subs.add(productKeysSub);
  }

  ngOnChanges() {
    if (this.keysInput) {
      if (this.subKey) {
        this.dataSource.data = this.keysInput.map(x => ({ key: x.key, ...x[this.subKey] }));
      } else {
        this.dataSource.data = this.keysInput;
      }
      this.clonedKeys = cloneDeep(this.keysInput);
    }
    this.displayedColumns = [...this._headers];
    if (this.hideCheckboxes) { this.displayedColumns.shift(); }
    if (!this.hideOrder) { this.displayedColumns.unshift('order'); }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

}
