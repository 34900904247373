import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transFormValue'
})
export class TransFormValuePipe implements PipeTransform {

  transform(value: any, key: string): any {
    switch (key) {
      case 'maximumStockKG': { return `${value} Kg`; }
      case 'maximumStockL': { return `${value} L`; }
      default: { return value; }
    }
  }

}
